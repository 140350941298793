import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";

// #region Section 1

export const StockMatchSection1StockList = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.Stock.List} className={classes.heading}>
                Stock
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.Stock.List}>
                <div className={classes.main}>
                    Stock list
                    <span className="triangle"></span>
                </div>
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.Stock.MaterialList} className={classes.prev}>
                Master materials
            </Link>
        </div>
    );
};

export const StockMatchSection1MaterialList = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.Stock.List} className={classes.heading}>
                Stock
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.Stock.List} className={classes.prev}>
                Stock list
            </Link>
            <Link component={RouterLink} to={AppUrls.Client.Stock.MaterialList}>
                <div className={classes.main}>
                    Master materials
                    <span className="triangle"></span>
                </div>
            </Link>
        </div>
    );
};

// #endregion Section 1

// #region Section 2

export const StockMatchSection2StockActions = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    plusSign: any,
): any => {
    return (
        <>
            <div className={classes.secondary}>
                <div onClick={viewModel.onRaiseNewPO}>
                    <img src={plusSign} className="add-image" alt="Add purchase order" />
                    <span className="add-text">Add purchase order</span>
                </div>
            </div>
        </>
    );
};

export const StockMatchSection2MaterialActions = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    plusSign: any,
): any => {
    return (
        <>
            <div className={classes.secondary}>
                <div onClick={viewModel.onAddNewMaterial}>
                    <img src={plusSign} className="add-image" alt="Add new material" />
                    <span className="add-text">Add new material</span>
                </div>
            </div>
        </>
    );
};

// #endregion Section 2

import { getHistory, FieldType, isEmptyOrWhitespace, ViewModelBase, ApiResult, isNullOrEmpty } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    VariationDocumentDTO,
    VariationAndRelatedResponseDTO,
    VariationModel,
    VariationNoteTypeDTO,
    VariationNoteTypeEnum,
    VariationRelatedResponseDTO,
    VariationStatusDTO,
    VariationStatusEnum,
    VariationDocumentTypeDTO,
    UpsertVariationAndRelatedRequestDTO,
} from "./VariationModel";
import { VariationCategoryViewModel } from "./CategoryGrid/VariationCategoryViewModel";
import { VariationItemDTO, VariationItemModel } from "./CategoryGrid/VariationItemModel";
import { VariationItemViewModel } from "./CategoryGrid/VariationItemViewModel";
import { PackagesViewModel } from "Views/Project/Commercial/PackagesViewModel";
import { VariationSubCategoryDTO, VariationSubCategoryModel } from "./CategoryGrid/VariationSubCategoryModel";
import { VariationSubCategoryViewModel } from "./CategoryGrid/VariationSubCategoryViewModel";
import { VariationCategoryDTO, VariationCategoryModel } from "./CategoryGrid/VariationCategoryModel";
import { formatCurrencyFromPounds, formatVariationNumber } from "Utils/Format";
import { VariationItemToAdd } from "./CategoryGrid/VariationLineModalModel";
import { VariationNoteViewModel } from "./Notes/VariationNoteViewModel";
import { VariationNoteDTO, VariationNoteModel } from "./Notes/VariationNoteModel";
import { VariationApprovalPanelViewModel } from "../VariationApprovalPanelViewModel";
import { ApprovalStatusBaseDTO } from "Globals/Models/ApprovalPanelModelBase";
import { NotificationBarViewModel } from "Components/NotificationBar/NotificationBarViewModel";
import { generateOrIncrementRevision } from "Utils/GenerateOrIncrementRevision";
import { csvAxiosRequestConfig, exportPDFWithGet } from "Utils/Utils";

export class VariationViewModel extends ViewModelBase<VariationModel> {
    // #region Constructors and Disposers

    constructor(id: string | null, ieId: string | null) {
        super(new VariationModel());
        this.setDecorators(VariationViewModel);
        this.model.id = id;
        this.model.ieId = ieId;

        this.packagesViewModel.apiGetRelated(ieId).then(() => {
            isEmptyOrWhitespace(this.model.id) ? this.loadRelated() : this.loadWithRelated();
        });
    }

    private packagesViewModel = PackagesViewModel.Instance;

    // #region Properties

    @observable
    public approvalPanelViewModel: VariationApprovalPanelViewModel = new VariationApprovalPanelViewModel();

    @observable
    public showSendForApprovalModal: boolean = false;

    @observable
    public isViewOnly: boolean = false;

    @observable
    public isInReviseMode: boolean = false;

    @computed
    public get getIsViewOnly(): boolean {
        return this.isViewOnly;
    }

    @action
    public setIsViewOnly = (val: boolean) => {
        this.isViewOnly = val;
    };

    @computed
    public get isFormDisabled(): boolean {
        if (this.isViewOnly) {
            return this.isViewOnly;
        }

        if (this.canSaveAsDraft || this.canAmendVariation || this.getIsInReviseMode) {
            return false;
        }

        return true;
    }

    @computed
    public get getIsInReviseMode() {
        return this.isInReviseMode;
    }

    @action
    public setIsInReviseMode = async (val: boolean) => {
        await this.getCanReviseVariation(val);
    };

    public getCanReviseVariation = async (val: boolean): Promise<void> => {
        this.setIsLoading(true);
        let variationId: string | null = this.model.id;

        const result = await this.Post<boolean>(AppUrls.Server.Variation.CanReviseVariation, { id: variationId })
            .then((apiResult) => {
                if (apiResult.errors.length > 0) {
                    if (apiResult.errors[0].message === "Variation is linked to one or more unapproved purchase orders.") {
                        runInAction(() => {
                            this.handleShowUnapprovedPurchaseOrderValidationModalChange(true);
                        });
                    }
                } else {
                    runInAction(() => {
                        this.isInReviseMode = val;
                        this.setIsViewOnly(!val);

                        // Reset client data when revising.
                        this.model.clientApprovalReference = null;
                        this.model.clientNote = null;
                        const documentType = this.variationDocumentTypes.find((d) => d.type === VariationDocumentTypeEnum.Client);
                        if (documentType) {
                            this.variationDocuments.forEach((doc) => {
                                if (doc.variationDocumentTypeId === documentType.id) {
                                    doc.isDeleted = true;
                                }
                            });
                        }
                    });
                }
            })
            .catch((err) => {
                this.setIsLoading(false);
            })
            .finally(() => this.setIsLoading(false));
    };

    @computed
    public get canShowReviseButton(): boolean {
        const approvedStatusId: string = this.approvalPanelViewModel.getApprovedStatusId;
        const isApproved: boolean = this.approvalPanelViewModel.model.approvalStatusId === approvedStatusId;
        const path: string = this.history.location.pathname;
        const isPathValid: boolean = this.model.id !== null && AppUrls.Client.Variation.View.replace(":variationid", this.model.id) === path;
        return isApproved && isPathValid;
    }

    @computed
    public get hasDefaults(): boolean {
        return this.variationCategoryViewModels.some((cat) =>
            cat.variationSubCategoryViewModels.some((subCat) => subCat.variationItemViewModels.some((item) => item.model.isDefaultItem)),
        );
    }

    @observable
    private ieTitle: string = "";

    @action setIETitle = (val: string) => {
        this.ieTitle = val;
    };

    @action setEmployersAgentRecipient = (val: string) => {
        this.model.employersAgentRecipient = val;
    };

    @action setEmployersAgentEmailAddress = (val: string) => {
        this.model.employersAgentEmailAddress = val;
    };

    @action setCostToBeAgreed = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.model.costToBeAgreed = checked;
    };

    @computed
    public get getIETitle(): string {
        return this.ieTitle;
    }

    @computed
    public get canDeleteVariation(): boolean {
        // Can't delete a variation if it has been approved.
        const approvedStatus = this.variationStatuses.find((s) => s.type === VariationStatusEnum.Approved);

        let isApproved = false;

        if (approvedStatus && this.model.variationStatusId === approvedStatus.id) {
            isApproved = true;
        }

        return !isNullOrEmpty(this.model.id) && !isApproved;
    }

    @computed
    public get canSaveAsDraft(): boolean {
        // Can only save a variation as draft if it is already draft, or is a new variation.
        const draftStatus = this.variationStatuses.find((s) => s.type === VariationStatusEnum.Draft);

        let isDraft = false;

        if (draftStatus && this.model.variationStatusId === draftStatus.id) {
            isDraft = true;
        }

        return isDraft || isNullOrEmpty(this.model.variationStatusId);
    }

    @computed
    public get isPendingClientApproval(): boolean {
        const pendingClientApprovalStatus = this.variationStatuses.find((s) => s.type === VariationStatusEnum.PendingClientApproval);

        let isPendingClientApproval = false;

        if (pendingClientApprovalStatus && this.model.variationStatusId === pendingClientApprovalStatus.id) {
            isPendingClientApproval = true;
        }

        return isPendingClientApproval;
    }

    @computed
    public get canEditClientApprovalSection(): boolean {
        const approvedStatus = this.variationStatuses.find((s) => s.type === VariationStatusEnum.Approved);

        let isApproved = false;

        if (approvedStatus && this.model.variationStatusId === approvedStatus.id) {
            isApproved = true;
        }

        return !isApproved;
    }

    /**
     * Get the notes for the client notes section.
     */
    @computed
    public get getClientNotes(): VariationNoteViewModel[] {
        const noteType = this.variationNoteTypes.find((n) => n.type === VariationNoteTypeEnum.Client);

        if (noteType) {
            return this.variationNoteViewModels.filter((n) => n.model.variationNoteTypeId === noteType.id);
        }

        return [];
    }

    /**
     * Get the notes for the internal notes section.
     */
    @computed
    public get getInternalNotes(): VariationNoteViewModel[] {
        const noteType = this.variationNoteTypes.find((n) => n.type === VariationNoteTypeEnum.Internal);

        if (noteType) {
            return this.variationNoteViewModels.filter((n) => n.model.variationNoteTypeId === noteType.id);
        }

        return [];
    }

    /**
     * Get the notes for the Notes/exclusions to Employers Agent.
     */
    @computed
    public get getEmployersAgentNotes(): VariationNoteViewModel[] {
        const noteType = this.variationNoteTypes.find((n) => n.type === VariationNoteTypeEnum.EmployersAgent);
        if (noteType) {
            return this.variationNoteViewModels.filter((n) => n.model.variationNoteTypeId === noteType.id);
        }

        return [];
    }

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get canAmendVariation(): boolean {
        return this.approvalPanelViewModel.getCanShowAmenderPanel;
    }

    @action
    public handleShowSendForApprovalModalChange(show: boolean) {
        this.showSendForApprovalModal = show;

        if (!show) {
            this.setRequesterNote(VariationModel.DEFAULT_REQUESTERNOTE);
        }
    }

    @computed
    public get getShowSendForApprovalModal() {
        return this.showSendForApprovalModal;
    }

    @observable
    public showUnapprovedPurchaseOrderValidationModal: boolean = false;

    @action
    public handleShowUnapprovedPurchaseOrderValidationModalChange(show: boolean) {
        this.showUnapprovedPurchaseOrderValidationModal = show;
    }

    @computed
    public get getShowUnapprovedPurchaseOrderValidationModal() {
        return this.showUnapprovedPurchaseOrderValidationModal;
    }

    @action
    public setRequesterNote = (val: string) => {
        this.model.requesterNotes = val;
    };

    @computed
    public get getRequesterNoteValid() {
        return this.model.requesterNotes !== "" && this.model.requesterNotes !== null && this.model.requesterNotes !== undefined;
    }

    @observable
    public variationStatuses = observable<VariationStatusDTO>([]);

    @observable
    public variationNoteTypes = observable<VariationNoteTypeDTO>([]);

    @observable
    public variationDocumentTypes = observable<VariationDocumentTypeDTO>([]);

    @computed
    public get variationStatus(): VariationStatusDTO | null {
        let result: VariationStatusDTO | undefined = undefined;

        if (this.model.variationStatusId) {
            result = this.variationStatuses.find((u) => u.id === this.model.variationStatusId);
        } else {
            result = this.variationStatuses.find((u) => u.type === VariationStatusEnum.Draft);
        }

        return result ? result! : null;
    }

    @computed
    public get variationStatusName() {
        return this.variationStatus ? this.variationStatus.displayName : "DRAFT";
    }

    @observable
    private variationCategoryViewModels: VariationCategoryViewModel[] = [];

    /**
     * List of the default variation category selections for a variation
     */
    @computed
    public get getDefaultVariationCategoryViewModels(): VariationCategoryViewModel[] {
        return this.variationCategoryViewModels.filter((c) => c.isDefault);
    }

    /**
     * List of the non-default variation category selections for a variation
     */
    @computed
    public get getAdditionalVariationCategoryViewModels(): VariationCategoryViewModel[] {
        return this.variationCategoryViewModels.filter((c) => !c.isDefault);
    }

    /**
     * List of the non-default variation items for a variation
     */
    @computed
    public get getAdditionalVariationItemViewModels(): VariationItemViewModel[] {
        let itemsToReturn: VariationItemViewModel[] = [];

        this.variationCategoryViewModels
            .filter((c) => !c.isDefault)
            .forEach((cat) => {
                cat.variationSubCategoryViewModels.forEach((subcat) => {
                    itemsToReturn.push(...subcat.variationItemViewModels.filter((i) => !i.model.isDeleted));
                });
            });

        return itemsToReturn;
    }

    @observable
    private variationNoteViewModels: VariationNoteViewModel[] = [];

    @computed
    public get getVariationNoteViewModels(): VariationNoteViewModel[] {
        return this.variationNoteViewModels;
    }

    @computed
    public get formattedVariationNumber(): string {
        return formatVariationNumber(this.model.variationNumber);
    }

    @computed
    public get valueAddedFromOverheadPercentage(): number {
        return this.lineTotalSumValue * (this.model.overheadPercentage / 100);
    }

    @computed
    public get formattedValueAddedOverheadPercentage(): string {
        return formatCurrencyFromPounds(this.valueAddedFromOverheadPercentage);
    }

    @computed
    public get valueAddedFromDesignPercentage(): number {
        return this.lineTotalSumValue * (this.model.designPercentage / 100);
    }

    @computed
    public get formattedValueAddedDesignPercentage(): string {
        return formatCurrencyFromPounds(this.valueAddedFromDesignPercentage);
    }

    @computed
    public get formattedOverheadPercentage(): string {
        return `${this.model.overheadPercentage.toFixed(2)}%`;
    }

    @computed
    public get formattedDesignPercentage(): string {
        return `${this.model.designPercentage.toFixed(2)}%`;
    }

    @computed
    public get futureSpendSumValue(): number {
        // Sum of expected costs.

        return this.variationCategoryViewModels.map((item) => item.futureSpendSum).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get futureSpendFormatted(): string {
        return formatCurrencyFromPounds(this.futureSpendSumValue);
    }

    @computed
    public get lineTotalSumValue(): number {
        // Sum of line totals.

        return this.variationCategoryViewModels.map((item) => item.lineTotalSum).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get lineTotalSumFormatted(): string {
        return formatCurrencyFromPounds(this.lineTotalSumValue);
    }

    @computed
    public get totalVariationValue(): number {
        // Sum of line totals plus overhead and design profit.
        return this.lineTotalSumValue + (this.valueAddedFromOverheadPercentage + this.valueAddedFromDesignPercentage);
    }

    @computed
    public get totalVariationFormatted(): string {
        return formatCurrencyFromPounds(this.totalVariationValue);
    }

    @computed
    public get varianceSumValue(): number {
        return this.futureSpendSumValue - this.lineTotalSumValue;
    }

    @computed
    public get varianceFormatted(): string {
        return formatCurrencyFromPounds(Math.abs(this.varianceSumValue));
    }

    @computed
    public get varianceTotalSumValue(): number {
        return this.totalVariationValue - this.futureSpendSumValue;
    }

    @computed
    public get varianceTotalSumValueFormatted(): string {
        return formatCurrencyFromPounds(Math.abs(this.varianceTotalSumValue));
    }

    @computed
    public get variationDocuments(): VariationDocumentDTO[] {
        return this.model.variationDocuments.filter((d) => !d.isDeleted);
    }

    /**
     * List of the documents for the client section
     */
    @computed
    public get getClientDocuments(): VariationDocumentDTO[] {
        const documentType = this.variationDocumentTypes.find((d) => d.type === VariationDocumentTypeEnum.Client);

        if (documentType) {
            return this.variationDocuments.filter((n) => n.variationDocumentTypeId === documentType.id);
        }

        return [];
    }

    /**
     * List of the documents for the internal section
     */
    @computed
    public get getInternalDocuments(): VariationDocumentDTO[] {
        const documentType = this.variationDocumentTypes.find((d) => d.type === VariationDocumentTypeEnum.Internal);

        if (documentType) {
            return this.variationDocuments.filter((n) => n.variationDocumentTypeId === documentType.id);
        }

        return [];
    }

    /**
     * Delete a document from the local array.
     * @param id The id of the document to be deleted
     */
    @action
    public handleDeleteDocument = async (id: string | null): Promise<void> => {
        // Used to delete by index but there's different types of documents separated into different lists.
        if (id !== null) {
            for (let i = 0; i < this.model.variationDocuments.length; i++) {
                if (this.model.variationDocuments[i].id === id) {
                    this.model.variationDocuments[i].isDeleted = true;
                }
            }
        }
    };

    @computed
    public get canShowClientApprovalSection(): boolean {
        const pendingClientapprovalStatus = this.variationStatuses.find((s) => s.type === VariationStatusEnum.PendingClientApproval);
        const approvedStatus = this.variationStatuses.find((s) => s.type === VariationStatusEnum.Approved);

        if (
            (pendingClientapprovalStatus && pendingClientapprovalStatus.id === this.model.variationStatusId) ||
            (approvedStatus && approvedStatus.id === this.model.variationStatusId)
        ) {
            return true;
        }

        return false;
    }

    @action
    private createViewModels() {
        for (const item of this.model.variationCategories) {
            this.variationCategoryViewModels.push(new VariationCategoryViewModel(item));
        }
        for (const item of this.model.variationNotes) {
            this.variationNoteViewModels.push(new VariationNoteViewModel(item));
        }
    }

    // /**
    //  * Generate the default category, subcategory and items. Every variation has a default category.
    //  */
    // @action
    // private createDefaultItems() {
    //     const existingDefaultCategory = this.variationCategoryViewModels.find((c) => c.isDefault);

    //     // Only generate the default items if they don't already exist.
    //     if (!existingDefaultCategory) {
    //         const packageCategory = this.packagesViewModel.model.categories.find((c) => c.isVariationDefault);
    //         const packageSubCategory = this.packagesViewModel.model.subCategories.find((s) => s.isVariationDefault);

    //         if (packageCategory && packageSubCategory) {
    //             this.packagesViewModel.model.descriptions
    //                 .filter((d) => d.isVariationDefault)
    //                 .forEach((item: PackageCategoryModel) => {
    //                     const itemToAdd: VariationItemToAdd = {
    //                         categoryId: packageCategory.id,
    //                         subCategoryId: packageSubCategory.id,
    //                         descriptionId: item.id,
    //                         descriptionOther: null,
    //                         rate: 0,
    //                         variationUnitName: null,
    //                         quantity: 0,
    //                         futureSpend: 0,
    //                     };

    //                     this.addNewLineItem(itemToAdd);
    //                 });
    //         }
    //     }
    // }

    /**
     * Add a new line item to the grid.
     * @param itemToAdd The line item to add.
     */
    @action
    public addNewLineItem = (itemToAdd: VariationItemToAdd) => {
        // Check if there's an existing variationCategory
        // If no, then add a new category with an empty id, then add a new subcategory, then add a new item.

        // If yes, then check if the subcategory exists
        // If no, then add a new subcategory with an empty id, then add a new item

        // If yes, then add a new item.

        const itemDisplayName = this.packagesViewModel.getDescriptionName(itemToAdd.descriptionId) ? this.packagesViewModel.getDescriptionName(itemToAdd.descriptionId) : null;
        const subDisplayName = this.packagesViewModel.getSubcategoryName(itemToAdd.subCategoryId) ? this.packagesViewModel.getSubcategoryName(itemToAdd.subCategoryId) : null;
        const catDisplayName = this.packagesViewModel.getCategoryName(itemToAdd.categoryId) ? this.packagesViewModel.getCategoryName(itemToAdd.categoryId) : null;

        const existingVariationCategory = this.variationCategoryViewModels.find((c) => c.model.categoryId === itemToAdd.categoryId);

        // Check for existing category
        if (existingVariationCategory) {
            const existingVariationSubCategory = existingVariationCategory.variationSubCategoryViewModels.find((c) => c.model.subCategoryId === itemToAdd.subCategoryId);

            // Check for existing subcategory
            if (existingVariationSubCategory) {
                const variationItemDTO: VariationItemDTO = {
                    id: null,
                    descriptionId: itemToAdd.descriptionId,
                    descriptionOther: itemToAdd.descriptionOther,
                    displayName: itemDisplayName != undefined ? itemDisplayName : "",
                    variationSubCategoryId: existingVariationSubCategory.model.id,
                    rate: itemToAdd.rate,
                    quantity: itemToAdd.quantity,
                    futureSpend: itemToAdd.futureSpend,
                    rowVersion: null,
                    variationUnitName: itemToAdd.variationUnitName,
                    createdByUserId: null,
                    createdDate: null,
                    isDeleted: false,
                    committedCost: 0,
                    canDeleteItem: true,
                    isDefaultItem: false,
                };

                let variationItemModel: VariationItemModel = new VariationItemModel();
                variationItemModel.fromDto(variationItemDTO);

                // Add new item to existing subcategory
                existingVariationSubCategory.model.variationItems.push(variationItemModel);
                existingVariationSubCategory.variationItemViewModels.push(new VariationItemViewModel(variationItemModel));
            } else {
                const variationSubCategoryDTO: VariationSubCategoryDTO = {
                    id: null,
                    subCategoryId: itemToAdd.subCategoryId,
                    displayName: subDisplayName != undefined ? subDisplayName : "",
                    variationCategoryId: existingVariationCategory.model.id,
                    createdByUserId: null,
                    createdDate: null,
                    isDeleted: false,
                    variationItems: [],
                };

                const variationItemDTO: VariationItemDTO = {
                    id: null,
                    descriptionId: itemToAdd.descriptionId,
                    descriptionOther: itemToAdd.descriptionOther,
                    displayName: itemDisplayName != undefined ? itemDisplayName : "",
                    variationSubCategoryId: null,
                    rate: itemToAdd.rate,
                    quantity: itemToAdd.quantity,
                    futureSpend: itemToAdd.futureSpend,
                    rowVersion: null,
                    variationUnitName: itemToAdd.variationUnitName,
                    createdByUserId: null,
                    createdDate: null,
                    isDeleted: false,
                    committedCost: 0,
                    canDeleteItem: true,
                    isDefaultItem: false,
                };

                // Add new item to new subcategory
                variationSubCategoryDTO.variationItems.push(variationItemDTO);

                let variationSubCategoryModel: VariationSubCategoryModel = new VariationSubCategoryModel();
                variationSubCategoryModel.fromDto(variationSubCategoryDTO);

                // Add new subcategory to existing category
                existingVariationCategory.model.variationSubCategories.push(variationSubCategoryModel);
                existingVariationCategory.variationSubCategoryViewModels.push(new VariationSubCategoryViewModel(variationSubCategoryModel));
            }
        } else {
            // JC: Had to update the array being observed to Type[] instead of IObservableArray<Type> as it was causing scope issues.
            // A newly created model contained existing data in the array when it definitely shouldn't.
            const variationCategoryDTO: VariationCategoryDTO = {
                id: null,
                variationId: null,
                createdByUserId: null,
                createdDate: null,
                isDeleted: false,
                categoryId: itemToAdd.categoryId,
                displayName: catDisplayName != undefined ? catDisplayName : "",
                variationSubCategories: [],
            };

            const variationSubCategoryDTO: VariationSubCategoryDTO = {
                id: null,
                subCategoryId: itemToAdd.subCategoryId,
                displayName: subDisplayName != undefined ? subDisplayName : "",
                variationCategoryId: null,
                createdByUserId: null,
                createdDate: null,
                isDeleted: false,
                variationItems: [],
            };

            const variationItemDTO: VariationItemDTO = {
                id: null,
                descriptionId: itemToAdd.descriptionId,
                descriptionOther: itemToAdd.descriptionOther,
                displayName: itemDisplayName != undefined ? itemDisplayName : "",
                variationSubCategoryId: null,
                rate: itemToAdd.rate,
                quantity: itemToAdd.quantity,
                futureSpend: itemToAdd.futureSpend,
                rowVersion: null,
                variationUnitName: itemToAdd.variationUnitName,
                createdByUserId: null,
                createdDate: null,
                isDeleted: false,
                committedCost: 0,
                canDeleteItem: true,
                isDefaultItem: false,
            };

            // Add new item to new subcategory
            variationSubCategoryDTO.variationItems.push(variationItemDTO);
            // Add new subcategory to new category
            variationCategoryDTO.variationSubCategories.push(variationSubCategoryDTO);

            let variationCategoryModel: VariationCategoryModel = new VariationCategoryModel();
            variationCategoryModel.fromDto(variationCategoryDTO);

            // Add new category to list of category
            this.model.variationCategories.push(variationCategoryModel);
            this.variationCategoryViewModels.push(new VariationCategoryViewModel(variationCategoryModel));
        }
    };

    /**
     * Add a new internal note
     * @param noteToAdd The note to be added
     */
    @action
    public addInternalNote = (noteToAdd: VariationNoteDTO) => {
        const internalNoteType = this.variationNoteTypes.find((n) => n.type === VariationNoteTypeEnum.Internal);

        if (internalNoteType) {
            noteToAdd.variationNoteTypeId = internalNoteType.id;
            let variationNoteModel: VariationNoteModel = new VariationNoteModel();
            variationNoteModel.fromDto(noteToAdd);
            this.model.variationNotes.push(variationNoteModel);
            this.variationNoteViewModels.push(new VariationNoteViewModel(variationNoteModel));
        }
    };

    /**
     * Add a new Notes/exclusions to Employers Agent
     * @param noteToAdd The note to be added
     */
    @action
    public addEmployersAgentNote = (noteToAdd: VariationNoteDTO) => {
        const employersNoteType = this.variationNoteTypes.find((n) => n.type === VariationNoteTypeEnum.EmployersAgent);

        if (employersNoteType) {
            noteToAdd.variationNoteTypeId = employersNoteType.id;
            let variationNoteModel: VariationNoteModel = new VariationNoteModel();
            variationNoteModel.fromDto(noteToAdd);
            this.model.variationNotes.push(variationNoteModel);
            this.variationNoteViewModels.push(new VariationNoteViewModel(variationNoteModel));
        }
    };

    @action
    public handleOverheadPercentageChange(val: string): void {
        const valNumber = Number(val);
        if (val === null || val === undefined || val === "" || isNaN(valNumber)) {
            this.model.overheadPercentage = 0;
        } else {
            this.model.overheadPercentage = valNumber;
        }
    }

    @action
    public handleDesignPercentageChange(val: string): void {
        const valNumber = Number(val);
        if (val === null || val === undefined || val === "" || isNaN(valNumber)) {
            this.model.designPercentage = 0;
        } else {
            this.model.designPercentage = valNumber;
        }
    }

    // #endregion Properties

    // #region Server Actions

    //related, for empty form
    public loadRelated = (): Promise<void> => {
        this.setIsLoading(true);
        return this.server
            .query<VariationRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Variation.Load.Related}\\${this.model.ieId}`),
                (result) => {
                    runInAction(() => {
                        this.variationStatuses.replace(result.variationStatuses);
                        this.variationNoteTypes.replace(result.variationNoteTypes);
                        this.variationDocumentTypes.replace(result.variationDocumentTypes);
                        this.model.overheadPercentage = result.overheadPercentage;
                        this.model.designPercentage = result.designPercentage;
                        this.setIETitle(result.ieTitle);
                        this.setEmployersAgentRecipient(result.eaContactName);
                        this.setEmployersAgentEmailAddress(result.eaContactEmail);
                        //this.createDefaultItems();
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Load an existing variation with any related data.
     */
    public loadWithRelated = (): Promise<void> => {
        this.setIsLoading(true);
        return this.server
            .query<VariationAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Variation.Load.WithRelatedById}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        this.variationStatuses.replace(result.variationStatuses);
                        this.variationNoteTypes.replace(result.variationNoteTypes);
                        this.variationDocumentTypes.replace(result.variationDocumentTypes);
                        this.model.fromDto({
                            variation: result.variation,
                            variationCategories: result.variationCategories,
                            variationNotes: result.variationNotes,
                            variationDocuments: result.variationDocuments,
                        });
                        this.setIETitle(result.ieTitle);
                        this.createViewModels();
                        //this.createDefaultItems();

                        // Set the approval panel data.
                        this.approvalPanelViewModel.model.fromDto(result.approvalPanel);
                        this.approvalPanelViewModel.populateRequisitionPOStatuses(result.requisitionPOStatuses);
                        this.approvalPanelViewModel.approvalHistoryViewModel.fromDto(result.approvalHistory);

                        // Determine whether to show the notification bar.
                        if (this.approvalPanelViewModel.model.id !== null && this.approvalPanelViewModel.model.approvalStatusId !== null) {
                            NotificationBarViewModel.Instance.setItem("variation");
                            NotificationBarViewModel.Instance.setPath(AppUrls.Client.Variation.Edit);
                            NotificationBarViewModel.Instance.setIsActive(!this.approvalPanelViewModel.getIsRejectedOrApproved);
                        }

                        const approvedStatus: VariationStatusDTO | undefined = this.variationStatuses.find((s) => s.type === VariationStatusEnum.Approved);
                        const rejectedStatus: VariationStatusDTO | undefined = this.variationStatuses.find((s) => s.type === VariationStatusEnum.RejectedInternally);
                        const pendingInternalApprovalStatus: VariationStatusDTO | undefined = this.variationStatuses.find(
                            (s) => s.type === VariationStatusEnum.PendingInternalApproval,
                        );
                        const pendingClientApprovalStatus: VariationStatusDTO | undefined = this.variationStatuses.find(
                            (s) => s.type === VariationStatusEnum.PendingClientApproval,
                        );

                        // Handle setting the form to viewonly.
                        if (
                            (approvedStatus && this.model.variationStatusId === approvedStatus.id) ||
                            (rejectedStatus && this.model.variationStatusId === rejectedStatus.id) ||
                            (pendingInternalApprovalStatus && this.model.variationStatusId === pendingInternalApprovalStatus.id) ||
                            (pendingClientApprovalStatus && this.model.variationStatusId === pendingClientApprovalStatus.id)
                        ) {
                            this.setIsViewOnly(true);
                        }
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Save a new or draft variation as draft.
     */
    public saveAsDraft = (): Promise<void> => {
        const draftStatus = this.variationStatuses.find((s) => s.type === VariationStatusEnum.Draft);

        if (draftStatus) {
            return this.upsert(draftStatus.id!);
        }

        return Promise.reject();
    };

    /**
     * Send a new or draft variation for internal approval.
     */
    public sendForInternalApproval = (): Promise<void> => {
        const internalApprovalStatus = this.variationStatuses.find((s) => s.type === VariationStatusEnum.PendingInternalApproval);

        if (internalApprovalStatus) {
            return this.upsert(internalApprovalStatus.id!);
        }

        return Promise.reject();
    };

    /**
     * Save the client approval changes and update the status of the variation to approved. (The final step)
     */
    public saveClientApproval = (): Promise<void> => {
        const approvalStatus = this.variationStatuses.find((s) => s.type === VariationStatusEnum.Approved);

        if (approvalStatus) {
            return this.upsert(approvalStatus.id!);
        }

        return Promise.reject();
    };

    public upsert = (statusId: string): Promise<void> => {
        this.setIsLoading(true);
        let model: UpsertVariationAndRelatedRequestDTO = this.model.toDto();

        model.variation.variationStatusId = statusId;

        if (this.isInReviseMode) {
            model.variation.revision = generateOrIncrementRevision(model.variation.revision);
        }

        const url = this.isInReviseMode ? AppUrls.Server.Variation.ReviseVariation : AppUrls.Server.Variation.Upsert;

        return this.server
            .command<VariationAndRelatedResponseDTO>(
                () => this.Post(url, model),
                (result: VariationAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.reset();
                        getHistory().goBack();
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the variation",
            )
            .finally(() => this.setIsLoading(false));
    };

    public upsertApprovalStatus = async (isApproved: boolean, approverNotes: string, requisitionStatusId: string): Promise<void> => {
        this.setIsLoading(true);
        let existingApproverNotes = this.approvalPanelViewModel.model.approverNotes !== null ? this.approvalPanelViewModel.model.approverNotes : "";
        let existingRequesterNotes = this.approvalPanelViewModel.model.requesterNotes !== null ? this.approvalPanelViewModel.model.requesterNotes : "";
        const request: ApprovalStatusBaseDTO = {
            requisitionPOId: null,
            variationId: this.model.id !== null ? this.model.id : "",
            invoiceId: null,
            requisitionStatusId: requisitionStatusId,
            requesterNotes: existingRequesterNotes,
            approverNotes: approverNotes !== "" ? approverNotes : existingApproverNotes,
            isApproved: isApproved,
        };

        const apiResult = await this.Post<any>(AppUrls.Server.Approval.UpsertVariationApprovalStatus, request);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.history.push(AppUrls.Client.Approval.List);
            } else {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    this.setSnackMessage("Failed to process approval");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        this.setIsLoading(false);
    };

    public amendVariationPost = async (): Promise<ApiResult<VariationRelatedResponseDTO>> => {
        this.setIsLoading(true);
        let model = this.model.toDto();

        const pendingInternalApproval = this.variationStatuses.find((s) => s.type === VariationStatusEnum.PendingInternalApproval);

        if (pendingInternalApproval) {
            model.variation.variationStatusId = pendingInternalApproval.id;
        }

        const result = await this.Post<VariationRelatedResponseDTO>(AppUrls.Server.Variation.Upsert, model)
            .then((apiResult) => {
                if (!apiResult.wasSuccessful) {
                    console.log(apiResult.errors);
                    runInAction(() => {
                        console.log(apiResult.errors);
                        this.setSnackMessage("There was an error trying to amend the variation");
                        this.setSnackType(this.SNACKERROR);
                        this.setSnackbarState(true);
                        this.handleShowSendForApprovalModalChange(false);
                    });
                }

                return apiResult;
            })
            .finally(() => this.setIsLoading(false));

        return result;
    };

    public amendVariation = async (): Promise<void> => {
        return this.server.command<VariationRelatedResponseDTO>(
            () => this.amendVariationPost(),
            (result) => {
                runInAction(() => {
                    this.reset();
                    getHistory().goBack();
                });
            },
            this.isModelValid,
            "There was an error trying to amend the variation",
        );
    };

    public deleteVariation = (): Promise<void> => {
        this.setIsLoading(true);
        return this.server
            .command<VariationAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Variation.Delete, this.model.toDeleteDto()),
                (result: VariationAndRelatedResponseDTO) => {
                    runInAction(() => {
                        this.reset();
                        getHistory().goBack();
                    });
                },
                this.isMyModelValid,
                "There was an error trying to delete the variation",
            )
            .finally(() => this.setIsLoading(false));
    };

    public handleSelectInternalFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const internalTypeId = this.variationDocumentTypes.find((s) => s.type === VariationDocumentTypeEnum.Internal);

        if (internalTypeId && internalTypeId.id) {
            await this.fileChange(event, internalTypeId.id);
        }
    };

    public handleSelectClientFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const clientTypeId = this.variationDocumentTypes.find((s) => s.type === VariationDocumentTypeEnum.Client);

        if (clientTypeId && clientTypeId.id) {
            await this.fileChange(event, clientTypeId.id);
        }
    };

    @action
    public apiGenerateVOPDF = async (id: string) => {
        await exportPDFWithGet(AppUrls.Server.Variation.GenerateVOPDFById, { id: id }, await this.getConfig(true, csvAxiosRequestConfig)).finally(() => this.setIsLoading(false));
    };

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     * @param variationdocumentTypeId The type of document being uploaded.
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>, variationdocumentTypeId: string): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: VariationDocumentDTO = {
                    id: Math.random().toString(16).substr(2, 8), // 6de5ccda
                    url: apiResult.payload,
                    fileName: data.fileName,
                    variationDocumentTypeId: variationdocumentTypeId,
                    isDeleted: false,
                };

                runInAction(() => this.model.variationDocuments.push(fileToDisplay));
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    let errorMessage: string = "Error uploading file please try again.";
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        errorMessage =
                            apiResult.errors[0].message === "The file type is not supported." ? "The file type is not supported." : "Error uploading file please try again.";
                    }
                    this.setSnackMessage(errorMessage);
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        //let fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    /**
     * Custom model validation function. Validates child category models and its children
     * @returns True if model is valid, false if not.
     */
    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.variationCategoryViewModels.length; i++) {
            let category = this.variationCategoryViewModels[i];

            // Validate each child category.
            if ((await category.isMyModelValid()) === false) {
                isValid = false;
            }
        }

        // Validate the variation model.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.variationCategoryViewModels.length = 0;
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<VariationModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            switch (fieldName) {
                case "description": {
                    const result = this.validateDescription;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "requestedBy": {
                    const result = this.validateRequestedBy;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "requestedDate": {
                    const result = this.validateRequestedDate;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "clientResponseRequiredDate": {
                    const result = this.validateClientResponseRequiredDate;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "employersAgentRecipient": {
                    const result = this.validateEmployersAgentRecipient;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "employersAgentEmailAddress": {
                    const result = this.validateEmployersAgentEmailAddress;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "overheadPercentage": {
                    const result = this.validateOverheadPercentage;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "designPercentage": {
                    const result = this.validateDesignPercentage;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateDescription() {
        const errorMessage = this.model.validateDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateRequestedBy() {
        const errorMessage = this.model.validateRequestedBy;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateRequestedDate() {
        const errorMessage = this.model.validateRequestedDate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateClientResponseRequiredDate() {
        const errorMessage = this.model.validateClientResponseRequiredDate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateEmployersAgentRecipient() {
        const errorMessage = this.model.validateEmployersAgentRecipient;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateEmployersAgentEmailAddress() {
        const errorMessage = this.model.validateEmployersAgentEmailAddress;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateOverheadPercentage() {
        const errorMessage = this.model.validateOverheadPercentage;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDesignPercentage() {
        const errorMessage = this.model.validateDesignPercentage;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

enum VariationDocumentTypeEnum {
    Internal = 10,
    Client = 20,
}

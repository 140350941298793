import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "@shoothill/core";
import { DarwinPage, DarwinPageTitle } from "Globals/Styles/AppStyling";
import { AllPurchaseOrderTableView } from "./AllPurchaseOrderTableView";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";
import { RejectedOrderListViewModel } from "./RejectedOrderListViewModel";
import { RejectedOrderListFilterView } from "./RejectedOrderListFilterView";
import { RejectedOrderListFilterViewModel } from "./RejectedOrderListFilterViewModel";

export const RejectedOrderListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new RejectedOrderListViewModel());
    const filtersViewModel = RejectedOrderListFilterViewModel.Instance;
    useEffect(() => {
        IEGridItemViewModel.Instance.setIsCentral(false);
        viewModel.GetAllWithFiltersAsync().then((result) => {
            if (result.wasSuccessful === true) {
                filtersViewModel.setCategories(result.payload.packageCategories, true);
                filtersViewModel.setStatuses(result.payload.statuses, true);
                filtersViewModel.setPurchaseUsers(result.payload.purchaseUsers, true);
                filtersViewModel.setOrderTypes(result.payload.orderTypes, true);
                filtersViewModel.setPurchaseUsers(result.payload.purchaseUsers, true);
                filtersViewModel.setProjects(result.payload.projects, true);
            }
        });

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const renderPage = () => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Rejected Orders
                    </Typography>
                </DarwinPageTitle>
                <RejectedOrderListFilterView rejectedOrderListViewModel={viewModel} filtersViewModel={filtersViewModel} />
                <AllPurchaseOrderTableView data={viewModel.getPurchaseOrdersList} isLoading={viewModel.IsLoading} downloadPDF={viewModel.apiGeneratePOPDF} />
            </DarwinPage>
        );
    };

    return useObserver(() => renderPage());
};

import * as React from "react";
import { Button, Typography } from "@material-ui/core";

import { CancelButton } from "Globals/Styles/Control/Buttons";
import { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { AddEditSupplierViewModel } from "./AddEditSupplierViewModel";
import { AddEditSupplierView } from "./AddEditSupplierView";
import { AddressComponentView } from "Views/Address/AddressComponentView";
import { AddressModel, AddressModelDTO } from "Globals/Models/Domain/AddressBase";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { ProjectButtonContainer } from "Views/Project/Project.styles";
import { AppUrls } from "AppUrls";
import { useRouter } from "@shoothill/core";
interface IAddEditSupplierModalProps {
    title: string;
    text: string;

    onClose(): void;
}

export const AddEditSupplierModal: React.FunctionComponent<IAddEditSupplierModalProps> = () => {
    const viewModel: AddEditSupplierViewModel = AddEditSupplierViewModel.Instance;
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [addressViewModel, setAddressModel] = React.useState<AddressViewModel>(new AddressViewModel(new AddressModel()));
    const { history } = useRouter();

    useEffect(() => {
        const addModel: AddressModel = viewModel.getModelAddress;
        setAddressModel(new AddressViewModel(addModel));
    }, [JSON.stringify(viewModel.getAddressViewModel)]);

    const onUpdateAddress = (address: AddressModelDTO): any => {
        viewModel.updateAddress(address);
    };

    const onSaveLocal = () => {
        setErrorMessage("");
        // Update the address object.
        viewModel.updateModelAddress(addressViewModel.model);

        const modelPromise: Promise<boolean> = viewModel.isMyModelValid();
        modelPromise.then((isValid: boolean) => {
            if (isValid === true) {
                let upsertPromise = viewModel.upsert();
                upsertPromise.then((response) => {
                    if (response.wasSuccessful) {
                        history.push(AppUrls.Client.Supplier.List);
                    }
                });
            } else {
                setErrorMessage("Please fix the following errors and re-submit");
            }
        });
    };

    const onCancel = () => {
        history.push(AppUrls.Client.Supplier.List);
    };

    return useObserver(() => (
        <DarwinTablePageContent>
            <div className="suppliesDetailsTemplate">
                <div className="sectionBlockHeading">
                    <Typography variant="h2">Invoice address</Typography>
                    {viewModel.hasLoaded && (
                        <AddressComponentView
                            onUpdateModel={onUpdateAddress}
                            viewModel={addressViewModel}
                            isLoading={false}
                            showAddButton={false}
                            isShowCountry={false}
                            disabled={!viewModel.getCanEditSupplierForm}
                        />
                    )}
                </div>

                <div className="sectionBlockHeading">
                    <Typography variant="h2">Financial information</Typography>
                    {viewModel.hasLoaded && <AddEditSupplierView />}
                </div>
                <SolidDivider gutterBottom={true} className="mt-20px" />
                <ProjectButtonContainer>
                    <Button color="primary" autoFocus variant="contained" onClick={onSaveLocal} disabled={!viewModel.getCanEditSupplierForm}>
                        Save
                    </Button>
                    <CancelButton onClick={onCancel}>Cancel</CancelButton>
                </ProjectButtonContainer>
            </div>
        </DarwinTablePageContent>
    ));
};

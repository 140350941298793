import { ModelBase } from "@shoothill/core";
import { action, observable, computed } from "mobx";

export class DisciplineFormModel extends ModelBase<DisciplineFormModel> {
    // #region Constants

    public static readonly DEFAULT_DISCIPLINE_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_NON_CONFORMANCE_TYPE_ID = "";

    // Concerning discipline
    public static readonly DEFAULT_ISSUED_TO_INDUCTION_USER_ID = null;
    public static readonly DEFAULT_DISCIPLINE_CARD_TYPE_ID = "";
    public static readonly DEFAULT_DATE_OF_OCCURRENCE = null;
    public static readonly DEFAULT_NOTES = "";
    public static readonly DEFAULT_NEAR_MISS_COMPLETED = false;
    public static readonly DEFAULT_CARD_ISSUED_BY_USER_ID = "";
    public static readonly DEFAULT_SENIOR_TEAM_TYPEID = "";
    public static readonly DEFAULT_SIGNATURE_ISSUED_BY_URL = null;
    public static readonly DEFAULT_SIGNATURE_ISSUED_BY_DATE = null;
    public static readonly DEFAULT_CARD_RECEIVEDBY_USER_ID = "";
    public static readonly DEFAULT_COMPANY_NAME = "";
    public static readonly DEFAULT_SIGNATURE_RECEIVED_BY_URL = null;
    public static readonly DEFAULT_SIGNATURE_RECEIVED_BY_DATE = null;

    // Concerning audit
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = null;
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_ROW_VERSION = null;

    // #endregion Constants

    // #region Related Properties

    @observable
    public cardReceivedByInductionUsers = observable<InductionUserDTO>([]);

    @observable
    public cardReceivedByWorkerUsers = observable<InductionUserDTO>([]);

    @computed
    public get cardReceivedByUsers() {
        const groupInuductionUsers = this.cardReceivedByInductionUsers.map((u) => {
            return {
                groupType: "INDUCTION_USER",
                groupDisplayName: "Inducted",
                ...u,
            };
        });

        const groupWorkerUsers = this.cardReceivedByWorkerUsers.map((u) => {
            return {
                groupType: "WORKER_USER",
                groupDisplayName: "Darwin staff",
                ...u,
            };
        });

        return [...groupInuductionUsers, ...groupWorkerUsers];
    }

    // #endregion Related Properties

    // #region Form Properties

    @observable
    public id: string | null = DisciplineFormModel.DEFAULT_DISCIPLINE_ID;

    @observable
    public projectId: string = DisciplineFormModel.DEFAULT_PROJECT_ID;

    @observable
    public nonConformanceTypeId: string = DisciplineFormModel.DEFAULT_NON_CONFORMANCE_TYPE_ID;

    // Concerning discipline
    @observable
    public cardReceivedByUserId: string | null = DisciplineFormModel.DEFAULT_ISSUED_TO_INDUCTION_USER_ID;

    public get cardReceivedByInductionUserId() {
        const result = this.cardReceivedByUsers.find((u) => u.id === this.cardReceivedByUserId);

        return result?.groupType === "INDUCTION_USER" ? result!.id : DisciplineFormModel.DEFAULT_CARD_ISSUED_BY_USER_ID;
    }

    public get cardReceivedByWorkerUserId() {
        const result = this.cardReceivedByUsers.find((u) => u.id === this.cardReceivedByUserId);

        return result?.groupType === "WORKER_USER" ? result!.id : DisciplineFormModel.DEFAULT_CARD_ISSUED_BY_USER_ID;
    }

    @observable
    public disciplineCardTypeId: string | null = DisciplineFormModel.DEFAULT_DISCIPLINE_CARD_TYPE_ID;
    @observable
    public dateOfOccurance: string | null = DisciplineFormModel.DEFAULT_DATE_OF_OCCURRENCE;
    @observable
    public notes: string = DisciplineFormModel.DEFAULT_NOTES;
    @observable
    public nearMissCompleted: boolean = DisciplineFormModel.DEFAULT_NEAR_MISS_COMPLETED;
    @observable
    public cardIssuedByUserId: string | null = DisciplineFormModel.DEFAULT_CARD_ISSUED_BY_USER_ID;
    @observable
    public seniorTeamTypeId: string | null = DisciplineFormModel.DEFAULT_SENIOR_TEAM_TYPEID;
    @observable
    public signatureIssuedByURL: string | null = DisciplineFormModel.DEFAULT_SIGNATURE_ISSUED_BY_URL;
    @observable
    public signatureIssuedByDate: string | null = DisciplineFormModel.DEFAULT_SIGNATURE_ISSUED_BY_DATE;
    @observable
    public companyName: string | null = DisciplineFormModel.DEFAULT_COMPANY_NAME;
    @observable
    public signatureReceivedByURL: string | null = DisciplineFormModel.DEFAULT_SIGNATURE_RECEIVED_BY_URL;
    @observable
    public signatureReceivedByDate: string | null = DisciplineFormModel.DEFAULT_SIGNATURE_RECEIVED_BY_DATE;
    @observable
    public nonConformanceNumber: number | null = 0;

    // Concerning audit
    @observable
    public createdDate: string | null = DisciplineFormModel.DEFAULT_CREATED_DATE;

    @observable
    public createdByUserId: number | null = DisciplineFormModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    public isDeleted: boolean = DisciplineFormModel.DEFAULT_IS_DELETED;

    @observable
    public rowVersion: string | null = DisciplineFormModel.DEFAULT_ROW_VERSION;

    // #endregion Form Properties

    // #region Validation

    @computed
    public get validateCardReceivedByUserId(): string {
        return this.cardReceivedByUserId === DisciplineFormModel.DEFAULT_CARD_RECEIVEDBY_USER_ID ? "Please select a Card received by user" : "";
    }

    @computed
    public get validateDisciplineCardTypeId(): string {
        return this.disciplineCardTypeId === DisciplineFormModel.DEFAULT_DISCIPLINE_CARD_TYPE_ID ? "Please select a card" : "";
    }

    @computed
    public get validateDateOfOccurance(): string {
        return this.dateOfOccurance === DisciplineFormModel.DEFAULT_DATE_OF_OCCURRENCE ? "Please select a Date of occurrence" : "";
    }

    @computed
    public get validateNotes(): string {
        return this.notes === DisciplineFormModel.DEFAULT_NOTES ? "Please enter a offender was witnessed" : "";
    }

    @computed
    public get validateCardIssuedByUserId(): string {
        return this.cardIssuedByUserId === DisciplineFormModel.DEFAULT_CARD_ISSUED_BY_USER_ID ? "Please select a card issued by user" : "";
    }

    @computed
    public get validateSeniorTeamTypeId(): string {
        return this.seniorTeamTypeId === DisciplineFormModel.DEFAULT_SENIOR_TEAM_TYPEID ? "Please select a name of Portakabin - Trial senior team informed" : "";
    }

    @computed
    public get validateCompanyName(): string {
        return this.companyName === DisciplineFormModel.DEFAULT_COMPANY_NAME ? "Please enter a name of company employing the individual" : "";
    }

    @computed
    public get validateSignatureIssuedByURL(): string {
        return this.signatureIssuedByURL === DisciplineFormModel.DEFAULT_SIGNATURE_ISSUED_BY_URL ? "Please enter a Card issued by signature" : "";
    }

    @computed
    public get validateSignatureReceivedByURL(): string {
        return this.signatureReceivedByURL === DisciplineFormModel.DEFAULT_SIGNATURE_RECEIVED_BY_URL ? "Please enter a Card received by signature" : "";
    }

    // #endregion Validation

    @action
    public fromRelatedDto(dto: DisciplineRelatedResponseDTO): void {}

    @action
    public reset = () => {
        this.id = DisciplineFormModel.DEFAULT_DISCIPLINE_ID;
        this.projectId = DisciplineFormModel.DEFAULT_PROJECT_ID;
        this.nonConformanceTypeId = DisciplineFormModel.DEFAULT_NON_CONFORMANCE_TYPE_ID;

        // Concerning discipline
        this.cardReceivedByUserId = DisciplineFormModel.DEFAULT_CARD_RECEIVEDBY_USER_ID;

        this.disciplineCardTypeId = DisciplineFormModel.DEFAULT_DISCIPLINE_CARD_TYPE_ID;
        this.dateOfOccurance = DisciplineFormModel.DEFAULT_DATE_OF_OCCURRENCE;
        this.notes = DisciplineFormModel.DEFAULT_NOTES;
        this.nearMissCompleted = DisciplineFormModel.DEFAULT_NEAR_MISS_COMPLETED;
        this.cardIssuedByUserId = DisciplineFormModel.DEFAULT_CARD_ISSUED_BY_USER_ID;
        this.seniorTeamTypeId = DisciplineFormModel.DEFAULT_SENIOR_TEAM_TYPEID;
        this.signatureIssuedByURL = DisciplineFormModel.DEFAULT_SIGNATURE_ISSUED_BY_URL;
        this.signatureIssuedByDate = DisciplineFormModel.DEFAULT_SIGNATURE_ISSUED_BY_DATE;
        this.companyName = DisciplineFormModel.DEFAULT_COMPANY_NAME;
        this.signatureReceivedByURL = DisciplineFormModel.DEFAULT_SIGNATURE_RECEIVED_BY_URL;
        this.signatureReceivedByDate = DisciplineFormModel.DEFAULT_SIGNATURE_RECEIVED_BY_DATE;

        // Concerning audit
        this.createdDate = DisciplineFormModel.DEFAULT_CREATED_DATE;
        this.createdByUserId = DisciplineFormModel.DEFAULT_CREATED_BY_USER_ID;
        this.isDeleted = DisciplineFormModel.DEFAULT_IS_DELETED;
        this.rowVersion = DisciplineFormModel.DEFAULT_ROW_VERSION;
    };

    public fromDto(dto: any): void {
        //throw new Error("Method not implemented.");
    }

    public toDto(): DisciplineDTO {
        return {
            id: this.id,
            projectId: this.projectId,
            nonConformanceTypeId: this.nonConformanceTypeId,

            cardReceivedByInductionUserId: this.cardReceivedByInductionUserId,
            cardReceivedByWorkerUserId: this.cardReceivedByWorkerUserId,

            nonConformanceNumber: this.nonConformanceNumber,
            disciplineCardTypeId: this.disciplineCardTypeId,
            dateOfOccurance: this.dateOfOccurance,
            notes: this.notes,
            nearMissCompleted: this.nearMissCompleted,
            cardIssuedByUserId: this.cardIssuedByUserId,
            seniorTeamTypeId: this.seniorTeamTypeId,
            signatureIssuedByURL: this.signatureIssuedByURL,
            companyName: this.companyName,
            signatureReceivedByURL: this.signatureReceivedByURL,
            signatureIssuedByDate: this.signatureIssuedByDate,
            signatureReceivedByDate: this.signatureReceivedByDate,
            completedByUserId: null,
            lastUpdatedDate: null,
            lastUpdatedByUserId: null,
            completedDate: null,
            isDeleted: false,
            rowVersion: null,
        };
    }
}

export interface DisciplineRelatedResponseDTO {
    cardReceivedByInductionUsers: InductionUserDTO[];
    cardReceivedByWorkerUsers: InductionUserDTO[];

    disciplineCardTypes: DisciplineCardTypeDTO[];
    cardIssuedByUsers: CardIssuedByUserDTO[];
    seniorTeamUsers: SeniorTeamUserDTO[];
    disciplineProjectDetails: DisciplineProjectDetailsDTO;
}

export interface DisciplineCardTypeDTO {
    id: string;
    displayName: string;
    type: DisciplineCardTypeEnum;
    statement: string;
    ordinal: number;
    isDeleted: boolean;
}

export interface CardIssuedByUserDTO {
    displayName: string;
    type: DisciplineCardTypeEnum;
    statement: string;
    ordinal: number;
    isDeleted: boolean;
}

export enum DisciplineCardTypeEnum {
    RedCard = 10,
    YellowCard = 20,
}

export interface DisciplineProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}

export interface SeniorTeamUserDTO {
    id: string;
    displayName: string;
}

export interface CardIssuedByUserDTO {
    id: string;
    displayName: string;
}

export interface InductionUserDTO {
    id: string;
    displayName: string;
}

export interface DisciplineDTO {
    id: string | null;
    projectId: string;
    nonConformanceTypeId: string;

    cardReceivedByInductionUserId: string | null;
    cardReceivedByWorkerUserId: string | null;

    signatureReceivedByDate: string | null;
    nonConformanceNumber: number | null;
    disciplineCardTypeId: string | null;
    dateOfOccurance: string | null;
    notes: string;
    nearMissCompleted: boolean;
    cardIssuedByUserId: string | null;
    seniorTeamTypeId: string | null;
    signatureIssuedByURL: string | null;
    signatureIssuedByDate: string | null;
    companyName: string | null;
    signatureReceivedByURL: string | null;
    completedByUserId: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedByUserId: string | null;
    completedDate: string | null;
    isDeleted: boolean;
    rowVersion: string | null;
}

export interface UpsertDisciplineAndRelatedRequestDTO {
    discipline: DisciplineDTO;
}

export interface DisciplineAndRelatedResponseDTO {
    discipline: DisciplineDTO;
}

import { action, computed } from "mobx";

import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { ProjectApprovalTypeModel } from "./ProjectApprovalTypeModel";
import { ProjectListModel } from "./ProjectListModel";
import { ProjectStatusTypeModel } from "./ProjectStatusTypeModel";
import { AppUrls } from "AppUrls";
import { StoresInstance } from "Globals/Stores";
import { RoleTypeEnum } from "Globals/Stores/Domain/Admin/RoleStore";

export class ProjectListItemViewModel extends ViewModelBase<any> {
    // #region Constructors and Disposers

    constructor(item: ProjectListModel, approvalType: ProjectApprovalTypeModel, statusType: ProjectStatusTypeModel) {
        super({});

        this.itemModel = item;
        this.approvalTypeModel = approvalType;
        this.statusTypeModel = statusType;
    }

    // #endregion Constructors and Disposers

    // #region Properties

    private itemModel: ProjectListModel;
    private approvalTypeModel: ProjectApprovalTypeModel;
    private statusTypeModel: ProjectStatusTypeModel;

    @computed
    public get projectReference() {
        return this.itemModel.reference;
    }

    @computed
    public get projectName() {
        return this.itemModel.name;
    }

    @computed
    public get currentAuthorizedValue() {
        return this.itemModel.currentAuthorisedValue;
    }

    @computed
    public get cellAllocation() {
        return this.itemModel.cell;
    }

    @computed
    public get statusName() {
        return this.statusTypeModel.name;
    }

    @computed
    public get statusOrdinal() {
        return this.statusTypeModel.ordinal;
    }

    @computed
    public get statusColor() {
        return this.statusTypeModel.color;
    }

    @computed
    public get statusTextColor() {
        return this.statusTypeModel.textColor;
    }

    @computed
    public get approvalType() {
        return this.approvalTypeModel.type;
    }

    @computed
    public get approvalOrdinal() {
        return this.approvalTypeModel.ordinal;
    }

    @computed
    public get approvalColor() {
        return this.approvalTypeModel.color;
    }

    // #endregion Properties

    // #region Actions

    @action
    public navigateToProject = () => {
        const isSiteManager: boolean = StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.SiteManager);

        if (isSiteManager) {
            this.history.push(AppUrls.Client.Project.Construction.replace(":projectid", this.itemModel.id));
        } else {
            this.history.push(AppUrls.Client.Project.General.replace(":projectid", this.itemModel.id));
        }
    };

    // #endregion Actions

    // #region Filtering

    public matchesFilter = (filterString: string): boolean => {
        if (isEmptyOrWhitespace(filterString)) {
            return true;
        }

        const filterStringUpperCase = filterString.toUpperCase();

        return this.itemModel.reference.toUpperCase().includes(filterStringUpperCase) || this.itemModel.name.toUpperCase().includes(filterStringUpperCase);
    };

    // #endregion Filtering

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<ProjectListItemViewModel>): Promise<boolean> {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}

import { Box, Breadcrumbs } from "@material-ui/core";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { autorun } from "mobx";
import styled from "styled-components";

export const BreadcrumbWrapper = styled(Box)`
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: min-content;
    width: inherit;
    padding: 0 !important;
    font-size: 12px;
    background-color: #425b66;
    height: 100vh;

    .triangle {
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 10px solid #fff;
        position: absolute;
        right: 0;
        top: 0;
    }
`;

export const useBreadcrumbStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "flex",
        flex: "1 1 1",
        flexDirection: "row",
        width: "100%",
        color: "#ffffff",
        // backgroundColor: "#efefef",
        padding: "0!important",
    },
    common: {},
    prev: {
        display: "block",
        flex: "1 0 0",
        width: "100%",
        left: 0,
        right: 0,
        height: "40px",
        maxHeight: "40px",
        position: "relative",
        textAlign: "left",
        lineHeight: "40px",
        verticalAlign: "middle",
        padding: "0 15px",

        "&:hover": {
            background: lighten("#e03c31", 0.5),
            cursor: "pointer",
        },
    },
    heading: {
        display: "block",
        flex: "1 0 0",
        width: "100%",
        left: 0,
        right: 0,
        minHeight: "40px",
        position: "relative",
        textAlign: "left",
        lineHeight: "40px",
        verticalAlign: "middle",
        padding: "0 15px",
        backgroundColor: "#191919",
        "&:hover": {
            backgroundColor: "#191919",
            cursor: "pointer",
        },
    },
    main: {
        // extend: "common",
        backgroundColor: "#e03c31",
        display: "block",
        flex: "1 0 0",
        width: "100%",
        left: 0,
        right: 0,
        height: "40px",
        maxHeight: "40px",
        position: "relative",
        textAlign: "left",
        lineHeight: "40px",
        verticalAlign: "middle",
        padding: "0 15px",
        fontSize: "12px",

        "&:hover": {
            background: lighten("#e03c31", 0.5),
            cursor: "pointer",
        },
    },
    secondary: {
        display: "block",
        flex: "1 0 0 ",
        width: "100%",
        left: 0,
        right: 0,
        lineHeight: "40px",
        height: "40px",
        position: "relative",
        textAlign: "left",
        padding: "0 15px",
        verticalAlign: "middle",
        color: "#ffffff",
        fontSize: "12px",

        "&:hover": {
            background: lighten("#e03c31", 0.5),
            color: "#ffffff",
            cursor: "pointer",
            textDecoration: "unset",
        },
    },

    lists: {
        backgroundColor: theme.palette.background.paper,
        paddingLeft: theme.spacing(4),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    parent: {
        // height: "80px",
        // maxHeight: "80px",
    },
    child: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#e03c31",
        "&:hover": {
            background: lighten("#e03c31", 0.5),
            cursor: "pointer",
        },
        "& a": {
            paddingLeft: "30px",
            fontSize: "10px !important",
            height: "20px",
            maxHeight: "20px",
            lineHeight: "10px",
            display: "flex",
            alignItems: "center",
        },
        "&:last-of-type": {
            "& a": {
                paddingBottom: "10px",
                maxHeight: "30px",
                height: "30px",
            },
        },
    },
}));

export const DarwinBreadcrumbs = styled(Breadcrumbs)`
    color: "FFFFFF";
    display: flex;
    flex-direction: row;
    flex: 0 0 48px;
    align-items: stretch;

    .MuiBreadcrumbs-ol,
    .MuiBreadcrumbs-li {
        a {
            color: #ffffff;
            font-size: 12px;
            text-decoration: none;
        }
    }

    li {
        width: 100%;
        display: flex;
        flex: 1 0 0;

        :not(:last-child) a {
            text-decoration: underline;
        }

        :last-child a {
            //pointer-events: none;
        }
    }

    ol {
        min-width: 168px;
        width: 100%;
        display: flex;
        flex: 1 0 0;
        min-height: 0;
        margin: 21px 0px 45px;
    }

    border-bottom: 1px dotted #ffffff;
`;

const CrumbSectionBase: any = styled(Box)`
    position: relative;
    display: block;
    width: 100%;
    right: 0;
    left: 0;
    align-items: left;

    .add-image {
        display: inline-block;
        padding-right: 10px;
        line-height: 12px;
        vertical-align: middle;
    }
    .add-text {
        line-height: 22px;
        vertical-align: middle;
        color: #ffffff;
        &:hover {
            text-decoration: none;
        }
    }
`;

export const BreadcrumbSection1 = styled(CrumbSectionBase)``;

export const BreadcrumbSection2 = styled(CrumbSectionBase)``;

export const BreadcrumbFooter = styled(CrumbSectionBase)``;

import { FormControlLabel, FormLabel, IconButton, InputAdornment, RadioGroup } from "@material-ui/core";
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";

import { AddEditSupplierViewModel } from "./AddEditSupplierViewModel";
import { DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";
import { AddEditSupplierModel } from "./AddEditSupplierModel";
import { AddEditSupplierViewRow, SupplierCell } from "./SupplierDetail.styles";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { CustomRadio } from "Views/Project/CustomComponents";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { Uploader } from "Components/Uploader/Uploader";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { SupplierDocumentModel } from "./SupplierDocumentModel";

interface IAddEditSupplierModalProps {
    //ref: any;
}

export const AddEditSupplierView: React.FunctionComponent<IAddEditSupplierModalProps> = ({}) => {
    const [viewModel, setViewModel] = useState<AddEditSupplierViewModel>(AddEditSupplierViewModel.Instance);

    return useObserver(() => (
        <>
            <AddEditSupplierViewRow className="formRow">
                <SupplierCell className="w-16">
                    <DarwinInputWrapper<AddEditSupplierModel>
                        type="text"
                        label="Reference"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="reference"
                        shrink={true}
                        editMode={viewModel.getCanEditSupplierReference}
                    />
                </SupplierCell>
                <SupplierCell className="w-16">
                    <DarwinInputWrapper<AddEditSupplierModel>
                        type="text"
                        label="Name"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="name"
                        shrink={true}
                        editMode={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
                <SupplierCell className="w-16">
                    <DarwinDateSelect
                        displayName="Account opened"
                        execute={(value: string | null) => viewModel.setValue("accountOpened", value)}
                        onBlur={() => viewModel.isFieldValid("accountOpened", viewModel.model.accountOpened)}
                        placeholder="dd/mm/yyyy"
                        validationMessage={viewModel.getError("accountOpened")}
                        value={viewModel.getValue("accountOpened")}
                        canExecute={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
                <SupplierCell className="w-20">
                    <DarwinInputWrapper<AddEditSupplierModel>
                        type="text"
                        label="VAT number:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="vatNumber"
                        shrink={true}
                        maxLength={128}
                        placeHolder="xxxxxxxxx"
                        editMode={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
                <SupplierCell className="w-10">
                    <DarwinInputWrapper<AddEditSupplierModel>
                        type="text"
                        label="Default VAT rate:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="defaultVatRate"
                        shrink={true}
                        maxLength={2}
                        placeHolder="xx"
                        editMode={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
                <SupplierCell className="w-15">
                    <DarwinInputWrapper<AddEditSupplierModel>
                        type="text"
                        label="Payment terms(days):"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="paymentTermsInDays"
                        shrink={true}
                        maxLength={3}
                        placeHolder="xxx"
                        editMode={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
            </AddEditSupplierViewRow>

            <AddEditSupplierViewRow className="formRow">
                <SupplierCell className="w-16">
                    <DarwinSelect
                        displayName="Payment terms:"
                        execute={viewModel.setPaymentTerms}
                        fullWidth={true}
                        getOptionLabel={(option: any) => option.displayName}
                        options={viewModel.paymentTerms}
                        validationMessage={viewModel.getError("paymentTerms")}
                        placeholder="Please select"
                        value={viewModel.paymentTerm}
                        canExecute={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
                <SupplierCell className="w-16">
                    <DarwinMoneyInput<AddEditSupplierModel>
                        type="number"
                        label="Credit limit:"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="creditLimit"
                        shrink={true}
                        maxLength={11}
                        placeHolder="x,xxx,xxx.xx"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        editMode={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
                <SupplierCell className="w-20">
                    <DarwinSelect
                        displayName="Payment group"
                        execute={viewModel.setPaymentType}
                        fullWidth={true}
                        getOptionLabel={(option: any) => option.displayName}
                        options={viewModel.paymentTypes}
                        placeholder="Please select"
                        validationMessage={viewModel.getError("paymentGroup")}
                        value={viewModel.paymentType}
                        canExecute={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
                <SupplierCell className="w-16">
                    <DarwinDateSelect
                        displayName="Insurance expiry date:"
                        execute={(value: string | null) => viewModel.setValue("insuranceExpiryDate", value)}
                        onBlur={() => viewModel.isFieldValid("insuranceExpiryDate", viewModel.model.insuranceExpiryDate)}
                        placeholder="dd/mm/yyyy"
                        validationMessage={viewModel.getError("insuranceExpiryDate")}
                        value={viewModel.getValue("insuranceExpiryDate")}
                        canExecute={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
                <SupplierCell className="w-25">
                    <DarwinInputWrapper<AddEditSupplierModel>
                        type="text"
                        label="Constructiononline Reg"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="constructionalReg"
                        shrink={true}
                        maxLength={128}
                        placeHolder="Please add"
                        editMode={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
            </AddEditSupplierViewRow>

            <AddEditSupplierViewRow className="formRow">
                <SupplierCell className="w-20">
                    <div className="w-full">
                        <FormLabel>PQQ</FormLabel>
                        <RadioGroup row defaultValue="Yes" name="radio-pqq-group" className="twoRadioBox" value={viewModel.model.pqq} onChange={viewModel.handlePQQChange}>
                            <FormControlLabel value="true" control={<CustomRadio checked={viewModel.model.pqq} />} label="Yes" disabled={!viewModel.getCanEditSupplierForm} />
                            <FormControlLabel value="false" control={<CustomRadio checked={!viewModel.model.pqq} />} label="No" disabled={!viewModel.getCanEditSupplierForm} />
                        </RadioGroup>
                    </div>
                </SupplierCell>
                <SupplierCell className="w-25">
                    <DarwinInputWrapper<AddEditSupplierModel>
                        type="text"
                        label="Invoice factoring"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="invoiceFactoring"
                        shrink={true}
                        maxLength={128}
                        placeHolder="Please add"
                        editMode={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>
                <SupplierCell className="w-25">
                    <DarwinInputWrapper<AddEditSupplierModel>
                        type="text"
                        label="Credit insurer"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="creditInsurer"
                        shrink={true}
                        maxLength={128}
                        placeHolder="Please add"
                        editMode={viewModel.getCanEditSupplierForm}
                    />
                </SupplierCell>

                <SupplierCell className="w-100" style={{ display: "flex", flexDirection: "column" }}>
                    <Uploader
                        handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.fileChange(e)}
                        isFormDisabled={viewModel.IsLoading}
                        label="Document(s):"
                        dropzoneMessage="Click here to add documents"
                    />

                    {viewModel.getSupplierDocuments.length > 0 && (
                        <FormLabel component="label">
                            <span className="control-label">Document(s) added</span>
                        </FormLabel>
                    )}

                    <div className="uploadedFile">
                        {viewModel.getSupplierDocuments.map((item: SupplierDocumentModel, index: number) => {
                            return (
                                <div className="uploadedFile-box">
                                    <div className="uploadedFile-heading">
                                        <IconButton onClick={() => viewModel.DownloadFile(item.url, item.fileName)}>
                                            <SystemUpdateTwoToneIcon />
                                        </IconButton>
                                        <h3>{item.fileName}</h3>
                                    </div>
                                    <IconButton onClick={() => item.handleDelete(true)} disabled={viewModel.IsLoading}>
                                        <DeleteOutlinedIcon />
                                    </IconButton>
                                </div>
                            );
                        })}
                    </div>
                </SupplierCell>
            </AddEditSupplierViewRow>
        </>
    ));
};

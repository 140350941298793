import { ApiResult, FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";
import { AppUrls } from "AppUrls";
import { InvoicingListModel } from "./InvoicingListModel";
import { InvoicingListItemsAndRelatedDTO } from "./InvoicingListItemsAndRelatedDTO";
import { InvoicingStatusTypeModel } from "./InvoicingStatusTypeModel";
import { InvoicingListItemViewModel } from "./InvoicingListItemViewModel";
import { InvoicingListFilterViewModel } from "./InvoicingListFilterViewModel";
import { InvoicingListFilterParamsBaseModelDTO } from "./InvoiceListFilterParamsBaseModel";
import { InvoiceListFilterViewModel } from "./InvoiceListFilterViewModel";

export class InvoicingListViewModel extends ViewModelBase<InvoicingListModel> {
    private static _instance: InvoicingListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    constructor() {
        super(new InvoicingListModel(), false);
        this.setDecorators(InvoicingListModel);
    }

    @observable
    public workingId = "";

    @computed
    public get getSearchString() {
        return InvoicingListFilterViewModel.Instance.model.searchText;
    }

    public getSearchStringComputed = () => {
        return computed(() => InvoicingListFilterViewModel.Instance.model.searchText);
    };

    @action
    public setSearchString = (value: string) => {
        InvoicingListFilterViewModel.Instance.setValue("searchText", value);
    };

    @action
    public resetDateFilters = () => {
        this.model.filterEndDateINV = null;
        this.model.filterStartDateINV = null;
    };

    @observable
    public errorMessage: string = "";

    @computed
    public get filteredInvoicingViewModels(): InvoicingListItemViewModel[] {
        return this.invoicingViewModels.filter((vm) => vm.matchesFilter(this.getSearchString));
    }

    @observable
    private invoicingViewModels: InvoicingListItemViewModel[] = [];

    @action
    private populateListItemViewModels = (dto: InvoicingListItemsAndRelatedDTO) => {
        const invoicingViewModels2: InvoicingListItemViewModel[] = [];
        for (const invoicingListItem of dto.invoiceItem) {
            let itemModel = new InvoicingListModel();
            itemModel.fromDto(invoicingListItem);
            const statusTypeModel = new InvoicingStatusTypeModel(dto.invoiceStatusType.find((pst) => pst.id === invoicingListItem.invoiceStatusId));
            invoicingViewModels2.push(new InvoicingListItemViewModel(itemModel, statusTypeModel));
        }
        this.invoicingViewModels = invoicingViewModels2;
    };

    public handleStatusChange = (val: number[] | null, ieid: string) => {
        InvoicingListFilterViewModel.Instance.setValue("statuses", val);
        this.apiGetAll(ieid);
    };

    public handleProjectChange = (val: number[] | null, ieid: string) => {
        InvoicingListFilterViewModel.Instance.setValue("projects", val);
        this.apiGetAll(ieid);
    };

    public handleSupplierChange = (val: number[] | null, ieid: string) => {
        InvoicingListFilterViewModel.Instance.setValue("suppliers", val);
        this.apiGetAll(ieid);
    };

    public apiGetAll = async (ieid: string): Promise<ApiResult<InvoicingListItemsAndRelatedDTO>> => {
        if (isNullOrUndefined(ieid) === false && ieid !== "") {
            return this.apiGetDateAllInvoices(ieid);
        } else {
            return this.apiGetAllInvoices();
        }
    };

    @action
    public clear = (ieid: string) => {
        InvoicingListFilterViewModel.Instance.model.reset();
        this.apiGetAll(ieid);
    };

    // Api GetAll Record

    public apiGetAllInvoices = async (): Promise<ApiResult<InvoicingListItemsAndRelatedDTO>> => {
        this.setIsLoading(true);
        let paramsViewModel = InvoicingListFilterViewModel.Instance;
        let params = paramsViewModel.model.toDto();

        const apiResult = await this.Post<InvoicingListItemsAndRelatedDTO>(AppUrls.Server.Invoice.GetAllInvoiceRelated, params);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateListItemViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    // End Api GetAll Record

    @action
    public setFromDateFilter = (val: Date | null) => {
        this.model.filterStartDateINV = val;
    };

    @action
    public setToDateFilter = (val: Date | null) => {
        this.model.filterEndDateINV = val;
    };

    // Invoice by Date

    public apiGetDateAllInvoices = async (val: string): Promise<ApiResult<InvoicingListItemsAndRelatedDTO>> => {
        this.setIsLoading(true);
        let paramsViewModel = InvoicingListFilterViewModel.Instance;
        let params: InvoicingListFilterParamsBaseModelDTO = paramsViewModel.model.toDto();
        const request: InvoicingListAndRelatedRequest = {
            id: val,
            startDatefilter: this.model.filterStartDateINV,
            endDateFilter: this.model.filterEndDateINV,
            filterParams: params,
        };
        let apiResult = await this.Post<InvoicingListItemsAndRelatedDTO>(AppUrls.Server.Invoice.GetDateRelatedInvoice, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateListItemViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    // end Invoice By Date

    public async isFieldValid(fieldName: keyof FieldType<InvoicingListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface RequestModel {
    Id: string;
}

export class InvoicingListAndRelatedRequest {
    id: string = "";
    startDatefilter?: Date | null = null;
    endDateFilter?: Date | null = null;
    filterParams: InvoicingListFilterParamsBaseModelDTO | null = null;
}

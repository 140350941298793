import { InputAdornment, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { pxToRem } from "Globals/Styles/AppTheme";
import { IComputedValue } from "mobx";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

interface IProps {
    className?: string;
    searchString: IComputedValue<string>;
    setSearchString(value: string): void;
    placeholder?: string;
}

const TableListSearchBase: React.FC<IProps> = (props) => {
    const onChange = (event: any) => {
        props.setSearchString(event.target.value);
    };

    return useObserver(() => (
        <TextField
            className={props.className}
            fullWidth={true}
            autoFocus={true}
            onChange={onChange}
            placeholder={props.placeholder ? props.placeholder : "Keyword search"}
            value={props.searchString.get()}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" onClick={() => props.setSearchString("")}>
                        <ClearIcon />
                    </InputAdornment>
                ),
            }}
        />
    ));
};

export const TableListSearch: any = styled(TableListSearchBase)`
    & > .MuiInput-root {
        font-size: ${pxToRem(12)};
        height: ${pxToRem(30)};

        & > .MuiInputAdornment-root.MuiInputAdornment-positionStart {
            color: #e03c31;
            margin-right: ${pxToRem(-5)};
            padding-left: ${pxToRem(5)};
        }

        & > .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
            padding-right: ${pxToRem(5)};

            & > svg {
                font-size: ${pxToRem(20)};
            }
        }
    }
`;

import React, { useContext, useEffect, useState } from "react";
import { Stores, StoresContext } from "Globals/Stores";
import { PrivateRoute } from "Globals/Views/PrivateRoute";
import * as RouterUtil from "Utils/Routing";
import { StoresInstance } from "Globals/Stores";
import { AppUrls } from "../../AppUrls";
import { ProjectListView } from "./ProjectListView";
import { GeneralAddEditView } from "./General/GeneralAddEditView";

import { GeneralView } from "./General/General";
import { CommercialView } from "./Commercial/Commercial";
import { IEView } from "./Commercial/IE.View";
import { ContractualView } from "./Contractual/Contractual";
import { ConstructionView } from "./Construction/Construction";
import { ConstructionAddEditView } from "./Construction/ConstructionAddEdit";
import { DeliveryView } from "./Delivery/Delivery";
import { DarwinLayout } from "Globals/Views/Layouts";
import { ContractualEditView } from "./Contractual/ContractualEdit";
import { IncomeAndExpenditureView } from "./Commercial/IncomeAndExpenditure/IncomeAndExpendatureView";
import { IEFutureSpendAmendmentView } from "./Commercial/FutureSpendAmendment/IEFutureSpendAmendmentView";
import { IERiskOppView } from "./Commercial/RiskOpp/IERiskOppView";
import { SiteAccessView } from "./SiteAccess/SiteAccess";

import { ProjectTrackersView } from "./ProjectTrackers/ProjectTrackers";
import { DesignDataView } from "./ProjectTrackers/DesignData/DesignDataView";
import { DesignDataFormView } from "./ProjectTrackers/DesignData/Forms/DesignDataFormView";
import { RFIListView } from "./ProjectTrackers/RFI/RFIListView";
import { RFIFormView } from "./ProjectTrackers/RFI/Forms/Edit/RFIFormView";
import { RFICreateFormView } from "./ProjectTrackers/RFI/Forms/Create/RFICreateFormView";
import { SiteAccessQRCodePDFTemplate } from "./SiteAccess/SiteAccessQRCodePDFTemplate";
import { Route } from "react-router";
import { PermitFormView } from "./Construction/Permit/PermitFormView";
import { HouseKeepingFormView } from "./Construction/NonConformance/HouseKeeping/HouseKeepingFormView";
import { IncidentView } from "./Construction/Incidents/Indicent/IncidentView";
import { ToolboxTalkFormView } from "./Construction/Toolbox/ToolboxTalkFormView";
import { IncidentFormNearMissView } from "./Construction/Incidents/Indicent/NearMiss/IncidentFormNearMissView";
import { IncidentFormWitnessStatementView } from "./Construction/Incidents/Indicent/WitnessStatement/IncidentFormWitnessStatementView";
import { AccidentFormView } from "./Construction/Incidents/Indicent/Accident/AccidentFormView";
import { ScaffoldInspectionView } from "./Construction/Scaffolding/Form/ScaffoldInspectionView";
import { DisciplineFormViewBase } from "./Construction/NonConformance/Discipline/DisciplineFormView";
import { SafetyFormView } from "./Construction/NonConformance/Safety/SafetyFormView";
import { QualityFormView } from "./Construction/NonConformance/Quality/QualityFormView";
import { EnvironmentFormView } from "./Construction/NonConformance/Environment/EnvironmentFormView";
import { InspectionsView } from "./Construction/Inspections/InspectionsView";
import { DilapidationFormView } from "./Construction/Dilapidation/DilapidationFormView";
import { RAMSReviewFormView } from "./Construction/RAMSReview/RAMSReviewFormView";
import { BuildingControlSiteVisitViewBase } from "./Construction/ProgrammeUpdates/BuildingControlSiteVisit/BuildingControlSiteVisitView";
import { VariationRiskOppView } from "./Commercial/VariationRiskOpp/VariationRiskOppView";
import { VariationFutureSpendAmendmentView } from "./Commercial/VariationFutureSpendAmendment/VariationFutureSpendAmendmentView";
import { ProjectListViewModel } from "./ProjectListViewModel";
import { ProjectListModelWithAddress } from "./ProjectListModelWithAddress";

export const ProjectRoutes: React.FC = () => {
    const [viewModel] = useState(() => new ProjectListViewModel());
    const [projectDetails, setProjectDetails] = useState<ProjectListModelWithAddress>({} as ProjectListModelWithAddress);

    function getRootLayout() {
        return DarwinLayout;
    }
    useEffect(() => {
        viewModel.apiGetSiteTabletProjectDetailsByUserId().then((response) => {
            if (response) {
                setProjectDetails(response);
            }
        });
        return () => {};
    }, []);

    const store = useContext<Stores>(StoresContext);
    return (
        <div className="textBox">
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.List}
                component={ProjectListView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.GeneralAdd}
                component={GeneralAddEditView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.GeneralEdit}
                component={GeneralAddEditView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.canEditProjects(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.General}
                component={GeneralView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Commercial}
                component={CommercialView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.CommercialIEAdd}
                component={IncomeAndExpenditureView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.CommercialIEEdit}
                component={IncomeAndExpenditureView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.IE}
                component={IEView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Contractual}
                component={ContractualView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ContractualEdit}
                component={ContractualEditView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Construction}
                component={ConstructionView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ConstructionHAndS}
                component={ConstructionView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ConstructionQuality}
                component={ConstructionView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ConstructionEdit}
                component={ConstructionAddEditView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Permit.Add}
                component={PermitFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Permit.View}
                component={PermitFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.HouseKeeping.Add}
                component={HouseKeepingFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.HouseKeeping.View}
                component={HouseKeepingFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.Discipline.Add}
                component={DisciplineFormViewBase}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.Discipline.View}
                component={DisciplineFormViewBase}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.Quality.Add}
                component={QualityFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.Add}
                component={SafetyFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.Quality.View}
                component={QualityFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.View}
                component={SafetyFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.Environment.Add}
                component={EnvironmentFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.NonConformance.Environment.View}
                component={EnvironmentFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ProgrammeUpdates.BuildingControlSiteVisit.Add}
                component={BuildingControlSiteVisitViewBase}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Dilapidation.Add}
                component={DilapidationFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Dilapidation.View}
                component={DilapidationFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.RAMSReview.Add}
                component={RAMSReviewFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.RAMSReview.View}
                component={RAMSReviewFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Scaffolding.View}
                component={ScaffoldInspectionView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Scaffolding.Add}
                component={ScaffoldInspectionView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Inspection.Add}
                component={() => <InspectionsView projectId={projectDetails.id} projectName={projectDetails.name} projectReference={projectDetails.reference} />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Incident.View}
                component={IncidentView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Incident.NearMiss.Add}
                component={IncidentFormNearMissView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Incident.NearMiss.View}
                component={IncidentFormNearMissView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Incident.Accident.Add}
                component={AccidentFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Incident.Accident.View}
                component={AccidentFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Incident.WitnessStatement.Add}
                component={IncidentFormWitnessStatementView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Incident.WitnessStatement.View}
                component={IncidentFormWitnessStatementView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ToolboxTalk.Add}
                component={ToolboxTalkFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ToolboxTalk.Edit}
                component={ToolboxTalkFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.Delivery}
                component={DeliveryView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.SiteAccess}
                component={SiteAccessView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn;
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ProjectTrackers}
                component={ProjectTrackersView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.DesignData}
                component={DesignDataView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ReviewableDesignData.Add}
                component={DesignDataFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ReviewableDesignData.AddWithReference}
                component={DesignDataFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ReviewableDesignData.AddWithRevision}
                component={DesignDataFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.ReviewableDesignData.View}
                component={DesignDataFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.RequestForInformation}
                component={RFIListView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.RFI.View}
                component={RFIFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.RFI.Add}
                component={RFICreateFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            {/* <PrivateRoute
                exact
                path={AppUrls.Client.Project.RFI.Add}
                component={RFIFormView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            /> */}
            {/* <PrivateRoute
                exact
                path={AppUrls.Client.Project.Completed}
                component={CompletedView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            /> */}
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.IEItemFutureSpendAmendment}
                component={IEFutureSpendAmendmentView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.IEItemRiskOpp}
                component={IERiskOppView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.VariationItemRiskOpp}
                component={VariationRiskOppView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Project.VariationItemFutureSpendAmendment}
                component={VariationFutureSpendAmendmentView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
        </div>
    );
};

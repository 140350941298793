import { getHistory, FieldType, ViewModelBase, ApiResult } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { IEVRCReportModel, IEVRCReportRequestDTO } from "./IEVRCReportModel";
import moment from "moment";
import { AppUrls } from "AppUrls";
import { IEReportIEDTO, IEReportResponseDTO, IEReportVariationGridDTO } from "../Shared/IEReportModelBase";
import { csvAxiosRequestConfig, exportCSV } from "Utils/Utils";

export class IEVRCReportViewModel extends ViewModelBase<IEVRCReportModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new IEVRCReportModel());

        this.setDecorators(IEVRCReportViewModel);
    }

    @observable public reportViewModel: IEReportIEDTO | null = null;

    @observable public variationReportViewModel: IEReportVariationGridDTO | null = null;

    @observable public canExportCSV: boolean = false;

    @action
    public setCanExportCSV(val: boolean) {
        this.canExportCSV = val;
    }

    @computed
    public get getTopDateFormatted(): string {
        return this.model.reportEndDateTop ? moment(this.model.reportEndDateTop).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    @computed
    public get getBottomDateFormatted(): string {
        return this.model.reportEndDateBottom ? moment(this.model.reportEndDateBottom).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    // #region Client Actions

    @action
    public runReport = async (ieId: string): Promise<ApiResult<IEReportResponseDTO>> => {
        this.setIsLoading(true);
        const toDateBottom = this.model.reportEndDateBottom;
        const toDateTop = this.model.reportEndDateTop;
        const request: IEVRCReportRequestDTO = {
            id: ieId,
            toDateBottom: toDateBottom,
            toDateTop: toDateTop,
        };
        let apiResult = await this.Post<IEReportResponseDTO>(AppUrls.Server.Report.IE.GetIEVRCReport, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.reportViewModel = apiResult.payload.ie;
                    this.variationReportViewModel = apiResult.payload.variation;
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    @action
    public generateIEVRCReportCSV = async (ieId: string) => {
        this.setIsLoading(true);

        // JC: Download a CSV file using a HTTP POST request.
        // Source: https://stackoverflow.com/a/55138366

        const toDateBottom = this.model.reportEndDateBottom;
        const toDateTop = this.model.reportEndDateTop;
        const request: IEVRCReportRequestDTO = {
            id: ieId,
            toDateBottom: toDateBottom,
            toDateTop: toDateTop,
        };

        await exportCSV(AppUrls.Server.Report.IE.GenerateIEVRCReportCSV, request, await this.getConfig(true, csvAxiosRequestConfig)).finally(() => this.setIsLoading(false));
    };

    public server: ServerViewModel = new ServerViewModel();

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.setCanExportCSV(false);
    };

    @action
    public handleCancel = (): void => {
        getHistory().goBack();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<IEVRCReportModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

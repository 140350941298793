import { Cell, Grid } from "@shoothill/core";
import { observer } from "mobx-react-lite";

import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { SolidDivider } from "../Dividers";
import { PurchaseOrderModel } from "../../PurchaseOrderModel";
import { PurchaseOrderViewModel } from "../../PurchaseOrderViewModel";
import { ProjectModel } from "../../Supporting/ProjectModel";
import { IncomeAndExpenditureModel } from "../../Supporting/IncomeAndExpenditureModel";

interface IProps {
    viewModel: PurchaseOrderViewModel;
}

export const NonStockSectionView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const viewModel = props.viewModel;

    const setIsStock = async (val: boolean) => {
        await viewModel.setIsStock(val);
        viewModel.deleteAllGroupsAndItems();
    };

    const setIsCentral = async (val: boolean) => {
        await viewModel.setIsCentral(val);
        viewModel.deleteAllGroupsAndItems();
    };

    const setProject = async (val: ProjectModel | null) => {
        await viewModel.setProjectAsync(val);
        viewModel.deleteAllGroupsAndItems();
    };

    const setIncomeAndExpenditure = (val: IncomeAndExpenditureModel | null) => {
        viewModel.setIncomeAndExpenditureAsync(val);
        viewModel.deleteAllGroupsAndItems();
    };

    // #endregion Code Behind

    return (
        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
            {/* Group of 8 columns */}
            {!viewModel.model.isCentral && (
                <>
                    <Cell ts={2} ds={2}>
                        <DarwinSelect
                            displayName="Is this a stock requisition?:*"
                            execute={(val: any) => setIsStock(val.id)}
                            fullWidth={true}
                            getOptionLabel={(option: any) => option.displayName || ""}
                            onBlur={() => viewModel.isFieldValid("isStock")}
                            options={viewModel.isStockOptions}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("isStock")}
                            value={viewModel.isStockOption}
                            canExecute={!viewModel.isFormDisabled && !viewModel.isExistingRequest}
                        />
                    </Cell>

                    <Cell display={{ xs: "none", md: "block" }} ts={6} ds={6} />
                </>
            )}

            {/* Group of 8 columns */}
            <Cell ts={2} ds={2}>
                <DarwinSelect
                    displayName="What type of requisition is this?:*"
                    execute={(val: any) => setIsCentral(val.id)}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName || ""}
                    onBlur={() => viewModel.isFieldValid("isCentral")}
                    options={viewModel.isCentralOptions}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("isCentral")}
                    value={viewModel.isCentralOption}
                    canExecute={!viewModel.isFormDisabled && !viewModel.isExistingRequest}
                />
            </Cell>

            <Cell display={{ xs: "none", md: "block" }} ts={6} ds={6} />

            {/* Group of 8 columns */}
            <Cell ts={4} ds={4}>
                <DarwinSelect
                    displayName="Project:*"
                    execute={setProject}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    onBlur={() => viewModel.isFieldValid("projectId")}
                    options={viewModel.projectOptions}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("projectId")}
                    value={viewModel.project}
                    canExecute={viewModel.canEditIEOrProject}
                />
            </Cell>

            <Cell ts={2} ds={2}>
                <DarwinSelect
                    displayName="I & E:*"
                    execute={setIncomeAndExpenditure}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    onBlur={() => viewModel.isFieldValid("ieId")}
                    options={viewModel.incomeAndExpendituresForProject}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("ieId")}
                    value={viewModel.incomeAndExpenditure}
                    canExecute={viewModel.canEditIEOrProject}
                />
            </Cell>

            <Cell display={{ xs: "none", md: "block" }} ts={2} ds={2} />

            {/* Group of 8 columns */}
            <Cell ts={2} ds={2}>
                <DarwinSelect
                    displayName="Order type:*"
                    execute={viewModel.setOrderType}
                    fullWidth={true}
                    getOptionLabel={(option: any) => option.displayName}
                    onBlur={() => viewModel.isFieldValid("orderTypeId")}
                    options={viewModel.orderTypes}
                    placeholder="Please select"
                    validationMessage={viewModel.getError("orderTypeId")}
                    value={viewModel.orderType}
                    canExecute={viewModel.canExecuteAmendingField}
                />
            </Cell>

            {viewModel.canDisplayLabourTypeFeatures && (
                <>
                    <Cell ts={2} ds={2}>
                        <DarwinDateSelect
                            displayName="Week commencing:*"
                            execute={(value: string | null) => viewModel.setValue("weekCommencing", value)}
                            onBlur={() => viewModel.isFieldValid("weekCommencing")}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("weekCommencing")}
                            value={viewModel.getValue("weekCommencing")}
                            canExecute={viewModel.canExecuteAmendingField}
                        />
                    </Cell>

                    <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />
                </>
            )}

            {viewModel.canDisplayPurchaseOrderTypeFeatures && (
                <>
                    <Cell ts={2} ds={2}>
                        <DarwinDateSelect
                            displayName="Date required:*"
                            execute={(value: string | null) => viewModel.setValue("dateRequired", value)}
                            onBlur={() => viewModel.isFieldValid("dateRequired")}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("dateRequired")}
                            value={viewModel.getValue("dateRequired")}
                            canExecute={viewModel.canExecuteDateRequiredField}
                        />
                    </Cell>

                    <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />
                </>
            )}

            {viewModel.canDisplayHireOrderTypeFeatures && (
                <>
                    <Cell ts={2} ds={2}>
                        <DarwinDateSelect
                            displayName="Date from:*"
                            execute={(value: string | null) => viewModel.setValue("dateFrom", value)}
                            onBlur={() => viewModel.isFieldValid("dateFrom")}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("dateFrom")}
                            value={viewModel.getValue("dateFrom")}
                            canExecute={viewModel.canExecuteHireOrderDateField}
                        />
                    </Cell>

                    <Cell ts={2} ds={2}>
                        <DarwinDateSelect
                            displayName="Date to:*"
                            execute={(value: string | null) => viewModel.setValue("dateTo", value)}
                            onBlur={() => viewModel.isFieldValid("dateTo")}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("dateTo")}
                            value={viewModel.getValue("dateTo")}
                            canExecute={viewModel.canExecuteHireOrderDateField}
                        />
                    </Cell>

                    <Cell display={{ xs: "none", md: "block" }} ts={2} ds={2} />
                </>
            )}

            {!viewModel.canDisplayHireOrderTypeFeatures && !viewModel.canDisplayPurchaseOrderTypeFeatures && <Cell display={{ xs: "none", md: "block" }} ts={6} ds={6} />}

            {/* Group of 8 columns */}
            <Cell ts={6} ds={6}>
                <DarwinInput<PurchaseOrderModel>
                    type="text"
                    label="Purchase order description:*"
                    placeholder="Description"
                    validateOnBlur={true}
                    viewModel={viewModel}
                    fieldName="description"
                    shrink={true}
                    editMode={viewModel.canExecuteAmendingField}
                />
            </Cell>

            <Cell display={{ xs: "none", md: "block" }} ts={2} ds={2} />

            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                <SolidDivider gutterBottom />
            </Cell>
        </Grid>
    );
});

import * as mobx from "mobx";
import { FieldType, observable, ViewModelBase } from "@shoothill/core";
import { PurchaseOrderModel, PurchaseOrderModelDTO, PurchaseOrderTableModel } from "./PurchaseOrder.Model";
import { PurchaseOrderViewModel } from "./PurchaseOrder.ViewModel";
import { AppUrls } from "AppUrls";
import { debounce } from "@material-ui/core";
import { csvAxiosRequestConfig, exportPDFWithGet } from "Utils/Utils";

export class PurchaseOrderListViewModel extends ViewModelBase<any> {
    private static _instance: PurchaseOrderListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public constructor() {
        super(new PurchaseOrderModel(), false);
        this.setDecorators(PurchaseOrderModel);
    }

    @observable
    private purchaseOrdersArr: mobx.IObservableArray<PurchaseOrderViewModel> = observable<PurchaseOrderViewModel>([]);

    @mobx.computed
    public get returnPurchaseOrders(): PurchaseOrderTableModel[] {
        return this.purchaseOrdersArr
            .filter((vm) => vm.matchesFilter(this.filterSearchString))
            .map((item: PurchaseOrderViewModel) => {
                console.log(item.model);
                return item.model.toTableModel();
            });
    }

    @mobx.action
    private populateViewModels = (dto: PurchaseOrderTabsDTO) => {
        const po: PurchaseOrderViewModel[] = [];

        for (const item of dto.purchaseOrderLists) {
            let vm = new PurchaseOrderViewModel();
            vm.model.fromDto(item);
            po.push(vm);
        }

        this.purchaseOrdersArr.replace(po);
    };

    @mobx.action
    public apiGetPurchaseOrders = async (val: string): Promise<void> => {
        const request: RequestModel = {
            Id: val,
        };
        let apiResult = await this.Post<PurchaseOrderTabsDTO>(AppUrls.Server.Projects.IncomeExpend.GetPurchaseOrderList, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    @mobx.action
    public apiGeneratePOPDF = async (id: string) => {
        await exportPDFWithGet(AppUrls.Server.PurchaseOrder.GeneratePOPDFById, { id: id }, await this.getConfig(true, csvAxiosRequestConfig));
    };

    // #region Search String Filter

    private readonly DEBOUNCE_VALUE_MS = 200;

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return mobx.computed(() => this.searchString);
    };

    @mobx.action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        mobx.action((value: string) => {
            this.filterSearchString = value;
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    // #endregion Search String Filter

    public async isFieldValid(fieldName: keyof FieldType<PurchaseOrderModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface PurchaseOrderTabsDTO {
    purchaseOrderLists: PurchaseOrderModelDTO[];
}

export interface RequestModel {
    Id: string;
}

import { ApiResult, isNullOrEmpty } from "@shoothill/core";
import { Lambda, observe } from "mobx";
import axios, * as Axios from "axios";

/**
 * Determines if the supplied object is null or undefined.
 */
export const isNullOrUndefined = (object: any | undefined | null): boolean => {
    return object === undefined || object === null;
};

/**
 * Determines if the supplied object is a number.
 *
 * This is useful if a property is used for a numeric input field. Such an input
 * field has to potentially handle negative numbers and decimal numbers. As a user
 * types, the field will be in a non-numeric intermediate state (i.e. '-' or '10.')
 * which go on to be numbers when the user completes typing (i.e. '-10' or '10.123').
 *
 * So before the property can be used (i.e. in a calculation), we can check if it is
 * a number to avoid potential errors.
 */
export const isTypeOfNumber = (value: any): boolean => {
    return typeof value === "number" && !isNaN(value);
};

export const isValidDate = (date: any): boolean => {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
};

export const isEmail = (item: string): boolean => {
    let retVal: boolean = false;
    const regexp = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    retVal = regexp.test(item);

    return retVal;
};

export const isEmpty = (str: string | null) => {
    return !str || str.length === 0;
};

export const generateID = (): string => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return "_" + Math.random().toString(36).substr(2, 9);
};

export const formatCreditDebitClass = (val: number | null) => {
    let retVal = " notdebitorcredit";
    if (val && val > 0) {
        retVal = " debit";
    }

    if (val && val < 0) {
        retVal = " credit";
    }
    return retVal;
};

export const formatCreditDebitReverseClass = (val: number | null) => {
    let retVal = "";
    if (val && val > 0) {
        retVal = " debit";
    }

    if (val && val < 0) {
        retVal = " credit";
    }
    return retVal;
};

export const formatCreditDebitReverseWithNeutralClass = (val: number | null) => {
    let retVal = "notdebitorcredit";
    if (val && val > 0) {
        retVal = " debit";
    }

    if (val && val < 0) {
        retVal = " credit";
    }
    return retVal;
};

export const getCreditDebitBackgroundColor = (val: number | null) => {
    let retVal = "#ebd900bf";
    if (val && val > 0) {
        retVal = "#ecb4b4";
    }

    if (val && val < 0) {
        retVal = "#caead4";
    }
    return retVal;
};

export const getCreditDebitReverseBackgroundColor = (val: number | null) => {
    let retVal = "unset";
    if (val && val > 0) {
        retVal = "#ecb4b4";
    }

    if (val && val < 0) {
        retVal = "#caead4";
    }
    return retVal;
};

/**
 * This helper method is used to link a mobx observable array to a mobx observable array of viewmodels
 * @param observableModelArray The observable array to link to that exists in your model
 * @param observableViewModelArray The observable array of viewmodels to link to that exists in your viewmodel
 * @param viewModelClass The type of viewmodel to create. IE TodoItemViewModel
 */
export const viewModelModelLink = (observableModelArray: any, observableViewModelArray: any, viewModelClass: any, logMessage: string = ""): Lambda => {
    const disposable: Lambda = observe(observableModelArray, (childModelChanges: any) => {
        if (!isNullOrEmpty(logMessage)) {
            console.log(`${logMessage}`, "Adding viewModel");
        }
        try {
            for (const addedChildModel of childModelChanges.added) {
                observableViewModelArray.push(new viewModelClass(addedChildModel));
            }

            for (const removedChildModel of childModelChanges.removed) {
                const childViewModelToRemove = observableViewModelArray.find((vm: any) => vm.model.KEY === removedChildModel.KEY);

                if (childViewModelToRemove) {
                    observableViewModelArray.remove(childViewModelToRemove);
                }
            }
        } catch (e: any) {
            console.log(`${viewModelClass.constructor.name} viewModelModelLink`, e);
        }
    });
    return () => {
        console.log(`${viewModelClass.constructor.name} viewModelModelLink`, "Disposing of observer");
        disposable();
    };
};

// List of supported file types and their mime types.
// JC: This should be kept inline with the SupportedFileExtensions dictionary in FileHelper.cs.
// Also see FileStore.cs > Create.
export const mimeTypeMap: Record<string, string> = {
    pdf: "application/pdf",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    txt: "text/plain",
    zip: "application/zip",
    csv: "text/csv",
    msg: "application/vnd.ms-outlook",
};

/**
 * List of file types that should be opened in a new tab first when downloading.
 */
export const newTabTypes = () => {
    const filteredMimeTypeMap: Record<string, string> = {};
    const extensionsToFilter: string[] = ["pdf", "jpg", "jpeg", "png", "gif"];

    for (const key in mimeTypeMap) {
        const fileExtension: string = key.toLowerCase();
        if (extensionsToFilter.includes(fileExtension)) {
            filteredMimeTypeMap[key] = mimeTypeMap[key];
        }
    }

    return filteredMimeTypeMap;
};

/**
 * Checks if a file should be opened in a new tab first when downloading. Based on the file extension.
 * @param extension The extension of the file.
 * @returns True if the file should be opened in a new tab when downloading, false otherwise.
 */
export const isNewTabType = (extension: string) => {
    const types: Record<string, string> = newTabTypes();
    return types.hasOwnProperty(extension.toLowerCase());
};

/**
 * Handles opening a file in a new tab. currently supports pdf, jpg, jpeg, png and gif.
 * The unfortunate downside of this method is that you can't manually set the file name. It will be set to a random id generated by the browser.
 * @param file The file to be opened.
 * @param fileName The name of the file including the extension.
 */
export const openFileInNewTab = (file: ApiResult<Blob>, fileName: string): void => {
    // Determine the MIME type based on the file extension
    let mimeType: string;

    const fileExtension: string = fileName.split(".").pop()!.toLowerCase();

    if (mimeTypeMap[fileExtension]) {
        mimeType = mimeTypeMap[fileExtension];
    } else {
        throw new Error(`Unsupported file type: ${fileExtension}`);
    }

    const blobObject: Blob = new Blob([file as any], { type: mimeType });

    // Create a Blob URL from the Blob object
    const blobUrl: string = URL.createObjectURL(blobObject);

    // Create a new window with the Blob URL as the source
    const newWindow = window.open(blobUrl, "_blank");

    // Hack because I can't get the revoke to trigger when the window closes.
    setTimeout(() => URL.revokeObjectURL(blobUrl), 20000);

    // if (newWindow) {
    //     // Add a "unload" event listener to the new window
    //     newWindow.addEventListener("unload", () => {
    //         // When the new window is closed, revoke the Blob URL to free up memory. This avoids a memory leak.
    //         // If revokeObjectURL is called instantly, then the user won't be able to download the file.
    //         // Which is why we wait for unload to be triggered.
    //         URL.revokeObjectURL(blobUrl);
    //     });
    //     // Add a "beforeunload" event listener to the new window
    //     newWindow.addEventListener("beforeunload", () => {
    //         // When the new window is closed, revoke the Blob URL to free up memory. This avoids a memory leak.
    //         // If revokeObjectURL is called instantly, then the user won't be able to download the file.
    //         // Which is why we wait for unload to be triggered.
    //         URL.revokeObjectURL(blobUrl);
    //     });
    // }
};

/**
 * Determines whether a number has two or fewer decimal places.
 * @param value The number to be validated.
 * @returns True if valid, false if invalid.
 */
export const validateTwoDecimalPlaces = (value: number): boolean => {
    // Regular expression to match numbers with up to 2 decimal places
    const regex: RegExp = /^-?\d+(\.\d{1,2})?$/;

    const valueString: string = value.toString();

    if (valueString === "" || valueString === "NaN") {
        return false;
    }

    return regex.test(valueString);
};

/**
 * Determines whether a number has four or fewer decimal places.
 * @param value The number to be validated.
 * @returns True if valid, false if invalid.
 */
export const validateFourDecimalPlaces = (value: number): boolean => {
    // Regular expression to match numbers with up to 2 decimal places
    const regex: RegExp = /^-?\d+(\.\d{1,4})?$/;

    const valueString: string = value.toString();

    if (valueString === "" || valueString === "NaN") {
        return false;
    }

    return regex.test(valueString);
};

export const formatDebit = (val: number) => {
    return val < 0 ? " debit" : "";
};

export const getTotalPrice = (units: number, pricePerUnit: number): number => {
    const totalPrice4dp = round(units * pricePerUnit + Number.EPSILON, 4);
    const totalPrice2dp = round(totalPrice4dp + Number.EPSILON, 2);

    return totalPrice2dp;
};

export const round = (value: number, precision: number): number => {
    const multiplier = Math.pow(10, precision || 0);
    const roundedValue = Math.round(value * multiplier) / multiplier;

    return roundedValue;
};

export let csvAxiosRequestConfig: Axios.AxiosRequestConfig = {
    responseType: "blob",
    headers: {
        "Content-Type": "application/json",
    },
};

/**
 * Handles the axios request to download a CSV from a HTTP POST Endpoint.
 * @param url The URL of the endpoint.
 * @param request The request. Ids, dates etc.
 * @param config The config required for auth.
 * @returns Promise.
 */
export const exportCSV = async (url: string, request: any, config: Axios.AxiosRequestConfig): Promise<void> => {
    // JC: Download a CSV file using a HTTP POST request.
    // Source: https://stackoverflow.com/a/55138366

    return await axios.post(url, request, config).then((response: any) => {
        if (response.status === 200) {
            const headerFileName: string = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
            let fileName: string = "exportedFile.csv";
            if (headerFileName.endsWith(".csv")) {
                fileName = headerFileName;
            }
            const url_1 = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url_1;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url_1);
        }
    });
};

/**
 * Handles the axios request to download a PDF from a HTTP POST Endpoint.
 * @deprecated Use exportPDFWithGet instead.
 * @param url The URL of the endpoint.
 * @param request The request. Ids, dates etc.
 * @param config The config required for auth.
 * @returns Promise.
 */
export const exportPDF = async (url: string, request: any, config: Axios.AxiosRequestConfig): Promise<void> => {
    // JC: Download a PDF file using a HTTP POST request.
    // Source: https://stackoverflow.com/a/55138366

    return await axios.post(url, request, config).then((response: any) => {
        if (response.status === 200) {
            const headerFileName: string = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
            let fileName: string = "exportedPDF.pdf";
            if (headerFileName.endsWith(".pdf")) {
                fileName = headerFileName;
            }
            const url_1 = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url_1;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url_1);
        }
    });
};

/**
 * Handles the axios request to download a PDF from a HTTP GET Endpoint with query parameters.
 * @param url The base URL of the endpoint.
 * @param request The request parameters (e.g., IDs, dates, etc.).
 * @param config The config required for auth and other configurations.
 * @returns Promise.
 */
export const exportPDFWithGet = async (url: string, request: any, config: Axios.AxiosRequestConfig): Promise<void> => {
    // Filter out null or undefined values from the request object otherwise they are sent as "null" instead of null.
    const filteredRequest = Object.entries(request).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined) {
            acc[key] = value;
        }
        return acc;
    }, {});

    // Construct query string from request object
    const queryString = new URLSearchParams(filteredRequest).toString();
    const fullUrl = `${url}?${queryString}`;

    return await axios.get(fullUrl, { ...config, responseType: "blob" }).then((response) => {
        if (response.status === 200) {
            let headerFileName: string = response.headers["content-disposition"].split("filename=")[1].split(";")[0];

            // When the server includes the filename in the Content-Disposition header, it often encloses the filename in double quotes, especially if the filename contains spaces or other special characters.
            // Remove double quotes from the beginning and end of the filename if present
            headerFileName = headerFileName.replace(/^"(.*)"$/, "$1");

            let fileName: string = "exportedPDF.pdf";
            if (headerFileName.endsWith(".pdf")) {
                fileName = headerFileName;
            }

            // Create a URL for the blob and trigger the download
            const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();

            // Cleanup
            link.remove();
            window.URL.revokeObjectURL(url);
        }
    });
};

import * as React from "react";
import { SVGProps } from "react";

export const DownloadFileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={16} {...props}>
        <g data-name="Group 7189">
            <g data-name="Path 3233" fill="#fff">
                <path d="M11.5 15.5H.514V4.458L.501.5h7.291L11.5 4.162V15.5Z" />
                <path d="m1.003 1 .011 3.458V15H11V4.37L7.587 1H1.003M0 0h7.998L12 3.953V16H.014V4.458L0 0Z" fill="#707070" />
            </g>
            <path data-name="Polygon 6" d="m6.007 11-3-3h6Z" fill="#e03c31" />
            <path data-name="Rectangle 1595" fill="#e03c31" d="M5.007 5h2v3h-2z" />
            <g data-name="Path 3299" fill="#d4d4d4">
                <path d="M10.787 3.875H8.094V1.201l2.693 2.674Z" />
                <path d="M8.594 2.402v.973h.98l-.98-.973M7.594 0 12 4.375H7.594V0Z" fill="#707070" />
            </g>
        </g>
    </svg>
);

import { isEmptyOrWhitespace } from "@shoothill/core";

export class StockHistoryItemViewModel {
    // #region Properties

    public note: string = "";
    public pricePerUnit: number = 0;
    public purchaseOrderId: string | null = null;
    public purchaseOrderNumber: string | null = null;
    public stockQuantity: number = 0;
    public stockTransactionType: string = "";
    public stockTransactionRequestNumber: string = "";
    public stockValue: number = 0;
    public skuNumber: string = "";
    public unitChange: number = 0;
    public updatedByUser: string = "";
    public updatedDate: string = "";
    public stockTransactionFrom: string = "";
    public stockTransactionTo: string = "";

    // #endregion Properties

    public get canDisplayPurchaseOrder() {
        return !isEmptyOrWhitespace(this.purchaseOrderId) && !isEmptyOrWhitespace(this.purchaseOrderNumber);
    }

    public get canDisplayStockTransactionRequestNumber() {
        return isEmptyOrWhitespace(this.purchaseOrderId) && !isEmptyOrWhitespace(this.stockTransactionRequestNumber);
    }
}

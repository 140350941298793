import { Link } from "@material-ui/core";
import { isNullOrUndefined, useRouter } from "@shoothill/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";
//import { ProjectBreadCrumbSection } from "./ProjectBreadCrumbSection";

export const VariationMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <>
            <Link component={RouterLink} to={AppUrls.Client.Project.List} className={classes.heading}>
                Projects
            </Link>
        </>
    );
};

import React, { useContext } from "react";

import { AppUrls } from "AppUrls";
import { Stores, StoresContext, StoresInstance } from "Globals/Stores";
import { DarwinLayout } from "Globals/Views/Layouts/DarwinLayout";
import { PrivateRoute } from "Globals/Views/PrivateRoute";
import * as RouterUtil from "Utils/Routing";
import { MaterialView } from "./Material/Form/MaterialView";
import { MaterialsListView } from "./Material/Table/MaterialsListView";
import { StockListView } from "./Stock/Table/StockListView";

export const StockRoutes: React.FC = () => {
    function getRootLayout() {
        return DarwinLayout;
    }
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                exact
                path={AppUrls.Client.Stock.List}
                component={StockListView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && store.Domain.AccountStore.getCanViewStock && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.Stock.MaterialList}
                component={MaterialsListView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && store.Domain.AccountStore.getCanViewStock && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />

            <PrivateRoute
                exact
                path={AppUrls.Client.Stock.MaterialAdd}
                component={MaterialView}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && store.Domain.AccountStore.getCanViewStock && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
        </>
    );
};

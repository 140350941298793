import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class SupplierDetailsModel extends ModelBase<SupplierDetailsModel, SupplierDetailsDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Defaults and Constants

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_ADDRESS = "";
    public static readonly DEFAULT_CONTACTNAME = "";
    public static readonly DEFAULT_CONTACTEMAIL = "";
    public static readonly DEFAULT_CONTACTNUMBER = "";
    public static readonly DEFAULT_PAYMENTTERMS = "";
    public static readonly DEFAULT_PAYMENTTERMSINDAYS = 0;
    public static readonly DEFAULT_PAYMENTTYPEID = "";

    // #endregion Defaults and Constants

    // #region Observables

    @observable
    public id: string = SupplierDetailsModel.DEFAULT_ID;

    @observable
    public displayName: string = SupplierDetailsModel.DEFAULT_DISPLAYNAME;

    @observable
    public address: string = SupplierDetailsModel.DEFAULT_ADDRESS;

    @observable
    public contactName: string = SupplierDetailsModel.DEFAULT_CONTACTNAME;

    @observable
    public contactEmail: string = SupplierDetailsModel.DEFAULT_CONTACTEMAIL;

    @observable
    public contactNumber: string = SupplierDetailsModel.DEFAULT_CONTACTNUMBER;

    @observable
    public paymentTerms: string = SupplierDetailsModel.DEFAULT_PAYMENTTERMS;

    @observable
    public paymentTermsInDays: number = SupplierDetailsModel.DEFAULT_PAYMENTTERMSINDAYS;

    public paymentTypeId: string = SupplierDetailsModel.DEFAULT_PAYMENTTYPEID;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = SupplierDetailsModel.DEFAULT_ID;
        this.displayName = SupplierDetailsModel.DEFAULT_DISPLAYNAME;
        this.address = SupplierDetailsModel.DEFAULT_ADDRESS;
        this.contactName = SupplierDetailsModel.DEFAULT_CONTACTNAME;
        this.contactEmail = SupplierDetailsModel.DEFAULT_CONTACTEMAIL;
        this.contactNumber = SupplierDetailsModel.DEFAULT_CONTACTNUMBER;
        this.paymentTerms = SupplierDetailsModel.DEFAULT_PAYMENTTERMS;
        this.paymentTermsInDays = SupplierDetailsModel.DEFAULT_PAYMENTTERMSINDAYS;
        this.paymentTypeId = SupplierDetailsModel.DEFAULT_PAYMENTTYPEID;
    }

    @action
    public fromDto(dto: SupplierDetailsDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
        this.address = dto.address;
        this.contactName = dto.contactName;
        this.contactEmail = dto.contactEmail;
        this.contactNumber = dto.contactNumber;
        this.paymentTerms = dto.paymentTerms;
        this.paymentTermsInDays = dto.paymentTermsInDays;
        this.paymentTypeId = dto.paymentTypeId;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions

    // #region Helpers

    public static fromDtos(dtos: SupplierDetailsDTO[]): SupplierDetailsModel[] {
        const types: SupplierDetailsModel[] = [];

        for (const dto of dtos) {
            const model = new SupplierDetailsModel();

            model.fromDto(dto);
            types.push(model);
        }

        return types;
    }

    // #endregion Helpers
}

export interface SupplierDetailsDTO {
    id: string;
    displayName: string;
    address: string;
    contactName: string;
    contactEmail: string;
    contactNumber: string;
    paymentTerms: string;
    paymentTermsInDays: number;
    paymentTypeId: string;
}

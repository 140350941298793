import { useObserver, useRouter } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { IBreadcrumbContextIdValues } from "Globals/Models/Breadcrumb";
import React, { useEffect, useState } from "react";
import { useRouteMatch, Link as RouterLink } from "react-router-dom";
import { BreadcrumbFooter, BreadcrumbSection1, BreadcrumbSection2, BreadcrumbWrapper, DarwinBreadcrumbs, useBreadcrumbStyles } from "./Breadcrumb.styles";
import PlusSign from "Content/WhitePlus.svg";
import ArrowSign from "Content/Arrow.svg";
import AddEditImg from "Content/AddEdit.svg";
import ExportImg from "Content/Export.svg";
import { BreadcrumbViewModel } from "./BreadcrumbViewModel";

import * as AdminClient from "./Admin/AdminClientBreadCrumb";
import * as AdminUser from "./Admin/AdminUserBreadCrumb";
import * as Approvals from "./Approvals/ApprovalBreadCrumb";

import * as Central from "./Central/CentralBreadCrumb";
import * as Invoice from "./Invoices/InvoiceBreadCrumb";
import * as Project from "./Project/ProjectBreadCrumb";
import * as IE from "./IE/IEBreadCrumb";
import * as Purchase from "./PurchaseOrder/PurchaseBreadCrumb";
import * as Report from "./Report/ReportBreadCrumb";
import * as Stock from "./Stock/StockBreadCrumb";
import * as Supplier from "./Supplier/SupplierBreadCrumb";
import * as Variation from "./Variation/VariationBreadCrumb";

import { ProjectBreadCrumbSection } from "Views/Breadcrumb/Project/ProjectBreadCrumbSection";
import { ChangeLog } from "Globals/Views/Changelog/Changelog";
import { IEBreadCrumbSection } from "./IE/IEBreadCrumbSection";
import { ReportBreadCrumbSection } from "./Report/ReportBreadCrumbSection";

export const BreadcrumbView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new BreadcrumbViewModel());
    const classes: any = useBreadcrumbStyles();

    const router = useRouter<{
        approvalid?: string;
        centralid?: string;
        //clientid?: string;
        invoiceid?: string;
        projectid?: string;
        poid?: string;
        reportid?: string;
        stockid?: string;
        supplierid?: string;
        userid?: string;
        ieid?: string;
    }>();

    const [matchIdData, setContextData] = React.useState<IBreadcrumbContextIdValues>({
        approvalid: undefined,
        centralid: undefined,
        //clientid: undefined,
        invoiceid: undefined,
        projectid: undefined,
        poid: undefined,
        reportid: undefined,
        stockid: undefined,
        supplierid: undefined,
        userid: undefined,
        ieid: undefined,
    });

    //useEffect below only gets run on initial render
    useEffect(() => {
        // eslint-disable-next-line prettier/prettier
        return () => {};
    }, []);

    useEffect(() => {
        const { approvalid, centralid, /* clientid, */ invoiceid, projectid, poid, reportid, stockid, supplierid, userid, ieid } = router.match.params;

        if (
            approvalid !== matchIdData.approvalid ||
            centralid !== matchIdData.centralid ||
            /* clientid !== matchIdData.clientid || */
            invoiceid !== matchIdData.invoiceid ||
            projectid !== matchIdData.projectid ||
            poid !== matchIdData.poid ||
            reportid !== matchIdData.reportid ||
            stockid !== matchIdData.stockid ||
            supplierid !== matchIdData.supplierid ||
            userid !== matchIdData.userid ||
            ieid !== matchIdData.ieid
        ) {
            const temp: IBreadcrumbContextIdValues = {
                centralid: centralid,
                /* clientid: clientid, */
                invoiceid: invoiceid,
                projectid: projectid,
                poid: poid,
                reportid: reportid,
                stockid: stockid,
                supplierid: supplierid,
                userid: userid,
                ieid: ieid,
            };

            setContextData(temp);
        }
    }, [location]);

    const homeMatch = useRouteMatch({
        path: AppUrls.Client.Home,
        exact: true,
        strict: true,
        sensitive: true,
    });

    const approvalMatch = useRouteMatch({ path: AppUrls.Client.Approval.List, exact: true });
    // const approvalInvoiceMatch = useRouteMatch({ path: AppUrls.Client.Approval.Invoice, exact: true });
    // const approvalLabourMatch = useRouteMatch({ path: AppUrls.Client.Approval.Labour, exact: true });
    // const approvalProjectMatch = useRouteMatch({ path: AppUrls.Client.Approval.Project, exact: true });
    const approvalMyAmendsMatch = useRouteMatch({ path: AppUrls.Client.Approval.MyAmends, exact: true });
    // const approvalStockMatch = useRouteMatch({ path: AppUrls.Client.Approval.Stock, exact: true });
    // const approvalSupplierMatch = useRouteMatch({ path: AppUrls.Client.Approval.Supplier, exact: true });
    // const approvalVariationsMatch = useRouteMatch({ path: AppUrls.Client.Approval.Variations, exact: true });

    const centralMatch = useRouteMatch(AppUrls.Client.Central.View);
    //const centralDetailMatch = useRouteMatch(AppUrls.Client.Central.Detail) || useRouteMatch(AppUrls.Client.Central.Edit);

    const invoiceMatch = useRouteMatch(AppUrls.Client.Invoicing.List) || useRouteMatch(AppUrls.Client.Invoicing.Edit) || useRouteMatch(AppUrls.Client.Invoicing.Detail);

    const reportMatch = useRouteMatch(AppUrls.Client.Report.AllProject) || useRouteMatch(AppUrls.Client.Report.Project) || useRouteMatch(AppUrls.Client.Report.IE);

    const projectMatch = useRouteMatch(AppUrls.Client.Project.List) || useRouteMatch(AppUrls.Client.Project.GeneralAdd);
    const projectDetailMatch = useRouteMatch(AppUrls.Client.Project.General) || useRouteMatch(AppUrls.Client.Project.GeneralEdit);

    const projectSections: ProjectBreadCrumbSection = {
        projectList: useRouteMatch({ path: AppUrls.Client.Project.List, exact: true }),
        projectGeneral:
            useRouteMatch({ path: AppUrls.Client.Project.General, exact: true }) ||
            useRouteMatch({ path: AppUrls.Client.Project.GeneralAdd, exact: true }) ||
            useRouteMatch({ path: AppUrls.Client.Project.GeneralEdit, exact: true }),
        projectCommercial: useRouteMatch({ path: AppUrls.Client.Project.Commercial, exact: true }),
        projectContractual:
            useRouteMatch({ path: AppUrls.Client.Project.Contractual, exact: true }) || useRouteMatch({ path: AppUrls.Client.Project.ContractualEdit, exact: true }),
        projectConstruction:
            useRouteMatch({ path: AppUrls.Client.Project.Construction, exact: true }) ||
            useRouteMatch({ path: AppUrls.Client.Project.ConstructionEdit, exact: true }) ||
            useRouteMatch({ path: AppUrls.Client.Project.ConstructionHAndS, exact: true }) ||
            useRouteMatch({ path: AppUrls.Client.Project.ConstructionQuality, exact: true }),
        projectDelivery: useRouteMatch({ path: AppUrls.Client.Project.Delivery, exact: true }),
        projectSiteAccess: useRouteMatch({ path: AppUrls.Client.Project.SiteAccess, exact: true }),
        projectProjectTrackers:
            useRouteMatch({ path: AppUrls.Client.Project.ProjectTrackers, exact: true }) ||
            useRouteMatch({ path: AppUrls.Client.Project.DesignData, exact: true }) ||
            useRouteMatch({ path: AppUrls.Client.Project.RequestForInformation, exact: true }),
        // projectCompleted: useRouteMatch({ path: AppUrls.Client.Project.Completed, exact: true }),
    };

    const ieMatch = useRouteMatch(AppUrls.Client.Project.IE);

    const ieSections: IEBreadCrumbSection = {
        ieDetails: useRouteMatch({ path: AppUrls.Client.Project.IE, exact: true }),
    };

    const purchaseOrderMatch =
        useRouteMatch({ path: AppUrls.Client.PurchaseOrder.List, exact: true }) ||
        useRouteMatch(AppUrls.Client.PurchaseOrder.Edit) ||
        useRouteMatch(AppUrls.Client.PurchaseOrder.View);

    const requisitionsMatch =
        useRouteMatch({ path: AppUrls.Client.PurchaseOrder.RequisitionList, exact: true }) ||
        useRouteMatch(AppUrls.Client.PurchaseOrder.Add) ||
        useRouteMatch(AppUrls.Client.PurchaseOrder.AddIE);

    const rejectedMatch = useRouteMatch({ path: AppUrls.Client.PurchaseOrder.RejectedList, exact: true });

    // const purchaseOrderMatch = useRouteMatch({ path: AppUrls.Client.PurchaseOrder.List, exact: true });

    // const requisitionsMatch = useRouteMatch({ path: AppUrls.Client.PurchaseOrder.RequisitionList, exact: true });

    const variationMatch =
        useRouteMatch(AppUrls.Client.Variation.Add) ||
        useRouteMatch(AppUrls.Client.Variation.AddIE) ||
        useRouteMatch(AppUrls.Client.Variation.Edit) ||
        useRouteMatch(AppUrls.Client.Variation.View);

    const stockMatch = useRouteMatch({ path: AppUrls.Client.Stock.List, exact: true });
    const materialListMatch = useRouteMatch({ path: AppUrls.Client.Stock.MaterialList, exact: true });
    const materialAddMatch = useRouteMatch({ path: AppUrls.Client.Stock.MaterialAdd, exact: true });
    const materialMatch = materialListMatch || materialAddMatch;

    const supplierMatch = useRouteMatch({ path: AppUrls.Client.Supplier.List, exact: true });
    const supplierDetailMatch = useRouteMatch({ path: AppUrls.Client.Supplier.Detail, exact: true }) || useRouteMatch({ path: AppUrls.Client.Supplier.Edit, exact: true });

    const adminMatch =
        useRouteMatch({
            path: AppUrls.Client.Admin.Root,
            exact: true,
        }) ||
        useRouteMatch({
            path: AppUrls.Client.Admin.User.List,
            exact: true,
        }) ||
        useRouteMatch({
            path: AppUrls.Client.Admin.User.Add,
            exact: true,
        });

    const adminMatchDetail =
        useRouteMatch({
            path: AppUrls.Client.Admin.User.Edit,
            exact: true,
        }) ||
        useRouteMatch({
            path: AppUrls.Client.Admin.User.Detail,
            exact: true,
        });

    const adminClientMatch = useRouteMatch({ path: AppUrls.Client.Admin.Client.List, exact: true });
    const adminClientDetailMatch =
        useRouteMatch({ path: AppUrls.Client.Admin.Client.Detail, exact: true }) || useRouteMatch({ path: AppUrls.Client.Admin.Client.Edit, exact: true });

    const reportSections: ReportBreadCrumbSection = {
        reportAllProject: useRouteMatch({ path: AppUrls.Client.Report.AllProject, exact: true }),
        reportProject: useRouteMatch({ path: AppUrls.Client.Report.Project, exact: true }),
        reportIE: useRouteMatch({ path: AppUrls.Client.Report.IE, exact: true }),
    };

    return useObserver(() => (
        <>
            <BreadcrumbWrapper>
                <DarwinBreadcrumbs className={classes.root} aria-label="breadcrumb">
                    <BreadcrumbSection1>
                        {(approvalMatch || homeMatch) && Approvals.approvalMatchSection1(classes)}
                        {/* {approvalInvoiceMatch && Approvals.approvalInvoiceMatchSection1(classes)}
                        {approvalLabourMatch && Approvals.approvalLabourMatchSection1(classes)}
                        {approvalProjectMatch && Approvals.approvalProjectMatchSection1(classes)} */}
                        {approvalMyAmendsMatch && Approvals.approvalPurchaseMatchSection1(classes)}
                        {/* {approvalStockMatch && Approvals.approvalStockMatchSection1(classes)}
                        {approvalSupplierMatch && Approvals.approvalSupplierMatchSection1(classes)}
                        {approvalVariationsMatch && Approvals.approvalVariationMatchSection1(classes)} */}
                        {centralMatch && Central.CentralMatchSection1(classes)}
                        {/* {centralDetailMatch && Central.CentralDetailMatchSection1(classes, ArrowSign)} */}
                        {invoiceMatch && Invoice.InvoiceMatchSection1(classes)}
                        {projectMatch && Project.ProjectMatchSection1(matchIdData.projectid!, matchIdData.ieid!, viewModel, projectSections, classes)}
                        {ieMatch && IE.IEMatchSection1(matchIdData.ieid!, viewModel, ieSections, classes)}
                        {purchaseOrderMatch && Purchase.PurchaseMatchSection1(classes)}
                        {requisitionsMatch && Purchase.PurchaseMatchSection3(classes)}
                        {rejectedMatch && Purchase.PurchaseMatchSection4(classes)}
                        {reportMatch && Report.ReportMatchSection1(viewModel, reportSections, classes)}

                        {stockMatch && Stock.StockMatchSection1StockList(classes)}
                        {materialMatch && Stock.StockMatchSection1MaterialList(classes)}

                        {supplierMatch && Supplier.SupplierMatchSection1(classes)}
                        {supplierDetailMatch && Supplier.SupplierDetailMatchSection1(classes, ArrowSign)}
                        {adminMatch && AdminUser.adminUserMatchSection1(classes)}
                        {adminMatchDetail && AdminUser.adminUserDetailMatchSection1(classes, ArrowSign)}
                        {adminClientMatch && AdminClient.adminClientMatchSection1(classes)}
                        {adminClientDetailMatch && AdminClient.adminClientDetailMatchSection1(classes, ArrowSign)}
                        {variationMatch && Variation.VariationMatchSection1(classes)}
                    </BreadcrumbSection1>
                </DarwinBreadcrumbs>
                <BreadcrumbSection2>
                    {/* {centralMatch && Central.CentralMatchSection2(viewModel, classes, PlusSign)} */}
                    {/* {centralDetailMatch && Central.CentralDetailMatchSection2(viewModel, classes, AddEditImg, ExportImg)} */}
                    {invoiceMatch && Invoice.InvoiceMatchSection2(viewModel, classes, PlusSign)}
                    {projectMatch && Project.ProjectMatchSection2(matchIdData.projectid!, viewModel, projectSections, classes, PlusSign)}
                    {(purchaseOrderMatch || requisitionsMatch || rejectedMatch) && Purchase.PurchaseMatchSection2(viewModel, classes, PlusSign)}

                    {stockMatch && Stock.StockMatchSection2StockActions(viewModel, classes, PlusSign)}
                    {materialListMatch && Stock.StockMatchSection2MaterialActions(viewModel, classes, PlusSign)}

                    {supplierMatch && Supplier.SupplierMatchSection2(viewModel, classes, PlusSign)}
                    {adminMatch && AdminUser.adminUserMatchSection2(viewModel, classes, PlusSign)}
                    {adminMatchDetail && AdminUser.adminUserDetailMatchSection2(viewModel, classes, PlusSign)}
                    {adminClientMatch && AdminClient.adminClientMatchSection2(viewModel, classes, PlusSign)}
                    {adminClientDetailMatch && AdminClient.adminClientDetailMatchSection2(viewModel, classes, AddEditImg, ExportImg)}
                </BreadcrumbSection2>
                <BreadcrumbFooter>
                    <ChangeLog />
                </BreadcrumbFooter>
            </BreadcrumbWrapper>
        </>
    ));
};

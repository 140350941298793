// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Loader, styled } from "@shoothill/core";

// Custom

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ControlLabel } from "Components/Form/ControlLabel";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { ProjectsAllReportViewModel } from "./ProjectsAllReportViewModel";
import { Project_DTO, Projects_DTO } from "./ProjectsAllReportModel";
import { formatCurrencyNoSign } from "Utils/Format";
import { formatCreditDebitClass, formatCreditDebitReverseClass, formatDebit } from "Utils/Utils";
import { SummaryGridView } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { ReportDatePickerContainer, RunReportButtonContainer } from "Views/Report/Report.styles";

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

export const ProjectsAllReportView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ProjectsAllReportViewModel());

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const onReportEndDateTopChanged = (date: string | null) => {
        viewModel.setValue("reportEndDateTop", date === null ? date : moment(date).startOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const onReportEndDateBottomChanged = (date: string | null) => {
        viewModel.setValue("reportEndDateBottom", date === null ? date : moment(date).startOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const runReport = () => {
        viewModel.runReport();
        viewModel.setCanExportCSV(true);
    };

    const exportCSV = () => {
        viewModel.generateReportCSV();
    };

    const renderGrid = (model: Projects_DTO | null) => {
        if (model === null) {
            return (
                <SummaryGridView>
                    <li className="heading">
                        <div>{"Project name"}</div>
                        <div>{"Reference"}</div>
                        <div>Income</div>
                        <div>Target cost</div>
                        <div>Committed cost</div>
                        <div>Future spend</div>
                        <div>Total expected spend</div>
                        <div>Variance</div>
                        <div>
                            Risk/
                            <br />
                            opportunity
                        </div>
                        <div></div>
                    </li>
                </SummaryGridView>
            );
        }
        return (
            <SummaryGridView>
                <li className="heading">
                    <div>Project name</div>
                    <div>Reference</div>
                    <div>Income</div>
                    <div>Target cost</div>
                    <div>Committed cost</div>
                    <div>Future spend</div>
                    <div>Total expected spend</div>
                    <div>Variance</div>
                    <div>
                        Risk/
                        <br />
                        opportunity
                    </div>
                </li>
                {model.projects &&
                    model.projects.map((obj: Project_DTO, index: number) => (
                        <>
                            <li className="project" key={"project_" + index}>
                                <div className="project-row-name">{obj.projectName}</div>
                                <div className="centre">{obj.projectReference}</div>
                                <div className="right">{formatCurrencyNoSign(obj.income)}</div>
                                <div className="right">{formatCurrencyNoSign(obj.estimateCost)}</div>
                                <div className={"right" + formatDebit(obj.committedCost)}>{formatCurrencyNoSign(obj.committedCost)}</div>
                                <div className="right">{formatCurrencyNoSign(obj.futureSpend)}</div>
                                <div className="right">{formatCurrencyNoSign(obj.totalExpectedSpend)}</div>
                                <div className={"right" + formatCreditDebitClass(obj.variance)}>{formatCurrencyNoSign(obj.variance)}</div>
                                <div className={"right" + formatCreditDebitReverseClass(obj.roValue)}>{formatCurrencyNoSign(obj.roValue)}</div>
                            </li>
                        </>
                    ))}
                {model.projects && model.projects.length > 0 && (
                    <>
                        <li className="footing">
                            <div className="right"></div>
                            <div className="right">Projects total</div>
                            <div className="right">{formatCurrencyNoSign(model.income)}</div>
                            <div className="right">{formatCurrencyNoSign(model.estimateCost)}</div>
                            <div className="right">{formatCurrencyNoSign(model.committedCost)}</div>
                            <div className="right">{formatCurrencyNoSign(model.futureSpend)}</div>
                            <div className="right">{formatCurrencyNoSign(model.totalExpectedSpend)}</div>
                            <div className={"right" + formatCreditDebitClass(model.variance)}>{formatCurrencyNoSign(model.variance)}</div>
                            <div className={"right" + formatCreditDebitReverseClass(model.roValue)}>{formatCurrencyNoSign(model.roValue)}</div>
                        </li>
                    </>
                )}
            </SummaryGridView>
        );
    };

    return useObserver(() => (
        <DetailsPage>
            <Typography variant="h1" style={{ marginLeft: "10px" }}>
                Projects report
            </Typography>
            <br />
            <ReportDatePickerContainer>
                <ControlLabel label="Date comparison:" htmlFor="start-date" />
                <Box>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportEndDateTopChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportEndDateTop}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                        />
                    </Box>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportEndDateBottomChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportEndDateBottom}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                        />
                    </Box>
                </Box>
            </ReportDatePickerContainer>

            <RunReportButtonContainer>
                <PrimaryButton displayName={"Run report"} execute={runReport} fullWidth={false} canExecute={!viewModel.IsLoading} />
                <PrimaryButton displayName={"Export CSV"} execute={exportCSV} fullWidth={false} canExecute={!viewModel.IsLoading && viewModel.canExportCSV} />
            </RunReportButtonContainer>

            <br />

            {!viewModel.IsLoading && (
                <>
                    {renderGrid(viewModel.topGridModel)}
                    {renderGrid(viewModel.bottomGridModel)}
                </>
            )}
            {viewModel.IsLoading && <Loader />}
        </DetailsPage>
    ));
};

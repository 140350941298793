import { action, computed, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { InvoiceProjectDTO, InvoiceProjectModel, UpsertInvoiceProjectDTO } from "./Supporting/InvoiceProjectModel";
import { InvoiceDisputedStatusCodeDTO } from "../Match/Supporting/InvoiceDisputedStatusCodeModel";
import { SupplierDTO } from "Views/PurchaseOrder/Form/Supporting/SupplierModel";
import { ProjectItemDTO } from "./InvoiceDetailsViewModel";
import moment from "moment";
import { validateTwoDecimalPlaces } from "Utils/Utils";
import { IsDateStringValid } from "Utils/DateTimeUtils";
export class InvoiceDetailsModel extends ModelBase<InvoiceDetailsModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_INVOICENUMBER = "";
    public static readonly DEFAULT_INVOICEDATE = null;
    public static readonly DEFAULT_DUEDATE = null;
    public static readonly DEFAULT_INVOICEVALUE = undefined;
    public static readonly DEFAULT_SUPPLIERID = null;
    public static readonly DEFAULT_INVOICESTATUSID = null;
    public static readonly DEFAULT_INVOICEDISPUTEDSTATUSCODEID = null;
    public static readonly DEFAULT_INVOICEDISPUTEDREASON = null;
    public static readonly DEFAULT_ROWVERSION = null;
    public static readonly DEFAULT_INVOICETAX = "";
    public static readonly DEFAULT_INVOICESENDEMAILTOSUPPLIER = true;
    public static readonly DEFAULT_POSTEDBYUSERID = null;
    public static readonly DEFAULT_POSTEDDATE = null;
    public static readonly DEFAULT_MATCHEDBYUSERID = null;
    public static readonly DEFAULT_MATCHEDDATE = null;
    public static readonly DEFAULT_INVOICEPROJECTS = [];
    public static readonly DEFAULT_INVOICEDOCUMENTS = [];
    public static readonly DEFAULT_IEID = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = InvoiceDetailsModel.DEFAULT_ID;

    @observable
    public invoiceNumber: string = InvoiceDetailsModel.DEFAULT_INVOICENUMBER;

    @observable
    public invoiceDate: string | null = InvoiceDetailsModel.DEFAULT_INVOICEDATE;

    @observable
    public dueDate: string | null = InvoiceDetailsModel.DEFAULT_DUEDATE;

    @observable
    public invoiceValue: number | undefined = InvoiceDetailsModel.DEFAULT_INVOICEVALUE;

    @observable
    public supplierId: string | null = InvoiceDetailsModel.DEFAULT_SUPPLIERID;

    @observable
    public invoiceStatusId: string | null = InvoiceDetailsModel.DEFAULT_INVOICESTATUSID;

    @observable
    public invoiceDisputedStatusCodeId: string | null = InvoiceDetailsModel.DEFAULT_INVOICEDISPUTEDSTATUSCODEID;

    @observable
    public invoiceDisputedReason: string | null = InvoiceDetailsModel.DEFAULT_INVOICEDISPUTEDREASON;

    @observable
    public rowVersion: string | null = InvoiceDetailsModel.DEFAULT_ROWVERSION;

    @observable
    public invoiceTax: string | undefined = InvoiceDetailsModel.DEFAULT_INVOICETAX;

    @observable
    public sendEmailToSupplier: boolean = InvoiceDetailsModel.DEFAULT_INVOICESENDEMAILTOSUPPLIER;

    @observable
    public postedByUserId: string | null = InvoiceDetailsModel.DEFAULT_POSTEDBYUSERID;

    @observable
    public postedDate: string | null = InvoiceDetailsModel.DEFAULT_POSTEDDATE;

    @observable
    public matchedByUserId: string | null = InvoiceDetailsModel.DEFAULT_MATCHEDBYUSERID;

    @observable
    public matchedDate: string | null = InvoiceDetailsModel.DEFAULT_MATCHEDDATE;

    @observable
    public invoiceProjects: InvoiceProjectModel[] = InvoiceDetailsModel.DEFAULT_INVOICEPROJECTS;

    @observable
    public invoiceDocuments: InvoiceDocumentDTO[] = InvoiceDetailsModel.DEFAULT_INVOICEDOCUMENTS;

    @observable
    public ieId: string | null = InvoiceDetailsModel.DEFAULT_IEID;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = InvoiceDetailsModel.DEFAULT_ID;
        this.invoiceNumber = InvoiceDetailsModel.DEFAULT_INVOICENUMBER;
        this.invoiceDate = moment().toString();
        this.dueDate = moment().toString();
        this.invoiceValue = InvoiceDetailsModel.DEFAULT_INVOICEVALUE;
        this.supplierId = InvoiceDetailsModel.DEFAULT_SUPPLIERID;
        this.invoiceStatusId = InvoiceDetailsModel.DEFAULT_INVOICESTATUSID;
        this.invoiceDisputedStatusCodeId = InvoiceDetailsModel.DEFAULT_INVOICEDISPUTEDSTATUSCODEID;
        this.invoiceDisputedReason = InvoiceDetailsModel.DEFAULT_INVOICEDISPUTEDREASON;
        this.rowVersion = InvoiceDetailsModel.DEFAULT_ROWVERSION;
        this.invoiceTax = InvoiceDetailsModel.DEFAULT_INVOICETAX;
        this.sendEmailToSupplier = InvoiceDetailsModel.DEFAULT_INVOICESENDEMAILTOSUPPLIER;
        this.postedByUserId = InvoiceDetailsModel.DEFAULT_POSTEDBYUSERID;
        this.postedDate = InvoiceDetailsModel.DEFAULT_POSTEDDATE;
        this.matchedByUserId = InvoiceDetailsModel.DEFAULT_MATCHEDBYUSERID;
        this.matchedDate = InvoiceDetailsModel.DEFAULT_MATCHEDDATE;
        this.invoiceProjects.length = 0;
        this.invoiceDocuments.length = 0;
    };

    @action
    public fromDto(dto: InvoiceUpsertAndRelatedResponseDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        const invoiceDto = dto.invoice;

        for (let key in invoiceDto) {
            if (invoiceDto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(invoiceDto[key]);
                } else {
                    this[key] = invoiceDto[key];
                }
            }
        }

        // Process the list of purchase orders.
        let processedPos: InvoiceProjectModel[] = [];

        for (const po of dto.invoiceProjects) {
            const poToAdd = new InvoiceProjectModel();
            poToAdd.fromDto(po);
            processedPos.push(poToAdd);
        }

        this.invoiceProjects = [...this.invoiceProjects, ...processedPos];

        // Process the invoice documents.
        this.invoiceDocuments = dto.invoiceDocuments;
    }

    public toDto(): UpsertInvoiceAndRelatedRequestDTO {
        let projectsToAdd: UpsertInvoiceProjectDTO[] = [];

        this.invoiceProjects.forEach((n) => {
            projectsToAdd.push(n.toDto());
        });

        const hasInvoiceValue: boolean = this.invoiceValue !== null && this.invoiceValue !== undefined && this.invoiceValue !== InvoiceDetailsModel.DEFAULT_INVOICEVALUE;

        const invoice: InvoiceUpsertRequestDTO = {
            id: this.id,
            invoiceNumber: this.invoiceNumber,
            invoiceDate: this.invoiceDate,
            dueDate: this.dueDate,
            invoiceValue: hasInvoiceValue ? this.invoiceValue! : null,
            supplierId: this.supplierId,
            invoiceStatusId: this.invoiceStatusId,
            rowVersion: this.rowVersion,
            invoiceTax: this.invoiceTax ? this.invoiceTax : null,
            sendEmailToSupplier: this.sendEmailToSupplier,
            postedByUserId: this.postedByUserId,
            postedDate: this.postedDate,
            matchedByUserId: this.matchedByUserId,
            matchedDate: this.matchedDate,
        };

        let documentsToAdd: InvoiceDocumentDTO[] = this.invoiceDocuments.slice();

        // Remove the temp ids before upserting.
        for (let i = 0; i < documentsToAdd.length; i++) {
            if (documentsToAdd[i].id !== null && documentsToAdd[i].id!.length < 10) {
                documentsToAdd[i].id = null;
            }
        }

        // Don't send deleted documents if they don't already exist on the server. Otherwise we're just adding deleted data to the database.
        // We still want to send existing documents that have just been deleted, as they may have just been deleted on the client and still need to be deleted on the server.
        const model: UpsertInvoiceAndRelatedRequestDTO = {
            invoice: invoice,
            invoiceProjects: projectsToAdd.filter((p) => !(p.id === null && p.isDeleted === true)),
            invoiceDocuments: documentsToAdd.filter((d) => !(d.id === null && d.isDeleted === true)),
        };

        return model;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateInvoiceNumber(): string {
        // RULES
        // The invoice number must be defined.
        return this.invoiceNumber === InvoiceDetailsModel.DEFAULT_INVOICENUMBER ? "Please provide an invoice number" : "";
    }

    @computed
    public get validateInvoiceDate(): string {
        // RULES
        // The invoice date must be defined.
        return this.invoiceDate === InvoiceDetailsModel.DEFAULT_INVOICEDATE || !IsDateStringValid(this.invoiceDate) ? "Please provide an invoice date" : "";
    }

    @computed
    public get validateDueDate(): string {
        // RULES
        // The due date must be defined.
        return this.dueDate === InvoiceDetailsModel.DEFAULT_DUEDATE || !IsDateStringValid(this.dueDate) ? "Please provide an due date" : "";
    }

    @computed
    public get validateInvoiceValue(): string {
        // RULES
        if (this.invoiceValue === InvoiceDetailsModel.DEFAULT_INVOICEVALUE || this.invoiceValue === undefined || this.invoiceValue === null || isNaN(this.invoiceValue)) {
            return "Please provide an invoice value";
        } else if (!validateTwoDecimalPlaces(this.invoiceValue)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @computed
    public get validateSupplierId(): string {
        // RULES
        // The supplierid must be defined.
        return this.supplierId === InvoiceDetailsModel.DEFAULT_SUPPLIERID ? "Please provide a supplier" : "";
    }

    @computed
    public get validateInvoiceTax(): string {
        // RULES
        const invoiceTaxValue: number = Number(this.invoiceTax);
        if (this.invoiceTax === InvoiceDetailsModel.DEFAULT_INVOICETAX || this.invoiceTax === undefined || this.invoiceTax === null || isNaN(invoiceTaxValue)) {
            return "Please provide a tax value";
        } else if (!validateTwoDecimalPlaces(invoiceTaxValue)) {
            return "No more than two decimal places";
        }

        return "";
    }

    // #endregion Custom Validation
}

export interface InvoiceUpsertRequestDTO {
    id: string | null;
    invoiceNumber: string | null;
    invoiceDate: string | null;
    dueDate: string | null;
    invoiceValue: number | null;
    invoiceStatusId: string | null;
    supplierId: string | null;
    invoiceTax: string | null;
    sendEmailToSupplier: boolean;
    postedByUserId: string | null;
    postedDate: string | null;
    matchedByUserId: string | null;
    matchedDate: string | null;
    rowVersion: string | null;
}

/**
 * Invoice upsert request.
 */
export interface UpsertInvoiceAndRelatedRequestDTO {
    invoice: InvoiceUpsertRequestDTO;
    invoiceProjects: UpsertInvoiceProjectDTO[];
    invoiceDocuments: InvoiceDocumentDTO[];
}

export interface InvoiceUpsertResponseDTO {
    id: string | null;
    invoiceNumber: string | null;
    invoiceDate: string | null;
    dueDate: string | null;
    invoiceValue: string | null;
    invoiceStatusId: string | null;
    invoiceDisputedStatusCodeId: string | null;
    invoiceDisputedReason: string | null;
    supplierId: string | null;
    invoiceTax: string | null;
    sendEmailToSupplier: boolean;
    rowVersion: string | null;
}

/**
 * Invoice upsert response.
 */
export interface InvoiceUpsertAndRelatedResponseDTO {
    invoice: InvoiceUpsertResponseDTO;
    invoiceProjects: InvoiceProjectDTO[];
    invoiceDocuments: InvoiceDocumentDTO[];
}

/**
 * Data required for a new invoice.
 */
export interface InvoiceRelatedResponseDTO {
    invoiceStatuses: InvoiceStatusDTO[];
    invoiceDisputedStatusCodes: InvoiceDisputedStatusCodeDTO[];
    suppliers: SupplierDTO[];
    ieTitle: string;
    projects: ProjectItemDTO[];
    canViewSupplierEmailCheckbox: boolean;
}

/**
 * Data required for an existing invoice. Extends InvoiceRelatedResponseDTO.
 */
export interface InvoiceAndRelatedResponseDTO extends InvoiceRelatedResponseDTO {
    invoice: InvoiceUpsertResponseDTO;
    invoiceProjects: InvoiceProjectDTO[];
    invoiceDocuments: InvoiceDocumentDTO[];
}

export interface UpsertInvoiceDateRequestDTO {
    id: string | null;
    invoiceDate: string | null;
    rowVersion: string | null;
}

export interface InvoiceStatusDTO {
    id: string | null;
    displayName: string;
    ordinal: number;
    type: InvoiceStatusEnum;
    isDeleted: boolean;
    color: string;
    textColor: string;
}

export enum InvoiceStatusEnum {
    Draft = 10,
    Pending = 20,
    Disputed = 30,
    RejectedInternally = 40,
    RejectedExternally = 50,
    Match = 60,
    Paid = 70,
    AllocatedAndAwaitingApproval = 80,
    Approved = 90,
}

export interface InvoiceDocumentDTO {
    id: string | null;
    fileName: string;
    url: string;
    isDeleted: boolean;
}

import { SupplierStatus } from "Globals/Models/Domain/SupplierStatusEnum";
import { CostCategory } from "Globals/Models/Domain/CostCategory";
import { observable, computed, action } from "mobx";
import { isNullOrUndefined, ModelBase } from "@shoothill/core";
import { IsArray, IsNotEmpty, MaxLength, ValidateNested } from "class-validator";
import { AddressModel, ContactBaseDTO, DarwinModelBaseDTO } from "Globals/Models/Domain";
import type { AddressModelDTO } from "Globals/Models/Domain";
import { SupplierContactDTO } from "./SupplierContactModel";
import { DefaultSupplierDetailModelDTO } from "./SupplierDetailModel";
import type { SupplierDetailModelDTO } from "./SupplierDetailModel";
import { AddEditContactModel } from "Views/Contacts/AddEditContactModel";
import { SupplierDocumentDTO, SupplierDocumentModel } from "./SupplierDocumentModel";

export class AddEditSupplierModel extends ModelBase<AddEditSupplierModel, AddEditSupplierModelDTO> {
    private NEWITEM = "DELETEME_";
    public id: string = "";
    public createdDate: string = "";

    @observable
    @IsNotEmpty({ message: "Supplier reference is required!" })
    @MaxLength(12, { message: "Address line length must be less than 128 characters" })
    public reference: string = "";

    @observable
    @IsNotEmpty({ message: "Supplier name is required!" })
    @MaxLength(128, { message: "Name length must be less than 128 characters" })
    public name: string = "";

    public isDeleted: boolean = false;
    public rowVersion: string | null = null;
    public originatorId: string | null = null;

    @observable
    public originatorName: string = "";

    @observable
    public costCategory: CostCategory = CostCategory.All;

    @observable
    public status: SupplierStatus = SupplierStatus.Draft;

    @observable
    public address: AddressModel = new AddressModel();

    @observable
    @IsArray()
    @ValidateNested()
    public contacts: ContactBaseDTO[] = [];

    @observable
    public supplierDocuments: SupplierDocumentModel[] = [];

    @computed
    public get getCostCategory(): string {
        return this.costCategory.toString();
    }

    @computed
    public get getAddress(): AddressModel {
        return this.address;
    }

    @action
    public cleanUp() {
        this.id = "";
        this.createdDate = "";
        this.reference = "";
        this.name = "";
        this.originatorName = "";
        this.isDeleted = false;
        this.rowVersion = null;
        this.originatorId = null;
        this.costCategory = CostCategory.All;
        // this.addresses = [];
        this.contacts = [];
        this.status = SupplierStatus.Draft;
        this.paymentGroup = null;
        this.paymentTerms = null;
        this.paymentTermsInDays = undefined;
        this.address = new AddressModel();
        this.supplierDocuments.length = 0;
    }

    @computed
    public get getContacts(): ContactBaseDTO[] {
        return this.contacts.slice().filter((a) => a.isDeleted === false);
    }

    fromDto(model: AddEditSupplierModelDTO): void {
        this.supplierDocuments.length = 0;
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedDocuments: SupplierDocumentModel[] = [];

        for (const doc of model.supplierDocuments) {
            const docToAdd = new SupplierDocumentModel();
            docToAdd.fromDto(doc);
            processedDocuments.push(docToAdd);
        }

        this.supplierDocuments = [...this.supplierDocuments, ...processedDocuments];
    }

    @action
    fromSupplierWithRelatedDto(model: SupplierDetailModelDTO) {
        this.supplierDocuments.length = 0;
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (key === "addresses") {
                    let addressToAdd: AddressModelDTO | null = null;

                    if (model[key].length > 0) {
                        const sortedAddresses = model[key].sort((a, b) => {
                            const dateA = a.createdDate ? new Date(a.createdDate) : null;
                            const dateB = b.createdDate ? new Date(b.createdDate) : null;

                            if (dateA && dateB) {
                                return dateB.getTime() - dateA.getTime();
                            } else if (dateA) {
                                return -1;
                            } else if (dateB) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });

                        addressToAdd = sortedAddresses[0];
                    }

                    if (addressToAdd) {
                        const addressModel: AddressModel = new AddressModel();
                        addressModel.fromDto(addressToAdd);
                        this.address = addressModel;
                    } else {
                        this.address = new AddressModel();
                    }
                } else if (key === "contacts") {
                    this.contacts = [];
                    model[key].forEach((contact: SupplierContactDTO) => {
                        const changedModel: AddEditContactModel = new AddEditContactModel();
                        changedModel.fromSupplierContactDto(contact);
                        this.contacts.push(contact);
                    });
                } else if (key === "supplierDocuments") {
                    // Do nothing
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }

        // Manually process the child array otherwise we will end up with an array of dtos being stored in the array of models.
        let processedDocuments: SupplierDocumentModel[] = [];

        for (const doc of model.supplierDocuments) {
            const docToAdd = new SupplierDocumentModel();
            docToAdd.fromDto(doc);
            processedDocuments.push(docToAdd);
        }

        this.supplierDocuments = [...this.supplierDocuments, ...processedDocuments];
    }

    toSupplierDetailModelDto(): SupplierDetailModelDTO {
        let retVal: SupplierDetailModelDTO = DefaultSupplierDetailModelDTO;
        for (let key in retVal) {
            if (retVal.hasOwnProperty(key)) {
                if (key === "addresses") {
                    retVal.addresses = [this.address];
                } else if (retVal[key] instanceof Date) {
                    retVal[key] = new Date(this[key]);
                } else {
                    retVal[key] = this[key];
                }
            }
        }
        return retVal;
    }

    //toDto is required but you can leave it blank
    toDto(model: AddEditSupplierModel): void {}

    @action
    public updateAddress(model: AddressModel) {
        if (isNullOrUndefined(this.address)) {
            this.address = model;
        } else {
            this.address!.fromDto(model.toAddressDto());
        }
    }

    @action
    public addContact(contact: AddEditContactModel) {
        const retVal = contact.toBaseContactDto(this.id);
        this.contacts.push(retVal);
    }

    @action
    public deleteContact(contactId: string) {
        const item: ContactBaseDTO | undefined = this.contacts.find((a) => a.id === contactId);
        if (isNullOrUndefined(item) === false) {
            this.contacts = this.contacts.filter((a) => a.id !== contactId);
        }
    }

    @action
    public updatContactIsPrimary(contactId: string, isPrimary: boolean) {
        this.contacts.forEach((contact) => {
            contact.isPrimary = false;
        });

        const item: ContactBaseDTO | undefined = this.contacts.slice().find((a) => a.id === contactId);
        if (isNullOrUndefined(item) === false) {
            item!.isPrimary = isPrimary;
        }
    }

    @action
    public setAddress(address: AddressModelDTO) {
        this.address.fromDto(address);
    }

    @computed
    public get validatePaymentTermsId(): string {
        return this.paymentTerms === null || this.paymentTerms === undefined ? "Please select payment terms" : "";
    }

    @computed
    public get validatePaymentTypeId(): string {
        return this.paymentGroup === null || this.paymentGroup === undefined ? "Please select a payment type" : "";
    }

    @computed
    public get validatePaymentTermsInDays(): string {
        return this.paymentTermsInDays === null || this.paymentTermsInDays === undefined ? "Please enter payment terms in days" : "";
    }

    @computed
    public get validateReference(): string {
        return this.reference === null || this.reference === undefined || this.reference === "" || this.reference.length > 128 ? "Please enter a valid reference" : "";
    }

    @computed
    public get validateName(): string {
        return this.name === null || this.name === undefined || this.name === "" || this.name.length > 128 ? "Please enter a valid name" : "";
    }

    @observable
    public accountOpened: string = "";

    @observable
    public vatNumber: string = "";

    @observable
    public defaultVatRate: number = 0;

    @observable
    public paymentTermsInDays: number | undefined = undefined;

    @observable
    public creditLimit: number = 0;

    @observable
    public paymentGroup: string | null = null;

    @observable
    public insuranceExpiryDate: string = "";

    @observable
    public constructionalReg: string = "";

    @observable
    public pqq: boolean = true;

    @observable
    public invoiceFactoring: string = "";

    @observable
    public creditInsurer: string = "";

    @observable
    public paymentTerms: string | null = null;
}

export interface AddEditSupplierModelDTO extends DarwinModelBaseDTO {
    reference: string;
    name: string;
    isDeleted: boolean;
    rowVersion: string | null;
    originatorId: string | null;
    originatorName: string;
    contacts: SupplierContactDTO[];
    addresses: AddressModelDTO[];
    costCategory: CostCategory;
    status: SupplierStatus;
    accountOpened: string;
    vatNumber: string;
    defaultVatRate: number;
    paymentTermsInDays: number;
    paymentTerms: string;
    creditLimit: number;
    paymentGroup: string;
    insuranceExpiryDate: string;
    constructionalReg: string;
    pqq: boolean;
    invoiceFactoring: string;
    creditInsurer: string;
    supplierDocuments: SupplierDocumentDTO[];
}

const SvgComponent = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={21.29} height={18.375} {...props}>
        <g data-name="Group 9405">
            <path
                fill="#e03c31"
                stroke="#e03c31"
                strokeWidth={0.75}
                d="M15.486 11.628a.405.405 0 0 0 .405-.7l-3.518-2.033V4.428a.406.406 0 1 0-.811 0v4.935Z"
                data-name="Path 5693"
            />
            <path
                fill="#707070"
                stroke="#fff"
                strokeWidth={0.25}
                d="M7.466 9.183h-2.72a7.272 7.272 0 1 1 4.907 6.877.833.833 0 0 0-.542 1.576 8.939 8.939 0 1 0-6.032-8.453H.359a.229.229 0 0 0-.168.385l3.553 3.835a.229.229 0 0 0 .336 0l3.553-3.835a.229.229 0 0 0-.168-.385Z"
                data-name="Path 5694"
            />
        </g>
    </svg>
);
export default SvgComponent;

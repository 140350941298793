// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Loader, styled } from "@shoothill/core";

// Custom
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ControlLabel } from "Components/Form/ControlLabel";
import { Box } from "@material-ui/core";
import moment from "moment";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { ProjectVRCReportViewModel } from "./ProjectVRCReportViewModel";
import { IEItemsWrapper } from "Views/Project/Commercial/IETables/IEItemsWrapper";
import { VRCGridHeader, VRCSummaryGridView } from "./ProjectVRCReport.styles";
import { ProjectReportGrid } from "../Shared/ProjectReportGrid";
import { ReportDatePickerContainer, RunReportButtonContainer } from "Views/Report/Report.styles";

// Styling & Images

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

interface Props {
    projectId: string;
}

export const ProjectVRCReportView: React.FunctionComponent<Props> = (props: Props) => {
    const [viewModel] = useState(() => new ProjectVRCReportViewModel());

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const onReportEndDateTopChanged = (date: string | null) => {
        viewModel.setValue("reportEndDateTop", date === null ? date : moment(date).startOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const onReportEndDateBottomChanged = (date: string | null) => {
        viewModel.setValue("reportEndDateBottom", date === null ? date : moment(date).startOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const runReport = () => {
        viewModel.runReport(props.projectId);
        viewModel.setCanExportCSV(true);
    };

    const exportCSV = () => {
        viewModel.generateProjectVRCReportCSV(props.projectId);
    };

    return useObserver(() => (
        <DetailsPage>
            <ReportDatePickerContainer>
                <ControlLabel label="Date comparison:" htmlFor="start-date" />
                <Box>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportEndDateTopChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportEndDateTop}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                        />
                    </Box>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportEndDateBottomChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportEndDateBottom}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                        />
                    </Box>
                </Box>
            </ReportDatePickerContainer>

            <RunReportButtonContainer>
                <PrimaryButton displayName={"Run report"} execute={runReport} fullWidth={false} canExecute={!viewModel.IsLoading} />
                {viewModel.reportViewModel && (
                    <PrimaryButton displayName={"Export CSV"} execute={exportCSV} fullWidth={false} canExecute={!viewModel.IsLoading && viewModel.canExportCSV} />
                )}
            </RunReportButtonContainer>

            <IEItemsWrapper>
                <div className="content" style={{ paddingTop: "30px", position: "relative", minWidth: "1715px" }}>
                    <VRCGridHeader>
                        <VRCSummaryGridView>
                            <li className="heading heading-main">
                                <div style={{ maxWidth: "280px" }}>Name & Type</div>
                                <div>Income</div>
                                <div>Total Expected Spend</div>
                                <div>Margin (£)</div>
                                <div>Margin (%)</div>
                            </li>
                        </VRCSummaryGridView>
                        <VRCSummaryGridView>
                            <li className="heading">
                                <div></div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                                <div>Previous</div>
                                <div>Current</div>
                                <div>Movement</div>
                            </li>
                        </VRCSummaryGridView>
                    </VRCGridHeader>

                    {!viewModel.IsLoading && viewModel.reportViewModel && <ProjectReportGrid reportViewModel={viewModel.reportViewModel} />}
                    {viewModel.IsLoading && <Loader />}
                </div>
            </IEItemsWrapper>
        </DetailsPage>
    ));
};

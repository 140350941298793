import { Select } from "@material-ui/core";
import styled from "styled-components";

export const ToggleButton: any = styled.span`
    font-size: 22px;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
    :hover {
        transform: scale(1.3);
    }

    color: #e03c31;
    font-weight: bold;
    user-select: none;
    margin-left: 34px;
`;

export const InductionDetailsPageContainer: any = styled.div`
    font-size: 12px;
    padding: 0px 30px 30px 30px;
`;

export const InductionItemRows: any = styled.div``;

export const InductionItemRow: any = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0px;

    > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        min-width: 250px;
        justify-content: space-between;
        margin-right: auto;
    }

    > div:nth-child(2) {
        margin-right: 55px;
    }

    > div:nth-child(3) {
        margin-right: 30px;
    }

    > div:nth-child(4) {
        margin-right: 30px;
    }
`;

export const ItemDetailsContainer: any = styled.div``;

export const ItemDetailsSection: any = styled.div`
    margin-bottom: 1.25rem;

    span {
        font-weight: bold;
    }

    > p {
        > span {
            margin-right: 10px;
        }

        margin-bottom: 12px;
    }
`;

export const ItemDetailsSectionRow: any = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    > p {
        > span {
            margin-right: 10px;
        }
    }
`;

export const AnswerTileContainer: any = styled.div`
    display: flex;
    flex-direction: column;

    > p {
        margin-bottom: 8px;
    }
`;

export const AnswerTile: any = styled.div`
    background-color: #eaf4f9;
    padding: 16px;
`;

export const VideoResponseTileContainer: any = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    > p {
        margin-bottom: 8px;
    }
`;

export const VideoResponseTile: any = styled.div`
    display: flex;
    flex-direction: row;

    > div {
        display: flex;
        padding: 16px;
        background-color: #eaf4f9;
        font-weight: bold;
    }

    > div:nth-child(1) {
        flex-direction: column;
        flex: 1 0 auto;
    }

    > div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        margin-left: 2px;
    }
`;

export const SignatureSection: any = styled(ItemDetailsSection)`
    img {
        max-width: 250px;
    }

    > div {
        display: flex;
        justify-content: center;
    }
`;

export const IsApprovedSection: any = styled(ItemDetailsSection)`
    margin: 0px;
    padding: 28px 0px 38px 0px;
`;

export const QualificationTable: any = styled.div`
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 40px;

    div.columns,
    div.rows {
        display: flex;
    }

    div.columns {
        flex-direction: row;
        background-color: #ced4da;
        padding: 12px 24px;

        div.column,
        div.column-autowidth {
            font-weight: bold;
        }
    }

    div.rows {
        flex-direction: column;

        div.row {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid grey;
            padding: 12px 24px;
        }
    }

    .cell {
        span.red {
            color: #df3a3a;
        }
    }

    .column,
    .column-autowidth,
    .cell,
    .cell-autowidth {
        padding: 0px 10px;
    }

    .column:nth-child(1),
    .cell:nth-child(1) {
        width: 300px;
    }

    .column:nth-child(2),
    .cell:nth-child(2) {
        width: 80px;
    }

    .column:nth-child(3),
    .cell:nth-child(3) {
        width: 320px;
    }

    .column:nth-child(4),
    .cell:nth-child(4) {
        width: 170px;
    }

    .column:nth-child(5),
    .cell:nth-child(5) {
        width: 180px;
    }

    .column:nth-child(6),
    .cell:nth-child(6) {
        width: 108px;
    }
`;

interface SelectStyleProps {
    textColor: string;
    backgroundColor: string;
}

export const CustomSelect = styled(Select)<SelectStyleProps>`
    min-width: 304px;

    .MuiSelect-root {
        background-color: ${(props) => props.backgroundColor} !important;
        color: ${(props) => props.textColor} !important;
    }
`;

import { Link } from "@material-ui/core";
import { isNullOrUndefined, useRouter } from "@shoothill/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";
import { ReportBreadCrumbSection } from "./ReportBreadCrumbSection";

export const ReportMatchSection1 = (
    viewModel: BreadcrumbViewModel,
    sections: ReportBreadCrumbSection,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested" | "parent" | "child">,
): any => {
    return (
        <div>
            <Link component={RouterLink} to={AppUrls.Client.Report.AllProject} className={classes.heading}>
                Reports
            </Link>

            <Link component={RouterLink} to={AppUrls.Client.Report.AllProject} className={sections.reportAllProject ? "" : classes.prev}>
                {sections.reportAllProject ? (
                    <div className={classes.main}>
                        All project
                        <span className="triangle"></span>
                    </div>
                ) : (
                    <>All project</>
                )}
            </Link>

            <Link component={RouterLink} to={AppUrls.Client.Report.Project} className={sections.reportProject ? "" : classes.prev}>
                {sections.reportProject ? (
                    <div className={classes.main}>
                        Project
                        <span className="triangle"></span>
                    </div>
                ) : (
                    <>Project</>
                )}
            </Link>

            <Link component={RouterLink} to={AppUrls.Client.Report.IE} className={sections.reportIE ? "" : classes.prev}>
                {sections.reportIE ? (
                    <div className={classes.main}>
                        IE
                        <span className="triangle"></span>
                    </div>
                ) : (
                    <>IE</>
                )}
            </Link>
        </div>
    );
};

export const ProjectMatchSection2 = (
    viewModel: BreadcrumbViewModel,
    sections: ReportBreadCrumbSection,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    plusSign: any,
): any => {
    const router = useRouter();
    const { history, match } = useRouter();
    return <></>;
};

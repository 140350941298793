import React, { useEffect, useState } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Route } from "react-router";
import { LandingView } from "./Landing/LandingView";
import { AppUrls } from "../../AppUrls";
import { InductionViewModel } from "./InductionViewModel";
import { Step1View } from "./Step1/Step1View";
import { Step2View } from "./Step2/Step2View";
import { SHBox } from "../../Components/Box";
import { DarwinAppBar } from "Views/Header/Header.style";
import DarwinLogo from "Content/darwinlogo.png";
import styled from "styled-components";
import { ProgressCounter } from "../../Content/ProgressCounter";
import { Step3View } from "./Step3/Step3View";
import { Step4View } from "./Step4/Step4View";
import { Step5View } from "./Step5/Step5View";
import { Step6View } from "./Step6/Step6View";
import { Submitted } from "./Step6/Submitted";
import { PreviewImage } from "../../Globals/Styles/AppStyling";
import { AccessRoutesView } from "./Access/AccessRoutes";

const FullName = styled.div`
    font-size: 28px;
    font-weight: 600;
    color: #191919;
    white-space: nowrap;
`;
const Captions = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: #191919;
    white-space: nowrap;
`;
export const InductionRoutes: React.FC = observer(() => {
    const [viewModel] = useState(() => new InductionViewModel());

    const routes = () => {
        return (
            <>
                {/* Wizard Landing Page */}
                <Route
                    exact
                    path={AppUrls.Client.Induction.Landing}
                    component={() => {
                        runInAction(() => {
                            viewModel.showHeaderBar = false;
                        });
                        return <LandingView parentViewModel={viewModel} viewModel={viewModel.landingViewModel} />;
                    }}
                />

                {/* Wizard Step 1 */}
                <Route exact path={AppUrls.Client.Induction.Step1} component={() => <Step1View parentViewModel={viewModel} viewModel={viewModel.step1ViewModel} />} />

                {/* Wizard Step 2 */}
                <Route
                    exact
                    path={AppUrls.Client.Induction.Step2}
                    component={() => (
                        <Step2View
                            declinedAge={!viewModel.landingViewModel.isOver18}
                            inductionTypeId={viewModel.landingViewModel.model.inductionTypeId}
                            parentViewModel={viewModel}
                            viewModel={viewModel.step2ViewModel}
                        />
                    )}
                />

                {/* Wizard Step 3 */}
                <Route exact path={AppUrls.Client.Induction.Step3} component={() => <Step3View parentViewModel={viewModel} viewModel={viewModel.step3ViewModel} />} />

                {/* Wizard Step 4 */}
                <Route exact path={AppUrls.Client.Induction.Step4} component={() => <Step4View parentViewModel={viewModel} viewModel={viewModel.step4ViewModel} />} />

                {/* Wizard Step 5 */}
                <Route
                    exact
                    path={AppUrls.Client.Induction.Step5}
                    component={() => (
                        <Step5View inductionTypeId={viewModel.landingViewModel.model.inductionTypeId} parentViewModel={viewModel} viewModel={viewModel.step5ViewModel} />
                    )}
                />

                {/* Wizard Step 6 */}
                <Route
                    exact
                    path={AppUrls.Client.Induction.Step6}
                    component={() => <Step6View onSubmit={viewModel.submitData} parentViewModel={viewModel} viewModel={viewModel.step6ViewModel} />}
                />

                {/* Wizard Completion Page */}
                <Route exact path={AppUrls.Client.Induction.Submitted} component={() => <Submitted parentViewModel={viewModel} />} />
            </>
        );
    };

    return (
        <SHBox position={"relative"} height={"98vh"}>
            {viewModel.showHeaderBar && (
                <SHBox>
                    <DarwinAppBar position={"sticky"} color={"transparent"} style={{ padding: "15px" }}>
                        <SHBox flexBox justifyContent={"space-between"}>
                            <img src={DarwinLogo} alt="Darwin Group" className="logoDarwin" />
                            <p>Induction</p>
                        </SHBox>
                    </DarwinAppBar>
                    <SHBox
                        grid
                        dc={"1fr 1fr"}
                        mc={"1fr"}
                        justifyContent={"space-between"}
                        pl={3}
                        pt={3}
                        pb={viewModel.isMobile ? 1 : 3}
                        style={{ borderBottom: "1px dashed #CED4DA" }}
                    >
                        <SHBox grid dc={"64px 1fr"} mc={"1fr"} style={{ order: viewModel.isMobile ? 2 : 1, gap: viewModel.isMobile ? 0 : "24px" }}>
                            {viewModel.step1ViewModel.hasImage && <PreviewImage src={viewModel.step1ViewModel.model.photoData} alt={"Profile Image"} />}
                            <SHBox grid dc={"1fr"} mc={"1fr"} style={{ gap: 0 }}>
                                <FullName>{viewModel.landingViewModel.fullName}</FullName>
                                <Captions>{viewModel.landingViewModel.model.priorityEmployerName}</Captions>
                                <Captions>{viewModel.landingViewModel.visitorTypeById(viewModel.landingViewModel.model.visitorType)}</Captions>
                            </SHBox>
                        </SHBox>
                        <SHBox flexBox mt={3} mr={3} justifyContent={viewModel.isMobile ? "center" : "flex-end"} style={{ order: viewModel.isMobile ? 1 : 2 }}>
                            <ProgressCounter />
                        </SHBox>
                    </SHBox>
                </SHBox>
            )}

            {routes()}

            <AccessRoutesView viewModel={viewModel} parentViewModel={viewModel} />
        </SHBox>
    );
});

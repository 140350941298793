// Libraries
import { Typography } from "@material-ui/core";
import { styled, useObserver } from "@shoothill/core";
import React from "react";

// Custom
import { ProjectListViewModel } from "../ProjectListViewModel";

// Styling & Images
import { DarwinPage, DarwinPageTitle, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { ProjectListModelWithAddress } from "../ProjectListModelWithAddress";
import { SHBox } from "../../../Components/Box";
import { uniqueId } from "lodash-es";
import { GlobalHistory } from "../../../index";
import { AppUrls } from "../../../AppUrls";

interface Props {
    viewModel: ProjectListViewModel;
    projectDetails: ProjectListModelWithAddress;
}
export const SiteTabletMenuView: React.FunctionComponent<Props> = (props: Props) => {
    const { projectDetails } = props;

    const tiles = [
        { name: "Induction", url: AppUrls.Client.Induction.Landing },
        {
            name: "Near Miss",
            url: AppUrls.Client.Project.SiteTablet.NearMiss,
        },
        { name: "Inspection", url: AppUrls.Client.Project.SiteTablet.Inspection },
        { name: "Permits", url: AppUrls.Client.Project.SiteTablet.Permit },
        { name: "Scaffold", url: AppUrls.Client.Project.SiteTablet.Scaffold },
        { name: "Sign in/Sign out", url: AppUrls.Client.Induction.Access.Landing.replace(":projectId", projectDetails.id) },
    ];

    return useObserver(() => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        {projectDetails?.reference} - {projectDetails?.name}
                    </Typography>
                    <Typography variant="h2" color="textPrimary">
                        {[projectDetails?.addressLine1, projectDetails?.addressLine2, projectDetails?.city, projectDetails?.postcode].filter(Boolean).join(", ")}
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <SHBox height={"85vh"} display={"grid"} dr={`repeat(${tiles.map.length}fr)`}>
                        {tiles.map((tile, index) => {
                            return <CardView key={uniqueId()} number={index} title={tile.name} url={tile.url} />;
                        })}
                    </SHBox>
                </DarwinTableToolbar>
            </DarwinPage>
        );
    });
};

const Tile = styled(SHBox)`
    display: grid;
    grid-template-columns: 60px 1fr;
    border: 1px solid #e03c31;
    margin-bottom: 15px;
    border-radius: 10px;
    cursor: pointer;
    //height: 25%;
    .number {
        color: #e03c31;
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        align-self: center;
    }
    .title {
        display: flex;
        align-items: center;
        padding-left: 20px;
        color: white;
        background-color: #e03c31;
        font-size: 56px;
        align-self: center;
        height: 100%;
    }
`;
interface CardProps {
    number: number;
    title: string;
    url: string;
}

const CardView: React.FunctionComponent<CardProps> = (props: CardProps) => {
    const handleClick = () => {
        GlobalHistory.push(props.url);
    };
    return (
        <Tile onClick={handleClick}>
            <SHBox className={"number"}>{props.number}</SHBox>
            <SHBox className={"title"}>{props.title}</SHBox>
        </Tile>
    );
};

import React, { useEffect } from "react";
import { useObserver, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinTablePageContent, OrderTable } from "Globals/Styles/AppStyling";
import { PurchaseOrderListTableModel2 } from "./PurchaseOrderListModel";
import { AppUrls } from "AppUrls";
import { formatCurrencyFromPounds, formatDate, formatEmptyValueForTableDash } from "Utils/Format";
import { PatchedPagination } from "../../Components/Table/PatchedPagination";
import DownloadDocIcon from "Content/fileIcon.svg";
interface IPurchaseOrderTableViewProps {
    data: PurchaseOrderListTableModel2[];
    isLoading: boolean;
    downloadPDF: (id: string) => Promise<void>;
}

export const AllPurchaseOrderTableView: React.FunctionComponent<IPurchaseOrderTableViewProps> = (props: IPurchaseOrderTableViewProps) => {
    const { history } = useRouter();
    //useEffect below only gets run on initial render
    useEffect(() => {
        // eslint-disable-next-line prettier/prettier
        return () => {};
    }, []);

    const handleRowClick = (e: any, rowData: PurchaseOrderListTableModel2 | undefined) => {
        if (localStorage.getItem("isPDFClick") === "1") {
            localStorage.removeItem("isPDFClick");
        } else if (rowData !== undefined) {
            history.push(AppUrls.Client.PurchaseOrder.View.replace(":poid", rowData.id));
        }
    };

    const handleGenerateAllPurchaseOrderPDF = async (rowData: PurchaseOrderListTableModel2) => {
        localStorage.setItem("isPDFClick", "1");
        await props.downloadPDF(rowData.id);
    };

    const renderPage = () => {
        return (
            <div style={{ width: "100%", paddingTop: 30 }}>
                <DarwinTablePageContent>
                    <OrderTable>
                        <MaterialTable
                            columns={[
                                {
                                    title: "PO no.",
                                    field: "poNumberFormatted",
                                    width: "auto",
                                    render: (rowData: PurchaseOrderListTableModel2) => <>{formatEmptyValueForTableDash(rowData.poNumberFormatted)}</>,
                                },
                                {
                                    title: "Revision",
                                    field: "revision",
                                    width: "auto",
                                    render: (rowData: PurchaseOrderListTableModel2) => <>{formatEmptyValueForTableDash(rowData.revision)}</>,
                                },
                                {
                                    title: "Purchase order description",
                                    field: "itemDescription",
                                    width: "auto",
                                    render: (rowData: PurchaseOrderListTableModel2) => <>{formatEmptyValueForTableDash(rowData.itemDescription)}</>,
                                },
                                {
                                    title: "Project",
                                    field: "projectName",
                                    width: "auto",
                                    render: (rowData: PurchaseOrderListTableModel2) => <>{formatEmptyValueForTableDash(rowData.projectName)}</>,
                                },
                                {
                                    title: "Raised by",
                                    field: "raisedBy",
                                    width: "auto",
                                    render: (rowData: PurchaseOrderListTableModel2) => <>{formatEmptyValueForTableDash(rowData.raisedBy)}</>,
                                },
                                {
                                    title: "PO Date",
                                    field: "createdDate",
                                    width: "auto",
                                    type: "date",
                                    defaultSort: "desc",
                                    render: (rowData: PurchaseOrderListTableModel2) => (
                                        <>{formatEmptyValueForTableDash(formatDate(rowData.createdDate !== null ? rowData.createdDate.toString() : ""))}</>
                                    ),
                                },
                                {
                                    title: "Start Date",
                                    field: "dateFrom",
                                    width: "auto",
                                    type: "date",
                                    render: (rowData: PurchaseOrderListTableModel2) => (
                                        <>{formatEmptyValueForTableDash(formatDate(rowData.dateFrom !== null ? rowData.dateFrom.toString() : ""))}</>
                                    ),
                                },
                                {
                                    title: "Completion Date",
                                    field: "dateTo",
                                    width: "auto",
                                    type: "date",
                                    render: (rowData: PurchaseOrderListTableModel2) => (
                                        <>{formatEmptyValueForTableDash(formatDate(rowData.dateTo !== null ? rowData.dateTo.toString() : ""))}</>
                                    ),
                                },
                                {
                                    title: "Supplier",
                                    field: "supplierName",
                                    width: "auto",
                                    render: (rowData: PurchaseOrderListTableModel2) => <>{formatEmptyValueForTableDash(rowData.supplierName)}</>,
                                },
                                {
                                    title: "Value",
                                    field: "committedCost",
                                    width: "auto",
                                    render: (rowData: PurchaseOrderListTableModel2) => <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(rowData.committedCost)}</div>,
                                },
                                {
                                    title: "Available balance",
                                    field: "availableBalance",
                                    width: "auto",
                                    render: (rowData: PurchaseOrderListTableModel2) => (
                                        <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(rowData.availableBalance)}</div>
                                    ),
                                },
                                {
                                    title: "Delivery required",
                                    field: "dateRequired",
                                    width: "auto",
                                    type: "date",
                                    render: (rowData: PurchaseOrderListTableModel2) => (
                                        <>{formatEmptyValueForTableDash(formatDate(rowData.dateRequired !== null ? rowData.dateRequired.toString() : ""))}</>
                                    ),
                                },
                                {
                                    title: "PDF",
                                    field: "",
                                    width: "auto",
                                    render: (rowData: PurchaseOrderListTableModel2) => (
                                        <div className="table-svg-container">
                                            <img src={DownloadDocIcon} onClick={() => handleGenerateAllPurchaseOrderPDF(rowData)} />
                                        </div>
                                    ),
                                },
                            ]}
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            options={Defaults.GetDarwinTableOptionsNoSearch()}
                            data={props.data}
                            onRowClick={handleRowClick}
                            title=""
                            isLoading={props.isLoading}
                        />
                    </OrderTable>
                </DarwinTablePageContent>
            </div>
        );
    };

    return useObserver(() => renderPage());
};

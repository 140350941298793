import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { InvoicingListFilterParamsBaseModel, InvoicingListFilterParamsBaseModelDTO } from "./InvoiceListFilterParamsBaseModel";

export class InvoicingListFilterParamsModel extends InvoicingListFilterParamsBaseModel {
    fromDto(model: InvoicingListFilterParamsBaseModelDTO): void {
        this.searchText = model.searchText;
    }

    toDto(): InvoicingListFilterParamsBaseModelDTO {
        let dto: InvoicingListFilterParamsBaseModelDTO = {
            includeDeleted: this.includeDeleted,
            statuses: this.statuses,
            projects: this.projects,
            suppliers: this.suppliers,
            searchText: this.searchText,
        };
        return dto;
    }
}

// export interface InvoicingListFilterParamsBaseModelDTO  {

// }

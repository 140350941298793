import { debounce } from "@material-ui/core";
import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { StockListItemViewModel } from "./StockListItemViewModel";
import { StockAdjustmentViewModel } from "../StockAdjustment/StockAdjustmentViewModel";
import { StockHistoryViewModel } from "../StockHistory/StockHistoryViewModel";
import { StockTransferViewModel } from "../StockTransfer/StockTransferViewModel";

export class StockListViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();

    private readonly DEBOUNCE_VALUE_MS = 200;

    private stocks = observable<StockListItemViewModel>([]);

    constructor() {
        super({});

        // Load materials.
        this.apiLoadStockAsync();
    }

    // #region Filtering

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    @computed
    public get filteredStocks(): StockListItemViewModel[] {
        if (isEmptyOrWhitespace(this.filterSearchString)) {
            return this.stocks.slice();
        }

        return this.stocks.filter((vm) => vm.matchesFilter(this.filterSearchString)).slice();
    }

    // #endregion Filtering

    // #region New Material

    @action
    public navigateToNewPurchaseOrder = () => {
        this.history.push(AppUrls.Client.PurchaseOrder.Add);
    };

    // #endregion New Material

    // #region Adjust Stock

    @observable
    public stockAdjustmentViewModel: StockAdjustmentViewModel | null = null;

    @computed
    public get canDisplayStockAdjustment() {
        return this.stockAdjustmentViewModel !== null;
    }

    @action
    public displayStockAdjustment = (rowData: any) => {
        if (rowData.canEditStock) {
            this.stockAdjustmentViewModel = new StockAdjustmentViewModel(rowData.id, this.closeStockAdjustment);
        }
    };

    @action
    public closeStockAdjustment = (refreshPage: boolean) => {
        this.stockAdjustmentViewModel = null;

        if (refreshPage) {
            this.apiLoadStockAsync();
        }
    };

    // #endregion Adjust Stock

    // #region Transfer Stock

    @observable
    public stockTransferViewModel: StockTransferViewModel | null = null;

    @computed
    public get canDisplayStockTransfer() {
        return this.stockTransferViewModel !== null;
    }

    @action
    public displayStockTransfer = (rowData: any) => {
        if (rowData.canEditStock) {
            this.stockTransferViewModel = new StockTransferViewModel(rowData.id, this.closeStockTransfer);
        }
    };

    @action
    public closeStockTransfer = (refreshPage: boolean) => {
        this.stockTransferViewModel = null;

        if (refreshPage) {
            this.apiLoadStockAsync();
        }
    };

    // #endregion Transfer Stock

    // #region Display Stock History

    @observable
    public stockHistoryViewModel: StockHistoryViewModel | null = null;

    @computed
    public get canDisplayStockHistory() {
        return this.stockHistoryViewModel !== null;
    }

    @action
    public displayStockHistory = (rowData: any) => {
        this.stockHistoryViewModel = new StockHistoryViewModel(rowData.id, this.closeStockHistory);
    };

    @action
    public closeStockHistory = () => {
        this.stockHistoryViewModel = null;
    };

    // #endregion Display Stock History

    // #region Api Actions

    @action
    public apiLoadStockAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Stock.GetStockViews),
            (result) => {
                runInAction(() => {
                    this.stocks.replace(
                        result.map((dto: any) => {
                            const material = new StockListItemViewModel();

                            material.id = dto.id;
                            material.description = dto.description;
                            material.materialType = dto.materialType;
                            material.materialSubType = dto.materialSubType;
                            material.supplierCode = dto.supplierCode;
                            material.unitOfMeasure = dto.unitOfMeasure;
                            material.stockQuantity = dto.stockQuantity;
                            material.stockValue = dto.stockValue;
                            material.canEditStock = dto.canEditStock;

                            return material;
                        }),
                    );
                });
            },
            "Error whilst loading the stock data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiDownloadStockAsync = async (): Promise<void> => {
        await this.server.queryAuthenticatedFile(
            AppUrls.Server.Stock.DownloadStockViews,
            this.getConfig,
            (data, filename) => {
                // Create a link element for the file and use the filename provided.
                const link = document.createElement("a");

                link.href = window.URL.createObjectURL(new Blob([data]));
                link.setAttribute("download", filename);

                document.body.appendChild(link);

                // Download.
                link.click();

                // Having clicked the link, delete the element otherwise it will
                // remain attached to the document.
                document.body.removeChild(link);
            },
            "Error whilst downloading the file",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    isFieldValid(fieldName: string | number | symbol, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    beforeUpdate?(fieldName: string | number | symbol, value: any) {
        throw new Error("Method not implemented.");
    }
    afterUpdate?(fieldName: string | number | symbol, value: any): void {
        throw new Error("Method not implemented.");
    }

    // #endregion Boliderplate
}

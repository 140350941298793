import { FormControlLabel, RadioGroup } from "@material-ui/core";
import { Loader } from "@shoothill/core";
import React, { useEffect } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import Vimeo from "@u-wave/react-vimeo";
import styled from "styled-components";

import { SHBox } from "Components/Box";
import { Step5ViewModel } from "./Step5ViewModel";
import { WizardButtonsView } from "../WizardButtons";
import { CustomRadio } from "../../Project/CustomComponents";
import { DashedDivider } from "../../PurchaseOrder/Form/Views/Dividers";
import { InductionViewModel } from "../InductionViewModel";

const Title = styled.p`
    font-size: 14px !important;
`;

const Questions = styled(SHBox)`
    p {
        font-size: 12px;
        font-weight: 600;
        color: #171716;
        letter-spacing: -0.02em;
        line-height: 24px;
    }
`;

const VideoWrapper = styled.div`
    .videoContainer {
        text-align: center;
    }
`;
const Error = styled.p`
    color: red !important;
    font-size: 14px !important;
`;

const VideoWrapperResponsive = styled.div`
    .videoContainer {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
    }

    .videoContainer iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

interface Props {
    inductionTypeId: string | null;
    parentViewModel: InductionViewModel;
    viewModel: Step5ViewModel;
}

export const Step5View: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;

    useEffect(() => {
        runInAction(() => {
            viewModel.setInductionTypeId(props.inductionTypeId);
        });
    });

    const onEnd = (event: any) => {
        setTimeout(() => {
            runInAction(() => {
                console.log("Video finished");
                viewModel.setIsVideoFinished(true);
            });
        }, 2000);
    };

    if (props.parentViewModel.isStageLoading) {
        return <Loader />;
    }

    return (
        <SHBox height={"85vh"}>
            <SHBox showIf={!viewModel.videoFinished} m={3} grid dc={"1fr"} mc={"1fr"} style={{ gap: viewModel.isMobile ? 0 : "24px" }} justifyContent={"center"}>
                <SHBox showIf={!viewModel.isMobile}>
                    <VideoWrapper>
                        <Vimeo className={"videoContainer"} video={viewModel.inductionVideoURL} onEnd={onEnd} />
                    </VideoWrapper>
                </SHBox>
                <SHBox showIf={viewModel.isMobile}>
                    <VideoWrapperResponsive>
                        <Vimeo className={"videoContainer"} video={viewModel.inductionVideoURL} onEnd={onEnd} />
                    </VideoWrapperResponsive>
                </SHBox>
                <SHBox textAlign={"center"}>
                    <p>The video is a MUST WATCH. Once finished, you will be asked some final statements to confirm and accept.</p>
                </SHBox>
            </SHBox>
            <Questions showIf={viewModel.videoFinished} ml={3} mr={3} pb={"60px"}>
                <SHBox>
                    <Title>General</Title>
                    <p>Fully understood the video induction and all messages contained within the video.</p>
                    <p>Know where to find a full copy of the Portakabin - Trial Health and Safety Policy.</p>
                    <p>Have read and understood your own company Health and Safety Policy.</p>
                    <p>Will adhere to all site rules and instructions relating to health, safety and housekeeping from site management/supervision.</p>
                    <RadioGroup
                        row
                        defaultValue="Yes"
                        name={"radio-general-group"}
                        value={viewModel.getValue("general")}
                        onChange={(e) => viewModel.radioChange("general", e.target.value)}
                    >
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("general") === "true"} />} label="Yes" />
                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("general") === "false"} />} label="No" />
                    </RadioGroup>
                    <SHBox showIf={viewModel.getValue("general") !== ""}>
                        <Error>{viewModel.getError("general")}</Error>
                    </SHBox>
                    <DashedDivider gutterBottom />
                </SHBox>

                <SHBox showIf={!props.viewModel.isVisitorInductionType}>
                    <Title>Training & Equipment</Title>
                    <p>Will only undertake tasks that you are fully trained on and hold the necessary in-date certification for.</p>
                    <p>Will only use equipment that you are fully trained on and hold the necessary in-date certification for.</p>
                    <p>Fully understand the faulty equipment reporting procedure and will report any faulty equipment immediately.</p>
                    <p>Have the correct tools and equipment, in good working order and free of defect or fault, required to undertake your tasks or visit the site safely.</p>
                    <p>Confirm that all equipment complies with electrical safety regulations and has been fully PAT tested.</p>
                    <RadioGroup
                        row
                        defaultValue="Yes"
                        name={"radio-tae-group"}
                        value={viewModel.getValue("trainingAndEquipment")}
                        onChange={(e) => viewModel.radioChange("trainingAndEquipment", e.target.value)}
                    >
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("trainingAndEquipment") === "true"} />} label="Yes" />
                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("trainingAndEquipment") === "false"} />} label="No" />
                    </RadioGroup>
                    <SHBox showIf={viewModel.getValue("trainingAndEquipment") !== ""}>
                        <Error>{viewModel.getError("trainingAndEquipment")}</Error>
                    </SHBox>{" "}
                    <DashedDivider gutterBottom />
                </SHBox>

                <SHBox>
                    <Title>PPE</Title>
                    <p>Fully understand the Personal Protective Equipment rules and requirements of the site.</p>
                    <p>Fully understand the Personal Protective Equipment requirements for all tasks that you are undertaking.</p>
                    <p>
                        Have the correct Personal Protective Equipment, in good working order and free of defect or fault, required to undertake your tasks or visit the site
                        safely.
                    </p>
                    <RadioGroup row defaultValue="Yes" name={"radio-ppe-group"} value={viewModel.getValue("ppe")} onChange={(e) => viewModel.radioChange("ppe", e.target.value)}>
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("ppe") === "true"} />} label="Yes" />
                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("ppe") === "false"} />} label="No" />
                    </RadioGroup>
                    <SHBox showIf={viewModel.getValue("ppe") !== ""}>
                        <Error>{viewModel.getError("ppe")}</Error>
                    </SHBox>{" "}
                    <DashedDivider gutterBottom />
                </SHBox>

                <SHBox showIf={!props.viewModel.isVisitorInductionType}>
                    <Title>RAMS</Title>
                    <p>Will fully read, understand and adhere to the Risk Assessment regarding the hazards and mitigation strategies for the tasks you are undertaking.</p>
                    <p>Will fully read, understand and will adhere to the Method Statement detailing the working practices for the tasks that you are undertaking.</p>
                    <p>Fully understand the accident/near miss reporting procedure and that all accidents and near misses must be reported.</p>
                    <p>Fully understand the lone working restrictions, that lone working should be avoided at all costs and that all lone working must be via permit.</p>
                    <RadioGroup row defaultValue="Yes" name={"radio-rams-group"} value={viewModel.getValue("rams")} onChange={(e) => viewModel.radioChange("rams", e.target.value)}>
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("rams") === "true"} />} label="Yes" />
                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("rams") === "false"} />} label="No" />
                    </RadioGroup>
                    <SHBox showIf={viewModel.getValue("rams") !== ""}>
                        <Error>{viewModel.getError("rams")}</Error>
                    </SHBox>{" "}
                    <DashedDivider gutterBottom />
                </SHBox>

                <SHBox showIf={!props.viewModel.isVisitorInductionType}>
                    <Title>Housekeeping</Title>
                    <p>Understand and accept the housekeeping standards and the requirement for daily housekeeping.</p>
                    <p>Understand and accept the Clean Up Notice protocols operated by Portakabin - Trial should you not adhere to housekeeping standards.</p>
                    <p>Understand the environmental impact of chemical spillages and understand the correct emergency spill response procedures.</p>
                    <p>Will dispose of waste in the correct way at all times.</p>
                    <p>Will not burn any waste, under any circumstances.</p>
                    <RadioGroup
                        row
                        defaultValue="Yes"
                        name={"radio-housekeeping-group"}
                        value={viewModel.getValue("housekeeping")}
                        onChange={(e) => viewModel.radioChange("housekeeping", e.target.value)}
                    >
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("housekeeping") === "true"} />} label="Yes" />
                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("housekeeping") === "false"} />} label="No" />
                    </RadioGroup>
                    <SHBox showIf={viewModel.getValue("housekeeping") !== ""}>
                        <Error>{viewModel.getError("housekeeping")}</Error>
                    </SHBox>{" "}
                    <DashedDivider gutterBottom />
                </SHBox>

                <SHBox>
                    <Title>Rules/Discipline</Title>
                    <p>Fully understand the discipline expected from you whilst working on or visiting a Portakabin - Trial site.</p>
                    {!props.viewModel.isVisitorInductionType && <p>Fully understand and accept the red/yellow card system operated on Portakabin - Trial sites.</p>}
                    <p>Fully understand and accept that bullying/harassment of any kind will not be tolerated and the reporting procedures.</p>
                    <p>Will not smoke, vape, or otherwise use e-cigarettes anywhere other than designated smoking areas or outside of the site boundaries.</p>
                    <p>Understand and will adhere to the rules on mobile phones and electronic devices, portable radios, entertainment systems etc.</p>
                    <RadioGroup
                        row
                        defaultValue="Yes"
                        name={"radio-rulesDiscipline-group"}
                        value={viewModel.getValue("rulesDiscipline")}
                        onChange={(e) => viewModel.radioChange("rulesDiscipline", e.target.value)}
                    >
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("rulesDiscipline") === "true"} />} label="Yes" />
                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("rulesDiscipline") === "false"} />} label="No" />
                    </RadioGroup>
                    <SHBox showIf={viewModel.getValue("rulesDiscipline") !== ""}>
                        <Error>{viewModel.getError("rulesDiscipline")}</Error>
                    </SHBox>
                    <DashedDivider gutterBottom />
                </SHBox>

                <SHBox>
                    <Title>Site Specific Briefing</Title>
                    <p>Will report to the Site Manager for a site specific briefing prior to entering the site for the first time.</p>
                    <p>Will not, under any circumstances, enter site for the first time without having received this briefing.</p>
                    <RadioGroup
                        row
                        defaultValue="Yes"
                        name={"radio-siteSpecificBriefing-group"}
                        value={viewModel.getValue("siteSpecificBriefing")}
                        onChange={(e) => viewModel.radioChange("siteSpecificBriefing", e.target.value)}
                    >
                        <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("siteSpecificBriefing") === "true"} />} label="Yes" />
                        <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("siteSpecificBriefing") === "false"} />} label="No" />
                    </RadioGroup>
                    <SHBox showIf={viewModel.getValue("siteSpecificBriefing") !== ""}>
                        <Error>{viewModel.getError("siteSpecificBriefing")}</Error>
                    </SHBox>
                </SHBox>
            </Questions>
            <WizardButtonsView nextStep={viewModel.nextStep} previousStep={viewModel.previousStep} canExecute={viewModel.canExecuteNextStep} />
        </SHBox>
    );
});

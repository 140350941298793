import clsx from "clsx";
import { DarwinAppBar, NavLinkButton } from "./Header.style";

import React, { useState, useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import { Drawer, Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Link from "@material-ui/core/Link";
import { useObserver } from "mobx-react-lite";
import { StoresContext, Stores } from "Globals/Stores";
import Cog from "Content/Cog.svg";
import { useMediaQuery } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { Grid, Else, Show } from "@shoothill/core";
import { defaultTheme } from "Globals/Styles/AppTheme";
import Logo from "../../Content/darwinlogo.png";
interface LinkButonProps {
    className?: string;
    linkText?: string;
    img?: any;
    imgDesc?: string;
    imgClass?: string;
    to?: any;
    onClick?: any;
}

export const LinkButton: React.FC<LinkButonProps> = (props: LinkButonProps) => {
    let component: any = RouterLink;
    if (props.to === null || props.to === undefined) {
        component = Box;
    }
    return (
        <NavLinkButton className={props.className} color="inherit">
            <Link color={"inherit"} component={component} to={props.to} onClick={props.onClick} underline="none">
                {props.linkText && (
                    <Typography color={"inherit"} variant={"button"}>
                        {props.linkText}
                    </Typography>
                )}
                {props.img && <img src={props.img} alt={props.imgDesc} className={props.imgClass} />}
            </Link>
        </NavLinkButton>
    );
};

export const Header: React.FC = () => {
    const location = useLocation();

    const getClassName = (root: string) => {
        return clsx({
            ["header-item"]: true,
            ["active"]: location.pathname.startsWith(root),
        });
    };

    const [open, setOpen] = useState(false);
    const storesContext = useContext<Stores>(StoresContext);
    const isMobile: boolean = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const loggedInList = (
        <>
            {!storesContext.Domain.AccountStore.isSiteManager && <LinkButton className={getClassName("/approval")} linkText={"Approvals"} to={AppUrls.Client.Approval.List} />}
            {storesContext.Domain.AccountStore.getCanViewCentralProject && (
                <LinkButton className={getClassName("/central")} linkText={"Central"} to={AppUrls.Client.Central.View} />
            )}
            {/* <LinkButton linkText={"Central"} to={AppUrls.Client.Central.List} /> */}
            <LinkButton className={getClassName("/project")} linkText={"Projects"} to={AppUrls.Client.Project.List} />
            {!storesContext.Domain.AccountStore.isSiteManager && <LinkButton className={getClassName("/po")} linkText={"Purchase Orders"} to={AppUrls.Client.PurchaseOrder.List} />}
            {!storesContext.Domain.AccountStore.isSiteManager && <LinkButton className={getClassName("/supplier")} linkText={"Suppliers"} to={AppUrls.Client.Supplier.List} />}
            {storesContext.Domain.AccountStore.getCanViewStock && <LinkButton className={getClassName("/stock")} linkText={"Stock"} to={AppUrls.Client.Stock.List} />}
            {!storesContext.Domain.AccountStore.isSiteManager && <LinkButton className={getClassName("/invoice")} linkText={"Invoicing"} to={AppUrls.Client.Invoicing.List} />}
            {storesContext.Domain.AccountStore.getCanViewReports && <LinkButton className={getClassName("/report")} linkText={"Reports"} to={AppUrls.Client.Report.AllProject} />}
            {/* For testing */}
            {/* {!storesContext.Domain.AccountStore.isSiteManager && <LinkButton linkText={"InductionLanding"} to={AppUrls.Client.Induction.Landing} />} */}
            {/* For testing */}
            {/* {!storesContext.Domain.AccountStore.isSiteManager && (
                <LinkButton linkText={"Signin/out"} to={AppUrls.Client.Induction.Access.Landing.replace(":projectId", "E7FFBCCB-E27D-482F-B217-0C1B766E6128")} />
            )} */}
            {storesContext.Domain.AccountStore.getCanViewInductions && (
                <LinkButton className={getClassName("/induction")} linkText={"Inductions"} to={AppUrls.Client.Induction.List} />
            )}
            {/* TODO later  <LinkButton linkText={"Reporting"} to={AppUrls.Client.Report.List} /> */}
            {storesContext.Domain.AccountStore.getCanViewSettings && (
                <LinkButton className={getClassName("/admin")} img={Cog} imgClass="admin-cog" imgDesc="Admin" to={AppUrls.Client.Admin.User.List} />
            )}
            <LinkButton linkText={"Logout"} onClick={() => storesContext.Domain.AccountStore.Logout(true)} />
        </>
    );

    const loggedOutList = (
        <>
            {/*<LinkButton linkText={"Login"} to={AppUrls.Client.Account.Login} />
            <LinkButton linkText={"Examples"} to={AppUrls.Client.Samples} />
            <LinkButton linkText={"Register"} to={AppUrls.Client.Account.Register} />
            <LinkButton linkText={"Test Page"} to={AppUrls.Client.Test} /> */}
        </>
    );

    return useObserver(() => (
        <>
            {/* Root */}
            <DarwinAppBar position={"sticky"} style={{ color: defaultTheme.palette.common.black }}>
                {/* Content */}
                <Toolbar>
                    {!isMobile && (
                        <>
                            {/* Navigate to Home */}
                            <Box flexGrow={1} style={{ cursor: "pointer" }}>
                                {/* <img
                                    src={darwinLogo}
                                    alt="Darwin Group"
                                    className="logoDarwin"
                                    onClick={() => getHistory().push(StoresInstance.Domain.AccountStore.getDefaultRoute())}
                                /> */}

                                <img src={Logo} alt="Logo" />
                                {storesContext.Domain.AccountStore.IsLoggedIn && !storesContext.Domain.AccountStore.isSiteTablet && (
                                    <>
                                        <span
                                            className="font-openSans"
                                            style={{ display: "inline-block", marginLeft: "30px", fontWeight: "700", fontSize: "12px", textTransform: "unset", color: "#000" }}
                                        >
                                            {storesContext.Domain.AccountStore.DisplayName}
                                        </span>
                                    </>
                                )}
                            </Box>
                            <Show if={storesContext.Domain.AccountStore.IsLoggedIn && !storesContext.Domain.AccountStore.isSiteTablet}>
                                <Box>
                                    {/* Navigation Options */}
                                    {loggedInList}
                                </Box>
                                <Else>
                                    <Box>
                                        {/* Navigation Options */}
                                        {loggedOutList}
                                    </Box>
                                </Else>
                            </Show>
                        </>
                    )}
                    {isMobile && !storesContext.Domain.AccountStore.isSiteTablet && (
                        <>
                            <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} edge="start">
                                <MenuIcon />
                            </IconButton>
                        </>
                    )}
                </Toolbar>
            </DarwinAppBar>
            <Drawer variant="persistent" anchor="left" open={open && isMobile}>
                <div>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <Show if={storesContext.Domain.AccountStore.IsLoggedIn}>
                    <Grid justifyItems={"flex-start"}>{loggedInList}</Grid>
                    <Else>
                        <Grid justifyItems={"flex-start"}>{loggedOutList}</Grid>
                    </Else>
                </Show>
            </Drawer>
        </>
    ));
};

import { SupplierStatus } from "Globals/Models/Domain/SupplierStatusEnum";
import { CostCategory } from "Globals/Models/Domain/CostCategory";
import { action, computed, observable } from "mobx";
import { ModelBase, isNullOrUndefined, sortByString } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { AddressModel, DarwinModelBaseDTO } from "Globals/Models/Domain";
import type { SupplierContactDTO } from "./SupplierContactModel";
import type { IObservableArray } from "mobx";
import type { AddressModelDTO } from "Globals/Models/Domain";
import { PurchaseOrderListModelDTO } from "Views/PurchaseOrder/PurchaseOrderListModel";
import { InvoiceDTO, InvoiceModel } from "Views/Invoice/InvoiceModel";
import { PaymentTermsModel } from "Views/PurchaseOrder/Form/Supporting/PaymentTermsModel";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import { PaymentTypeModel } from "Views/PurchaseOrder/Form/Supporting/PaymentTypeModel";
import { SupplierDocumentDTO } from "./SupplierDocumentModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class SupplierDetailModel extends ModelBase<SupplierDetailModel, SupplierDetailModelDTO> {
    clear() {
        throw new Error("Method not implemented.");
    }
    toAddressDto(): AddressModelDTO {
        throw new Error("Method not implemented.");
    }

    typeId: any;
    model: any;
    validateFilters() {
        throw new Error("Method not implemented.");
    }
    public id: string | undefined = undefined;
    public createdDate: string | undefined = undefined;
    public isDeleted: boolean = false;
    public rowVersion: string | undefined = undefined;
    public originatorId: string | undefined = undefined;

    @observable
    @IsNotEmpty({ message: "Supplier name is required" })
    public name: string = "";

    @observable
    @IsNotEmpty({ message: "Supplier reference is required" })
    public reference: string = "";

    @observable
    public originatorName: string = "";

    @observable
    public status: SupplierStatus = SupplierStatus.Draft;

    @observable
    public costCategory: CostCategory = CostCategory.All;

    @observable
    public contacts: IObservableArray<SupplierContactDTO> = observable([]);

    @observable
    public addresses: IObservableArray<AddressModel> = observable([]);

    @observable
    //public invoices: IObservableArray<InvoiceModel> = observable([]);
    public filterStartDate: string = "";

    @observable
    public paymentTermsId: string | null = null;

    @observable
    public addressFormViewModel = new AddressViewModel(new AddressModel());

    @observable
    public accountOpened: string = "";

    @observable
    public vatNumber: string = "";

    @observable
    public defaultVatRate: number = 0;

    @observable
    public paymentTermsDays: number = 0;

    @observable
    public creditLimit: number = 0;

    @observable
    public paymentTermsInDays: number = 0;

    @observable
    public paymentGroup: string | null = null;

    @observable
    public insuranceExpiryDate: string = "";

    @observable
    public constructionalReg: string = "";

    @observable
    public pqq: boolean = true;

    @observable
    public invoiceFactoring: string = "";

    @observable
    public creditInsurer: string = "";

    @computed
    public get validatePaymentTermsId(): string {
        return this.paymentTerms === this.paymentTerms ? "Please select payment terms" : "";
    }

    @computed
    public get validatePaymentTypeId(): string {
        return this.paymentGroup === this.paymentGroup ? "Please select a payment type" : "";
    }

    fromDto(model: SupplierDetailModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: SupplierDetailModel): void {}

    @action
    public reset = () => {
        this.fromDto(DefaultSupplierDetailModelDTO);
    };

    @action
    public addContact(contact: SupplierContactDTO) {
        const newArray: SupplierContactDTO[] = this.contacts.slice();
        newArray.push(contact);

        newArray.sort((a: SupplierContactDTO, b: SupplierContactDTO) => {
            return sortByString(a.lastName + " " + a.firstName, b.lastName + " " + b.firstName);
        });

        this.contacts.replace(newArray);
    }

    @action
    public replaceContacts(contacts: SupplierContactDTO[]) {
        this.contacts.clear();

        contacts.sort((a: SupplierContactDTO, b: SupplierContactDTO) => {
            return sortByString(a.lastName + " " + a.firstName, b.lastName + " " + b.firstName);
        });

        this.contacts.push(...contacts);
    }

    @action
    public replaceContact(contact: SupplierContactDTO) {
        const newArray: SupplierContactDTO[] = this.contacts.slice().filter((a) => a.id !== contact.id);
        newArray.push(contact);

        newArray.sort((a: SupplierContactDTO, b: SupplierContactDTO) => {
            return sortByString(a.lastName + " " + a.firstName, b.lastName + " " + b.firstName);
        });

        this.contacts.replace(newArray);
    }

    @action
    public removeContact(contactId: string) {
        const item: SupplierContactDTO | undefined = this.contacts.slice().find((a) => a.id === contactId);
        if (isNullOrUndefined(item) === false) {
            item!.isDeleted = true;
        }
    }

    @observable
    public paymentTerms = observable<PaymentTermsModel>([]);

    @computed
    public get paymentTerm() {
        const result = this.paymentTerms.find((p) => p.id === this.model.paymentTermsId);
        return result ? result! : null;
    }

    @action
    public setPaymentTerms = (value: PaymentTermsModel | null) => {
        this.model.paymentTermsId = value ? value.id : null;
    };

    @observable
    public paymentTypes = observable<PaymentTypeModel>([]);

    @computed
    public get paymentType() {
        const result = this.paymentTypes.find((p) => p.id === this.model.paymentGroup);
        return result ? result! : null;
    }

    @action
    public updateAddress(address: AddressModelDTO) {
        this.model.setAddress(0, address);
    }

    @action
    public setAddress(index: number, address: AddressModelDTO) {
        let newArray: AddressModel[] = [];
        const changedModel: AddressModel = new AddressModel();
        changedModel.fromDto(address);
        newArray.push(changedModel);

        this.addresses.replace(newArray);
    }
}

export interface SupplierDetailModelDTO extends DarwinModelBaseDTO {
    reference: string;
    name: string;
    isDeleted: boolean;
    rowVersion: string | undefined;
    originatorId: string | undefined;
    originatorName: string;
    costCategory: CostCategory;
    status: SupplierStatus;
    contacts: SupplierContactDTO[];
    addresses: AddressModelDTO[];
    supplierDocuments: SupplierDocumentDTO[];

    invoices: InvoiceDTO[]; // This is probably the wrong type
    purchaseOrders: PurchaseOrderListModelDTO[];

    accountOpened: string;
    vatNumber: string;
    defaultVatRate: number | null;
    paymentTermsInDays: number | null;
    paymentTerms: string;
    creditLimit: number | null;
    paymentGroup: string;
    insuranceExpiryDate: string;
    constructionalReg: string;
    pqq: boolean;
    invoiceFactoring: string;
    creditInsurer: string;
}

export const DefaultSupplierDetailModelDTO: SupplierDetailModelDTO = {
    id: "",
    createdDate: "",
    costCategory: CostCategory.All,
    reference: "",
    name: "",
    isDeleted: false,
    rowVersion: undefined,
    originatorId: undefined,
    originatorName: "",
    status: SupplierStatus.Draft,
    contacts: [],
    addresses: [],
    supplierDocuments: [],
    invoices: [],
    purchaseOrders: [],
    accountOpened: "",
    vatNumber: "",
    defaultVatRate: null,
    paymentTermsInDays: null,
    paymentTerms: "",
    creditLimit: null,
    paymentGroup: "",
    insuranceExpiryDate: "",
    constructionalReg: "",
    pqq: false,
    invoiceFactoring: "",
    creditInsurer: "",
};

export interface AddSupplierModelDTO extends DarwinModelBaseDTO {
    name: string;
    costCategory: CostCategory;
    reference: string;
}

export const DefaultAddSupplierModelDTO: AddSupplierModelDTO = {
    id: "",
    createdDate: "",
    costCategory: CostCategory.All,
    name: "",
    reference: "",
};

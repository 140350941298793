import { Box, Checkbox, FormControl, FormControlLabel, IconButton, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { VariationViewModel } from "./VariationViewModel";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { VariationDocumentDTO, VariationModel } from "./VariationModel";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { VariationLineModal } from "./CategoryGrid/VariationLineModal";
import { formatVariationNumber } from "Utils/Format";
import { VariationGridViewCatSubDescContainer } from "./CategoryGrid/VariationGridView.CatSubDescContainer";
import { VariationGridSummary } from "./CategoryGrid/VariationGrid.Styles";
import { VariationNotesContainer } from "./Notes/VariationNoteForm";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { DeleteModal } from "Components/Modal/DeleteModal";
import { VariationStatus, VariationStatusHelpers } from "Views/Project/Variations/VariationStatusEnum";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { AppUrls } from "AppUrls";
import { CustomArrow } from "Views/Project/CustomComponents";
import { Uploader } from "Components/Uploader/Uploader";
import { VariationApprovalPanel } from "../VariationApprovalPanel";
import { useLocation } from "react-router-dom";
import { SendForApprovalModal } from "Views/PurchaseOrder/Form/SendForApprovalModal";
import DownloadDocIcon from "Content/fileIcon.svg";
import { VariationDocumentsRow } from "./VariationView.Styles";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";
import { VariationUnapprovedPurchaseOrderValidationModal } from "./VariationUnapprovedPurchaseOrderValidationModal/VariationUnapprovedPurchaseOrderValidationModal";
import { VariationChangesRequiredModal } from "./VariationChangesRequiredModal";
interface IProps {
    className?: string;
}

const VariationViewBase: React.FC<IProps> = (props) => {
    // #region Code Behind

    const { match } = useRouter();
    const { variationid } = match.params as any;
    const { ieid } = match.params as any;
    const [viewModel] = useState(() => new VariationViewModel(variationid, ieid));
    const { history } = useRouter();
    const location = useLocation();
    const [isFromApprovalSection, setIsFromApprovalSection] = useState<boolean>(false);
    const [isChangedRequiredModalOpen, setIsChangedRequiredModalOpen] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    useEffect(() => {
        // Check if the user has navigated from the approvals list.
        const { approvalMode } = match.params as any;
        if (approvalMode && approvalMode === "true") {
            setIsFromApprovalSection(true);
        } else {
            setIsFromApprovalSection(false);
        }

        // Check if the form should be view only.
        if (viewModel.model.id && AppUrls.Client.Variation.View.replace(":variationid", viewModel.model.id) === location.pathname) {
            viewModel.setIsViewOnly(true);
        }

        return () => {
            viewModel.reset();
        };
    }, []);

    // Add/Edit IE Line/Description modal
    const [variationLineModalActive, setVariationLineModalActive] = useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const handleChangesRequiredClick = () => {
        setIsChangedRequiredModalOpen(true);
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const addNewVariationLineClick = () => {
        if (!viewModel.isFormDisabled) {
            setVariationLineModalActive(true);
        }
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const handleDeleteVariation = () => {
        viewModel.deleteVariation();
        setDeleteOpen(false);
    };

    const handleGenerateVOPDF = async (rowData: VariationViewModel) => {
        await viewModel.apiGenerateVOPDF(rowData.model.id as string);
    };

    const setShowUnapprovedPurchaseOrderValidationModal = (val: boolean) => {
        viewModel.handleShowUnapprovedPurchaseOrderValidationModalChange(val);
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                {viewModel.showSendForApprovalModal && (
                    <SendForApprovalModal
                        onClose={() => viewModel.handleShowSendForApprovalModalChange(false)}
                        open={viewModel.getShowSendForApprovalModal}
                        title="Send for approval"
                        confirmText="Send for approval"
                        onSave={viewModel.amendVariation}
                        //viewModel={viewModel}
                        value={viewModel.model.requesterNotes}
                        setValue={(val: string) => viewModel.setRequesterNote(val)}
                        isLoading={viewModel.IsLoading}
                        isDisabled={!viewModel.getRequesterNoteValid}
                    />
                )}
                <VariationApprovalPanel
                    viewModel={viewModel}
                    sideOpen={viewModel.approvalPanelViewModel.getCanShowPanel && !viewModel.IsLoading && isFromApprovalSection}
                    isFromApprovalSection={isFromApprovalSection}
                    primaryTitle={viewModel.approvalPanelViewModel.getTitle}
                    secondaryTitle={`${viewModel.approvalPanelViewModel.model.requesterName} ${viewModel.approvalPanelViewModel.getRequestedDateFormatted}`}
                />
                <BackButtonHolder
                    onClick={() => {
                        if (IEGridItemViewModel.Instance.isCentral) {
                            history.push(AppUrls.Client.Central.View.replace(":ieid", ieid ? ieid : viewModel.model.ieId) + "#var");
                        } else {
                            history.push(AppUrls.Client.Project.IE.replace(":ieid", ieid ? ieid : viewModel.model.ieId) + "#var");
                        }
                    }}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>Back to {viewModel.getIETitle} (Variations)</div>
                    </div>
                </BackButtonHolder>
                <HeaderContainer>
                    <FormHeader
                        displayName={`${
                            viewModel.model.id
                                ? "Variation - " + formatVariationNumber(viewModel.model.variationNumber) + (viewModel.model.revision ? viewModel.model.revision : "")
                                : "New variation"
                        }`}
                    />
                    {viewModel.canShowReviseButton && (
                        <PrimaryButton
                            displayName="Revise"
                            execute={() => viewModel.setIsInReviseMode(true)}
                            fullWidth={false}
                            canExecute={!viewModel.getIsInReviseMode && !viewModel.IsLoading}
                        />
                    )}
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                        <StatusCell
                            className={VariationStatusHelpers.getClassName(viewModel.variationStatusName.toUpperCase() as VariationStatus)}
                            style={{
                                backgroundColor: VariationStatusHelpers.getCellColour(viewModel.variationStatusName.toUpperCase() as VariationStatus, theme),
                                width: 275,
                                color: VariationStatusHelpers.getCellTextColour(viewModel.variationStatusName.toUpperCase() as VariationStatus),
                            }}
                        >
                            <div>{VariationStatusHelpers.getText(viewModel.variationStatusName.toUpperCase() as VariationStatus)}</div>
                        </StatusCell>
                        {viewModel.model.id && (
                            <Box display={"flex"} alignItems="center" ml={"15px"} style={{ cursor: "pointer" }}>
                                <img src={DownloadDocIcon} onClick={() => handleGenerateVOPDF(viewModel)} width="16px" />
                            </Box>
                        )}
                    </div>
                </HeaderContainer>
                <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                {viewModel.canShowClientApprovalSection && (
                    <FormSection>
                        <ClientApprovalContainer>
                            <Typography variant="h2" color="textPrimary">
                                Client approval documents
                            </Typography>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                                <Cell ts={8} ds={8}>
                                    <Uploader
                                        handleSelectFile={(e: any) => viewModel.handleSelectClientFile(e)}
                                        isFormDisabled={!viewModel.canEditClientApprovalSection || viewModel.IsLoading}
                                        label="Attach CAI and any other supporting information"
                                    />

                                    <VariationDocumentsRow>
                                        {viewModel.getClientDocuments.map((item: VariationDocumentDTO, index: number) => {
                                            return (
                                                <div className="uploadedFile-box">
                                                    <div className="uploadedFile-heading">
                                                        <IconButton onClick={() => viewModel.DownloadFile(item.url, item.fileName)}>
                                                            <SystemUpdateTwoToneIcon />
                                                        </IconButton>
                                                        <h3>{item.fileName}</h3>
                                                    </div>
                                                    <IconButton onClick={() => viewModel.handleDeleteDocument(item.id)} disabled={!viewModel.canEditClientApprovalSection}>
                                                        <DeleteOutlinedIcon />
                                                    </IconButton>
                                                </div>
                                            );
                                        })}
                                    </VariationDocumentsRow>
                                </Cell>
                            </Grid>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                                <Cell ts={2} ds={2}>
                                    <DarwinInputWrapper<VariationModel>
                                        type="text"
                                        label="Client approval reference:"
                                        placeHolder="Client approval reference"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="clientApprovalReference"
                                        shrink={true}
                                        maxLength={256}
                                        editMode={viewModel.canEditClientApprovalSection}
                                    />
                                </Cell>
                            </Grid>
                            <br />
                            <DarwinInputWrapper<VariationModel>
                                type="text"
                                label="Add any notes"
                                multiLine={true}
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="clientNote"
                                shrink={true}
                                maxLength={1024}
                                editMode={viewModel.canEditClientApprovalSection}
                                InputProps={{
                                    placeholder: "Please add your note...",
                                }}
                            />
                        </ClientApprovalContainer>
                    </FormSection>
                )}

                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell ts={8} ds={8}>
                            <DarwinInputWrapper<VariationModel>
                                type="text"
                                label="Variation description:*"
                                placeHolder="Description"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="description"
                                shrink={true}
                                maxLength={1024}
                                editMode={!viewModel.isFormDisabled}
                            />
                        </Cell>

                        {/* Group of 8 columns */}
                        <Cell ts={2} ds={2}>
                            <DarwinInputWrapper<VariationModel>
                                type="text"
                                label="Requested by:*"
                                placeHolder="Requested by"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="requestedBy"
                                shrink={true}
                                maxLength={256}
                                editMode={!viewModel.isFormDisabled}
                            />
                        </Cell>

                        <Cell ts={1} ds={1}>
                            <DarwinDateSelect
                                displayName="Date requested:*"
                                execute={(value: string | null) => viewModel.setValue("requestedDate", value)}
                                onBlur={() => viewModel.isFieldValid("requestedDate")}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("requestedDate")}
                                value={viewModel.getValue("requestedDate")}
                                canExecute={!viewModel.isFormDisabled}
                            />
                        </Cell>
                        <Cell ts={1} ds={1}>
                            <DarwinDateSelectCustom
                                displayName="Client response required by:*"
                                execute={(value: string | null) => viewModel.setValue("clientResponseRequiredDate", value)}
                                onBlur={() => viewModel.isFieldValid("clientResponseRequiredDate")}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("clientResponseRequiredDate")}
                                value={viewModel.getValue("clientResponseRequiredDate")}
                                canExecute={!viewModel.isFormDisabled}
                            />
                        </Cell>
                        <Cell ts={1} ds={1}>
                            <VariationCheckboxContainer>
                                <FormControlLabel
                                    control={<Checkbox checked={viewModel.model.costToBeAgreed} onChange={viewModel.setCostToBeAgreed} />}
                                    label={"Cost to be agreed:"}
                                    name={`cost-to-be-agreed-checkbox`}
                                    disabled={viewModel.isFormDisabled}
                                />
                            </VariationCheckboxContainer>
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <DarwinInputWrapper<VariationModel>
                                type="text"
                                label="Programme impact:"
                                placeHolder="Programme impact"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="programmeImpact"
                                shrink={true}
                                maxLength={256}
                                editMode={!viewModel.isFormDisabled}
                            />
                        </Cell>

                        {/* Group of 8 columns */}
                        <Cell ts={2} ds={2}>
                            <DarwinInputWrapper<VariationModel>
                                type="text"
                                label="Employers agent recipient:*"
                                placeHolder="Recipient"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="employersAgentRecipient"
                                shrink={true}
                                maxLength={256}
                                editMode={!viewModel.isFormDisabled}
                            />
                        </Cell>

                        <Cell ts={2} ds={2}>
                            <DarwinInputWrapper<VariationModel>
                                type="text"
                                label="Employers agent email address:*"
                                placeHolder="Email address"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="employersAgentEmailAddress"
                                shrink={true}
                                maxLength={256}
                                editMode={!viewModel.isFormDisabled}
                            />
                        </Cell>

                        <Cell ts={1} ds={1}>
                            <TextField
                                id="overheadPercentage"
                                label="Ohp %:*"
                                placeholder="Ohp %"
                                value={viewModel.model.overheadPercentage}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.handleOverheadPercentageChange(event.target.value)}
                                fullWidth
                                type="number"
                                disabled={viewModel.isFormDisabled || viewModel.isInReviseMode}
                                error={viewModel.getError("overheadPercentage") !== ""}
                                helperText={viewModel.getError("overheadPercentage")}
                            />
                        </Cell>

                        <Cell ts={1} ds={1}>
                            <TextField
                                id="designPercentage"
                                label="Design %:*"
                                placeholder="Design %"
                                value={viewModel.model.designPercentage}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.handleDesignPercentageChange(event.target.value)}
                                fullWidth
                                type="number"
                                disabled={viewModel.isFormDisabled || viewModel.isInReviseMode}
                                error={viewModel.getError("designPercentage") !== ""}
                                helperText={viewModel.getError("designPercentage")}
                            />
                        </Cell>

                        <Cell display={{ xs: "none", md: "block" }} ts={2} ds={2} />
                    </Grid>
                </FormSection>

                <FormSection>
                    <DashedDivider gutterBottom={true} />
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={4} ds={4}>
                            <TextField
                                id="email-body"
                                multiline
                                rows={25}
                                value={viewModel.model.emailBodyToEmployersAgent}
                                onChange={(event: any) => viewModel.setValue("emailBodyToEmployersAgent", event.target.value)}
                                fullWidth
                                className="textarea"
                                label="Message to employers agent:"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={`For example, Hi Dave, variation pulled together as promised.  Let me know if you want to chat through`}
                                disabled={viewModel.isFormDisabled}
                            />
                        </Cell>

                        <Cell ts={4} ds={4}>
                            <Uploader
                                handleSelectFile={(e: React.ChangeEvent<HTMLInputElement>) => viewModel.handleSelectInternalFile(e)}
                                isFormDisabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                label="Associated design information"
                            />

                            <div className="uploadedFile">
                                {viewModel.getInternalDocuments.map((item: VariationDocumentDTO, index: number) => {
                                    return (
                                        <div className="uploadedFile-box">
                                            <div className="uploadedFile-heading">
                                                <IconButton onClick={() => viewModel.DownloadFile(item.url, item.fileName)}>
                                                    <SystemUpdateTwoToneIcon />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </div>
                                            <IconButton onClick={() => viewModel.handleDeleteDocument(item.id)} disabled={viewModel.isFormDisabled}>
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                            </div>
                        </Cell>
                    </Grid>
                </FormSection>

                <Box>
                    <DashedDivider gutterBottom={true} />

                    <VariationGridViewCatSubDescContainer
                        IEid={""}
                        viewModel={viewModel}
                        IEType={"MASTER"}
                        addNewVariationLineClick={addNewVariationLineClick}
                        isDisabled={viewModel.isFormDisabled}
                    />

                    <VariationGridSummary>
                        <ul>
                            <div>
                                <li className="item title">
                                    <div></div>
                                    <div>Percentage</div>
                                    <div>Totals</div>
                                    <div>Future spend</div>
                                    <div>Variance</div>
                                </li>
                            </div>
                            <div>
                                <li className="item title">
                                    <div>Sub total</div>
                                    <div></div>
                                    <div>{viewModel.lineTotalSumFormatted}</div>
                                    <div>{viewModel.futureSpendFormatted}</div>
                                    <div>{viewModel.varianceFormatted}</div>
                                </li>
                            </div>
                            <div>
                                <li className="item title">
                                    <div>Overhead</div>
                                    <div>{viewModel.formattedOverheadPercentage}</div>
                                    <div>{viewModel.formattedValueAddedOverheadPercentage}</div>
                                    <div>0</div>
                                    <div>{viewModel.formattedValueAddedOverheadPercentage}</div>
                                </li>
                            </div>
                            <div>
                                <li className="item title">
                                    <div>Design</div>
                                    <div>{viewModel.formattedDesignPercentage}</div>
                                    <div>{viewModel.formattedValueAddedDesignPercentage}</div>
                                    <div>0</div>
                                    <div>{viewModel.formattedValueAddedDesignPercentage}</div>
                                </li>
                            </div>
                            <div>
                                <li className="item title">
                                    <div>Total (Excl. VAT)</div>
                                    <div></div>
                                    <div>{viewModel.totalVariationFormatted}</div>
                                    <div>{viewModel.futureSpendFormatted}</div>
                                    <div>{viewModel.varianceTotalSumValueFormatted}</div>
                                </li>
                            </div>
                        </ul>
                    </VariationGridSummary>
                </Box>

                <FormSection>
                    <VariationNotesContainer
                        canExecute={!viewModel.isFormDisabled}
                        notes={viewModel.getInternalNotes}
                        label="Notes for internal approval:"
                        addNote={viewModel.addInternalNote}
                    />
                </FormSection>
                <FormSection>
                    <VariationNotesContainer
                        canExecute={!viewModel.isFormDisabled}
                        notes={viewModel.getEmployersAgentNotes}
                        label="Notes/exclusions to Employers Agent:"
                        addNote={viewModel.addEmployersAgentNote}
                    />
                </FormSection>

                {(viewModel.canSaveAsDraft || viewModel.getIsInReviseMode) && (
                    <FormButtonsContainer>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                            {/* Group of 8 columns */}
                            <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                            <Cell ts={2} ds={2}>
                                <PrimaryButton
                                    displayName="Save as draft"
                                    execute={viewModel.saveAsDraft}
                                    fullWidth={true}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading && viewModel.canSaveAsDraft}
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <PrimaryButton
                                    displayName="Send for internal approval"
                                    execute={viewModel.sendForInternalApproval}
                                    fullWidth={true}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                            </Cell>

                            <Cell ts={2} ds={2}>
                                <DefaultButton
                                    displayName="Delete"
                                    execute={() => setDeleteOpen(true)}
                                    fullWidth={true}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading && viewModel.canDeleteVariation && !viewModel.getIsInReviseMode}
                                />
                            </Cell>
                            <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                        </Grid>
                    </FormButtonsContainer>
                )}

                {viewModel.isPendingClientApproval && (
                    <FormButtonsContainer>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                            {/* Group of 8 columns */}
                            <Cell display={{ xs: "none", md: "block" }} ts={2} ds={2} />
                            <Cell ts={2} ds={2}>
                                <PrimaryButton
                                    displayName="Changes required"
                                    execute={handleChangesRequiredClick}
                                    fullWidth={true}
                                    canExecute={viewModel.canEditClientApprovalSection && !viewModel.IsLoading}
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <PrimaryButton
                                    displayName="Approved by client"
                                    execute={viewModel.saveClientApproval}
                                    fullWidth={true}
                                    canExecute={viewModel.canEditClientApprovalSection && !viewModel.IsLoading}
                                />
                            </Cell>
                            <Cell display={{ xs: "none", md: "block" }} ts={2} ds={2} />
                        </Grid>
                    </FormButtonsContainer>
                )}

                {viewModel.canAmendVariation && (
                    <FormButtonsContainer>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                            {/* Group of 8 columns */}
                            <Cell display={{ xs: "none", md: "block" }} ts={3} ds={3} />
                            <Cell ts={2} ds={2}>
                                <PrimaryButton
                                    displayName="Resubmit amendment"
                                    execute={() => viewModel.handleShowSendForApprovalModalChange(true)}
                                    fullWidth={true}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                            </Cell>
                            <Cell display={{ xs: "none", md: "block" }} ts={3} ds={3} />
                        </Grid>
                    </FormButtonsContainer>
                )}

                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />

                {variationLineModalActive && (
                    <VariationLineModal open={variationLineModalActive} onClose={() => setVariationLineModalActive(false)} variationViewModel={viewModel} />
                )}
                <DeleteModal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onDelete={handleDeleteVariation}
                    title="Delete variation?"
                    text="Are you sure you want to delete this variation?"
                    isLoading={viewModel.isFormDisabled || viewModel.IsLoading || !viewModel.canDeleteVariation}
                />

                <VariationUnapprovedPurchaseOrderValidationModal
                    onClose={() => setShowUnapprovedPurchaseOrderValidationModal(false)}
                    open={viewModel.getShowUnapprovedPurchaseOrderValidationModal}
                    title="There is an unapproved order against one or more of the lines."
                    message={"There is an unapproved order against one or more of the lines. Please wait until that order has been approved."}
                    isLoading={viewModel.IsLoading}
                />

                <VariationChangesRequiredModal
                    open={isChangedRequiredModalOpen}
                    onClose={() => setIsChangedRequiredModalOpen(false)}
                    title={"Send for amends"}
                    confirmText={"Send for amends"}
                    viewModel={viewModel}
                    isLoading={viewModel.IsLoading}
                />
            </Form>
        );
    });
};

export const VariationView = styled(VariationViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

export const DarwinDateSelectCustom = styled(DarwinDateSelect)`
    label {
        white-space: nowrap;
    }
`;

export const FormButtonsContainer = styled(FormSection)`
    position: sticky;
    bottom: 0;
    padding: 10px 0px;
    background-color: #ffffff;
    border-top: 1px solid lightgrey;
    margin: 0px;
    box-shadow: 0px 1px 6px 0px #888888;

    button {
        > span {
            font-size: ${pxToRem(14)} !important;
        }
    }
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 70px;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ClientApprovalContainer = styled(Box)`
    background-color: #eaf4f9;
    padding: 20px;
    border-radius: 15px;
`;

export const VariationCheckboxContainer = styled(FormControl)`
    > label {
        flex-direction: column-reverse;
        align-items: flex-start;

        > span.MuiTypography-root {
            color: rgba(0, 0, 0, 0.87) !important;
            font-size: 0.6875rem !important;
            letter-spacing: -0.01125rem;
            font-family: Open Sans;
            font-weight: 600;
        }

        > span.MuiButtonBase-root {
            padding-left: 0px;
        }
    }
`;

import { Box, Fade, Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { generateID } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import { AccountImageBox, AccountPage, BoxAccount, BoxPage } from "Globals/Styles/AccountStyling";
import { AccountTitleBox } from "Views/Login/LoginView.style";
import Logo from "../../Content/darwinlogo.png";
export interface IWindowState {
    width: number;
    height: number;
}

export const ForgotPasswordSuccessView: React.FunctionComponent = () => {
    const componentRef: any = useRef(generateID());

    const [dimensions, setDimensions] = React.useState<IWindowState>({
        width: 0,
        height: 0,
    });

    ///
    /// updateWindowDimensions
    ///
    const updateWindowDimensions = () => {
        if (componentRef !== undefined && componentRef != null && componentRef.current !== null && componentRef.current !== undefined) {
            setDimensions({
                width: componentRef!.current!.offsetWidth,
                height: componentRef.current!.offsetHeight!,
            });
        }
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        // tidy up after yourself
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    const inPortrait: boolean = dimensions.height > dimensions.width;
    const triangleHeight: string = (inPortrait === true ? (dimensions.width * 0.765).toString() : (dimensions.height * 0.765).toString()) + "px";

    return useObserver(() => (
        <Fade in timeout={1000}>
            <AccountPage triangleheight={triangleHeight} ref={componentRef}>
                <div className="triangle"></div>
                <BoxPage>
                    <BoxAccount>
                        <AccountImageBox>
                            <img src={Logo} alt="Logo" />
                        </AccountImageBox>
                        <AccountTitleBox>{/* <span>Project Management System</span> */}</AccountTitleBox>
                        <Box textAlign="left" margin="20px 0px 0px 88px">
                            <Typography component="h1" variant="h3" style={{ marginBottom: "10px", marginTop: "25px" }}>
                                Forgot password
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Thank you, please check your inbox for an email from us
                            </Typography>
                        </Box>
                    </BoxAccount>
                </BoxPage>
            </AccountPage>
        </Fade>
    ));
};

import { action, computed, observable } from "mobx";
import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { validateTwoDecimalPlaces } from "Utils/Utils";

export class IEFutureSpendAmendmentModel extends ModelBase<IEFutureSpendAmendmentModel, IEFutureSpendAmendmentUpsertResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_IEID = "";
    public static readonly DEFAULT_IEITEMID = "";
    public static readonly DEFAULT_FUTURESPEND = "";
    public static readonly DEFAULT_NOTE = "";
    public static readonly DEFAULT_ROWVERSION = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = null;

    @observable
    public ieId: string = IEFutureSpendAmendmentModel.DEFAULT_IEID;

    @observable
    public ieItemId: string = IEFutureSpendAmendmentModel.DEFAULT_IEITEMID;

    @observable
    public futureSpend: string = IEFutureSpendAmendmentModel.DEFAULT_FUTURESPEND;

    @observable
    public note: string = IEFutureSpendAmendmentModel.DEFAULT_NOTE;

    @observable
    public rowVersion: string | null = IEFutureSpendAmendmentModel.DEFAULT_ROWVERSION;

    // #endregion Properties

    // #region Actions

    @action
    public fromDto(dto: IEFutureSpendAmendmentUpsertResponseDTO): void {
        this.id = dto.id;
        this.ieId = dto.ieId;
        this.ieItemId = dto.ieItemId;
        this.futureSpend = dto.futureSpend;
        this.note = dto.note;
        this.rowVersion = dto.rowVersion;
    }

    public toDto(): IEFutureSpendAmdnementUpsertRequestDTO {
        return {
            id: this.id,
            ieId: this.ieId,
            ieItemId: this.ieItemId,
            futureSpend: this.futureSpend,
            note: this.note,
            rowVersion: this.rowVersion,
        };
    }

    public reset(): void {
        this.id = null;
        //this.ieId = IEFutureSpendAmendmentModel.DEFAULT_IEID;
        //this.ieItemId = IEFutureSpendAmendmentModel.DEFAULT_IEITEMID;
        this.futureSpend = IEFutureSpendAmendmentModel.DEFAULT_FUTURESPEND;
        this.note = IEFutureSpendAmendmentModel.DEFAULT_NOTE;
        this.rowVersion = IEFutureSpendAmendmentModel.DEFAULT_ROWVERSION;
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateFutureSpend(): string {
        // RULES
        const futureSpendNumber: number = Number(this.futureSpend);
        if (this.futureSpend === IEFutureSpendAmendmentModel.DEFAULT_FUTURESPEND || isNaN(futureSpendNumber)) {
            return "Please enter a future spend";
        } else if (!validateTwoDecimalPlaces(futureSpendNumber)) {
            return "No more than two decimal places";
        }

        return "";
    }

    @computed
    public get validateNote(): string {
        return this.note === IEFutureSpendAmendmentModel.DEFAULT_NOTE ? "Please enter a note" : "";
    }

    // #endregion Custom Validation
}

export interface IEFutureSpendAmdnementUpsertRequestDTO {
    id: string | null;
    ieId: string;
    ieItemId: string;
    futureSpend: string;
    note: string;
    rowVersion: string | null;
}

export interface IEFutureSpendAmendmentUpsertResponseDTO {
    id: string;
    ieId: string;
    ieItemId: string;
    futureSpend: string;
    note: string;
    rowVersion: string | null;
}

export interface IEFutureSpendAmendmentAndRelatedResponseDTO {
    categoryName: string;
    subCategoryName: string;
    lineDescriptionName: string;
    ieTitle: string;
    ieFutureSpendAmendmentItems: IEFutureSpendAmdnementDTO[];
}

export interface IEFutureSpendAmdnementDTO {
    id: string;
    isPO: boolean;
    isAmendment: boolean;
    futureSpendType: string;
    poNumber: number;
    formattedPONumber: string;
    revision: string;
    formattedPONumberWithRevision: string;
    poId: string;
    note: string;
    orderValue: number;
    previousFutureSpend: number;
    futureSpend: number;
    projectImpact: number;
    isDeleted: boolean;
    amendmentCreatedByUserName: string;
    poApprovedByUserName: string;
    createdDate: string;
}

//export interface IncomeAndExpenditureAndRelatedResponseDTO extends IncomeAndExpenditureUpsertResponseDTO, IncomeAndExpenditureRelatedResponseDTO {}

import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";
import { formatCurrencyFromPounds, formatVariationNumber } from "Utils/Format";

export class VariationListModel extends ModelBase<VariationListModel, VariationListItemDTO> {
    public id: string = "";

    @MobX.observable
    public variation: number = 0;

    @MobX.observable
    public variationNumberFormatted: string = "";

    @MobX.observable
    public lineTotal: number = 0;

    @MobX.observable
    public futureSpend: number = 0;

    @MobX.observable
    public variance: number = 0;

    @MobX.observable
    public status: string = "";

    @MobX.observable
    public clientResponseBy: string = "";

    @MobX.observable
    public rowVersion: string = "";

    @MobX.observable
    public description: string = "";

    @MobX.observable
    public canDeleteVariation: boolean = true;

    @MobX.observable
    public filterStartDateVAR: string | null = null;

    @MobX.observable
    public filterEndDateVAR: string | null = null;

    @MobX.action validateFilters = () => {
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;

        if (this.filterStartDateVAR !== null && this.filterStartDateVAR !== undefined) {
            start = moment.utc(this.filterStartDateVAR);
        }
        if (this.filterEndDateVAR !== null && this.filterEndDateVAR !== undefined) {
            end = moment.utc(this.filterEndDateVAR);
        }

        if (start !== null && end !== null) {
            if (start > end) {
                this.filterStartDateVAR = start.toISOString();
                this.filterEndDateVAR = end.toISOString();
                return true;
            }
        } else {
            return true;
        }

        return false;
    };

    fromDto(model: VariationListItemDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(): void {}

    @MobX.action
    public reset = () => {};

    public toTableModel = (): VariationTableModel => {
        const po: VariationTableModel = {
            id: this.id,
            variationNumberFormatted: this.variationNumberFormatted,
            lineTotalFormatted: formatCurrencyFromPounds(this.lineTotal),
            futureSpendFormatted: formatCurrencyFromPounds(this.futureSpend),
            varianceFormatted: formatCurrencyFromPounds(this.variance),
            lineTotal: this.lineTotal,
            futureSpend: this.futureSpend,
            variance: this.variance,
            status: this.status.toLocaleUpperCase(),
            clientResponseBy: this.clientResponseBy ? moment(this.clientResponseBy).toDate() : null,
            clientResponseByFormatted: this.clientResponseBy ? moment(this.clientResponseBy).format("DD/MM/YY").toString() : "-",
            rowVersion: this.rowVersion,
            canDeleteVariation: this.canDeleteVariation,
        };
        return po;
    };
}

export type VariationTableModel = {
    id: string;
    variationNumberFormatted: string;
    lineTotalFormatted: string;
    lineTotal: number;
    futureSpendFormatted: string;
    futureSpend: number;
    varianceFormatted: string;
    variance: number;
    status: string;
    clientResponseBy: Date | null;
    clientResponseByFormatted: string;
    rowVersion: string;
    canDeleteVariation: boolean;
};

export interface VariationListItemDTO {
    id: string;
    variation: number;
    lineTotal: number;
    futureSpend: number;
    variance: number;
    status: string;
    clientResponseBy: string;
    rowVersion: string;
    description: string;
}

export interface VariationListAndRelatedRequest {
    id: string;
    fromDate: string | null;
    toDate: string | null;
}

import * as React from "react";
import { SVGProps } from "react";

export const LeftArrowCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 8335" transform="translate(-198 -188)">
            <rect data-name="Rectangle 62" width={30} height={30} rx={15} transform="translate(198 188)" fill="#e03c31" />
            <path
                data-name="Path 3323"
                d="M215.826 209.659a1.012 1.012 0 0 0 .174-.683v-12.122a1.285 1.285 0 0 0-.348-.683.77.77 0 0 0-.522-.171 1.328 1.328 0 0 0-.7.341l-6.082 5.976A.882.882 0 0 0 208 203a1.285 1.285 0 0 0 .348.683l6.261 5.976a.909.909 0 0 0 .691.341c.174-.171.348-.171.522-.341Zm0 0"
                fill="#fff"
            />
        </g>
    </svg>
);

import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed } from "mobx";
import { formatCurrencyFromPounds } from "Utils/Format";
import { VariationItemModel } from "./VariationItemModel";

export class VariationItemViewModel extends ViewModelBase<VariationItemModel> {
    // #region Constructors and Disposers
    constructor(item: VariationItemModel) {
        super(item);
        this.setDecorators(VariationItemViewModel);
    }

    // #region Properties

    // #endregion Properties

    @action
    public reset = () => {
        this.model.reset();
    };

    @action
    public setQuantity = (val: string) => {
        this.model.quantity = val !== null && val !== "" ? Number(val) : null;
    };

    @action
    public setRate = (val: string) => {
        this.model.rate = val !== null && val !== "" ? Number(val) : null;
    };

    @action
    public setFutureSpend = (val: string) => {
        this.model.futureSpend = val !== null && val !== "" ? Number(val) : null;
    };

    @computed
    public get canDelete(): boolean {
        return this.model.id === "" || this.model.id === null || this.model.id === undefined || this.model.canDeleteItem;
    }

    @computed
    private get validateRate(): ValidationResponse {
        const errorMessage = this.model.validateRate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateVariationUnitName(): ValidationResponse {
        const errorMessage = this.model.validateVariationUnitName;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateQuantity(): ValidationResponse {
        const errorMessage = this.model.validateQuantity;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateFutureSpend(): ValidationResponse {
        const errorMessage = this.model.validateFutureSpend;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    /**
     * The line total calculation per variation item.
     * Returns null if nothing can be calculated.
     * (rate * quantity).
     */
    @computed
    public get lineTotal(): number | null {
        if (this.model.rate !== null && this.model.quantity !== null && !isNaN(this.model.rate) && !isNaN(this.model.quantity)) {
            return this.model.rate * this.model.quantity;
        } else {
            return null;
        }
    }

    /**
     * The formatted line total. E.g. £0.00.
     */
    @computed
    public get lineTotalFormatted(): string {
        if (this.lineTotal !== null) {
            return formatCurrencyFromPounds(this.lineTotal);
        } else {
            return "-";
        }
    }

    /**
     * The formatted committed cost. E.g. £0.00.
     */
    @computed
    public get committedCostFormatted(): string {
        if (this.model.committedCost !== null) {
            return formatCurrencyFromPounds(this.model.committedCost);
        } else {
            return "-";
        }
    }

    /**
     * The variance calculation per variation item.
     * Returns null if nothing can be calculated.
     * (futurespend - lineTotal).
     */
    @computed
    public get variance(): number | null {
        if (this.lineTotal !== null && this.model.futureSpend !== null && !isNaN(this.lineTotal) && !isNaN(this.model.futureSpend)) {
            return this.model.futureSpend - this.lineTotal;
        } else {
            return null;
        }
    }

    /**
     * The formatted variance. E.g. £0.00.
     */
    @computed
    public get varianceFormatted(): string {
        if (this.variance !== null && this.variance !== 0) {
            return formatCurrencyFromPounds(this.variance);
        } else {
            return "-";
        }
    }

    @action
    public setDeleted = () => {
        this.model.isDeleted = true;
    };

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<VariationItemModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "rate": {
                const result = this.validateRate;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "quantity": {
                const result = this.validateQuantity;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "futureSpend": {
                const result = this.validateFutureSpend;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

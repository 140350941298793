import { computed } from "mobx";
import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { InductionStatusTypeModel } from "./InductionStatusTypeModel";
import { InductionListModel } from "./InductionListModel";
import moment from "moment";

export class InductionListItemViewModel extends ViewModelBase<InductionListModel> {
    constructor(item: InductionListModel, statusType: InductionStatusTypeModel) {
        super(item);
        this.itemModel = item;
        this.statusTypeModel = statusType;
    }

    private itemModel: InductionListModel;
    private statusTypeModel: InductionStatusTypeModel;

    @computed
    public get id(): string {
        return this.itemModel.id;
    }

    @computed
    public get fullName(): string {
        return this.itemModel.firstName + " " + this.itemModel.lastName;
    }

    @computed
    public get priorityEmployerName(): string {
        return this.itemModel.priorityEmployerName;
    }

    @computed
    public get age(): number {
        return this.itemModel.age;
    }

    @computed
    public get visitorTypeName(): string {
        return this.itemModel.visitorTypeName;
    }

    @computed
    public get healthProblemsFormatted(): "Y" | "N" {
        return this.itemModel.hasHealthProblems ? "Y" : "N";
    }

    @computed
    public get lastSignedInDate(): Date | null {
        return this.itemModel.lastSignedInDate;
    }

    @computed
    public get lastSignedInDateFormatted(): string {
        return this.itemModel.lastSignedInDate ? moment.utc(this.itemModel.lastSignedInDate).format("DD/MM/YYYY") : "";
    }

    @computed
    public get photographUrl(): string {
        return this.itemModel.photographUrl;
    }

    @computed
    public get statusName(): string {
        return this.statusTypeModel.displayName;
    }

    @computed
    public get statusColor(): string {
        return this.statusTypeModel.color;
    }

    @computed
    public get statusTextColor(): string {
        return this.statusTypeModel.textColor;
    }

    public matchesFilter = (filterString: string): boolean => {
        if (isEmptyOrWhitespace(filterString)) {
            return true;
        }
        const filterStringUpperCase = filterString.toUpperCase();
        return (
            this.fullName?.toUpperCase().includes(filterStringUpperCase) ||
            this.statusTypeModel.displayName.toUpperCase().includes(filterStringUpperCase) ||
            this.itemModel.priorityEmployerName?.toUpperCase().includes(filterStringUpperCase) ||
            this.itemModel.visitorTypeName?.toUpperCase().includes(filterStringUpperCase)
        );
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<InductionListModel>): Promise<boolean> {
        return true;
    }
}

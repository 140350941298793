import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { InvoiceDetailsViewModel } from "./Details/InvoiceDetailsViewModel";
import { InvoiceMatchViewModel } from "./Match/InvoiceMatchViewModel";
import { InvoiceAndRelatedResponseDTO, InvoiceStatusDTO, InvoiceStatusEnum } from "./Details/InvoiceDetailsModel";
import { InvoiceModel } from "./InvoiceModel";
import { InvoiceDisputedStatusCodeModel, ResetTypeEnum } from "./Match/Supporting/InvoiceDisputedStatusCodeModel";
import { InvoiceDisputeModalViewModel } from "./Match/InvoiceDisputedModal/InvoiceDisputeModalViewModel";
import { ApprovalStatusBaseDTO } from "Globals/Models/ApprovalPanelModelBase";
import { AppUrls } from "AppUrls";
import { InvoiceApprovalPanelViewModel } from "../InvoiceApprovalPanelViewModel";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";
import { ChangeEvent } from "react";
import { StoresInstance } from "Globals/Stores";

export class InvoiceViewModel extends ViewModelBase<InvoiceModel> {
    // #region Constructors and Disposers

    @observable
    private static _instance: InvoiceViewModel;

    @action
    public static Instance(id: string | null, ieid: string | null) {
        return this.GetInstance || (this._instance = new this(id, ieid));
    }
    @computed
    public static get GetInstance() {
        return this._instance;
    }
    @action
    public static ResetInstance() {
        this._instance = undefined as any;
    }

    // public static get Instance() {
    //     return this._instance || (this._instance = new this(id, ieid));
    // }

    constructor(id: string | null, ieid: string | null) {
        super();
        this.setDecorators(InvoiceViewModel);

        this.detailsViewModel = new InvoiceDetailsViewModel(id, ieid);
        this.matchViewModel = new InvoiceMatchViewModel(id, ieid);
        this.invoiceDisputeModalViewModel = new InvoiceDisputeModalViewModel();
    }

    // #region Properties

    @observable
    public invoiceStatuses: InvoiceStatusDTO[] = [];

    @observable
    public invoiceDisputedStatusCodes = observable<InvoiceDisputedStatusCodeModel>([]);

    @observable
    public canViewSupplierEmailCheckbox: boolean = false;

    @observable
    public detailsViewModel: InvoiceDetailsViewModel = new InvoiceDetailsViewModel(null, null);

    @observable
    public matchViewModel: InvoiceMatchViewModel = new InvoiceMatchViewModel(null, null);

    @observable
    public invoiceDisputeModalViewModel: InvoiceDisputeModalViewModel = new InvoiceDisputeModalViewModel();

    @observable
    public approvalPanelViewModel: InvoiceApprovalPanelViewModel = new InvoiceApprovalPanelViewModel();

    @computed
    public get getCanEditInvoice(): boolean {
        return StoresInstance.Domain.AccountStore.getCanEditInvoice;
    }

    public handleMatchSubmit = (): Promise<void> => {
        return this.matchViewModel.handleUpsert();
    };

    public handleMatchSaveAsDraft = (): Promise<void> => {
        return this.matchViewModel.handleUpsertAsDraft();
    };

    public handleApproveInvoice = (): Promise<void> => {
        const approvedStatus: string = this.approvalPanelViewModel.getApprovedStatusId;

        if (approvedStatus) {
            return this.upsertApprovalStatus(true, approvedStatus);
        }

        return Promise.reject();
    };

    public upsertApprovalStatus = async (isApproved: boolean, requisitionStatusId: string): Promise<void> => {
        this.setIsLoading(true);
        const request: InvoiceApprovalStatusDTO = {
            requisitionPOId: null,
            variationId: null,
            invoiceId: this.detailsViewModel.model.id !== null ? this.detailsViewModel.model.id : "",
            requisitionStatusId: requisitionStatusId,
            requesterNotes: null,
            approverNotes: null,
            isApproved: isApproved,
            sendEmailToSupplier: this.detailsViewModel.model.sendEmailToSupplier,
        };

        const apiResult = await this.Post<any>(AppUrls.Server.Approval.UpsertInvoiceApprovalStatus, request);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.history.push(AppUrls.Client.Approval.List);
            } else {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    //this.setSnackMessage("Failed to process approval");
                    //this.setSnackType(this.SNACKERROR);
                    //this.setSnackbarState(true);
                });
            }
        }
        this.setIsLoading(false);
    };

    public updateInvoiceStatus = async (invoiceStatus: InvoiceStatusDTO): Promise<void> => {
        this.setIsLoading(true);

        const apiResult = await this.Post<any>(AppUrls.Server.Invoice.UpdateInvoiceStatus, {
            id: this.detailsViewModel.model.id !== null ? this.detailsViewModel.model.id : "",
            invoiceStatusId: invoiceStatus.id,
            rowVersion: this.detailsViewModel.model.rowVersion,
        });

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.history.push(AppUrls.Client.Invoicing.List);
            } else {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    //this.setSnackMessage("Failed to process approval");
                    //this.setSnackType(this.SNACKERROR);
                    //this.setSnackbarState(true);
                });
            }
        }
        this.setIsLoading(false);
    };

    private setContainerIsLoading = (val: boolean) => {
        this.setIsLoading(val);
        this.detailsViewModel.setFormIsLoading(val);
        this.matchViewModel.setFormIsLoading(val);
    };

    public setSendEmailToSupplier = async (event: ChangeEvent<HTMLInputElement>, checked: boolean): Promise<void> => {
        this.setContainerIsLoading(true);

        const apiResult = await this.Post<InvoiceAndRelatedResponseDTO>(AppUrls.Server.Invoice.SetCanSendInvoiceEmailToSupplier, {
            id: this.detailsViewModel.model.id !== null ? this.detailsViewModel.model.id : "",
            sendEmailToSupplier: checked,
            rowVersion: this.detailsViewModel.model.rowVersion,
        });

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.detailsViewModel.setLoadWithRelatedData(apiResult.payload);
            } else {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    //this.setSnackMessage("Failed to process approval");
                    //this.setSnackType(this.SNACKERROR);
                    //this.setSnackbarState(true);
                });
            }
        }
        this.setContainerIsLoading(false);
    };

    @computed
    public get canViewTabs(): boolean {
        if (this.invoiceStatus !== null && this.invoiceStatus.type === InvoiceStatusEnum.Disputed && this.hasBeenResetToMatch) {
            return true;
        } else if (this.invoiceStatus !== null && this.invoiceStatus.type === InvoiceStatusEnum.Disputed && this.hasBeenResetToDetails) {
            if (StoresInstance.Domain.AccountStore.isFinanceRole) {
                return true;
            }
            return false;
        }
        return (this.invoiceStatus !== null && this.invoiceStatus.type !== InvoiceStatusEnum.Draft) || this.hasBeenResetToMatch;
    }

    @computed
    public get disputedStatusCode(): InvoiceDisputedStatusCodeModel | undefined {
        let statusCode: InvoiceDisputedStatusCodeModel | undefined = this.invoiceDisputedStatusCodes.find((c) => c.id === this.detailsViewModel.model.invoiceDisputedStatusCodeId);
        return statusCode;
    }

    @computed
    public get hasBeenResetToDetails(): boolean {
        return this.disputedStatusCode !== undefined && this.disputedStatusCode.resetType === ResetTypeEnum.ToDetailsForm;
    }

    @computed
    public get hasBeenResetToMatch(): boolean {
        return this.disputedStatusCode !== undefined && this.disputedStatusCode.resetType === ResetTypeEnum.ToMatchForm;
    }

    @computed
    public get getPageTitle(): string {
        if (this.detailsViewModel.model.id) {
            return `Invoice - ${this.detailsViewModel.model.invoiceNumber}`;
        }

        return `New invoice`;
    }

    /**
     * The current status of the invoice. Draft as default.
     */
    @computed
    public get invoiceStatus(): InvoiceStatusDTO | null {
        let result: InvoiceStatusDTO | undefined = undefined;

        if (this.detailsViewModel.model.invoiceStatusId) {
            result = this.invoiceStatuses.find((u) => u.id === this.detailsViewModel.model.invoiceStatusId);
        } else {
            result = this.invoiceStatuses.find((u) => u.type === InvoiceStatusEnum.Draft);
        }

        return result ? result! : null;
    }

    @computed
    public get invoiceStatusName() {
        return this.invoiceStatus ? this.invoiceStatus.displayName : "DRAFT";
    }

    @computed
    public get invoiceStatusNameFormatted() {
        if (!this.invoiceStatus) {
            return "DRAFT";
        }

        return this.disputedStatusCode && this.invoiceStatus.type === InvoiceStatusEnum.Disputed
            ? `${this.disputedStatusCode.code} - ${this.invoiceStatus.displayName.toUpperCase()}`
            : this.invoiceStatus.displayName.toUpperCase();
    }

    @computed
    public get isDisputed() {
        if (!this.invoiceStatus) {
            return false;
        }

        return this.disputedStatusCode && this.invoiceStatus.type === InvoiceStatusEnum.Disputed;
    }

    @computed
    public get invoiceStatusColor() {
        return this.invoiceStatus ? this.invoiceStatus.color : "#707070";
    }

    @computed
    public get invoiceStatusTextColor() {
        return this.invoiceStatus ? this.invoiceStatus.textColor : "#FFFFFF";
    }

    @computed
    public get getBackButtonText(): string {
        return this.detailsViewModel.model.ieId ? `Back to ${this.detailsViewModel.getIETitle}` : "Back to Invoicing";
    }

    @computed
    public get isPageLoading(): boolean {
        return this.IsLoading || this.detailsViewModel.IsLoading || !this.detailsViewModel.hasLoaded || this.matchViewModel.IsLoading;
    }

    @computed
    public get isApprovedStatus(): boolean {
        const approvedStatus = this.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Approved);
        if (approvedStatus) {
            return approvedStatus.id === this.detailsViewModel.model.invoiceStatusId;
        }
        return false;
    }

    @computed
    public get isPaidStatus(): boolean {
        const paidStatus = this.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Paid);
        if (paidStatus) {
            return paidStatus.id === this.detailsViewModel.model.invoiceStatusId;
        }
        return false;
    }

    @computed
    public get approvedInvoiceStatusDropdownOptions(): InvoiceStatusDTO[] {
        return this.invoiceStatuses.filter((s) => s.type === InvoiceStatusEnum.Paid || s.type === InvoiceStatusEnum.Approved);
    }

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Server Actions

    public goBack = () => {
        const ieid: string | null = this.detailsViewModel.model.ieId;
        if (ieid) {
            if (IEGridItemViewModel.Instance.isCentral) {
                this.history.push(AppUrls.Client.Central.View.replace(":ieid", ieid) + "#inv");
            } else {
                ieid ? this.history.push(AppUrls.Client.Project.IE.replace(":ieid", ieid) + "#inv") : this.history.push(AppUrls.Client.Invoicing.List);
            }
        } else {
            this.history.push(AppUrls.Client.Invoicing.List);
        }
    };

    @action
    public reset = () => {
        if (this.detailsViewModel) {
            this.detailsViewModel.model.reset();
            this.detailsViewModel.server.reset();
            this.detailsViewModel = new InvoiceDetailsViewModel(null, null);
        }

        if (this.matchViewModel) {
            this.matchViewModel.model.reset();
            this.matchViewModel.server.reset();
            this.matchViewModel = new InvoiceMatchViewModel(null, null);
        }

        if (this.invoiceDisputeModalViewModel) {
            this.invoiceDisputeModalViewModel.model.reset();
            this.invoiceDisputeModalViewModel = new InvoiceDisputeModalViewModel();
        }

        InvoiceViewModel.ResetInstance();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InvoiceModel>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

export interface InvoiceApprovalStatusDTO extends ApprovalStatusBaseDTO {
    sendEmailToSupplier: boolean;
}

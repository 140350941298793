import { FieldType } from "@shoothill/core";
import { computed } from "mobx";

import { VariationApprovalPanelModel } from "./VariationApprovalPanelModel";
import { ApprovalPanelViewModelBase } from "Globals/ViewModels/ApprovalPanelViewModelBase";
import { ApprovalHistoryViewModel } from "Components/ApprovalHistory/ApprovalHistoryViewModel";

export class VariationApprovalPanelViewModel extends ApprovalPanelViewModelBase<VariationApprovalPanelModel> {
    public approvalHistoryViewModel = new ApprovalHistoryViewModel();

    // #region Constructors and Disposers

    constructor() {
        super(VariationApprovalPanelModel);
        this.setDecorators(VariationApprovalPanelViewModel);
    }

    // #endregion Constructors and Disposers

    // // Approver will see when approving.
    // @computed
    // public get getCanShowNewApprovalPanel(): boolean {
    //     // True if the approver needs to perform an action on a new requisition request.
    //     const isSubmittedForApproval = this.model.approvalStatusId === this.getSubmittedForApprovalStatusId;
    //     const isDraft = this.model.approvalStatusId === this.getDraftStatusId;

    //     const isApprover = this.model.isApprover;
    //     const isNotResolved = !this.model.isResolved;

    //     const approvalStatusValid = isSubmittedForApproval || isDraft;

    //     const isMissingRequesterNotes = this.model.requesterNotes === null || this.model.requesterNotes === "";

    //     if (isApprover) {
    //         return isNotResolved && isMissingRequesterNotes && approvalStatusValid;
    //     }

    //     return false;
    // }

    // // Approver will see after requester has amended.
    // @computed
    // public get getCanShowAmendedApprovalPanel(): boolean {
    //     // True if the approver needs to perform an action on an amended requisition request.
    //     const isSubmittedForApproval = this.model.approvalStatusId === this.getSubmittedForApprovalStatusId;
    //     const isDraft = this.model.approvalStatusId === this.getDraftStatusId;

    //     const isApprover = this.model.isApprover;
    //     const isNotResolved = !this.model.isResolved;

    //     const approvalStatusValid = isSubmittedForApproval || isDraft;

    //     const hasRequesterNotes = this.model.requesterNotes !== null && this.model.requesterNotes !== "";

    //     if (isApprover) {
    //         return isNotResolved && hasRequesterNotes && approvalStatusValid;
    //     }

    //     return false;
    // }

    // // Requester will see when amending.
    // @computed
    // public get getCanShowAmenderPanel(): boolean {
    //     // True if the requester needs to amend a requisition request.
    //     const isAmendRequired = this.model.approvalStatusId === this.getAmendRequiredStatusId;

    //     return this.model.isRequester && isAmendRequired;
    // }

    // // True if the user has permission to view the panel, regardless of it's content.
    // @computed
    // public get getCanShowPanel(): boolean {
    //     return this.getCanShowNewApprovalPanel || this.getCanShowAmendedApprovalPanel || this.getCanShowAmenderPanel;
    // }

    @computed
    public get getTitle(): string {
        if (this.getCanShowNewApprovalPanel) {
            return "New variation";
        } else if (this.getCanShowAmendedApprovalPanel) {
            return "Variation amend";
        } else if (this.getCanShowAmenderPanel) {
            return "Amends required";
        } else {
            return "New variation";
        }
    }

    @computed
    public get getCanUpsertStatus(): boolean {
        const isSubmittedForApproval = this.model.approvalStatusId === this.getSubmittedForApprovalStatusId;
        const isDraft = this.model.approvalStatusId === this.getDraftStatusId;

        const isApprover = this.model.isApprover;

        if (isApprover) {
            return !this.model.isResolved && (isSubmittedForApproval || isDraft);
        }

        return false;
    }

    // #region Server Actions

    // #endregion Server Actions

    // #region Client Actions

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<any>): Promise<boolean> {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

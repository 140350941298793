// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

// Custom

import { IEGridItemModel } from "../../IEmodels";
import { IEGridItemViewModel } from "../../IEmodels/IEGridItemViewModel";
import { CommercialViewModel } from "../../Commercial.ViewModel";
import { PackagesViewModel } from "../../PackagesViewModel";
import { IEViewModel } from "../../IE.ViewModel";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { makeStyles } from "@material-ui/core";
import { AppUrls } from "AppUrls";
import { PopulateFromIEItemModel } from "Views/PurchaseOrder/Form/Supporting/PopulateFromIEItemModel";

// Styling & Images
import EditLogo from "Content/AddEdit.svg";
import DeleteLogo from "Content/Bin.svg";
import RequisitionIcon from "Content/RequisitionIcon.svg";
import { RowDash } from "Views/Project/CustomComponents";
import { ToggleButton } from "../IEGrid.Styles";
import { formatCreditDebitClass } from "Utils/Utils";

interface ITableProps {
    ieId: string;
    itemModel: IEGridItemModel;
    sourceId: number;
    rowIndex: number;
    thisGuid: string;
    parentId: string;
    rowEdit(row: string): void;
    rowEnable(categoryIndex: number, subCategoryIndex: number, index: number): void;
    subCategoryIndex: number;
    categoryIndex: number;
    currentActiveCatIndex: number;
    currentActiveSubCatIndex: number;
    currentActiveDescIndex: number;
    guidHistory: PopulateFromIEItemModel;
    isReport: boolean;
}

export const IEItem: React.FunctionComponent<ITableProps> = (props) => {
    const { history } = useRouter();
    const {
        itemModel,
        rowEdit,
        rowIndex,
        parentId,
        rowEnable,
        categoryIndex,
        subCategoryIndex,
        currentActiveCatIndex,
        currentActiveSubCatIndex,
        currentActiveDescIndex,
        isReport,
    } = props;
    const [viewModel] = useState(new IEGridItemViewModel());
    const classes = useStyles();
    const commercialViewModel = CommercialViewModel.Instance;
    const packagesViewModel = PackagesViewModel.Instance;

    const ieViewModel = IEViewModel.Instance;

    useEffect(() => {
        if (itemModel !== null && itemModel !== undefined) {
            viewModel.loadModel(itemModel, parentId);
        }
    }, []);

    const formatDebit = (val: number) => {
        return commercialViewModel.formatDebit(val);
    };

    const onDeleteClick = async () => {
        if (viewModel.model.id !== undefined && viewModel.model.id.length > 0) {
            // category and subcategory index have been changed to Id, the following will need to be reworked accordingly
            /* const promise = viewModel.apiDeleteIEItem(categoryId, subcategoryId, ieId, parentId);
            promise.then((result: any) => {
                if (result.wasSuccesful === true) {
                }
            }); */
        }
    };

    const rowPRClick = (name: string | undefined) => {
        if (!isReport) {
            const ieItemViewModel = IEGridItemViewModel.Instance;
            let item = props.guidHistory;
            item.ieItemName = name != undefined ? name : "";
            ieItemViewModel.setIEAndRelatedGuids(item);
            history.push(AppUrls.Client.PurchaseOrder.Add);
        }
    };

    const renderFutureSpendCell = () => {
        return (
            <Link component={RouterLink} to={AppUrls.Client.Project.IEItemFutureSpendAmendment.replace(":ieid", props.ieId).replace(":ieitemid", props.guidHistory.ieItemLineId)}>
                {formatCurrencyForGrid(formatCurrencyNoSign(itemModel.futureSpend))}
            </Link>
        );
    };

    const renderRiskOppCell = () => {
        return (
            <Link component={RouterLink} to={AppUrls.Client.Project.IEItemRiskOpp.replace(":ieid", props.ieId).replace(":ieitemid", props.guidHistory.ieItemLineId)}>
                {formatCurrencyForGrid(formatCurrencyNoSign(itemModel.roValue))}
            </Link>
        );
    };

    return useObserver(() => (
        <>
            <li className="item" key={"item_" + rowIndex}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div
                        style={{
                            color: `${
                                currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === subCategoryIndex && currentActiveDescIndex === rowIndex ? "#e03c31" : ""
                            }`,
                            paddingLeft: "35px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                        <span className="ie-row-name ie-item-name" title={itemModel.getName()}>
                            {itemModel.getName()}
                        </span>
                    </div>
                    {currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === subCategoryIndex && currentActiveDescIndex === rowIndex ? (
                        <ToggleButton>
                            <span
                                className={"open"}
                                onClick={() => {
                                    rowEnable(-1, -1, -1);
                                }}
                            >
                                &minus;
                            </span>
                        </ToggleButton>
                    ) : (
                        <ToggleButton>
                            <span
                                className={"close"}
                                onClick={() => {
                                    rowEnable(categoryIndex, subCategoryIndex, rowIndex);
                                }}
                            >
                                &#43;
                            </span>
                        </ToggleButton>
                    )}
                </div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.income))}</div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.estimateCost))}</div>
                <div className="right">{itemModel.margin}</div>
                <div className={"right" + formatDebit(itemModel.committedCost)}>{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.committedCost))}</div>
                <div className="right">{renderFutureSpendCell()}</div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.totalExpectedSpend))}</div>
                <div className={"right" + formatCreditDebitClass(itemModel.variance)}>{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.variance))}</div>
                <div className={"right"}>{renderRiskOppCell()}</div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                    {ieViewModel.projectStatusType != "DRAFT" && !isReport && (
                        <>
                            <span onClick={() => rowPRClick(itemModel.getName())} className={classes.inputIcon}>
                                <img src={RequisitionIcon} />
                            </span>
                        </>
                    )}
                    {ieViewModel.canEditIELineDescription && !isReport && (
                        <span onClick={() => rowEdit(itemModel.id)} className={classes.inputIcon}>
                            <img src={EditLogo} />
                        </span>
                    )}
                    {ieViewModel.projectStatusType == "DRAFT" && !IEGridItemViewModel.Instance.isCentral && !isReport && (
                        <span onClick={() => onDeleteClick()} className={classes.inputIcon}>
                            <img src={DeleteLogo} />
                        </span>
                    )}
                </div>
            </li>
        </>
    ));
};

const useStyles = makeStyles({
    buttonGroup: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    autoCompleteInput: {
        display: "flex",
        justifyContent: "start",
        paddingRight: "30px !important",
        paddingLeft: "40px !important",
        "& div.MuiAutocomplete-inputRoot": {
            width: "90%",
            height: "90%",
            display: "flex",
            alignItems: "center",
        },
    },
    input: {
        "& .MuiInputBase-root": {
            display: "flex !important",
            alignItems: "center !important",
            justifyContent: "center !important",
            margin: "0 5px",
            height: "90%",
            backgroundColor: "gold",
            width: "90%",

            "& > input": {
                textAlign: "right",
                fontSize: "12px",
            },
        },
    },
    inputIcon: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

import { Box, FormControlLabel, FormHelperText, FormLabel, RadioGroup, TextField, Typography } from "@material-ui/core";
import { Cell, Grid, isEmptyOrWhitespace, useRouter } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { AppUrls } from "AppUrls";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { Signature } from "Components/Signature/Signature";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { formatDate, formatTime } from "Utils/Format";
import { BackButtonHolder } from "Views/Project/Commercial/VariationTables/VariationGrid.Styles";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { CustomArrow, CustomRadio } from "Views/Project/CustomComponents";
import { DisciplineFormViewModel } from "./DisciplineFormViewModel";
import NonConformanceFormHeader from "../NonConformanceFormHeader";
import { NonConformanceListViewModel } from "../NonConformanceListViewModel";
import { InductionUserDTO } from "./DisciplineFormModel";

interface IProps {
    className?: string;
}

const DisciplineFormView: React.FC<IProps> = (props) => {
    const { match } = useRouter();
    const { history } = useRouter();
    const { projectid, nonConformanceTypeId, nonConformanceId } = match.params as any;
    const [viewModel] = useState(() => new DisciplineFormViewModel(nonConformanceId, projectid, nonConformanceTypeId));
    const [listViewModel] = useState(() => new NonConformanceListViewModel());

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    useEffect(() => {
        listViewModel.projectId = projectid;
        listViewModel.loadNonConformanceAndRelated();
    }, []);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const renderForm = () => {
        return (
            <FormBox>
                <Form className={props.className} onSubmit={onSubmit}>
                    <FormSection>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                            <DarwinSelect
                                displayName="Card:"
                                execute={(data: { id: string; displayName: string }) => viewModel.handleDisciplineCardTypeId(data)}
                                fullWidth={true}
                                getOptionLabel={(option: any) => option.displayName}
                                options={viewModel.disciplineCardTypes}
                                onBlur={() => viewModel.isFieldValid("disciplineCardTypeId")}
                                placeholder="Please select"
                                value={viewModel.disciplineCardType}
                                error={viewModel.getError("disciplineCardTypeId") !== ""}
                                validationMessage={viewModel.getError("disciplineCardTypeId")}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "20px" }}>
                            <Box>
                                <DarwinDateSelect
                                    displayName="Date of occurrence:"
                                    execute={(value: string | null) => viewModel.setValue("dateOfOccurance", value)}
                                    onBlur={() => viewModel.isFieldValid("dateOfOccurance")}
                                    placeholder="dd/mm/yyyy"
                                    validationMessage={viewModel.getError("dateOfOccurance")}
                                    value={viewModel.getValue("dateOfOccurance")}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                            </Box>
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} style={{ marginBottom: "20px" }}>
                            <Cell ts={8} ds={8}>
                                <TextField
                                    multiline
                                    minRows={4}
                                    value={viewModel.model.notes}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue<string>("notes", event.target.value)}
                                    fullWidth
                                    className="textarea"
                                    label="The offender was witnessed:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={`Please add notes`}
                                    onBlur={() => viewModel.isFieldValid("notes")}
                                    disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                    error={viewModel.getError("notes") !== ""}
                                    helperText={viewModel.getError("notes")}
                                />
                            </Cell>
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={"1fr 1fr 1fr 1fr 1fr 1fr"} dc={"1fr 1fr 1fr 1fr 1fr 1fr"}>
                            <Box>
                                <FormLabel>Has a near miss been completed?</FormLabel>
                                <RadioGroup
                                    row
                                    defaultValue="Yes"
                                    name={"radio"}
                                    value={viewModel.getValue("nearMissCompleted")}
                                    onChange={(e) => viewModel.nearMissCompleted("nearMissCompleted", e.target.value)}
                                >
                                    <FormControlLabel value="true" control={<CustomRadio checked={viewModel.getValue("nearMissCompleted") === true} />} label="Yes" />
                                    <FormControlLabel value="false" control={<CustomRadio checked={viewModel.getValue("nearMissCompleted") === false} />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                    </FormSection>
                    <FormSection>
                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                        <SubtitleContainer>
                            <Typography variant="h2" style={{ font: "normal normal 600 18px/30px Open Sans", letterSpacing: "-0.36px", color: "#191919" }}>
                                Authorisations
                            </Typography>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                                <DarwinSelect
                                    displayName="Card issued by:"
                                    execute={(data: { id: string; displayName: string }) => viewModel.handleCardIssuedByUserId(data)}
                                    fullWidth={false}
                                    getOptionLabel={(option: any) => option.displayName}
                                    options={viewModel.cardIssuedByUsers}
                                    placeholder="Please select"
                                    value={viewModel.cardIssuedByUser}
                                    onBlur={() => viewModel.isFieldValid("cardIssuedByUserId")}
                                    error={viewModel.getError("cardIssuedByUserId") !== ""}
                                    validationMessage={viewModel.getError("cardIssuedByUserId")}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                                <DarwinSelect
                                    displayName="Name of Portakabin - Trial senior team informed:"
                                    execute={(data: { id: string; displayName: string }) => viewModel.handleSeniorTeamTypeId(data)}
                                    fullWidth={false}
                                    getOptionLabel={(option: any) => option.displayName}
                                    options={viewModel.seniorTeamUsers}
                                    placeholder="Please select"
                                    value={viewModel.seniorTeamUser}
                                    onBlur={() => viewModel.isFieldValid("seniorTeamTypeId")}
                                    error={viewModel.getError("seniorTeamTypeId") !== ""}
                                    validationMessage={viewModel.getError("seniorTeamTypeId")}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                />
                            </Grid>
                            <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4}>
                                <SignatureContainer>
                                    <SubtitleContainer></SubtitleContainer>
                                    <Box>
                                        <Box>
                                            <Signature
                                                displayName={"Card issued by signature:"}
                                                execute={(data: any) => viewModel.setValue<string>("signatureIssuedByURL", data)}
                                                value={viewModel.model.signatureIssuedByURL}
                                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                            />
                                            {viewModel.model.signatureIssuedByURL === null && viewModel.getError("signatureIssuedByURL") !== "" && (
                                                <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("signatureIssuedByURL")}</FormHelperText>
                                            )}
                                        </Box>
                                    </Box>
                                    <SubtitleContainer>
                                        <Typography variant="h2">
                                            <span>Date / time added:</span>
                                            {moment(new Date()).format("DD/MM/YYYY @ HH:mm")}
                                        </Typography>
                                    </SubtitleContainer>
                                </SignatureContainer>
                            </Grid>
                        </SubtitleContainer>
                    </FormSection>
                    <FormSection>
                        <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                        <Typography variant="h2" style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.3px", color: "#191919" }}>
                            "I have read and understand the reason for this notice and accept its contents."
                        </Typography>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ margin: "20px 0 0" }}>
                            <DarwinSelect
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                displayName="Card received by:"
                                error={viewModel.getError("cardReceivedByUserId") !== ""}
                                execute={viewModel.setCardReceivedByUser}
                                fullWidth={false}
                                groupBy={(option) => option.groupDisplayName}
                                getOptionLabel={(option: InductionUserDTO) => option.displayName}
                                onBlur={() => viewModel.isFieldValid("cardReceivedByUserId")}
                                options={viewModel.cardReceivedByUsers}
                                placeholder="Please select"
                                validationMessage={viewModel.getError("cardReceivedByUserId")}
                                value={viewModel.cardReceivedByUser}
                            />
                            <TextField
                                type="text"
                                value={viewModel.getValue("companyName")}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => viewModel.setValue("companyName", e.target.value)}
                                fullWidth
                                error={viewModel.getError("companyName") !== ""}
                                disabled={viewModel.isFormDisabled || viewModel.IsLoading}
                                label={"Name of company employing the individual:"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={() => viewModel.isFieldValid("companyName")}
                                helperText={viewModel.getError("companyName")}
                                placeholder="Please add"
                            />
                        </Grid>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4}>
                            <SignatureContainer style={{ marginTop: "25px" }}>
                                <SubtitleContainer></SubtitleContainer>
                                <Box>
                                    <Box>
                                        <Signature
                                            displayName={"Card received by signature:"}
                                            execute={(data: any) => viewModel.setValue<string>("signatureReceivedByURL", data)}
                                            value={viewModel.model.signatureReceivedByURL}
                                            canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                        />
                                        {viewModel.model.signatureReceivedByURL === null && viewModel.getError("signatureReceivedByURL") !== "" && (
                                            <FormHelperText style={{ color: "#f44336" }}>{viewModel.getError("signatureReceivedByURL")}</FormHelperText>
                                        )}
                                    </Box>
                                </Box>
                                <SubtitleContainer>
                                    <Typography variant="h2">
                                        <span>Date / time added:</span>
                                        {moment(new Date()).format("DD/MM/YYYY @ HH:mm")}
                                    </Typography>
                                </SubtitleContainer>
                            </SignatureContainer>
                        </Grid>
                    </FormSection>

                    <Box style={{ margin: `0 ${pxToRem(30)} 0 ${pxToRem(30)}` }}>
                        <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                    </Box>

                    <FormSection>
                        <ButtonsContainer>
                            <PrimaryButton displayName="Submit" execute={() => viewModel.upsert()} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            <DefaultButton displayName="Cancel" execute={() => viewModel.handleCancel(projectid)} fullWidth={true} canExecute={!viewModel.IsLoading} />
                        </ButtonsContainer>
                    </FormSection>
                </Form>
            </FormBox>
        );
    };

    const renderReadOnlyView = () => {
        return (
            <FormBox>
                <FormSection>
                    <DashedDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />

                    {/* Concerning discipline */}
                    <Grid columnGap={20} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "15px" }}>
                        <DataList>
                            <span>Card: </span>
                            {viewModel.discipline?.disciplineCardTypeId !== null ? viewModel.displayDisciplineCardTypeId(viewModel.discipline?.disciplineCardTypeId) : "-"}
                        </DataList>
                        <DataList>
                            <span>Date of occurance: </span>
                            {viewModel.discipline?.dateOfOccurance !== null ? formatDate(viewModel.discipline?.dateOfOccurance) : "-"}
                        </DataList>
                    </Grid>
                    <DataList style={{ marginBottom: "6px" }}>
                        <span>The offender was witnessed:</span>
                    </DataList>
                    <ProjectDetailsContainer>
                        {viewModel.discipline?.notes !== null || viewModel.discipline?.notes !== "" ? viewModel.discipline?.notes : "-"}
                    </ProjectDetailsContainer>

                    <Grid columnGap={30} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} mt={"16px"} mb={"18px"}>
                        <CustomCheckBox>
                            <Typography variant="h5">Has a near miss been completed?</Typography>
                            {viewModel.discipline?.nearMissCompleted ? (
                                <FormControlLabel style={{ cursor: "default !important" }} value="true" control={<CustomRadio checked={true} />} label="Yes" />
                            ) : (
                                <FormControlLabel style={{ cursor: "default !important" }} value="false" control={<CustomRadio checked={true} />} label="No" />
                            )}
                        </CustomCheckBox>
                    </Grid>
                    <SolidDivider />
                    <ContentBox>
                        <Typography style={{ marginBottom: "12px", font: "normal normal 600 18px/26px Open Sans", letterSpacing: "-0.36px", color: "#191919" }} variant="h5">
                            Authorisations
                        </Typography>
                        <Typography>{viewModel.authorisationsText}</Typography>
                    </ContentBox>

                    <Grid columnGap={20} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                        <DataList>
                            <span>Date / time added:</span>
                            {viewModel.discipline?.signatureIssuedByDate !== null ? formatDate(viewModel.discipline?.signatureIssuedByDate) : "-"} @
                            {viewModel.discipline?.signatureIssuedByDate !== null ? formatTime(viewModel.discipline?.signatureIssuedByDate) : "-"}
                        </DataList>
                        <DataList>
                            <span>Card issued by:</span>
                            {viewModel.discipline?.cardIssuedByUserId !== null ? viewModel.displayCardIssuedByUserId(viewModel.discipline?.cardIssuedByUserId) : "-"}
                        </DataList>
                        <DataList style={{ marginBottom: "6px" }}>
                            <span>Name of Portakabin - Trial senior team informed:</span>
                            {viewModel.discipline?.seniorTeamTypeId !== null ? viewModel.displayCardSeniorTeamTypeId(viewModel.discipline?.seniorTeamTypeId) : "-"}
                        </DataList>
                    </Grid>
                    <Grid>
                        <SignBlock>
                            <span>Card issued by signature:</span>
                            {viewModel.discipline?.signatureIssuedByURL && <img src={viewModel.discipline?.signatureIssuedByURL} />}
                        </SignBlock>
                    </Grid>
                    <DashedDivider />
                    <ContentBox mt={"15px"}>
                        <Typography variant="h5">"I have read and understand the reason for this notice and accept its contents."</Typography>
                    </ContentBox>
                    <Grid columnGap={20} rowGap={20} tc={COLUMNS4} dc={COLUMNS4} style={{ marginBottom: "20px" }}>
                        <DataList>
                            <span>Date / time added:</span>
                            {viewModel.discipline?.signatureReceivedByDate !== null ? formatDate(viewModel.discipline?.signatureReceivedByDate) : "-"} @
                            {viewModel.discipline?.signatureReceivedByDate !== null ? formatTime(viewModel.discipline?.signatureReceivedByDate) : "-"}
                        </DataList>
                        <DataList>
                            <span>Card received by:</span>
                            {viewModel.readOnlyReceivedByUserDisplayName}
                        </DataList>
                        <DataList style={{ marginBottom: "6px" }}>
                            <span>Name of company employing the individual:</span>
                            {viewModel.readOnlyCompanyDisplayName}
                        </DataList>
                    </Grid>
                    <Grid>
                        <SignBlock>
                            <span>Card received by signature:</span>
                            {viewModel.discipline?.signatureReceivedByURL && <img src={viewModel.discipline?.signatureReceivedByURL} />}
                        </SignBlock>
                    </Grid>
                </FormSection>
            </FormBox>
        );
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={() => {}}>
                <BackButtonHolder
                    onClick={() => {
                        history.push(AppUrls.Client.Project.ConstructionQuality.replace(":projectid", projectid ? projectid : viewModel.model.projectId) + "#nonconformance");
                    }}
                    style={{ padding: "10px 0", marginTop: 0 }}
                >
                    <Box style={{ position: "relative", display: "flex", marginLeft: "10px", alignItems: "center" }}>
                        <Box style={{ position: "absolute", height: "15px", width: "8px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </Box>
                        <Box style={{ marginLeft: "20px", font: "normal normal 600 18px/32px Open Sans", letterSpacing: "-0.36px", color: theme.palette.blue.main }}>
                            Back to Pre-use inspections {viewModel.projectDisplayName}
                        </Box>
                    </Box>
                </BackButtonHolder>
                <NonConformanceFormHeader
                    ProjectDetails={viewModel.disciplineProjectDetails}
                    ProjectDate={viewModel.getTodayDateFormatted}
                    CompletedBy={"Emma Girlface"}
                    SafetyId={viewModel.model.id}
                    FormHeaderTitle={viewModel.nonConformanceTypeDisplayName(listViewModel)}
                    NonConformanceListItems={listViewModel.nonConformanceListModels}
                    item={viewModel.discipline}
                    IsNewForm={isEmptyOrWhitespace(viewModel.model.id)}
                />

                {isEmptyOrWhitespace(viewModel.model.id) ? renderForm() : renderReadOnlyView()}
            </Form>
        );
    });
};

export const DisciplineFormViewBase = styled(DisciplineFormView)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        max-width: 262px;
    }

    > button:nth-child(1) {
        margin-right: 30px;
    }
`;

const DataList = styled(Box)`
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.18px;
    color: #191919;
    display: flex;
    align-items: center;
    gap: 12px;
    span {
        font-weight: 700;
    }
`;

const ContentBox = styled(Box)`
    margin: 18px 0;
    .MuiTypography-root {
        font: normal normal normal 12px/16px Open Sans;
        letter-spacing: -0.3px;
        color: #191919;
    }
`;

const SignBlock = styled(Box)`
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.18px;
    color: #191919;
    span {
        font-weight: 700;
        display: block;
        margin-bottom: 12px;
    }
`;

const FormBox = styled(Box)`
    .MuiFormLabel-root {
        font: normal normal 700 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        margin: 0 0 7px;
    }
`;

interface SubtitleProps {
    margin?: string;
}

export const SubtitleContainer = styled.div<SubtitleProps>`
    margin-top: 12px;
    h2 {
        margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
        font: normal normal 600 12px/16px Segoe UI;
        letter-spacing: -0.18px;
        color: #191919;
        span {
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;

    > div:nth-child(2) {
        display: flex;
        flex-direction: row;

        > div {
            width: 100%;

            canvas {
                border: 1px solid #d4d4d4;
            }
        }
    }
    .MuiFormControl-root {
        width: 100% !important;
    }
`;

const ProjectDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.18px;
    color: #191919;
    max-width: 100%;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

const CustomCheckBox = styled(Box)`
    .MuiFormControlLabel-root span {
        cursor: auto;
    }
    .MuiRadio-root.MuiButtonBase-root {
        .MuiIconButton-label {
            div {
                height: 20px;
                width: 20px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                flex: 20px 0 0;
                border: 2px solid #eb8d00;
                background-color: transparent;
                transition: all 0.2s ease;
            }
            input:checked + div {
                background-color: #eb8d00;
            }
        }
    }
`;

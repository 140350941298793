// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { isNullOrEmpty, isNullOrUndefined, useRouter } from "@shoothill/core";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "../General/ProjectGeneralViewModel";
//import { IEViewModel } from "./IEViewModel";
import { PackagesViewModel } from "./PackagesViewModel";
import { IEGridViewList } from "./IETables/IEGridView.List";
import { CommercialViewModel } from "./Commercial.ViewModel";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { IEItemsWrapper } from "./IETables/IEItemsWrapper";

import { DeleteIncomeAndExpenditureDialog } from "./IncomeAndExpenditure/DeleteIncomeAndExpenditureDialog";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ProjectEditBox } from "../Project.styles";
import { ActionButtonHolder, IEButton } from "./IETables/IEGrid.Styles";
import { AppUrls } from "AppUrls";
import { ContractCell } from "../Contractual/Contractual.styles";
import { ControlLabel } from "Components/Form/ControlLabel";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { useHasRoutedTabs } from "Utils/UseHasRoutedTabs";
import { InvoiceTableView } from "Views/Invoice/InvoiceTableView";
import { StoresInstance } from "Globals/Stores";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

export const CommercialView: React.FunctionComponent = () => {
    const [projectId, setProjectId] = useState<string>("");

    const PAGE_COMMERCIAL = "commercial";

    useEffect(() => {
        let { projectid } = router.match.params as any;
        if (viewModel.showView === "") {
            viewModel.setShowView(viewModel.DEFAULTVIEW);
        }
        if (isNullOrUndefined(projectid) === false) {
            setProjectId(projectid);
            projectGeneralViewModel.apiGetbyId(projectid);
            viewModel.apiGetIECommercial(projectid);
        } else {
            history.push(AppUrls.Client.Project.List);
        }

        return () => {
            projectGeneralViewModel.clean();
            viewModel.reset();
        };
    }, []);

    const getActivePage = (): string => {
        let { projectid } = router.match.params as any;
        const commercial = AppUrls.Client.Project.Commercial.replace(":projectid", projectid ? projectid : projectId);

        let retVal: string = PAGE_COMMERCIAL;

        switch (location.pathname) {
            case commercial:
                retVal = PAGE_COMMERCIAL;
                break;
            default:
                retVal = PAGE_COMMERCIAL;
                break;
        }

        return retVal;
    };

    const router = useRouter();
    const projectGeneralViewModel = ProjectGeneralViewModel.Instance;
    const [viewModel] = useState(() => CommercialViewModel.Instance);

    //const ieViewModel = IEViewModel.Instance;
    const { history } = useRouter();
    const [activePage, setActivePage] = useState<string>(getActivePage());
    const [snackActive, setSnackActive] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<string>("");
    const [snackType, setSnackType] = useState<string>("info");

    useEffect(() => {
        setActivePage(getActivePage());
    }, [location.pathname]);

    const handleIEItemsClick = (id: string) => {
        history.push(AppUrls.Client.Project.IE.replace(":ieid", id));
    };

    const onEndDateChanged = (date: string | null) => {
        viewModel.setValue("filterEndDate", date === null ? date : moment(date).toISOString());
        let isValid = viewModel.model.validateFilters();

        if (isValid) {
            viewModel.apiGetIECommercial(projectId);
        }
    };

    const handleImportClick = () => {};

    const renderIEItems = () => {
        return (
            <IEGridViewList
                rowEdit={viewModel.editIncomeAndExpenditure}
                rowDelete={viewModel.displayDeleteIncomeAndExpenditure}
                gridViewModel={viewModel.commercialGridViewModel}
                handleRowClick={(i, t) => handleIEItemsClick(i)}
                isViewOnly={false}
                isLoading={viewModel.IsLoading}
            />
        );
    };

    //<InvoiceTableView data={viewModel.getInvoices} invoiceViewURL={AppUrls.Client.Invoicing.Detail} />

    const renderInvoiceItems = () => {
        return (
            <>
                <div style={{ padding: "0 5px" }}>
                    <Typography variant="h2" color="textPrimary">
                        Invoices
                    </Typography>
                </div>
                <br />
                <InvoiceTableView
                    data={viewModel.commercialGridViewModel.getInvoices}
                    invoiceViewURL={AppUrls.Client.Invoicing.Detail}
                    showSearch={true}
                    isLoading={viewModel.IsLoading}
                />
                ;
            </>
        );
    };

    const renderTabContent = () => {
        switch (activePage) {
            case PAGE_COMMERCIAL:
                return (
                    <div>
                        {projectGeneralViewModel.projectStatusType?.type !== "DRAFT" && (
                            <>
                                <ProjectEditBox>
                                    <div className="row" style={{ display: "grid", gridAutoFlow: "column", columnGap: "10px", justifyContent: "flex-start" }}>
                                        <ContractCell>
                                            <ControlLabel label="End date:" htmlFor="start-date">
                                                <Box maxWidth="200px">
                                                    <DarwinDateSelect
                                                        displayName="End date:"
                                                        execute={(value: string | null) => onEndDateChanged(value)}
                                                        placeholder="Please select"
                                                        value={viewModel.model.filterEndDate}
                                                        maxDate={moment.utc().toDate()}
                                                        clearable
                                                        format="DD/MM/YYYY"
                                                    />
                                                </Box>
                                            </ControlLabel>
                                        </ContractCell>
                                        <ContractCell>{/* TODO start iw*/}</ContractCell>
                                        <ContractCell>{/* TODO end iw*/}</ContractCell>
                                    </div>
                                </ProjectEditBox>
                            </>
                        )}
                        <ActionButtonHolder>
                            {StoresInstance.Domain.AccountStore.getCanAddIE && <IEButton onClick={viewModel.addIncomeAndExpenditure}>Create new I & E</IEButton>}
                            <IEButton style={{ backgroundColor: "#dddddd" }} onClick={() => handleImportClick()}>
                                Import existing project I & E
                            </IEButton>
                        </ActionButtonHolder>
                        <IEItemsWrapper>
                            <div className="content" style={{ paddingTop: "30px", position: "relative" }}>
                                {renderIEItems()}
                                {renderInvoiceItems()}
                            </div>
                        </IEItemsWrapper>
                        <DeleteIncomeAndExpenditureDialog viewModel={viewModel.deleteIncomeAndExpenditureViewModel} />
                        <SnackBar messageText={snackMessage} messageType={snackType} active={snackActive} closeOption={() => setSnackActive(false)} autoHideDuration={5000} />
                    </div>
                );
        }
    };

    return useObserver(() => (
        <DetailsPage>
            <DetailsHeader viewModel={projectGeneralViewModel.getHeader} />
            {renderTabContent()}
        </DetailsPage>
    ));
};

import { Box, Fade, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";
import { useObserver } from "mobx-react-lite";
import { EditableInput, generateID, useRouter } from "@shoothill/core";
import { StoresInstance } from "Globals/Stores";
import { Redirect } from "react-router";
import { AccountPage, AccountImageBox, BoxAccount, BoxPage } from "Globals/Styles/AccountStyling";
import { AccountTitleBox, AccountForm } from "Views/Login/LoginView.style";
import { AcceptButton, CancelButton } from "Globals/Styles/Control/Buttons";
import { AppUrls } from "AppUrls";
import Logo from "../../Content/darwinlogo.png";

export interface IWindowState {
    width: number;
    height: number;
}

const domainStores = StoresInstance.Domain;

export const ForgotPasswordView = () => {
    const router = useRouter();
    const componentRef: any = useRef(generateID());
    const [dimensions, setDimensions] = React.useState<IWindowState>({
        width: 0,
        height: 0,
    });

    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    const { history, location, match } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    if (StoresInstance.Domain.AccountStore.IsLoggedIn) {
        return <Redirect to={StoresInstance.Domain.AccountStore.getDefaultRoute()} />;
    }

    ///
    /// updateWindowDimensions
    ///
    const updateWindowDimensions = () => {
        if (componentRef !== undefined && componentRef != null && componentRef.current !== null && componentRef.current !== undefined) {
            setDimensions({
                width: componentRef!.current!.offsetWidth,
                height: componentRef.current!.offsetHeight!,
            });
        }
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        // tidy up after yourself
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    const cancel = () => {
        router.history.push(AppUrls.Client.Home);
    };

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (await viewModel.isModelValid()) {
            setIsLoading(true);
            const apiResult: any = await viewModel.ForgotPasswordAsync();
            setIsLoading(false);
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    history.push(AppUrls.Client.Account.ForgotPasswordSuccess);
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
        }
    };

    const inPortrait: boolean = dimensions.height > dimensions.width;
    const triangleHeight: string = (inPortrait === true ? (dimensions.width * 0.765).toString() : (dimensions.height * 0.765).toString()) + "px";

    return useObserver(() => (
        <Fade in timeout={1000}>
            <AccountPage triangleheight={triangleHeight} ref={componentRef}>
                <div className="triangle"></div>
                <BoxPage>
                    <BoxAccount>
                        <AccountImageBox>
                            <img src={Logo} alt="Logo" />
                        </AccountImageBox>
                        <AccountTitleBox>{/* <span>Project Management System</span> */}</AccountTitleBox>
                        <AccountForm onSubmit={doSubmit}>
                            <EditableInput type="email" label="Email Address:" shrink={true} validateOnBlur={true} viewModel={viewModel} fieldName="emailAddress" />
                            {errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <Box margin="50px 0 0 0" textAlign="center">
                                <AcceptButton type="submit">{viewModel.IsLoading ? <span>Please wait...</span> : <span>Reset Password</span>}</AcceptButton>
                                <CancelButton onClick={cancel}>{viewModel.IsLoading ? <span>Please wait...</span> : <span>Cancel</span>}</CancelButton>
                            </Box>
                        </AccountForm>
                    </BoxAccount>
                </BoxPage>
            </AccountPage>
        </Fade>
    ));
};

// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";

// Custom

// Styling & Images
import { RowDash } from "Views/Project/CustomComponents";
import { VariationSubCategoryViewModel } from "./VariationSubCategoryViewModel";
import { ToggleButton } from "Views/Project/Commercial/IETables/IEGrid.Styles";

interface ITableProps {
    itemModel: VariationSubCategoryViewModel;
    rowType: string;
    categoryIndex: number;
    rowIndex: number;
    rowEnable(parentIndex: number, index: number): void;
    currentActiveCatIndex: number;
    currentActiveSubCatIndex: number;
}

export const VariationSubCat: React.FunctionComponent<ITableProps> = (props) => {
    const { itemModel, rowType, rowEnable, rowIndex, categoryIndex, currentActiveCatIndex, currentActiveSubCatIndex } = props;

    const handleName = () => {
        return itemModel.model.displayName;
    };

    return useObserver(() => (
        <>
            <li className={rowType} key={"subs_" + rowIndex}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ color: `${currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === rowIndex ? "#e03c31" : ""}`, paddingLeft: "10px" }}>
                        <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                        <span style={{ display: "inline-block" }}>{handleName()}</span>
                    </div>
                    {currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === rowIndex ? (
                        <ToggleButton>
                            <span
                                className={"open"}
                                onClick={() => {
                                    rowEnable(-1, -1);
                                }}
                            >
                                &minus;
                            </span>
                        </ToggleButton>
                    ) : (
                        <ToggleButton>
                            <span
                                className={"close"}
                                onClick={() => {
                                    rowEnable(categoryIndex, rowIndex);
                                }}
                            >
                                &#43;
                            </span>
                        </ToggleButton>
                    )}
                </div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
            </li>
        </>
    ));
};

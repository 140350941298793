import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import { pxToRem } from "Globals/Styles/AppTheme";
import { DeleteIncomeAndExpenditureViewModel } from "./DeleteIncomeAndExpenditureViewModel";
import { PrimaryButton, DefaultButton } from "Components/Buttons/Buttons";
import { Alert, AlertTitle } from "@material-ui/lab";

interface IProps {
    className?: string;
    viewModel: DeleteIncomeAndExpenditureViewModel | null;
}

const DeleteIncomeAndExpenditureDialogBase: React.FC<IProps> = (props) => {
    // #region Code Behind

    const canDisplayDialog = (): boolean => props.viewModel !== null;

    const handleCancelDialog = () => {
        props.viewModel!.cancel();
    };

    const handleDeleteDialog = () => {
        props.viewModel!.delete();
    };

    // #endregion Code Behind

    return useObserver(() => {
        return (
            <Dialog className={props.className} open={canDisplayDialog()} onClose={handleCancelDialog}>
                {canDisplayDialog() ? (
                    <React.Fragment>
                        <DialogTitle>Delete confirmation</DialogTitle>
                        <DialogContent>
                            Are you sure you want to delete <strong>{props.viewModel!.getValue("name")}</strong>?
                        </DialogContent>
                        <Divider variant="middle" />
                        <DialogActions disableSpacing={true}>
                            <Grid columnGap={30} rowGap={20} tc={"1fr 1fr 1fr 1fr"} dc={"1fr 1fr 1fr 1fr"}>
                                {props.viewModel!.server.HaveValidationMessage && (
                                    <Cell tabletSpan={4} desktopSpan={4}>
                                        <Alert severity="error">
                                            <AlertTitle>Error</AlertTitle>
                                            {props.viewModel!.server.ValidationMessage}
                                        </Alert>
                                    </Cell>
                                )}
                                <Cell tabletSpan={1} desktopSpan={1} display={{ xs: "none", md: "block" }} />
                                <Cell>
                                    <PrimaryButton displayName="Delete" canExecute={!props.viewModel!.server.IsBusy} execute={handleDeleteDialog} />
                                </Cell>
                                <Cell>
                                    <DefaultButton displayName="Cancel" canExecute={!props.viewModel!.server.IsBusy} execute={handleCancelDialog} />
                                </Cell>
                                <Cell tabletSpan={1} desktopSpan={1} display={{ xs: "none", md: "block" }} />
                            </Grid>
                        </DialogActions>
                    </React.Fragment>
                ) : (
                    <React.Fragment />
                )}
            </Dialog>
        );
    });
};

export const DeleteIncomeAndExpenditureDialog = styled(DeleteIncomeAndExpenditureDialogBase)`
    & > .MuiDialog-container {
        & > .MuiDialog-paper {
            border-radius: ${pxToRem(10)};
            width: ${pxToRem(594)};

            & > .MuiDialogTitle-root {
                background: #e03c31 0% 0% no-repeat padding-box;
                color: white;
                padding: ${pxToRem(13)} ${pxToRem(20)};

                & > h2 {
                    font-weight: normal;
                    font-size: ${pxToRem(18)};
                }
            }
            & > .MuiDialogContent-root {
                font-size: ${pxToRem(14)};
                padding: ${pxToRem(16)} ${pxToRem(20)};
            }
            & > .MuiDialogActions-root {
                display: block;
                padding: ${pxToRem(20)} ${pxToRem(8)};

                & > .MuiBox-root {
                    & > .MuiBox-root {
                        & > .MuiAlert-root {
                            margin: 0 ${pxToRem(12)};
                        }

                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
`;

import { getHistory, FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { ProjectModel } from "Views/PurchaseOrder/Form/Supporting/ProjectModel";
import { AppUrls } from "AppUrls";
import { ProjectReportModel, ReportRelatedResponseDTO } from "./ProjectReportModel";

export enum ReportType {
    All = "All",
    VRC = "VRC",
    Spend = "Spend",
}

export class ProjectReportViewModel extends ViewModelBase<ProjectReportModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new ProjectReportModel());

        this.setDecorators(ProjectReportViewModel);
    }

    @observable
    public reportTypeOptions = observable<{ id: string; displayName: string }>([
        { id: "", displayName: "Please select" },
        { id: ReportType.All, displayName: ReportType.All },
        { id: ReportType.VRC, displayName: ReportType.VRC },
        { id: ReportType.Spend, displayName: ReportType.Spend },
    ]);

    @computed
    public get reportTypeOption() {
        return this.reportTypeOptions.find((o) => o.id === this.model.reportType);
    }

    @action
    public setReportType = (reportType: string) => {
        this.model.reportType = reportType;
    };

    @observable
    public projects = observable<ProjectModel>([]);

    @computed
    public get projectOptions() {
        return this.projects
            .filter((p) => p.isStockProject === false)
            .filter((p) => p.isCentralProject === false)
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    @computed
    public get project() {
        return this.projects.find((p) => p.id === this.model.projectId) ?? null;
    }

    @action
    public setProjectAsync = async (value: ProjectModel | null) => {
        this.model.projectId = value?.id ?? "";
    };

    public loadRelated = async (): Promise<void> => {
        return await this.server.query<ReportRelatedResponseDTO>(
            () => this.Get(`${AppUrls.Server.Report.LoadRelated}`),
            (result) => {
                runInAction(() => {
                    this.projects.replace(ProjectModel.fromDtos(result.projects));
                });
            },
        );
    };

    public server: ServerViewModel = new ServerViewModel();

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel = (): void => {
        getHistory().goBack();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<ProjectReportModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

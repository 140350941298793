import React from "react";
import { observer } from "mobx-react-lite";
import { Step6ViewModel } from "./Step6ViewModel";
import { SHBox } from "../../../Components/Box";
import { WizardButtonsView } from "../WizardButtons";
import styled from "styled-components";
import SignaturePad from "react-signature-pad-wrapper";
import { Step6Model } from "./Step6Model";
import { DarwinInput } from "../../../Globals/Styles/Control/DarwinInput";
import { Loader } from "@shoothill/core";
import { InductionViewModel } from "../InductionViewModel";

const Wrapper = styled(SHBox)`
    canvas {
        border: 1px solid #707070;
    }
`;
const Title = styled.p`
    font-size: 14px !important;
    font-weight: 600;
`;
const Title2 = styled.p`
    font-size: 18px !important;
    font-weight: 600;
`;
interface Props {
    viewModel: Step6ViewModel;
    onSubmit: () => void;
    parentViewModel: InductionViewModel;
}
export const Step6View: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;
    const signaturePadRef = React.createRef<SignaturePad>();

    const nextStep = async () => {
        handleSave();
        const isValid = await viewModel.isModelValid();
        if (isValid) {
            //await viewModel.nextStep();
            props.onSubmit();
        }
    };
    const handleClear = (): void => {
        const signaturePad = signaturePadRef.current;

        if (signaturePad) {
            signaturePad.instance.clear();
        }
    };

    const handleSave = (): void => {
        const signaturePad = signaturePadRef.current;

        if (!signaturePad) {
            return;
        }

        if (signaturePad.isEmpty()) {
            // eslint-disable-next-line no-alert
            viewModel.setError("signature", "Please provide your signature");
        } else {
            viewModel.setError("signature", "");
            viewModel.setValue("signature", signaturePad.toDataURL());
        }
    };

    if (props.parentViewModel.isStageLoading) {
        return (
            <>
                <Loader />
            </>
        );
    }

    return (
        <>
            <SHBox showIf={!viewModel.submitted} height={"85vh"}>
                <SHBox m={3} grid dc={"1fr 1fr"} mc={"1fr"}>
                    <Wrapper alignSelf={"center"} pb={"200px"}>
                        <SHBox mb={3}>
                            Please sign in the box below to confirm you have completed the Portakabin - Trial Site Induction and that the information provided is, to the best of
                            your knowledge, correct.
                        </SHBox>
                        <SignaturePad ref={signaturePadRef} options={{ penColor: "black" }} />
                        <p style={{ color: "red" }}>{viewModel.getError("signature")}</p>
                        <Title>Last 3 phone digits</Title>
                        <p style={{ fontSize: "10px" }}>
                            We require the last 3 digits of your phone number so we can identify you on our system for access to our construction sites.
                        </p>
                        <SHBox width={"125px"} mt={3} mb={3}>
                            <DarwinInput<Step6Model>
                                shrink={true}
                                validateOnBlur={true}
                                viewModel={props.viewModel}
                                maxLength={3}
                                editMode={true}
                                type={"number"}
                                inputProps={{
                                    maxLength: 3,
                                    placeholder: "Last 3 digits",
                                }}
                                fieldName="last3PhoneDigits"
                                validationMessage={props.viewModel.getError("last3PhoneDigits")}
                            />
                        </SHBox>
                        <Title>
                            Once submitted your induction will be assessed by Portakabin - Trial. Once approved you will be emailed to confirm and give you further instructions on
                            accessing any Portakabin - Trial Site.
                        </Title>
                    </Wrapper>
                </SHBox>
                <WizardButtonsView nextStep={nextStep} previousStep={viewModel.previousStep} canExecute={viewModel.canExecuteNextStep} submit={true} />
            </SHBox>
            <SHBox showIf={viewModel.submitted} height={"85vh"} ml={3} mt={3}>
                <Title2>Thank you for completing the Portakabin - Trial site induction.</Title2>
                <Title2>Remember, the most important thing is that you stay safe!</Title2>
            </SHBox>
        </>
    );
});

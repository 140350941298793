import { Box, CircularProgress, TextField } from "@material-ui/core";
import { Cell, Grid } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { DefaultButton, PrimaryButton, RejectButton } from "Components/Buttons/Buttons";
import { InvoiceMatchViewModel } from "./InvoiceMatchViewModel";
import { InvoicePurchaseOrderViewModel } from "./Supporting/InvoicePurchaseOrderViewModel";
import { InvoiceGroupViewModel } from "./Supporting/InvoiceGroupViewModel";
import {
    InvoiceMatchFooter,
    InvoiceMatchGroupRow,
    InvoiceMatchGroupRowContainer,
    InvoiceMatchHeader,
    InvoiceMatchItemRow,
    InvoiceMatchItemRowsContainer,
    InvoiceMatchPORow,
    InvoiceMatchRows,
} from "./InvoiceMatch.Styles";
import { ToggleButton } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { InvoiceViewModel } from "../InvoiceViewModel";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { InvoiceDisputeModal } from "./InvoiceDisputedModal/InvoiceDisputeModal";
import { Alert, AlertTitle, Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { InvoiceMatchSubmitWarningModal } from "./InvoicesubmittedModal/InvoiceMatchSubmitWarningModal";
import { InvoicePOModal } from "./InvoicePOModal/InvoicePOModal";
import DeleteLogo from "Content/Bin.svg";
import { InvoiceMatchProjectAllocationWarningModal } from "./InvoiceMatchProjectAllocationWarningModal/InvoiceMatchProjectAllocationWarningModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { InvoiceMatchItemAlreadyAllocatedModal } from "./InvoiceMatchItemAlreadyAllocatedModal/InvoiceMatchItemAlreadyAllocatedModal";
import { InvoiceMatchItem } from "./Supporting/InvoiceMatchItem";

interface IProps {
    className?: string;
    viewModel: InvoiceMatchViewModel;
    isApprovalMode: boolean;
}

const InvoiceMatchViewBase: React.FC<IProps> = (props: IProps) => {
    const [instanceViewModel] = useState(() => InvoiceViewModel.GetInstance);
    const { viewModel } = props;
    const [activePOId, setActivePOId] = React.useState<string | null>(null);
    const [showSubmittedModal, setShowSubmittedModal] = React.useState<boolean>(false);
    const [showProjectAllocationModal, setShowProjectAllocationModal] = React.useState<boolean>(false);
    const [showPOModal, setShowPOModal] = React.useState<boolean>(false);
    const [currentPOId, setCurrentPOId] = React.useState<string | null>(null);

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    const handleClickSubmit = () => {
        if (viewModel.getProjectsExceedingAllocation.length > 0) {
            setShowProjectAllocationModal(true);
        } else if (viewModel.getTotalBalanceToAllocate !== 0) {
            setShowSubmittedModal(true);
        } else {
            instanceViewModel.handleMatchSubmit();
        }
    };

    const handleClickSaveAsDraft = () => {
        instanceViewModel.handleMatchSaveAsDraft();
    };

    const handleShowDisputedModalChange = (val: boolean) => {
        viewModel.handleShowDisputedModalChange(val);

        if (val === false) {
            InvoiceViewModel.GetInstance.invoiceDisputeModalViewModel.reset();
        }
    };

    const handleShowPOModalChange = (id: string | null, val: boolean) => {
        setShowPOModal(val);
        setCurrentPOId(id);
    };

    const renderPurchaseOrders = () => {
        return (
            <InvoiceMatchRows>
                {viewModel.getInvoicePurchaseOrders.map((po) => {
                    return (
                        <div>
                            <InvoiceMatchPORow>
                                <div>
                                    {`${po.model.projectName} - ${po.getInvoiceValueFormatted}`}
                                    {activePOId === po.model.id ? (
                                        <ToggleButton>
                                            <span
                                                className={"open"}
                                                onClick={() => {
                                                    setActivePOId(null);
                                                }}
                                            >
                                                &minus;
                                            </span>
                                        </ToggleButton>
                                    ) : (
                                        <ToggleButton>
                                            <span
                                                className={"opcloseen"}
                                                onClick={() => {
                                                    setActivePOId(po.model.id);
                                                }}
                                            >
                                                &#43;
                                            </span>
                                        </ToggleButton>
                                    )}
                                </div>
                                <div>
                                    <p className={"po-link"} onClick={() => handleShowPOModalChange(po.model.requisitionRequestId, true)}>
                                        {po.model.formattedPONumber}
                                    </p>
                                </div>
                                <div>{po.getInitialValueFormatted}</div>
                                <div>{po.getAlreadyAllocatedFormatted}</div>
                                <div>{po.getAvailableBalanceFormatted}</div>
                                <div>{po.getAmountAllocatedFormatted}</div>
                                <div>
                                    {!viewModel.isFormDisabled && (
                                        <img
                                            src={DeleteLogo}
                                            onClick={(e) => viewModel.handleDeletePurchaseOrder(e, po.model.id)}
                                            style={{ cursor: viewModel.isFormDisabled ? "default" : "pointer" }}
                                        />
                                    )}
                                </div>
                            </InvoiceMatchPORow>
                            {activePOId === po.model.id && renderGroups(po)}
                            <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                        </div>
                    );
                })}
            </InvoiceMatchRows>
        );
    };

    const renderGroups = (po: InvoicePurchaseOrderViewModel) => {
        return (
            <>
                {po.getInvoiceGroups.map((group) => {
                    return (
                        <InvoiceMatchGroupRowContainer>
                            <InvoiceMatchGroupRow>
                                <div>{group.model.groupName}</div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </InvoiceMatchGroupRow>

                            <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />

                            {renderItems(group)}
                        </InvoiceMatchGroupRowContainer>
                    );
                })}
            </>
        );
    };

    const renderItems = (group: InvoiceGroupViewModel) => {
        return (
            <InvoiceMatchItemRowsContainer>
                {group.getInvoicePurchaseOrderItems.map((item) => {
                    return (
                        <>
                            <InvoiceMatchItem viewModel={viewModel} item={item} />
                        </>
                    );
                })}
            </InvoiceMatchItemRowsContainer>
        );
    };

    const renderPage = () => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                <InvoiceMatchCalculationsContainer>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell ts={4} ds={4}>
                            <InvoiceMatchCalculations>
                                <div>
                                    <span>Invoice value:</span> <div>{viewModel.getInvoiceValueFormatted}</div>
                                </div>
                                <div>
                                    <span>Balance to allocate:</span>
                                    <div className={viewModel.getTotalBalanceToAllocate < 0 ? "negative" : ""}>{viewModel.getTotalBalanceToAllocateFormatted}</div>
                                </div>
                            </InvoiceMatchCalculations>
                            <ProjectBalanceContainer>
                                {viewModel.getTotalBalanceToAllocateByProject.map((p) => {
                                    return (
                                        <ProjectBalanceItem key={p.projectId}>
                                            <div>{p.name}:</div> <div className={p.balanceToAllocate < 0 ? "negative" : ""}>{p.balanceToAllocateFormatted}</div>
                                        </ProjectBalanceItem>
                                    );
                                })}
                            </ProjectBalanceContainer>
                        </Cell>
                        <Cell display={{ xs: "none", md: "block" }} ts={4} ds={4} />
                    </Grid>
                    <br />
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                </InvoiceMatchCalculationsContainer>

                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        <Cell ts={4} ds={4}>
                            <Autocomplete
                                key={"po-autocomplete"}
                                onChange={viewModel.handleAddPurchaseOrder}
                                onInputChange={viewModel.handleSearchPurchaseOrders}
                                options={viewModel.purchaseOrderOptions}
                                getOptionLabel={(option) => option.value.toString()}
                                getOptionDisabled={(option) => viewModel.isPOSelected(option)}
                                filterOptions={(x) => x} //  Disable the build in filtering.
                                value={viewModel.selectedPurchaseOrder}
                                inputValue={viewModel.searchText}
                                loading={viewModel.IsLoading}
                                clearOnBlur={false}
                                disableClearable={false}
                                disabled={viewModel.isFormDisabled}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {viewModel.IsLoading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Associating PO for Project/Central cost"
                                        placeholder={"Please select"}
                                        disabled={viewModel.isFormDisabled}
                                    />
                                )}
                            />
                        </Cell>
                    </Grid>
                </FormSection>

                <FormSection>
                    <InvoiceMatchHeader>
                        <div>Project</div>
                        <div>Purchase order</div>
                        <div>Initial value</div>
                        <div>Already allocated</div>
                        <div>Available balance</div>
                        <div>Amount allocated</div>
                        <div></div>
                    </InvoiceMatchHeader>
                    {renderPurchaseOrders()}
                </FormSection>

                <FormSection>
                    <SolidDivider gutterBottom={true} borderThickness={3} borderOpacity={0.7} />
                    <InvoiceMatchFooter>
                        <div></div>
                        <div>Total</div>
                        <div>{viewModel.getInitialValueFormatted}</div>
                        <div>{viewModel.getAlreadyAllocatedFormatted}</div>
                        <div>{viewModel.getAvailableBalanceFormatted}</div>
                        <div>{viewModel.getAmountAllocatedFormatted}</div>
                    </InvoiceMatchFooter>
                    <br />
                    <SolidDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                </FormSection>

                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {viewModel!.server.HaveValidationMessage && (
                            <>
                                <Cell ts={8} ds={8}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {viewModel!.server.ValidationMessage}
                                    </Alert>
                                </Cell>
                            </>
                        )}
                    </Grid>
                </FormSection>

                {!InvoiceViewModel.GetInstance.approvalPanelViewModel.getCanShowNewApprovalPanel && (
                    <FormSection>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                            {/* Group of 8 columns */}
                            <Cell ts={2} ds={2}>
                                <RejectButton
                                    displayName="Dispute"
                                    execute={() => handleShowDisputedModalChange(true)}
                                    fullWidth={true}
                                    canExecute={!viewModel.IsLoading && viewModel.canDispute && viewModel.getCanEditInvoice}
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <PrimaryButton
                                    displayName="Save as draft"
                                    execute={handleClickSaveAsDraft}
                                    fullWidth={true}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading && viewModel.canSaveAsDraft && viewModel.getCanEditInvoice}
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <PrimaryButton
                                    displayName="Submit for approval"
                                    execute={handleClickSubmit}
                                    fullWidth={true}
                                    canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading && viewModel.canSubmit && viewModel.getCanEditInvoice}
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <DefaultButton displayName="Cancel" execute={viewModel.handleCancel} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            </Cell>
                        </Grid>
                    </FormSection>
                )}

                {InvoiceViewModel.GetInstance.approvalPanelViewModel.getCanShowNewApprovalPanel && props.isApprovalMode && (
                    <FormSection>
                        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                            {/* Group of 8 columns */}
                            <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                            <Cell ts={2} ds={2}>
                                <RejectButton
                                    displayName="Dispute"
                                    execute={() => handleShowDisputedModalChange(true)}
                                    fullWidth={true}
                                    canExecute={!viewModel.IsLoading && viewModel.canDispute && viewModel.getCanEditInvoice}
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <PrimaryButton
                                    displayName="Approve"
                                    execute={instanceViewModel.handleApproveInvoice}
                                    fullWidth={true}
                                    canExecute={
                                        !viewModel.IsLoading && InvoiceViewModel.GetInstance.approvalPanelViewModel.getCanShowNewApprovalPanel && viewModel.getCanEditInvoice
                                    }
                                />
                            </Cell>
                            <Cell ts={2} ds={2}>
                                <DefaultButton displayName="Cancel" execute={viewModel.handleCancel} fullWidth={true} canExecute={!viewModel.IsLoading} />
                            </Cell>
                            <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                        </Grid>
                    </FormSection>
                )}

                <InvoiceDisputeModal
                    onClose={() => handleShowDisputedModalChange(false)}
                    open={viewModel.getShowDisputedModal}
                    title={viewModel.isDisputed ? "Change code?" : "Dispute invoice?"}
                    confirmText={viewModel.isDisputed ? "Change code" : "Dispute invoice"}
                    onSave={viewModel.handleDisputeInvoice}
                    viewModel={InvoiceViewModel.GetInstance.invoiceDisputeModalViewModel}
                    isLoading={viewModel.IsLoading}
                    isDisputed={viewModel.isDisputed}
                />

                <InvoiceMatchSubmitWarningModal
                    onClose={() => setShowSubmittedModal(false)}
                    open={showSubmittedModal}
                    title="Unable to submit invoice"
                    text="The balance allocated must match the invoice value."
                    isLoading={viewModel.IsLoading}
                />

                <InvoiceMatchProjectAllocationWarningModal
                    onClose={() => setShowProjectAllocationModal(false)}
                    open={showProjectAllocationModal}
                    title="Unable to submit invoice"
                    text="The following projects are over allocated:"
                    isLoading={viewModel.IsLoading}
                    projects={viewModel.getProjectsExceedingAllocation}
                />

                <InvoicePOModal onClose={() => handleShowPOModalChange(null, false)} open={showPOModal} title="Purchase order" isLoading={viewModel.IsLoading} poId={currentPOId} />

                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </Form>
        );
    };

    return useObserver(() => renderPage());
};

export const InvoiceMatchView = styled(InvoiceMatchViewBase)`
    form {
        position: relative;
    }
`;

export const DarwinDateSelectCustom = styled(DarwinDateSelect)`
    label {
        white-space: nowrap;
    }
`;

export const POList = styled(Box)`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: bold;

    .po-table-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 12px 30px;
        background-color: #ced4da;
    }

    .po-table-body {
        display: flex;
        flex-direction: column;
        padding: 10px 30px;

        > div.po-table-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 10px 0px;

            img {
                cursor: pointer;
            }

            .po-table-cell:nth-child(4) {
                text-align: right;
            }
        }
    }

    .po-table-cell:nth-child(1) {
        width: 40%;
    }

    .po-table-cell:nth-child(2) {
        width: 40%;
    }

    .po-table-cell:nth-child(3) {
        width: 10%;
    }

    .po-table-cell:nth-child(4) {
        width: 10%;
    }
`;

export const InvoiceMatchCalculations = styled(Box)`
    display: flex;
    flex-direction: row;

    > div {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        font-size: ${pxToRem(18)};
        line-height: 18px;
        letter-spacing: -0.27px;

        > span {
            margin-right: 10px;
        }
    }

    > div:nth-child(1) {
        margin-right: 60px;
    }

    .negative {
        color: ${theme.palette.error.main};
    }
`;

export const ProjectBalanceContainer = styled.div`
    margin-top: 20px;
`;

export const ProjectBalanceItem = styled.div`
    display: flex;
    min-width: 500px;
    width: 100%;

    .negative {
        color: ${theme.palette.error.main};
    }

    > div {
        width: 200px;
    }

    > div:last-child {
        display: flex;
        justify-content: flex-end;
    }
`;

export const InvoiceMatchCalculationsContainer = styled(FormSection)`
    margin-bottom: 0px;
`;

export const InvoiceMatchGridTotals = styled(Box)`
    > .invoice-value-summary {
        display: flex;
        flex-direction: column;
        max-width: 214px;

        > .invoice-value-summary-row {
            display: flex;
            flex-direction: row;
            font-weight: bold;
            margin: 5px 0px;

            > span {
                margin-right: 10px;
                width: 40px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }

            > div {
                display: flex;
                justify-content: flex-end;
                flex: 1 0 auto;

                input {
                    text-align: right;
                }
            }
        }

        > .invoice-value-summary-row:nth-child(1) {
            color: #7f7f7f;

            > span {
                color: #191919;
            }
        }
    }
`;

import styled from "styled-components";

const VRCGridViewBase: any = styled.ul`
    list-style: none;
    margin: 0px 10px 50px;
    li {
        width: 100%;
        border-bottom: solid 1px #dddddd;
        display: grid;
        grid-template-columns: 280px repeat(auto-fit, minmax(100px, 1fr));
        grid-template-rows: 35px;
        gap: 0px 0px;
        grid-auto-flow: column;
        height: 50px;

        > div {
            height: 50px;
            padding: 10px 20px;
            font-size: 12px;
            font-weight: bold;
            border-right: solid 1px #dddddd;
        }

        > div:first-of-type {
            //border-left: solid 1px #dddddd;
        }

        > div:last-of-type {
            border-right: none;
        }

        > .noPadding {
            padding: 0px;
        }
    }

    .heading,
    .footing,
    .project,
    .ie,
    .category,
    .subcategory,
    .item {
        & > * {
            display: flex;
            align-items: center;
        }

        .ie-row-name {
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
        }

        .ie-item-name {
            max-width: 138px;
        }

        .ie-subcat-name {
            max-width: 160px;
        }

        .ie-cat-name {
            max-width: 190px;
        }

        .ie-po-name {
            -webkit-line-clamp: 1;
            max-width: 170px;
        }

        .right {
            /* text-align: right !important; */
            justify-content: flex-end;
        }

        .centre {
            justify-content: center;
        }

        .credit {
            background-color: #caead4;
        }

        .debit {
            background-color: #ecb4b4;
        }

        .notdebitorcredit {
            background-color: #efe350bf;
        }

        .italic {
            font-style: italic;
        }
    }

    .heading-main {
        background-color: #425b66 !important;
        color: #ffffff !important;

        > div {
            border-right: 2px solid #808080 !important;
            border-bottom: 2px solid #808080 !important;
            border-top: 2px solid #808080 !important;
        }
    }

    .heading > div:nth-child(1) {
        border-left: 2px solid #808080;
    }

    .heading > div:nth-child(1),
    .heading > div:nth-child(4),
    .heading > div:nth-child(7),
    .heading > div:nth-child(10),
    .heading > div:nth-child(13) {
        border-right: 2px solid #808080;
    }

    li > div:nth-child(1) {
        border-left: 2px solid #808080;
    }

    li > div:nth-child(1),
    li > div:nth-child(4),
    li > div:nth-child(7),
    li > div:nth-child(10),
    li > div:nth-child(13) {
        border-right: 2px solid #808080;
    }

    .heading > div {
        min-height: 60px;
    }

    .subcategory {
        > div {
            > div {
                display: flex;
                align-items: center;
            }
        }
    }

    .subcategory div.MuiInput-root,
    .subcategory div.MuiFormControl-root,
    .subcategory div.MuiAutocomplete-root {
        padding-left: 0px;
    }

    .subcategory div.MuiAutocomplete-root {
        padding-right: 20px;
    }

    .item,
    .title {
        background-color: #f6fcff;

        .MuiAutocomplete-root,
        .MuiBox-root {
            padding: 0px;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .title {
        grid-template-rows: none;
        height: 80px;

        > div:nth-child(1) {
            font-size: 16px;
        }

        > div {
            padding: 30px 20px 10px 20px;
            height: 100%;
        }
    }

    .heading {
        background-color: #ced4da;
        height: 60px;
        line-height: 1.25;
        //padding: 5px 0;
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    .inputIERow {
        border-bottom: dashed 1px #dddddd;
    }
`;

export const VRCSummaryGridView: any = styled(VRCGridViewBase)``;

export const VRCGridHeader: any = styled.div`
    position: sticky;
    top: 68px;
    @media (max-width: 1379px) {
        top: 136px;
    }
    @media (max-width: 775px) {
        top: 65px;
    }
    @media (max-width: 599px) {
        top: 48px;
    }
    ul {
        margin-bottom: 0px;
    }
`;

export const VRCSubsGridView: any = styled(VRCGridViewBase)`
    font-style: italic;
    color: #6c93a6;
    border-top: solid 1px #ced4da;
    border-bottom: solid 1px #ced4da;

    > div {
        border-right: #ffffff;
    }
`;

import { ApiResult, FieldType, generateID, isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { AddEditSupplierModel } from "./AddEditSupplierModel";
import { observable, action, computed, runInAction } from "mobx";
import type { SupplierWithRelatedDTO } from "./SupplierWithRelatedDTO";
import { AppUrls } from "AppUrls";
import { SupplierDetailModelDTO } from "./SupplierDetailModel";
import { get as _get } from "lodash-es";
import { AddressModel } from "Globals/Models/Domain";
import type { AddressModelDTO } from "Globals/Models/Domain";
import { AddEditContactModel } from "Views/Contacts/AddEditContactModel";
import AddressViewModel from "Globals/ViewModels/AddressViewModel";
import { PaymentTypeModel } from "Views/PurchaseOrder/Form/Supporting/PaymentTypeModel";
import { PaymentTermsModel } from "Views/PurchaseOrder/Form/Supporting/PaymentTermsModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { PurchaseOrderRelatedResponseDTO } from "Views/PurchaseOrder/Form/PurchaseOrderModel";
import { StoresInstance } from "Globals/Stores";
import { openFileInNewTab } from "Utils/Utils";
import { SupplierDocumentDTO, SupplierDocumentModel } from "./SupplierDocumentModel";

export class AddEditSupplierViewModel extends ViewModelBase<AddEditSupplierModel> {
    NEWITEM = "DELETEME_";

    private static _instance: AddEditSupplierViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @computed
    public get getCanEditSupplierForm(): boolean {
        return StoresInstance.Domain.AccountStore.getCanEditSupplier && !this.IsLoading && this.hasLoaded;
    }

    @computed
    public get getCanEditSupplierReference(): boolean {
        return StoresInstance.Domain.AccountStore.getCanEditSupplierReference && !this.IsLoading && this.hasLoaded;
    }

    @observable public errorMessage: string = "";

    @observable public hasLoaded: boolean = false;

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @observable
    private addressViewModel: AddressViewModel = new AddressViewModel(new AddressModel());

    @observable
    public addressModel = new AddressModel();

    public constructor() {
        super(new AddEditSupplierModel(), false);
        this.setDecorators(AddEditSupplierModel);
    }

    @action
    public setAddressModel(val: AddressModel) {
        this.addressModel = val;
    }

    @action
    public updateModelAddress(model: AddressModel) {
        this.model.updateAddress(model);
        this.createAddressViewModels();
    }

    @computed
    public get getAddressViewModel(): AddressViewModel {
        return this.addressViewModel;
    }

    @action public addContact(contact: AddEditContactModel) {
        if (isNullOrUndefined(contact.id) === true) {
            contact.id = this.NEWITEM + generateID();
        }
        this.model.addContact(contact);
    }

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
        this.model.cleanUp();
        this.setHasLoaded(false);
    };

    @computed
    public get getModelAddress(): AddressModel {
        return this.model.getAddress;
    }

    public areModelsValid = async (): Promise<boolean> => {
        let retVal: boolean = await this.isModelValid();
        return retVal;
    };

    @action
    deleteContact(id: string) {
        this.model.deleteContact(id);
    }

    @computed
    public get getSupplierDocuments(): SupplierDocumentModel[] {
        return this.model.supplierDocuments.filter((d) => !d.isDeleted);
    }

    public upsert = async (): Promise<ApiResult<SupplierWithRelatedDTO>> => {
        if (await this.isModelValid()) {
            const request: SupplierDetailModelDTO = this.model.toSupplierDetailModelDto();

            request.contacts.forEach((contact) => {
                if (contact.id?.startsWith(this.NEWITEM) === true) {
                    contact.id = undefined;
                }
            });

            request.addresses.forEach((address) => {
                if (address.id?.startsWith(this.NEWITEM) === true) {
                    address.id = "";
                }
            });

            const documents: SupplierDocumentDTO[] = this.model.supplierDocuments.map((d) => d.toDto());

            request.supplierDocuments = documents;

            let apiResult = await this.Post<SupplierWithRelatedDTO>(AppUrls.Server.Supplier.Upsert, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromSupplierWithRelatedDto(apiResult.payload.supplier);
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    };

    @action
    public setSupplier(payload: SupplierWithRelatedDTO) {
        this.model.fromSupplierWithRelatedDto(payload.supplier);
        this.paymentTerms.replace(PaymentTermsModel.fromDtos(payload.paymentTerms));
        this.paymentTypes.replace(PaymentTypeModel.fromDtos(payload.paymentTypes));
        this.createAddressViewModels();
    }

    @action
    private createAddressViewModels(): void {
        this.addressViewModel = new AddressViewModel(this.model.address);
        this.setAddressModel(this.model.address);
    }

    public async isFieldValid(fieldName: keyof FieldType<AddEditSupplierModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "reference": {
                const result = this.validateReference;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "name": {
                const result = this.validateName;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "paymentTerms": {
                const result = this.validatePaymentTerms;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "paymentGroup": {
                const result = this.validatePaymentType;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "paymentTermsInDays": {
                const result = this.validatePaymentTermsInDays;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    /**
     * Custom model validation function. Validates child models.
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // Contact validation is handle before this point, as the contact form has its own "Add" button.

        // Validate Address

        let addressVM: AddressViewModel = this.getAddressViewModel;

        if ((await addressVM.isModelValid()) === false) {
            isValid = false;
        }

        // Validate Contact

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @action
    public updateAddress(address: AddressModelDTO) {
        this.model.setAddress(address);
    }

    @action
    updatContactIsPrimary(id: string, isPrimary: boolean) {
        this.model.updatContactIsPrimary(id, isPrimary);
    }

    /// code new added start

    @action
    public setPaymentType = (value: PaymentTypeModel | null) => {
        this.model.paymentGroup = value ? value.id : null;
    };

    @action
    public setPaymentTerms = (value: PaymentTermsModel | null) => {
        this.model.paymentTerms = value ? value.id : null;
    };

    @action
    public handlePQQChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.model.pqq = (event.target as HTMLInputElement).value === "true" ? true : false;
    };

    @computed
    public get paymentTerm() {
        const result = this.paymentTerms.find((p) => p.id === this.model.paymentTerms);
        return result ? result! : null;
    }

    @computed
    public get paymentType() {
        const result = this.paymentTypes.find((p) => p.id === this.model.paymentGroup);
        return result ? result! : null;
    }

    @observable
    public paymentTerms = observable<PaymentTermsModel>([]);

    @observable
    public paymentTypes = observable<PaymentTypeModel>([]);

    @computed
    private get validatePaymentTerms(): ValidationResponse {
        const errorMessage = this.model.validatePaymentTermsId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validatePaymentType(): ValidationResponse {
        const errorMessage = this.model.validatePaymentTypeId;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validatePaymentTermsInDays(): ValidationResponse {
        const errorMessage = this.model.validatePaymentTermsInDays;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateReference(): ValidationResponse {
        const errorMessage = this.model.validateReference;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateName(): ValidationResponse {
        const errorMessage = this.model.validateName;
        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public server: ServerViewModel = new ServerViewModel();

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: SupplierDocumentDTO = {
                    id: null,
                    url: apiResult.payload,
                    fileName: data.fileName,
                    isDeleted: false,
                    createdByUserId: null,
                    createdDate: null,
                };

                let model: SupplierDocumentModel = new SupplierDocumentModel();
                model.fromDto(fileToDisplay);
                runInAction(() => this.model.supplierDocuments.push(model));
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    // this.setSnackMessage("Error uploading file please try again.");
                    // this.setSnackType(this.SNACKERROR);
                    // this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });

            openFileInNewTab(apiResult, fileName);
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    /// code new added end

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { DetailsHeaderModel } from "Globals/Views/DetailsPage/DetailsHeaderModel";
import { IEFutureSpendAmdnementDTO, IEFutureSpendAmendmentAndRelatedResponseDTO, IEFutureSpendAmendmentModel } from "./IEFutureSpendAmendmentModel";
import { StoresInstance } from "Globals/Stores";
import { RoleLevelEnum } from "Globals/Stores/Domain/Admin";

export class IEFutureSpendAmendmentViewModel extends ViewModelBase<IEFutureSpendAmendmentModel> {
    // #region Constructors and Disposers

    constructor(ieId: string, ieItemId: string) {
        super(new IEFutureSpendAmendmentModel());
        this.setDecorators(IEFutureSpendAmendmentModel);

        this.model.ieId = ieId;
        this.model.ieItemId = ieItemId;

        console.log(ieId);
        console.log(ieItemId);

        this.loadFutureSpendAndAmdnement(ieId, ieItemId);
    }

    // #endregion Constructors and Disposers

    @observable
    private ieTitle: string = "";

    @action setIETitle = (val: string) => {
        this.ieTitle = val;
    };

    @computed
    public get getIETitle(): string {
        return this.ieTitle;
    }

    /**
     * Header
     */
    @computed
    get getHeader(): DetailsHeaderModel {
        const retVal: DetailsHeaderModel = new DetailsHeaderModel();

        if (this.categoryName === "" || this.subCategoryName === "" || this.lineDescriptionName === "") {
            retVal.setValue("title", "");
        } else {
            retVal.setValue("title", `${this.categoryName} > ${this.subCategoryName} > ${this.lineDescriptionName}`);
        }

        retVal.setValue("subTitle", "Future spend");

        return retVal;
    }

    @computed
    public get canAddFutureSpendAmendment(): boolean {
        // Only users with role CommDirector and above can add amendments.
        const roleLevel = StoresInstance.Domain.AccountStore.UserRoleLevel;
        return roleLevel === null || roleLevel >= RoleLevelEnum.CommDir;
    }

    @observable
    public ieFutureSpendAmendmentItems = observable<IEFutureSpendAmdnementDTO>([]); // Should probably update to viewmodel.

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Actions

    @action
    public setNote = (val: string) => {
        this.model.note = val;
    };

    @observable private categoryName: string = "";
    @observable private subCategoryName: string = "";
    @observable private lineDescriptionName: string = "";

    @action
    public loadFutureSpendAndAmdnement = (ieId: string, ieItemId: string): Promise<void> => {
        return this.server.query<IEFutureSpendAmendmentAndRelatedResponseDTO>(
            () => this.Get(`${AppUrls.Server.Projects.IncomeExpend.GetIEFutureSpendAmendmentAndRelated}\\${ieId}\\${ieItemId}`),
            (result) => {
                runInAction(() => {
                    this.ieFutureSpendAmendmentItems.replace(result.ieFutureSpendAmendmentItems);

                    this.categoryName = result.categoryName;
                    this.subCategoryName = result.subCategoryName;
                    this.lineDescriptionName = result.lineDescriptionName;

                    this.setIETitle(result.ieTitle);
                });
            },
        );
    };

    public handleSave = async (): Promise<void> => {
        if (this.canAddFutureSpendAmendment) {
            await this.save();
        }
    };

    private save = (): Promise<void> => {
        return this.server.command<IEFutureSpendAmendmentAndRelatedResponseDTO>(
            () => this.Post(AppUrls.Server.Projects.IncomeExpend.UpsertFutureSpendAmendment, this.model.toDto()),
            (result) => {
                runInAction(() => {
                    this.ieFutureSpendAmendmentItems.replace(result.ieFutureSpendAmendmentItems);

                    this.categoryName = result.categoryName;
                    this.subCategoryName = result.subCategoryName;
                    this.lineDescriptionName = result.lineDescriptionName;

                    this.model.reset();
                });
            },
            this.isModelValid,
            "There was an error trying to add the amendment",
        );
    };

    // #endregion Actions

    @computed
    private get validateFutureSpend(): ValidationResponse {
        const errorMessage = this.model.validateFutureSpend;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateNote(): ValidationResponse {
        const errorMessage = this.model.validateNote;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<IEFutureSpendAmendmentModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.server.IsSubmitted) {
            // Process the properties of the model that cannot be supported via
            // the use of decorators.
            switch (fieldName) {
                case "futureSpend": {
                    const result = this.validateFutureSpend;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "note": {
                    const result = this.validateNote;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted to the server.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Bolierplate
}

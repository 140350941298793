// Libraries
import { Typography } from "@material-ui/core";
import { styled, useObserver } from "@shoothill/core";
import React, { useEffect, useState } from "react";

// Custom
import { ProjectListViewModel } from "../ProjectListViewModel";

// Styling & Images
import { DarwinPage, DarwinPageTitle, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { theme } from "Globals/Styles/AppTheme";
import { CustomArrow } from "../CustomComponents";
import { ProjectListModelWithAddress } from "../ProjectListModelWithAddress";
import { SHBox } from "../../../Components/Box";
import { uniqueId } from "lodash-es";
import { GlobalHistory } from "../../../index";
import { AppUrls } from "../../../AppUrls";
import { PermitTypesDTO } from "../Construction/Permit/PermitTypesLookupModel";
import { BackButtonHolder } from "../Commercial/IETables/IEGrid.Styles";
interface Props {
    viewModel: ProjectListViewModel;
    projectDetails: ProjectListModelWithAddress;
}
export const SiteTabletPermitView: React.FunctionComponent<Props> = (props: Props) => {
    const { viewModel, projectDetails } = props;
    const [permitTypes, setPermitTypes] = useState<PermitTypesDTO[]>([]);

    useEffect(() => {
        viewModel.apiGetPermitTypes().then((response) => {
            setPermitTypes(response);
        });
        return () => {};
    }, []);

    return useObserver(() => {
        return (
            <DarwinPage>
                <BackButtonHolder
                    onClick={() => {
                        history.back();
                    }}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>Back to menu</div>
                    </div>
                </BackButtonHolder>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        {projectDetails?.reference} - {projectDetails?.name}
                    </Typography>
                    <Typography variant="h2" color="textPrimary">
                        {[projectDetails?.addressLine1, projectDetails?.addressLine2, projectDetails?.city, projectDetails?.postcode].filter(Boolean).join(", ")}
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <SHBox height={"85vh"} width={"103vh"} display={"grid"} dr={`repeat(${permitTypes.map.length}fr)`}>
                        {permitTypes.map((permitType: PermitTypesDTO, index) => {
                            return (
                                <CardView
                                    key={uniqueId()}
                                    number={index}
                                    title={permitType.displayName}
                                    url={AppUrls.Client.Project.Permit.Add.replace(":projectid", projectDetails.id).replace(":permitTypeId", permitType.id)}
                                />
                            );
                        })}
                    </SHBox>
                </DarwinTableToolbar>
            </DarwinPage>
        );
    });
};

const Tile = styled(SHBox)`
    display: grid;
    grid-template-columns: 60px 1fr;
    border: 1px solid #e03c31;
    margin-bottom: 15px;
    border-radius: 10px;
    cursor: pointer;
    //height: 25%;
    .number {
        color: #e03c31;
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        align-self: center;
    }
    .title {
        display: flex;
        align-items: center;
        padding-left: 20px;
        color: white;
        background-color: #e03c31;
        font-size: 56px;
        align-self: center;
        height: 100%;
    }
`;
interface CardProps {
    number: number;
    title: string;
    url: string;
}

const CardView: React.FunctionComponent<CardProps> = (props: CardProps) => {
    const handleClick = () => {
        GlobalHistory.push(props.url);
    };
    return (
        <Tile onClick={handleClick}>
            <SHBox className={"number"}>{props.number}</SHBox>
            <SHBox className={"title"}>{props.title}</SHBox>
        </Tile>
    );
};

import { Box } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../../Globals/Styles/AppTheme";

export const AccountTitleBox: any = styled(Box)`
    text-align: left;
    font: normal normal 600 24px/32px "Open Sans";
    letter-spacing: -0.36px;
    color: ${theme.palette.common.black};
    opacity: 1;

    span {
        display: block;
        width: 100%;
        text-align: right;
    }
`;

export const AccountForm: any = styled.form`
    text-align: left;
    margin: 20px 0px 0px 88px;
    label {
        font-weight: bold;
        font-size: 14px;
    }
    input {
        color: #757474;
        font-size: 12px;
    }
    .MuiLink-root {
        color: #e03c31;
        font-size: 10px;
        text-decoration: underline !important;
    }
`;

import { observable } from "mobx";
import { ModelBase } from "@shoothill/core";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class PurchaseOrderListModel extends ModelBase<PurchaseOrderListModel, PurchaseOrderListModelDTO> {
    @observable
    public id: string = "";

    @observable
    public poNumber: number | null = null;

    @observable
    public formattedPONumber: string | null = null;

    @observable
    public itemDescription: string = "";

    @observable
    public unitPrice: string = "";

    @observable
    public quantity: number = 0;

    @observable
    public committedCost: number = 0;

    @observable
    public availableBalance: number = 0;

    @observable
    public supplierName: string = "";

    @observable
    public raisedBy: string = "";

    @observable
    public status: string = "";

    @observable
    public dateFrom: Date | null = null;

    @observable
    public dateTo: Date | null = null;

    @observable
    public deliveryRequired: boolean = false;

    @observable public query: string = "";

    @observable public createdDate: Date | null = null;

    @observable public dateRequired: Date | null = null;

    @observable public revision: string = "";

    @observable public projectName: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: PurchaseOrderListModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: PurchaseOrderListModel): void {}

    public toTableModel2 = (): PurchaseOrderListTableModel2 => {
        const po: PurchaseOrderListTableModel2 = {
            id: this.id,
            createdDate: this.createdDate,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            poNumberFormatted: this.formattedPONumber ? this.formattedPONumber : "",
            raisedBy: this.raisedBy,
            dateRequired: this.dateRequired,
            supplierName: this.supplierName,
            itemDescription: this.itemDescription,
            query: this.query,
            committedCost: this.committedCost,
            availableBalance: this.availableBalance,
            quantity: this.quantity,
            revision: this.revision,
            projectName: this.projectName,
        };

        return po;
    };
}

export type PurchaseOrderListModelDTO = {
    id: string;
    poNumber: number | null;
    formattedPONumber: string | null;
    itemDescription: string;
    unitPrice: number;
    quantity: number;
    committedCost: number;
    supplierName: string;
    raisedBy: string;
    status: string;
    dateFrom: string;
    dateTo: string;
    dateRequired: Date | null;
    query: string;
    createdDate: Date | null;
    projectName: string;
};

export type PurchaseOrderListTableModel = {
    id: string;
    createdDate: Date | null;
    poNumberFormatted: string;
    originatorName: string;
    poDate: string;
    dateRequired: Date | null;
    supplierName: string;
    quantity: number;
    unitPrice: string;
    total: string;
};

export type PurchaseOrderListTableModel2 = {
    id: string;
    poNumberFormatted: string;
    itemDescription: string;
    committedCost: number;
    availableBalance: number;
    supplierName: string;
    raisedBy: string;
    dateRequired: Date | null;
    query: string;
    createdDate: Date | null;
    quantity: number;
    revision: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    projectName: string;
};

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { LandingViewModel } from "./LandingViewModel";
import { SHBox } from "../../../../Components/Box";
import { DarwinInput } from "../../../../Globals/Styles/Control/DarwinInput";
import styled from "styled-components";
import { DashedDivider } from "../../../PurchaseOrder/Form/Views/Dividers";
import { useParams } from "react-router";
import { TopAccessHeaderView } from "../Common/TopAccessHeader";
import { Circles } from "../../../../Content/Circles";
import { Loader } from "@shoothill/core";
import { InductionViewModel } from "Views/Induction/InductionViewModel";

const SignInBox = styled(SHBox)`
    width: 175px;
    height: 175px;
    background-color: #e03c31;
    border-radius: 10px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        font-size: 18px;
        font-weight: 700;
    }

    p:nth-child(2) {
        font-size: 50px;
        text-transform: uppercase;
    }
`;
const SiteTitle = styled.p`
    font-size: 18px;
`;
type Params = {
    projectId: string;
};

interface Props {
    viewModel: LandingViewModel;
    setIsLoading: (val: boolean) => void;
    parentViewModel: InductionViewModel;
}

export const LandingView: React.FC<Props> = observer((props: Props) => {
    const { projectId } = useParams<Params>();
    useEffect(() => {
        props.viewModel.model.projectId = projectId;
    }, []);

    const handleSignIn = () => {
        props.setIsLoading(true);
        props.viewModel.signIn().finally(() => props.setIsLoading(false));
    };

    const handleSignOut = () => {
        props.setIsLoading(true);
        props.viewModel.signOut().finally(() => props.setIsLoading(false));
    };

    if (props.parentViewModel.isStageLoading) {
        return (
            <>
                <Loader />
            </>
        );
    }

    return (
        <SHBox id={"siteAccess"} pl={3} pr={3} pb={"50px"} flexBox justifyContent={"center"}>
            <SHBox maxWidth={"600px"}>
                <TopAccessHeaderView />
                <SHBox grid dc={"1fr"} mc={"1fr"} justifyItems={"center"}>
                    <SiteTitle>To sign in please add the following</SiteTitle>
                </SHBox>
                <SHBox showIf={props.viewModel.server.HaveValidationMessage}>
                    <p style={{ color: "red" }}>{props.viewModel.server.ValidationMessage}</p>
                </SHBox>
                <SHBox mt={3}>
                    <SHBox width={"100%"}>
                        <DarwinInput<LandingViewModel>
                            label="Your last name:"
                            shrink={true}
                            validateOnBlur={false}
                            viewModel={props.viewModel}
                            fieldName="lastName"
                            placeholder={"Last name"}
                        />
                    </SHBox>
                    <SHBox mt={3} mb={3} width={"50%"}>
                        <DarwinInput<LandingViewModel>
                            label="Last 3 phone digits:"
                            shrink={true}
                            type={"password"}
                            maxLength={3}
                            autoComplete={"off"}
                            validateOnBlur={true}
                            viewModel={props.viewModel}
                            fieldName="lastThreeDigits"
                            placeholder={"Last 3 digits"}
                        />
                    </SHBox>
                    <DashedDivider />
                    <SHBox mt={3}>Please choose an option below to let our system know whether you are signing in or signing out:</SHBox>
                    <SHBox mt={3} grid dc={"1fr 1fr"} mc={"1fr 1fr"} justifyItems={"center"}>
                        <SignInBox onClick={handleSignIn} style={{ backgroundColor: props.viewModel.server.IsBusy ? "gray" : "" }}>
                            <p>I am signing</p>
                            <p>in</p>
                            <p>click to continue</p>
                        </SignInBox>
                        <SignInBox onClick={handleSignOut} style={{ backgroundColor: props.viewModel.server.IsBusy ? "gray" : "" }}>
                            <p>I am signing</p>
                            <p>out</p>
                            <p>click to continue</p>
                        </SignInBox>
                    </SHBox>
                </SHBox>
            </SHBox>
        </SHBox>
    );
});

import { FieldType, isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed, IComputedValue, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ClientViewModel } from "Globals/ViewModels/ClientViewModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { formatCurrencyFromPounds } from "Utils/Format";
import { OrderLineModel } from "./OrderLineModel";
import { CategoryModel } from "../../CategoryModel";
import { LineDescriptionModel } from "../../LineDescriptionModel";
import { MaterialModel } from "../../MaterialModel";
import { SubCategoryModel } from "../../SubCategoryModel";
import { getTotalPrice } from "Utils/Utils";

export class OrderLineViewModel extends ViewModelBase<OrderLineModel> {
    // #region Constructors and Disposers

    constructor(
        activeIEId: IComputedValue<string | null>,
        activeSupplierId: IComputedValue<string | null>,
        isCentral: IComputedValue<boolean>,
        addOrderLineCallbackAsync: (orderLineModel: OrderLineModel) => Promise<boolean>,
    ) {
        super(new OrderLineModel());

        this.activeIEId = activeIEId;
        this.activeSupplierId = activeSupplierId;
        this.isCentral = isCentral;
        this.addOrderLineCallbackAsync = addOrderLineCallbackAsync;

        this.setDecorators(OrderLineModel);
    }

    // #endregion Constructors and Disposers

    // #region: misc stuff from IEItemLine

    @observable
    public fromIEItemLine = false;

    // Category

    @observable
    public categoryIdFromIEItem = "";

    @observable
    public categoryNameFromIEItem = "";

    @action
    public setCategoryFromIEItem = (id: string, name: string | undefined) => {
        this.categoryIdFromIEItem = id;
        this.categoryNameFromIEItem = name != undefined ? name : OrderLineModel.DEFAULT_CATEGORYDISPLAYNAME;
    };

    // Subcategory

    @observable
    public subcategoryIdFromIEItem = "";

    @observable
    public subcategoryNameFromIEItem = "";

    @action
    public setSubcategoryFromIEItem = (id: string, name: string | undefined) => {
        this.subcategoryIdFromIEItem = id;
        this.subcategoryNameFromIEItem = name != undefined ? name : OrderLineModel.DEFAULT_SUBCATEGORYDISPLAYNAME;
    };

    // Line description

    @observable
    public lineDescriptionIdFromIEItem = "";

    @observable
    public lineDescriptionNameFromIEItem = "";

    @action
    public setDescriptionFromIEItem = (id: string, name: string | undefined) => {
        this.lineDescriptionIdFromIEItem = id;
        this.lineDescriptionNameFromIEItem = name != undefined ? name : OrderLineModel.DEFAULT_LINEDESCRIPTIONDISPLAYNAME;
    };

    // #endregion: misc stuff from IEItemLine

    // #region: misc stuff from VariationItemLine

    @observable
    public fromVariationItemLine = false;

    // Category

    @observable
    public categoryIdFromVariationItem = "";

    @observable
    public categoryNameFromVariationItem = "";

    @observable
    public variationIdFromVariationItem = "";

    @action
    public setCategoryFromVariationItem = (id: string, name: string | undefined, variationId: string | undefined) => {
        this.categoryIdFromVariationItem = id;
        this.categoryNameFromVariationItem = name != undefined ? name : OrderLineModel.DEFAULT_CATEGORYDISPLAYNAME;
        this.variationIdFromVariationItem = variationId != undefined && variationId != null ? variationId : "";
    };

    // Subcategory

    @observable
    public subcategoryIdFromVariationItem = "";

    @observable
    public subcategoryNameFromVariationItem = "";

    @action
    public setSubcategoryFromVariationItem = (id: string, name: string | undefined) => {
        this.subcategoryIdFromVariationItem = id;
        this.subcategoryNameFromVariationItem = name != undefined ? name : OrderLineModel.DEFAULT_SUBCATEGORYDISPLAYNAME;
    };

    // Line description

    @observable
    public lineDescriptionIdFromVariationItem = "";

    @observable
    public lineDescriptionNameFromVariationItem = "";

    @action
    public setDescriptionFromVariationItem = (id: string, name: string | undefined) => {
        this.lineDescriptionIdFromVariationItem = id;
        this.lineDescriptionNameFromVariationItem = name != undefined ? name : OrderLineModel.DEFAULT_LINEDESCRIPTIONDISPLAYNAME;
    };

    // #endregion: misc stuff from VariationItemLine

    // #region Properties

    private activeIEId: IComputedValue<string | null>;

    private activeSupplierId: IComputedValue<string | null>;

    private isCentral: IComputedValue<boolean>;

    public client = new ClientViewModel();

    public server = new ServerViewModel();

    /**
     * Determines the configuration of the orderline form.
     */
    @computed
    public get isStockOrderLine() {
        return this.category?.displayName === "Stock";
    }

    // #endregion Properties

    // #region Materials

    @observable
    public materials = observable<MaterialModel>([]);

    @computed
    public get material() {
        return this.materials.find((m) => m.id === this.model.materialId) ?? null;
    }

    @action
    public setMaterial = (value: MaterialModel | null) => {
        this.model.materialId = value?.id ?? OrderLineModel.DEFAULT_MATERIALID;

        // SIDE-EFFECT.
        // Use the item description and unit price to record information
        // about the material selected at this time.
        this.model.itemDescription = value?.displayName ?? OrderLineModel.DEFAULT_ITEMDESCRIPTION;
        this.model.unitPrice = value?.price ?? OrderLineModel.DEFAULT_UNITPRICE;
    };

    @computed
    public get materialsForSupplier(): MaterialModel[] {
        return this.materials.filter((m) => m.supplierId === this.activeSupplierId.get() || m.supplierId === null);
    }

    public setMaterialsAsync = async (supplierId: string) => {
        if (this.materials.findIndex((m) => m.supplierId === supplierId) === -1) {
            return this.loadMaterialsAsync();
        }
    };

    @computed
    private get validateMaterial(): ValidationResponse {
        const errorMessage = this.model.validateMaterialId(this.isStockOrderLine);

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Materials

    // #region Categories

    @observable
    public categories = observable<CategoryModel>([]);

    @computed
    public get category() {
        return this.categories.find((p) => p.id === this.model.categoryId) ?? null;
    }

    @action
    public setCategoryAsync = async (value: CategoryModel | null) => {
        this.model.categoryId = value?.id ?? OrderLineModel.DEFAULT_CATEGORYID;
        this.model.categoryDisplayName = value ? value.displayName : OrderLineModel.DEFAULT_CATEGORYDISPLAYNAME;
        this.model.variationId = value?.variationId ?? OrderLineModel.DEFAULT_VARIATIONID;

        // SIDE-EFFECT.
        // Having set the category, we need to see if we have the sub-categories available
        // locally and if not load them from the server.
        switch (true) {
            case value?.displayName === "Stock":
                await this.setSubCategoriesAsync(value!.id);
                await this.setSubCategoryAsync(this.subCategories.find((sc: any) => sc.displayName === "Stock") ?? null);
                break;

            case !isNullOrUndefined(value):
                await this.setSubCategoriesAsync(value!.id);
                await this.setSubCategoryAsync(OrderLineModel.DEFAULT_SUBCATEGORYID);
                break;

            default:
                await this.setSubCategoryAsync(OrderLineModel.DEFAULT_SUBCATEGORYID);
                break;
        }
    };

    @computed
    private get validateCategory(): ValidationResponse {
        const errorMessage = this.model.validateCategoryId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get categoriesForIEAndVariations(): CategoryModel[] {
        if (this.activeIEId.get()) {
            if (this.isCentral.get()) {
                return this.categories.filter((c) => c.incomeAndExpenditureId === this.activeIEId.get());
            } else {
                return this.categories.filter((c) => c.incomeAndExpenditureId === this.activeIEId.get() || c.variationId !== null);
            }
        }

        return [];
    }

    public setCategoriesAsync = async (incomeAndExpenditureId: string) => {
        if (this.categories.findIndex((cat) => cat.incomeAndExpenditureId === incomeAndExpenditureId) === -1) {
            return this.loadCategoriesAsync();
        }
    };

    // #endregion Categories

    // #region Sub-categories

    @observable
    public subCategories = observable<SubCategoryModel>([]);

    @computed
    public get subCategory() {
        return this.subCategories.find((p) => p.id === this.model.subCategoryId) ?? null;
    }

    @action
    public setSubCategoryAsync = async (value: SubCategoryModel | null) => {
        this.model.subCategoryId = value?.id ?? OrderLineModel.DEFAULT_SUBCATEGORYID;
        this.model.subCategoryDisplayName = value ? value.displayName : OrderLineModel.DEFAULT_SUBCATEGORYDISPLAYNAME;

        // SIDE-EFFECT.
        // Having set the sub-category, we need to see if we have the line description available
        // Locally and if not load from the server.
        switch (true) {
            case value?.displayName === "Stock":
                await this.setLineDescriptionsAsync(value!.id);
                this.setLineDescription(this.lineDescriptions.find((ld: any) => ld.displayName === "Stock") ?? null);
                break;

            case !isNullOrUndefined(value):
                await this.setLineDescriptionsAsync(value!.id);
                this.setLineDescription(OrderLineModel.DEFAULT_LINEDESCRIPTIONID);
                break;

            default:
                this.setLineDescription(OrderLineModel.DEFAULT_LINEDESCRIPTIONID);
                break;
        }
    };

    @computed
    private get validateSubCategory(): ValidationResponse {
        const errorMessage = this.model.validateSubCategoryId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get subCategoriesForCategory(): SubCategoryModel[] {
        return this.subCategories.filter((sc) => sc.categoryId === this.model.categoryId);
    }

    public setSubCategoriesAsync = async (categoryId: string) => {
        if (this.subCategories.findIndex((subCat) => subCat.categoryId === categoryId) === -1) {
            return this.loadSubCategoriesAsync();
        }
    };

    // #endregion Sub-categories

    // #region Line descriptions

    @observable
    public lineDescriptions = observable<LineDescriptionModel>([]);

    @computed
    public get lineDescription() {
        return this.lineDescriptions.find((p) => p.id === this.model.lineDescriptionId) ?? null;
    }

    @action
    public setLineDescription = (value: LineDescriptionModel | null) => {
        this.model.lineDescriptionId = value ? value.id : OrderLineModel.DEFAULT_LINEDESCRIPTIONID;
        this.model.lineDescriptionDisplayName = value ? value.displayName : OrderLineModel.DEFAULT_LINEDESCRIPTIONDISPLAYNAME;
    };

    @computed
    private get validateLineDescription(): ValidationResponse {
        const errorMessage = this.model.validateLineDescriptionId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get lineDescriptionsForSubCategory(): LineDescriptionModel[] {
        return this.lineDescriptions.filter((ld) => ld.subCategoryId === this.model.subCategoryId);
    }

    public setLineDescriptionsAsync = async (subCategoryId: string) => {
        if (this.lineDescriptions.findIndex((ld) => ld.subCategoryId === subCategoryId) === -1) {
            return this.loadLineDescriptionsAsync();
        }
    };

    // #endregion Line descriptions

    // #region Item description

    @computed
    private get validateItemDescription(): ValidationResponse {
        const errorMessage = this.model.validateItemDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Item description

    // #region Units

    @computed
    private get validateUnits(): ValidationResponse {
        const errorMessage = this.model.validateUnits;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Units

    // #region Unit Price

    @computed
    private get validateUnitPrice(): ValidationResponse {
        const errorMessage = this.model.validateUnitPrice;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    public get materialUnitPriceFormatted(): string {
        if (this.isStockOrderLine) {
            return formatCurrencyFromPounds(this.material?.price ?? 0, 2, 4);
        } else {
            return formatCurrencyFromPounds(0);
        }
    }

    // #endregion Unit Price

    // #region Units Total

    @computed
    public get unitsTotal(): number {
        if (this.isStockOrderLine) {
            return getTotalPrice(this.model.units, this.material?.price ?? 0);
        } else {
            return getTotalPrice(this.model.units, this.model.unitPrice);
        }
    }

    @computed
    public get unitsTotalFormatted(): string {
        return formatCurrencyFromPounds(this.unitsTotal);
    }

    // #endregion Units Total

    // #region Client Actions

    @observable
    private addOrderLineCallbackAsync: (orderLineModel: OrderLineModel) => Promise<boolean>;

    @action
    public add = () => {
        this.client.command(
            () => {
                return true;
            },
            async () => {
                let noMatches = await this.addOrderLineCallbackAsync(this.model);

                if (!noMatches) {
                    // Reset the state ready for next time. However we need to
                    // account for stock.
                    this.reset(this.category?.displayName !== "Stock" ?? true);
                }
            },
            this.isModelValid,
            "There was an error trying to add the order line",
        );
    };

    @action
    public reset = (fullReset: boolean) => {
        this.model.reset(fullReset);
        this.client.reset();

        this.fromIEItemLine = false;
        this.fromVariationItemLine = false;
    };

    // #endregion Client Actions

    // #region Server Actions

    public loadMaterialsAsync = async () => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.GetMaterialsBySupplierId}\\${this.activeSupplierId}`),
            (result: any) => {
                runInAction(() => {
                    this.materials.push(...MaterialModel.fromDtos(result));
                });
            },
        );
    };

    public loadCategoriesAsync = async () => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.GetIECategoryByIEId}\\${this.activeIEId}`),
            (result: any) => {
                runInAction(() => {
                    this.categories.push(...CategoryModel.fromDtos(result));
                });
            },
        );
    };

    public loadSubCategoriesAsync = async () => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.GetIESubcategoryByCatId}\\${this.model.categoryId}`),
            (result: any) => {
                runInAction(() => {
                    this.subCategories.push(...SubCategoryModel.fromDtos(result));
                });
            },
        );
    };

    public loadLineDescriptionsAsync = async () => {
        return this.server.query<any>(
            () => this.Get(`${AppUrls.Server.PurchaseOrder.GetIELineItemsBySubcategoryId}\\${this.model.subCategoryId}`),
            (result: any) => {
                runInAction(() => {
                    this.lineDescriptions.push(...LineDescriptionModel.fromDtos(result));
                });
            },
        );
    };

    // #endregion Server Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<OrderLineModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.client.IsSubmitted) {
            // Process the properties of the model that cannot be supported via
            // the use of decorators.
            switch (fieldName) {
                case "categoryId": {
                    const result = this.validateCategory;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "subCategoryId": {
                    const result = this.validateSubCategory;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "lineDescriptionId": {
                    const result = this.validateLineDescription;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "itemDescription": {
                    const result = this.validateItemDescription;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "units": {
                    const result = this.validateUnits;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "unitPrice": {
                    const result = this.validateUnitPrice;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "materialId": {
                    const result = this.validateMaterial;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

import { Stores } from "./Globals/Stores";

export class GlobalAppUrls {
    // CMR NEVER USE :id  make sure values are unique.
    public Client = {
        Account: {
            ForgotPassword: "/forgotpassword",
            ForgotPasswordSuccess: "/forgotpassword-success",
            Login: "/login",
            Logout: "/logout",
            ResetPassword: "/resetpassword",
            SessionExpired: "/sessionexpired",
        },

        Error: "/error",
        Home: "/",
        NotFound: "/notfound",
        PrivacyPolicy: "/privacy-policy",
        Terms: "/terms",
        // Test: "/test",

        Admin: {
            Root: "/admin",
            User: {
                Add: "/admin/user/add",
                Detail: "/admin/user/:userid",
                Edit: "/admin/user/:userid/edit",
                List: "/admin/user",
            },
            Client: {
                Add: "/admin/client/add",
                Detail: "/admin/client/:clientid",
                Edit: "/admin/client/:clientid/edit",
                List: "/admin/client",
            },
        },
        Approval: {
            List: "/approval",
            MyAmends: "/approval/myamends",
        },
        Central: {
            View: "/central/view/:ieid",
        },
        Induction: {
            List: "/inductionadminlist",
            View: "/inductionadmin/:inductionid/view",
            Index: "/induction/*",
            Landing: "/induction/landing",
            Step1: "/induction/step/1",
            Step2: "/induction/step/2",
            Step3: "/induction/step/3",
            Step4: "/induction/step/4",
            Step5: "/induction/step/5",
            Step6: "/induction/step/6",
            Submitted: "/induction/submitted/7",
            Access: {
                Index: "/induction/access/*",
                Landing: "/induction/access/landing/:projectId",
                SignedIn: "/induction/access/signedin/",
                Rams: "/induction/access/rams/:projectId",
                QR: "/induction/access/qr",
            },
        },
        Invoicing: {
            Add: "/invoice/add",
            AddIE: "/invoice/add/:ieid",
            Detail: "/invoice/:invoiceid",
            Edit: "/invoice/edit/:invoiceid/:approvalMode",
            EditIE: "/invoice/:ieid/edit/:invoiceid",
            List: "/invoice",
        },
        Project: {
            List: "/project",
            General: "/project/general/:projectid",
            GeneralAdd: "/project/add/general/",
            GeneralEdit: "/project/edit/general/:projectid",
            Commercial: "/project/commercial/:projectid",
            CommercialIEAdd: "/project/commercial/:projectid/add",
            CommercialIEEdit: "/project/commercial/:projectid/edit/:id",
            IE: "/project/ie/:ieid",
            Contractual: "/project/contractual/:projectid",
            ContractualEdit: "/project/edit/contractual/:projectid",
            Construction: "/project/construction/:projectid/main",
            ConstructionHAndS: "/project/construction/:projectid/hands",
            ConstructionQuality: "/project/construction/:projectid/quality",
            ConstructionEdit: "/project/edit/construction/:projectid",
            Delivery: "/project/delivery/:projectid",
            SiteAccess: "/project/siteaccess/:projectid",
            ProjectTrackers: "/project/projecttrackers/:projectid/main",
            DesignData: "/project/projecttrackers/:projectid/rdd",
            RequestForInformation: "/project/projecttrackers/:projectid/rfi",
            QR: "/project/qr/:projectId/:displayName/:bitly",
            // Completed: "/project/completed/:projectid",
            IEItemFutureSpendAmendment: "/project/ie/:ieid/futurespendamendment/:ieitemid",
            IEItemRiskOpp: "/project/ie/:ieid/riskandopportunity/:ieitemid",
            VariationItemFutureSpendAmendment: "/project/ie/:ieid/variationfuturespendamendment/:variationid/:variationitemid",
            VariationItemRiskOpp: "/project/ie/:ieid/variationriskandopportunity/:variationid/:variationitemid",
            SiteTablet: {
                Menu: "/project/sitetablet/menu",
                Permit: "/project/sitetablet/permit",
                Inspection: "/project/sitetablet/inspection",
                NearMiss: "/project/sitetablet/nearmiss",
                Scaffold: "/project/sitetablet/scaffold",
            },
            Scaffolding: {
                Add: "/project/construction/:projectid/scaffolding/add",
                View: "/project/construction/:projectid/scaffolding/view/:scaffoldingId",
            },
            Permit: {
                Add: "/project/construction/:projectid/permit/add/:permitTypeId",
                View: "/project/construction/:projectid/permit/view/:permitId",
            },
            NonConformance: {
                Add: "/project/construction/:projectid/nonconformance/add/:nonConformanceTypeId",
                View: "/project/construction/:projectid/nonconformance/view/:nonConformanceId",
                HouseKeeping: {
                    Add: "/project/construction/:projectid/nonconformance/addHouseKeeping/:nonConformanceTypeId",
                    View: "/project/construction/:projectid/nonconformance/viewHouseKeeping/:nonConformanceId",
                },
                Discipline: {
                    Add: "/project/construction/:projectid/nonconformance/addDiscipline/:nonConformanceTypeId",
                    View: "/project/construction/:projectid/nonconformance/viewDiscipline/:nonConformanceId",
                },
                Quality: {
                    Add: "/project/construction/:projectid/nonconformance/addQuality/:nonConformanceTypeId",
                    View: "/project/construction/:projectid/nonconformance/viewQuality/:nonConformanceId",
                },
                Environment: {
                    Add: "/project/construction/:projectid/nonconformance/addEnvironment/:nonConformanceTypeId",
                    View: "/project/construction/:projectid/nonconformance/viewEnvironment/:nonConformanceId",
                },
            },
            Dilapidation: {
                Add: "/project/construction/:projectid/dilapidation/add/",
                View: "/project/construction/:projectid/dilapidation/view/:dilapidationReportId",
            },
            RAMSReview: {
                Add: "/project/construction/:projectid/ramsreview/add/",
                View: "/project/construction/:projectid/ramsreview/view/:ramsId",
            },
            Inspection: {
                Add: "/project/construction/:projectid/inspection/add/:inspectionTypeId",
            },
            Incident: {
                //Add: "/project/construction/:projectid/incident/add/:incidentTypeId",
                View: "/project/construction/:projectid/incident/view/:incidentid",
                NearMiss: {
                    Add: "/project/construction/:projectid/incident/:incidentid/nearmiss/add",
                    View: "/project/construction/:projectid/incident/:incidentid/nearmiss/view/:incidentnearmissid",
                },
                WitnessStatement: {
                    Add: "/project/construction/:projectid/incident/:incidentid/witnessstatement/add",
                    View: "/project/construction/:projectid/incident/:incidentid/witnessstatement/view/:incidentwitnessstatementid",
                },
                Accident: {
                    Add: "/project/construction/:projectid/accident/:incidentid/accident/add",
                    View: "/project/construction/:projectid/accident/:incidentid/accident/view/:incidentaccidentid",
                },
            },
            ReviewableDesignData: {
                Add: "/project/projecttrackers/:projectid/designdata/add/:isdarwinrdd",
                AddWithReference: "/project/projecttrackers/:projectid/designdata/add/:isdarwinrdd/:ref",
                AddWithRevision: "/project/projecttrackers/:projectid/designdata/view/:designdataid/:isdarwinrdd/:isrevision",
                View: "/project/projecttrackers/:projectid/designdata/view/:designdataid/:isdarwinrdd",
            },
            RFI: {
                Add: "/project/projecttrackers/:projectid/rfi/add",
                AddWithReference: "/project/projecttrackers/:projectid/rfi/add/:ref",
                AddWithRevision: "/project/projecttrackers/:projectid/rfi/view/:rfiid/:isrevision",
                View: "/project/projecttrackers/:projectid/rfi/view/:rfiid",
            },
            ToolboxTalk: {
                Add: "/project/construction/:projectid/toolboxTalk/add",
                Edit: "/project/construction/:projectid/toolboxTalk/edit/:toolboxtalkid",
                View: "/project/construction/:projectid#toolbox",
            },
            ProgrammeUpdates: {
                BuildingControlSiteVisit: {
                    Add: "/project/construction/:projectid/programmeUpdates/buildingcontrolsitevisit/add/:date",
                },
            },
        },
        PurchaseOrder: {
            Add: "/po/add",
            AddIE: "/po/add/:projectid/:ie",
            Edit: "/po/:poid/edit/:approvalMode",
            View: "/po/:poid/view",
            List: "/po",
            RequisitionList: "/po/requisition",
            RejectedList: "/po/rejected",
        },
        Report: {
            //List: "/report",
            AllProject: "/report/allproject",
            Project: "/report/project",
            IE: "/report/ie",
        },
        Stock: {
            List: "/stock",
            MaterialList: "/stock/material",
            MaterialAdd: "/stock/material/add",
        },
        Supplier: {
            Add: "/supplier/add",
            Detail: "/supplier/:supplierid",
            Edit: "/supplier/:supplierid/edit",
            List: "/supplier",
        },
        Variation: {
            Add: "/variation/add",
            AddIE: "/variation/add/:ieid",
            Edit: "/variation/:variationid/edit/:approvalMode",
            View: "/variation/:variationid/view",
        },
    };

    public Server = {
        Account: {
            Login: "/api/account/login",
            Logout: "/api/account/logout",
            ResetPassword: {
                Reset: "/api/account/resetpassword",
                VerifyToken: "/api/account/resetpasswordtoken",
            },

            ForgotPassword: "/api/account/forgotpassword",
            Register: "/api/account/register",
            SetPassword: "/api/account/setpassword",
        },
        Admin: {
            ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
            User: {
                GetAll: "/api/user/GetAll",
                GetDetails: "/api/user/GetUserDetails",
                GetUserRoles: "/api/user/GetUserRoles",
                Upsert: "/api/user/Upsert",
                IsEmailInUse: "/api/user/IsEmailInUse",
            },
        },
        Approval: {
            DismissApprovalDelegate: "/api/approval/DismissApprovalDelegate",
            GetApprovalDelegateData: "/api/approval/GetApprovalDelegateAndRelatedByUserId",
            UpsertApprovalDelegation: "/api/approval/UpsertApprovalDelegation",
            SetDelegationsAsNotified: "/api/approval/SetDelegationsAsNotified",
            DeleteDelegations: "/api/approval/DeleteDelegations",
            GetRequisitionPOStatusCounts: "/api/approval/GetRequisitionPOStatusCounts",
            GetApprovalCounts: "/api/approval/GetApprovalCounts",
            SearchMyApprovalsNew: "/api/approval/SearchMyApprovalsNew",
            SearchMyApprovalsActioned: "/api/approval/SearchMyApprovalsActioned",
            SearchMyAmends: "/api/approval/SearchMyAmends",
            GetAllRequisitionPOStatuses: "/api/approval/GetAllRequisitionPOStatuses",
            UpsertPurchaseOrderApprovalStatus: "/api/approval/UpsertPurchaseOrderApprovalStatus",
            UpsertVariationApprovalStatus: "/api/approval/UpsertVariationApprovalStatus",
            UpsertInvoiceApprovalStatus: "/api/approval/UpsertInvoiceApprovalStatus",
        },
        Central: {
            GetLatestCentralProjectView: "/api/project/GetLatestCentralProjectView",
            GetCentralProjectViewByProjectId: "/api/project/GetCentralProjectViewByProjectId",
        },
        Client: {
            GetAll: "/api/client/GetAll",
            GetDetails: "/api/client/GetClientDetails",
            Upsert: "/api/client/Upsert",
            GetClientContact: "/api/client/GetClientContact",
            UpsertContact: "/api/client/UpsertContact",
            DeleteContact: "/api/client/DeleteClientContact",
            UpdateClientContactIsPrimary: "/api/client/UpdateClientContactIsPrimary",
            IsReferenceInUse: "/api/client/IsReferenceInUse",
            GetClientNameAndId: "/api/client/GetClientNameAndId",
        },
        Invoice: {
            Upsert: "/api/invoice/UpsertInvoice",
            UpdateInvoiceDate: "/api/invoice/UpdateInvoiceDate",
            UpsertMatch: "/api/invoice/UpsertInvoiceMatch",
            UpsertMatchDraft: "/api/invoice/UpsertInvoiceMatchDraft",
            DisputeInvoice: "/api/invoice/DisputeInvoice",
            UpdateDisputedInvoiceStatusCode: "/api/invoice/UpdateInvoiceDisputedStatusCode",
            GetAllInvoiceRelated: "/api/invoice/GetAllInvoiceRelated",
            GetDateRelatedInvoice: "/api/invoice/GetDateRelatedInvoice",
            IsInvoiceNumberValid: "/api/invoice/IsInvoiceNumberValid",
            Load: {
                Related: "/api/invoice/GetRelated",
                WithRelatedById: "/api/invoice/GetWithRelatedById",
                GetFilteredPurchaseOrders: "/api/invoice/GetFilteredPurchaseOrders",
                GetFilteredProjects: "/api/invoice/GetFilteredProjects",
                InvoiceMatchById: "/api/invoice/GetInvoiceMatchById",
                InsertInvoicePurchaseOrder: "/api/invoice/InsertInvoicePurchaseOrder",
                DeleteInvoicePurchaseOrder: "/api/invoice/DeleteInvoicePurchaseOrder",
            },
            UpdateInvoiceStatus: "/api/invoice/UpdateInvoiceStatus",
            SetCanSendInvoiceEmailToSupplier: "/api/invoice/SetCanSendInvoiceEmailToSupplier",
        },
        Induction: {
            GetRelated: "/api/induction/GetRelated",
            GetRAMSsByProjectId: "/api/induction/GetRAMSByProjectId/:projectId",
            GetRAMSAttachmentsByRAMSId: "/api/induction/GetRAMSAttachmentsByRAMSId/:ramsId",
            SignIn: "/api/induction/SignInductionUserIn",
            SignOut: "/api/induction/SignInductionUserOut",
            GetInductionItemsRelated: "/api/induction/GetInductionItemsRelated",
            GetInductionDetailsById: "/api/induction/GetInductionDetailsById",
            Submit: "/api/induction/Submit",
            Delete: "/api/induction/DeleteInduction",
            Approve: "/api/induction/ApproveInduction",
            UpdateInductionStatus: "/api/induction/UpdateInductionStatus",
            GetInductionSiteAccessByInductionUserId: "/api/induction/GetSiteAccess",
            GenerateSiteAccessCSV: "/api/induction/GenerateSiteAccessCSV",
        },
        Roles: {
            GetAll: "/api/roles/getAll",
        },
        Projects: {
            Construction: {
                GetByProjectId: "/api/project/GetProjectConstruction",
                Upsert: "/api/project/UpsertProjectConstruction",
                Permit: {
                    Load: {
                        Related: "/api/permit/GetRelated",
                        WithRelatedById: "/api/permit/GetWithRelatedById",
                    },
                    Upsert: "/api/permit/Upsert",
                    LoadPermitsAndRelated: "/api/permit/GetPermitsAndRelated",
                },

                Inspections: {
                    LoadInspectionsAndRelated: "/api/project/GetProjectInspectionsAndRelated",
                    LoadQuestions: "/api/project/GetQuestionsByInspectionType",
                    LoadInspection: "/api/project/GetInspectionById",
                    UpsertInspection: "/api/project/UpsertInspection",
                },
                Audits: {
                    GetAuditQuestions: "/api/project/GetAuditQuestionsByAuditType",
                    GetEndOfDayAuditById: "/api/project/GetEndOfDayAuditById",
                    GetEndOfWeekAuditById: "/api/project/GetEndOfWeekAuditById",
                    GetAuditStatus: "/api/project/GetAuditStatus",
                    UpsertEndOfDayAudit: "/api/project/UpsertEndOfDayAudit",
                    UpsertEndOfWeekAudit: "/api/project/UpsertEndOfWeekAudit",
                    GetEndOfWeekAuditAndRelated: "/api/project/GetEndOfWeekAuditAndRelated",
                },
                ScaffoldInspection: {
                    GetProjectScaffoldList: "/api/project/GetProjectScaffoldList",
                    GetProjectScaffoldRelated: "/api/project/GetProjectScaffoldRelated",
                    GetProjectScaffoldWithRelated: "/api/project/GetProjectScaffoldWithRelated",
                    UpsertProjectScaffoldInspection: "/api/project/UpsertProjectScaffoldInspection",
                },
                NonConformance: {
                    GetNonConformanceAndRelated: "/api/nonconformance/GetNonConformanceAndRelated",
                    Load: {
                        Related: "/api/safety/GetRelated",
                        WithRelatedById: "/api/safety/GetWithRelatedById",
                    },
                    Upsert: "/api/safety/Upsert",
                    Housekeeping: {
                        Related: "/api/housekeeping/GetRelated",
                        WithRelatedById: "/api/housekeeping/GetWithRelatedById",
                        Upsert: "/api/housekeeping/Upsert",
                    },
                    Quality: {
                        Related: "/api/quality/GetRelated",
                        WithRelatedById: "/api/quality/GetWithRelatedById",
                        Upsert: "/api/quality/Upsert",
                    },
                    Environment: {
                        Related: "/api/environment/GetRelated",
                        WithRelatedById: "/api/environment/GetWithRelatedById",
                        Upsert: "/api/environment/Upsert",
                    },
                    Discipline: {
                        Related: "/api/discipline/GetRelated",
                        WithRelatedById: "/api/discipline/GetWithRelatedById",
                        Upsert: "/api/discipline/Upsert",
                    },
                },
                ProgrammeUpdates: {
                    GetProgrammeUpdatesAndRelated: "/api/project/GetProgrammeUpdatesAndRelated",
                    UpsertBuildingControl: "/api/project/UpsertBuildingControl",
                    GetBuildingControlSiteVisitAndRelatedByProjectId: "/api/project/GetBuildingControlSiteVisitAndRelatedByProjectId",
                    CleanlinessReport: {
                        UpsertCleanlinessReport: "/api/project/UpsertCleanlinessReport",
                        GetCleanlinessReportAndRelatedById: "/api/project/GetCleanlinessReportAndRelatedById",
                    },
                    WeatherReport: {
                        UpsertCustomerVisitsSiteInstructions: "/api/project/UpsertWeatherReport",
                        GetCustomerVisitsSiteInstructionsAndRelatedByProjectId: "/api/project/GetWeatherReportAndRelatedByProjectIdAndDate",
                    },
                    CustomerVisitsSiteInstructions: {
                        UpsertCustomerVisitsSiteInstructions: "/api/project/UpsertCustomerVisitsSiteInstructions",
                        GetCustomerVisitsSiteInstructionsAndRelatedByProjectId: "/api/project/GetCustomerVisitsSiteInstructionsAndRelatedByProjectId",
                    },
                    DailyProgrammeProgressUpdate: {
                        UpsertDailyProgrammeProgressUpdate: "/api/project/UpsertDailyProgrammeProgressUpdate",
                        GetDailyProgrammeProgressUpdateAndRelatedByProjectId: "/api/project/GetDailyProgrammeProgressUpdateAndRelatedByProjectId",
                    },
                    OtherProgrammeNotes: {
                        UpsertOtherProgrammeNotes: "/api/project/UpsertOtherProgrammeNotes",
                        GetOtherProgrammeNotesAndRelatedByProjectId: "/api/project/GetOtherProgrammeNotesAndRelatedByProjectId",
                    },
                    PotentialProgrammeDelays: {
                        GetPotentialProgrammeDelaysAndRelatedById: "/api/project/GetPotentialProgrammeDelaysAndRelatedById",
                        UpsertPotentialProgrammeDelays: "/api/project/UpsertPotentialProgrammeDelays",
                    },
                    OutstandingDesignInformation: {
                        GetOutstandingDesignInformationAndRelatedById: "/api/project/GetOutstandingDesignInformationAndRelatedById",
                        UpsertOutstandingDesignInformation: "/api/project/UpsertOutstandingDesignInformation",
                    },
                    MaterialStorage: {
                        GetMaterialStorageAndRelatedById: "/api/project/GetMaterialStorageAndRelatedById",
                        UpsertMaterialStorage: "/api/project/UpsertMaterialStorage",
                    },
                    LabourShortage: {
                        GetLabourShortageAndRelatedById: "/api/project/GetLabourShortageAndRelatedById",
                        UpsertLabourShortage: "/api/project/UpsertLabourShortage",
                    },
                },
                Dilapidation: {
                    GetDilapidationList: "/api/dilapidation/GetDilapidationList",
                    GetDilapidationAndRelated: "/api/dilapidation/GetDilapidationAndRelated",
                    GetDilapidationRelated: "/api/dilapidation/GetDilapidationRelated",
                    Upsert: "/api/dilapidation/Upsert",
                },
                Rams: {
                    GetRAMSAndRelated: "/api/rams/GetRamsAndRelated",
                    GetRAMSAndRelatedById: "/api/rams/GetRamsWithRelatedById",
                    GetRAMSRelated: "/api/rams/GetRamsRelated",
                    UpsertRAMSForm: "/api/rams/upsert",
                },
                Incident: {
                    Load: {
                        WithRelatedById: "/api/incident/GetWithRelatedById",
                    },
                    Upsert: "/api/incident/Upsert",
                    LoadIncidentsAndRelated: "/api/incident/GetIncidentsAndRelated",
                    NearMiss: {
                        Load: {
                            Related: "/api/incident/GetNearMissRelated",
                            WithRelatedById: "/api/incident/GetNearMissWithRelatedById",
                        },
                        Upsert: "/api/incident/UpsertIncidentFormNearMiss",
                    },
                    WitnessStatement: {
                        Load: {
                            Related: "/api/incident/GetWitnessStatementRelated",
                            WithRelatedById: "/api/incident/GetWitnessStatementWithRelatedById",
                        },
                        Upsert: "/api/incident/UpsertIncidentFormWitnessStatement",
                    },
                    Accident: {
                        Load: {
                            Related: "/api/incident/GetAccidentRelated",
                            WithRelatedById: "/api/incident/GetAccidentWithRelatedById",
                            GetCasualtyUserDetails: "/api/incident/GetIncidentAccidentCasualtyUserDetails",
                        },
                        Upsert: "/api/incident/UpsertIncidentFormAccident",
                    },
                },
                ToolboxTalk: {
                    Load: {
                        Related: "/api/toolboxtalk/GetToolboxTalkRelated",
                        WithRelatedById: "/api/toolboxtalk/GetWithRelatedById",
                    },
                    Upsert: "/api/toolboxtalk/Upsert",
                    LoadToolboxTalksAndRelated: "/api/toolboxtalk/GetToolboxTalksAndRelated",
                },
                DailyDiary: {
                    Load: "/api/project/GetDailyDiary",
                    Upsert: "/api/project/UpsertDailyDiary",
                },
            },
            Contractual: {
                GetByProjectId: "/api/project/GetProjectContractual",
                Upsert: "/api/project/UpsertProjectContractual",
            },
            ProjectTrackers: {
                DesignData: {
                    ExportRDDList: "/api/designdata/DesignDataListCSV",
                    GetDesignDataList: "/api/designdata/GetDesignDataList",
                    GetDesignDataAndRelated: "/api/designdata/GetDesignDataAndRelated",
                    GetDesignDataRelated: "/api/designdata/GetDesignDataRelated",
                    Upsert: "/api/designdata/Upsert",
                    UpdateStatus: "/api/designdata/UpdateStatus",
                    GetDesignDataResponseById: "/api/designdata/GetDesignDataResponseById",
                    UpsertDesignDataResponse: "/api/designdata/UpsertDesignDataResponse",
                    GetDesignDataItemsById: "/api/designdata/GetDesignDataItemsById",
                    GetDesignDataFilesById: "/api/designdata/GetDesignDataFilesById",
                },
                RFI: {
                    ExportRFIList: "/api/rfi/RFIListCSV",
                    GetRFIList: "/api/rfi/GetRFIList",
                    GetRFIAndRelated: "/api/rfi/GetRFIAndRelated",
                    GetRFIRelated: "/api/rfi/GetRFIRelated",
                    Upsert: "/api/rfi/Upsert",
                    UpdateStatus: "/api/rfi/UpdateStatus",
                    GetRFIResponseById: "/api/rfi/GetRFIResponseById",
                    UpsertRFIResponse: "/api/rfi/UpsertRFIResponse",
                    GetRFIItemsById: "/api/rfi/GetRFIItemsById",
                    GetRFIFilesById: "/api/rfi/GetRFIFilesById",
                    Create: "/api/rfi/Create",
                },
            },
            SiteAccess: {
                GetProjectSiteAccessById: "/api/induction/GetProjectSiteAccess",
                ForceInductionsSignOut: "/api/induction/ForceInductionsSignOut",
                GenerateProjectSiteAccessCSV: "/api/induction/GenerateProjectSiteAccessCSV",
                UpdateSiteVideoLink: "/api/induction/UpdateSiteVideoLink",
            },
            GetAll: "/api/project/GetAll",
            GetAllUsers: "/api/project/GetAllUsers",
            GetAllAndRelated: "/api/project/GetAllAndRelated",
            GetProjectListItems: "/api/project/GetProjectListItems",
            GetDetails: "/api/project/GetProjectDetails",
            GetProjectDetailsByUserId: "/api/project/GetUserDetailsWithProject",
            GetProjectInspectionTypes: "/api/project/GetProjectInspectionTypes",
            GetPermitTypes: "/api/permit/getpermittypes",
            GetRelated: "/api/project/GetProjectRelated",
            Commercial: {
                GetIECommercial: "/api/ie/GetIECommercialView",
            },
            IncomeExpend: {
                DeleteIE: "/api/ie/DeleteIE",
                GetDeleteIE: "/api/ie/GetDeleteIE",
                GetEditIERelated: "/api/ie/GetEditIERelated",
                GetEditIEAndRelated: "/api/ie/GetEditIEAndRelated",
                UpsertIE: "/api/ie/UpsertIE",
                GetCategories: "/api/ie/GetAllProjectCategories",
                GetIEandRelated: "/api/ie/GetIEandRelated",
                GetIEView: "/api/ie/GetIEView",
                GenerateIEViewCSV: "/api/ie/GenerateIEViewCSV",
                UpsertIECategory: "/api/ie/UpsertIECategory",
                UpsertIESubCategory: "/api/ie/UpsertIESubcategory",
                UpsertIELine: "/api/ie/UpsertIEItemLine",
                DeleteIELine: "/api/ie/DeleteIEItemLine",
                UpsertItem: "/api/ie/UpsertItem",
                GetRequisitionsList: "/api/ie/GetRequisitionsList",
                GetPurchaseOrderList: "/api/ie/GetPurchaseOrderList",
                GetIEFutureSpendAmendmentAndRelated: "/api/ie/GetIEFutureSpendAmendmentAndRelated",
                UpsertFutureSpendAmendment: "/api/ie/UpsertFutureSpendAmendment",
                GetIERiskOppAndRelated: "/api/ie/GetIERiskOppAndRelated",
                UpsertRiskOpp: "/api/ie/UpsertriskOpp",
            },

            Upsert: "/api/project/Upsert",
            IsReferenceInUse: "/api/project/IsReferenceInUse",
            UploadDocument: "/api/project/UploadDocument",
            UploadProjectNote: "/api/project/UploadProjectNote",
        },
        Stock: {
            DownloadStockViews: "/api/stock/downloadstockviews",
            GetStockForTransferWithRelatedByMaterialId: "/api/stock/getstockfortransferwithrelatedbymaterialid/{materialid}",
            GetStockViews: "/api/stock/getstockviews",
            GetStockHistoryViewsByMaterialId: "api/stock/getstockhistoryviewsbymaterialid/{materialid}",
            GetStockTransferHistoryViewsByIEItemMaterialId: "api/stock/getstocktransferhistoryviewsbyieitemmaterialid/{ieItemMaterialId}",

            GetUnitsAndCostsForAdjustment: "/api/stock/getunitsandcostsforadjustment/{materialid}/{units}",
            GetUnitsAndCostsForTransfer: "/api/stock/getunitsandcostsfortransfer/{materialid}/{units}/{transactiontypeid}/{ieitemid}",

            GetIncomeAndExpendituresByProjectId: "/api/stock/getincomeandexpendituresbyprojectid/{projectid}",
            GetCategoriesByIncomeAndExpenditureId: "/api/stock/getcategoriesbyincomeandexpenditureid/{incomeandexpenditureid}",
            GetSubCategoriesByCategoryId: "/api/stock/getsubcategoriesbycategoryid/{categoryid}",
            GetLineDescriptionsBySubCategoryId: "/api/stock/getlinedescriptionsbysubcategoryid/{subcategoryid}",

            RequestAdjustStock: "/api/stock/requestadjuststock/{materialid}",
            RequestTransferStock: "api/stock/requesttransferstock/{materialid}",

            GetStockTransactionRequestViewByStockTransactionRequestId: "/api/stock/getstocktransactionrequestviewbystocktransactionrequestid/{stocktransactionrequestid}",
            AdjustStock: "/api/stock/adjuststock/{stocktransactionrequestid}",
            TransferStock: "/api/stock/transferstock/{stocktransactionrequestid}",

            Material: {
                DownloadMaterialViews: "/api/material/downloadmaterialviews",
                GetMaterialDetailsWithRelatedByMaterialId: "/api/material/getmaterialdetailswithrelatedbymaterialid/{materialid}",
                GetMaterialPriceDetailsViewsByMaterialId: "/api/material/getmaterialpricedetailsviewsbymaterialid/{materialid}",
                GetMaterialPriceDetailsWithRelatedByMaterialId: "/api/material/getmaterialpricedetailswithrelatedbymaterialid/{materialid}",
                GetMaterialRelated: "/api/material/getmaterialrelated",
                GetMaterialViews: "/api/material/getmaterialviews",

                RequestCreateMaterial: "/api/material/requestcreatematerial",
                RequestUpdateMaterialPriceDetails: "/api/material/requestupdatematerialpricedetails/{materialid}",

                UpdateMaterialDetails: "/api/material/updatematerialdetails/{materialid}",

                GetMaterialTransactionRequestViewByMaterialTransactionRequestId:
                    "/api/material/getmaterialtransactionrequestviewbymaterialtransactionrequestid/{materialtransactionrequestid}",
                CreateMaterial: "/api/material/creatematerial/{materialtransactionrequestid}",
                UpdateMaterialPriceDetails: "/api/material/updatematerialpricedetails/{materialtransactionrequestid}",
            },
        },
        Supplier: {
            GetAll: "/api/supplier/GetAll",
            GetDetails: "/api/supplier/GetSupplierDetails",
            GetContactType: "/api/supplier/GetContactType",
            Upsert: "/api/supplier/Upsert",
            GetSupplierContact: "/api/supplier/GetSupplierContact",
            UpsertContact: "/api/supplier/UpsertContact",
            DeleteContact: "/api/supplier/DeleteSupplierContact",
            UpdateSupplierContactIsPrimary: "/api/supplier/UpdateSupplierContactIsPrimary",
            IsReferenceInUse: "/api/supplier/IsReferenceInUse",
            UpdateSupplierStatus: "/api/supplier/UpdateSupplierStatus",
        },
        PurchaseOrder: {
            GetAll: "/api/purchaseorder/GetAll",
            GetAllWithFilters: "/api/purchaseorder/GetAllWithFilters",
            CanVoidOrderLine: "/api/requisitionrequest/CanVoidOrderLine/{orderLineId}",
            GetAllRequisitionList: "/api/requisitionrequest/GetAllRequisitionList",
            GetSupplierDetailBySupplierId: "/api/requisitionrequest/GetSupplierDetailBySupplierId",
            GetAddressesByProjectId: "/api/requisitionrequest/GetAddressesByProjectId",
            GetIEByProjectId: "/api/requisitionrequest/GetIEByProjectId",
            GetVariationsByIEId: "/api/requisitionrequest/GetVariationsByIEId",
            GetIECategoryByIEId: "/api/requisitionrequest/GetIECategoryByIEId",
            GetIESubcategoryByCatId: "/api/requisitionrequest/GetIESubcategoryByCatId",
            GetIELineItemsBySubcategoryId: "/api/requisitionrequest/GetIELineItemsBySubcategoryId",
            GetMaterialsBySupplierId: "/api/requisitionrequest/GetMaterialsBySupplierId",
            GetBudgetCosts: "/api/requisitionrequest/GetBudgetCosts",
            Load: {
                Related: "/api/requisitionrequest/GetRelated",
                WithRelatedById: "/api/requisitionrequest/GetWithRelatedById",
            },
            Upsert: "/api/requisitionrequest/UpsertRequisition",
            AmendRequisition: "/api/requisitionrequest/AmendRequisition",
            ReviseRequisition: "/api/requisitionrequest/ReviseRequisition",
            Unreject: "/api/requisitionrequest/UnrejectRequisition",
            GeneratePOPDFById: "/api/exportpdf/GeneratePOPDFById",
            GeneratePOPDFPreviewById: "/api/exportpdf/GeneratePOPDFPreviewById",
            GeneratePOPDFByIdAndRevision: "/api/exportpdf/GeneratePOPDFByIdAndRevision",
            GetLineIdMatchingExistingRequisition: "/api/requisitionrequest/GetLineIdMatchingExistingRequisition",
            SendSupplierEmail: "/api/requisitionrequest/SendPOApprovedEmail",
            ValidateRequisitionDocuments: "/api/requisitionrequest/ValidateRequisitionDocuments",
        },
        Variation: {
            Upsert: "/api/variation/UpsertVariation",
            Delete: "/api/variation/DeleteVariation",
            GetVariationList: "/api/variation/GetVariationList",
            GetApprovedVariationList: "/api/variation/GetApprovedVariationList",
            GetVarianceView: "/api/variation/GetVarianceView",
            Load: {
                Related: "/api/variation/GetRelated",
                WithRelatedById: "/api/variation/GetWithRelatedById",
            },
            //AmendVariation: "/api/variation/AmendVariation",
            GenerateVOPDFById: "/api/exportpdf/GenerateVariationPDFById",
            GetVariationRiskOppAmendmentAndRelated: "/api/variation/GetVariationRiskOppAmendmentAndRelated",
            UpsertRiskOppAmendment: "/api/variation/UpsertRiskOppAmendment",
            GetVariationFutureSpendAmendmentAndRelated: "/api/variation/GetVariationFutureSpendAmendmentAndRelated",
            UpsertVariationFutureSpendAmendment: "/api/variation/UpsertVariationFutureSpendAmendment",
            ReviseVariation: "/api/variation/ReviseVariation",
            CanReviseVariation: "/api/variation/CanReviseVariation",
        },
        File: {
            UploadFile: "/api/upload/UploadFile",
            DownloadFile: "/api/upload/downloadfile",
        },
        Report: {
            LoadRelated: "/api/report/GetRelated",
            AllProjects: {
                GetProjectsReport: "/api/report/GetAllProjectsReport",
                GetAllProjectsVRCReport: "/api/report/GetAllProjectsVRCReport",
                GetAllProjectsSpendReport: "/api/report/GetAllProjectsSpendReport",
                GenerateAllProjectsVRCReportCSV: "/api/report/GenerateAllProjectsVRCReportCSV",
                GenerateAllProjectsSpendReportCSV: "/api/report/GenerateAllProjectsSpendReportCSV",
                GenerateAllProjectsReportCSV: "/api/report/GenerateAllProjectsReportCSV",
                GetAllProjectsInvoiceReport: "/api/report/GetAllProjectsInvoiceReport",
                GenerateAllProjectsInvoiceReportCSV: "/api/report/GenerateAllProjectsInvoiceReportCSV",
                LoadRelated: "/api/report/GetAllProjectsRelated",
            },
            Project: {
                GetProjectReport: "/api/report/GetProjectReport",
                GetProjectVRCReport: "/api/report/GetProjectVRCReport",
                GetProjectSpendReport: "/api/report/GetProjectSpendReport",
                GenerateProjectVRCReportCSV: "/api/report/GenerateProjectVRCReportCSV",
                GenerateProjectSpendReportCSV: "/api/report/GenerateProjectSpendReportCSV",
                GenerateProjectReportCSV: "/api/report/GenerateProjectReportCSV",
                GetProjectInvoiceReport: "/api/report/GetProjectInvoiceReport",
                GenerateProjectInvoiceReportCSV: "/api/report/GenerateProjectInvoiceReportCSV",
            },
            IE: {
                GetIEReport: "/api/report/GetIEReport",
                GenerateIEReportCSV: "/api/report/GenerateIEReportCSV",
                GetIEVRCReport: "/api/report/GetIEVRCReport",
                GetIESpendReport: "/api/report/GetIESpendReport",
                GenerateIEVRCReportCSV: "/api/report/GenerateIEVRCReportCSV",
                GenerateIESpendReportCSV: "/api/report/GenerateIESpendReportCSV",
            },
        },
    };
}

export const AppUrls = new GlobalAppUrls();

// Libraries
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

// Custom

// Styling & Images
import { theme } from "Globals/Styles/AppTheme";
import { makeStyles } from "@material-ui/core";
import { VariationItem } from "./VariationItem";
import { VariationSubCat } from "./VariationSubCat";
import { VariationCat } from "./VariationCat";
import { VariationCategoryViewModel } from "./VariationCategoryViewModel";
import { VariationSubCategoryViewModel } from "./VariationSubCategoryViewModel";
import { VariationItemViewModel } from "./VariationItemViewModel";
import { VariationViewModel } from "../VariationViewModel";

interface ITableProps {
    viewModel: VariationViewModel;
    variationCategoryViewModels: VariationCategoryViewModel[];
    IEType: string;
    isDisabled: boolean;
}

export const VariationGridViewCatSubDesc: React.FunctionComponent<ITableProps> = (props) => {
    const { variationCategoryViewModels, viewModel, isDisabled } = props;

    // Stores the indexes of each expanded row.
    const [activeDescription, setActiveDescription] = useState<number>(-1);
    const [activeSubCategory, setActiveSubCategory] = useState<number>(-1);
    const [activeCategory, setActiveCategory] = useState<number>(-1);

    const classes = useStyles();

    const handleSubCategoryEnable = (categoryIndex: number, index: number) => {
        if (categoryIndex !== -1) {
            setActiveCategory(categoryIndex);
        }
        setActiveSubCategory(index);
        setActiveDescription(-1);
    };

    const handleCategoryEnable = (index: number) => {
        setActiveCategory(index);
        setActiveSubCategory(-1);
        setActiveDescription(-1);
    };

    const itemView = (obj: VariationItemViewModel[], catIndex: number, subIndex: number) => {
        return (
            <>
                {obj.map((i: VariationItemViewModel, index: number) => (
                    <>
                        <VariationItem
                            itemViewModel={i}
                            rowIndex={index}
                            categoryIndex={catIndex}
                            subCategoryIndex={subIndex}
                            currentActiveCatIndex={activeCategory}
                            currentActiveSubCatIndex={activeSubCategory}
                            currentActiveDescIndex={activeDescription}
                            isDisabled={isDisabled}
                        />
                    </>
                ))}
            </>
        );
    };

    const subcategoryView = (obj: VariationSubCategoryViewModel[], catIndex: number) => {
        return (
            <>
                {obj.map((s: VariationSubCategoryViewModel, index: number) => (
                    <>
                        <div className={activeSubCategory === index && activeCategory === catIndex ? classes.SubCatBorder : ""}>
                            <VariationSubCat
                                itemModel={s}
                                categoryIndex={catIndex}
                                rowIndex={index}
                                rowEnable={(p, i) => handleSubCategoryEnable(p, i)}
                                rowType={"subcategory"}
                                currentActiveCatIndex={activeCategory}
                                currentActiveSubCatIndex={activeSubCategory}
                            />
                            {activeSubCategory === index && activeCategory === catIndex && (
                                <>
                                    {itemView(
                                        s.variationItemViewModels.filter((i) => !i.model.isDeleted),
                                        catIndex,
                                        index,
                                    )}
                                </>
                            )}
                        </div>
                    </>
                ))}
            </>
        );
    };

    return useObserver(() => (
        <>
            {variationCategoryViewModels.map((c: VariationCategoryViewModel, index: number) => (
                <>
                    <div className={activeCategory === index ? classes.CatBorder : ""}>
                        <VariationCat
                            viewModel={viewModel}
                            itemModel={c}
                            rowIndex={index}
                            rowType={"ie"}
                            rowEnable={(i) => handleCategoryEnable(i)}
                            currentActiveCatIndex={activeCategory}
                        />
                        {activeCategory === index && <>{subcategoryView(c.variationSubCategoryViewModels, index)}</>}
                    </div>
                </>
            ))}

            {variationCategoryViewModels.length === 0 && props.IEType === "MASTER" && (
                <>
                    <div style={{ fontSize: "12px", letterSpacing: "-0.24px", marginLeft: "10px", marginTop: "30px" }}>
                        There are currently no lines in this variation. Click <span style={{ color: "#e03c31" }}>Add new line</span> to add one to this variation
                    </div>
                </>
            )}
        </>
    ));
};

const useStyles = makeStyles({
    IELineGroup: {
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    CatBorder: {
        borderTop: `4px solid ${theme.palette.detail.main}`,
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    SubCatBorder: {
        borderTop: `2px solid ${theme.palette.detail.main}`,
        borderBottom: `2px solid ${theme.palette.detail.main}`,
    },
    ItemBorder: {
        borderTop: `1px solid ${theme.palette.detail.main}`,
        borderBottom: `1px solid ${theme.palette.detail.main}`,
    },
});

export const IESubItem = styled.div`
    > li {
        > div:nth-child(1) {
            margin-left: 50px;
        }
    }
`;

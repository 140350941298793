import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { BaseModal } from "Components/Modal/BaseModal";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { Typography } from "@material-ui/core";
import { Cell, Grid, styled } from "@shoothill/core";
import { DesignDataItemViewModel } from "./DesignDataItemViewModel";
import { DesignDataFormItemModel } from "../../Forms/DesignDataFormItemModel";
import { ReportModalStyle } from "Views/Project/Modals/Modal.Styles";

interface ICreatedModalProps {
    open?: boolean;
    onClose(): void;
    designDataId: string | null;
}

export const DesignDataItemView: React.FC<ICreatedModalProps> = (props) => {
    const { open, onClose, designDataId } = props;
    const [viewModel] = useState(() => new DesignDataItemViewModel(designDataId));
    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const COLUMNS4 = "1fr 1fr 1fr 1fr";

    const renderItem = (item: DesignDataFormItemModel, index: number) => {
        return (
            <>
                <DesignDataItem>
                    <Grid columnGap={30} rowGap={0} tc={COLUMNS4} dc={COLUMNS4} style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <Cell ts={4} ds={4}>
                            <ItemTextContainer>
                                <Typography variant="body1">
                                    <span style={{ fontWeight: "bold" }}>{item.title}</span> - {item.description}
                                </Typography>
                            </ItemTextContainer>
                        </Cell>
                    </Grid>
                </DesignDataItem>
            </>
        );
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={"Items"}
            PaperComponent={ReportModalStyle}
            actions={
                <>
                    <CancelButton style={{ background: "#e03c31" }} onClick={onClose}>
                        Ok
                    </CancelButton>
                </>
            }
        >
            <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8} mb="35px">
                <Cell ts={8} ds={8}>
                    {viewModel.items
                        .filter((d) => d.isDeleted === false)
                        .map((item, index) => {
                            return renderItem(item, index);
                        })}
                </Cell>
            </Grid>
        </BaseModal>
    ));
};

export const DesignDataItem: any = styled.li`
    position: relative;
    list-style-type: none;
    padding: 5px;
    margin-bottom: 10px;
`;

export const ItemTextContainer = styled.div`
    p {
        font-size: 12px !important;
    }
`;

import { isNullOrUndefined } from "@shoothill/core";
import { action, observable } from "mobx";

import { ApprovalPanelModelBase } from "Globals/Models/ApprovalPanelModelBase";

export class VariationApprovalPanelModel extends ApprovalPanelModelBase<VariationApprovalPanelModel> {
    // #region Properties
    // #endregion Properties
    // #region Actions
    // #endregion Actions
}

import { getHistory, FieldType, isEmptyOrWhitespace, ViewModelBase, KeyValuePair } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { InvoiceMatchModel, InvoiceMatchRequestDTO, InvoiceMatchResponseDTO } from "./InvoiceMatchModel";
import { InvoicePurchaseOrderViewModel } from "./Supporting/InvoicePurchaseOrderViewModel";
import { InvoiceViewModel } from "../InvoiceViewModel";
import { formatCurrencyFromPounds } from "Utils/Format";
import { InvoiceDisputedRequestDTO, UpdateInvoiceDisputedStatusCodeDTO } from "./InvoiceDisputedModal/InvoiceDisputeModalModel";
import { InvoiceStatusDTO, InvoiceStatusEnum } from "../Details/InvoiceDetailsModel";
import { NotificationBarViewModel } from "Components/NotificationBar/NotificationBarViewModel";
import { ChangeEvent } from "react";
import { debounce } from "lodash-es";
import { InvoicePurchaseOrderDTO, InvoicePurchaseOrderModel } from "./Supporting/InvoicePurchaseOrderModel";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";
import { InvoiceProjectViewModel } from "../Details/Supporting/InvoiceProjectViewModel";
import { StoresInstance } from "Globals/Stores";
import { InvoicePurchaseOrderItemsAllocatedDTO } from "./Supporting/InvoicePurchaseOrderItemModel";
import { ThemeConsumer } from "styled-components";

export class InvoiceMatchViewModel extends ViewModelBase<InvoiceMatchModel> {
    // #region Constructors and Disposers

    constructor(id: string | null, ieid: string | null) {
        super(new InvoiceMatchModel());
        this.setDecorators(InvoiceMatchViewModel);

        this.model.id = id ? id : null;
        this.model.ieId = ieid ? ieid : null;

        //isEmptyOrWhitespace(id) || id === null ? {} : this.loadInvoiceMatch(id);
    }

    notificationBarViewModel = NotificationBarViewModel.Instance;

    // #region Properties

    @computed
    public get getCanEditInvoice(): boolean {
        return InvoiceViewModel.GetInstance.getCanEditInvoice;
    }

    @observable
    public hasLoaded: boolean = false;

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @action
    public setFormIsLoading = (val: boolean) => {
        this.setIsLoading(val);
    };

    @observable
    public invoicePurchaseOrders: InvoicePurchaseOrderViewModel[] = [];

    @computed
    public get getInvoicePurchaseOrders(): InvoicePurchaseOrderViewModel[] {
        return this.invoicePurchaseOrders;
    }

    @observable
    public isViewOnly: boolean = false;

    @computed
    public get getIsViewOnly(): boolean {
        return this.isViewOnly;
    }

    @action
    public setIsViewOnly = (val: boolean) => {
        this.isViewOnly = val;
    };

    @computed
    public get isFormDisabled(): boolean {
        if (!this.getCanEditInvoice) {
            return true;
        }

        // Finance role can post when disputed, which will send it back through the approval process.
        if (
            InvoiceViewModel.GetInstance.invoiceStatus !== null &&
            InvoiceViewModel.GetInstance.invoiceStatus.type === InvoiceStatusEnum.Disputed &&
            StoresInstance.Domain.AccountStore.isFinanceRole
        ) {
            return false;
        }

        if (InvoiceViewModel.GetInstance.hasBeenResetToMatch) {
            return false;
        }
        return (
            this.getIsViewOnly ||
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId ===
                InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Disputed)!.id
        );
    }

    /**
     * Determines whether the invoice match form can be saved as draft. Enables/disables the save as draft button.
     */
    @computed
    public get canSaveAsDraft(): boolean {
        if (
            InvoiceViewModel.GetInstance.hasBeenResetToMatch &&
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId === InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Match)!.id
        ) {
            return true;
        }
        return (
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId === InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Match)!.id
        );
    }

    /**
     * Determines whether the invoice match form can be submitted. Enables/disables the submit button.
     */
    @computed
    public get canSubmit(): boolean {
        if (
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId ===
                InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Disputed)!.id &&
            StoresInstance.Domain.AccountStore.isFinanceRole
        ) {
            return true;
        }

        if (
            InvoiceViewModel.GetInstance.hasBeenResetToMatch &&
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId !==
                InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Approved)!.id &&
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId !== InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Paid)!.id
        ) {
            return true;
        }
        return (
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId !==
                InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Disputed)!.id &&
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId !==
                InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Approved)!.id &&
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId !== InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Paid)!.id
        );
    }

    @computed
    public get canDispute(): boolean {
        // return (
        //     InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId !==
        //     InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Disputed)!.id
        // );
        return true;
    }

    @computed
    public get isDisputed(): boolean {
        return (
            InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId ===
            InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Disputed)!.id
        );
    }

    // @observable
    // public alreadyAllocatedInvoices: InvoicePurchaseOrderItemsAllocatedDTO[] = [];

    // @observable
    // public showAlreadyAllocatedModalId: string | null = null;

    // @computed
    // public get canShowAlreadyAllocatedModalId(): string | null {
    //     return this.showAlreadyAllocatedModalId;
    // }

    // @action
    // public setShowAlreadyAllocatedModalId = (id: string | null) => {
    //     this.showAlreadyAllocatedModalId = id;
    // };

    @computed
    public get getProjectsExceedingAllocation(): { projectId: string; name: string; exceededByAmount: number }[] {
        const pos: InvoicePurchaseOrderViewModel[] = this.getInvoicePurchaseOrders;

        let projects: { projectId: string; name: string; invoiceValue: number | null }[] = [];
        let projectsExceedingAllocation: { projectId: string; name: string; exceededByAmount: number }[] = [];

        pos.forEach((po) => {
            let existingProject = projects.find((project) => project.projectId === po.model.projectId);

            // Add project to the list if it hasn't been processed yet.
            if (!existingProject) {
                projects.push({ projectId: po.model.projectId, name: po.model.projectName, invoiceValue: po.model.invoiceValue });
                existingProject = projects.find((project) => project.projectId === po.model.projectId);
            }

            const poAmountAllocated: number = po.getAmountAllocated;
            const projectInvoiceValue: number = existingProject!.invoiceValue ? existingProject!.invoiceValue : 0;

            const poAmountAllocatedAbs: number = Math.abs(po.getAmountAllocated);
            const projectInvoiceValueAbs: number = Math.abs(existingProject!.invoiceValue ? existingProject!.invoiceValue : 0);

            // Process the current purchase order.
            if (poAmountAllocatedAbs > projectInvoiceValueAbs) {
                const difference: number = poAmountAllocated - projectInvoiceValue;
                projectsExceedingAllocation.push({ projectId: po.model.projectId, name: po.model.projectName, exceededByAmount: difference });
            }
        });

        return projectsExceedingAllocation;
    }

    @computed
    public get getInvoiceValue(): number {
        return Number(InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceValue);
    }

    @computed
    public get getTotalBalanceToAllocate(): number {
        const value: Number = Number(this.getInvoiceValue.toFixed(2)) - Number(this.getAmountAllocated.toFixed(2));
        return Number(value.toFixed(2));
    }

    @computed
    public get getInitialValue(): number {
        return this.getInvoicePurchaseOrders.map((item) => item.getInitialValue).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getAlreadyAllocated(): number {
        return this.getInvoicePurchaseOrders.map((item) => item.getAlreadyAllocated).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getAvailableBalance(): number {
        return this.getInvoicePurchaseOrders.map((item) => item.getAvailableBalance).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getAmountAllocated(): number {
        return this.getInvoicePurchaseOrders.map((item) => item.getAmountAllocated).reduce((prev, next) => parseFloat((prev + next).toFixed(2)), 0);
    }

    @computed
    public get getInitialValueFormatted(): string {
        if (this.getInitialValue !== 0) {
            return formatCurrencyFromPounds(this.getInitialValue);
        } else {
            return "---";
        }
    }

    @computed
    public get getAlreadyAllocatedFormatted(): string {
        if (this.getAlreadyAllocated !== 0) {
            return formatCurrencyFromPounds(this.getAlreadyAllocated);
        } else {
            return "---";
        }
    }

    @computed
    public get getAvailableBalanceFormatted(): string {
        if (this.getAvailableBalance !== 0) {
            return formatCurrencyFromPounds(this.getAvailableBalance);
        } else {
            return "---";
        }
    }

    @computed
    public get getAmountAllocatedFormatted(): string {
        if (this.getAmountAllocated !== 0) {
            return formatCurrencyFromPounds(this.getAmountAllocated);
        } else {
            return "---";
        }
    }

    @computed
    public get getInvoiceValueFormatted(): string {
        if (this.getInvoiceValue !== 0) {
            return formatCurrencyFromPounds(this.getInvoiceValue);
        } else {
            return "---";
        }
    }

    @computed
    public get getTotalBalanceToAllocateFormatted(): string {
        if (this.getTotalBalanceToAllocate !== 0) {
            return formatCurrencyFromPounds(this.getTotalBalanceToAllocate);
        } else {
            return "---";
        }
    }

    @computed
    public get getTotalBalanceToAllocateByProject(): {
        projectId: string;
        name: string;
        amountAllocated: number;
        balanceToAllocate: number;
        balanceToAllocateFormatted: string;
        invoiceValue: number;
    }[] {
        const pos: InvoicePurchaseOrderViewModel[] = this.getInvoicePurchaseOrders;
        let invoiceProjects: InvoiceProjectViewModel[] = InvoiceViewModel.GetInstance.detailsViewModel.getInvoiceProjects;

        let projects: {
            projectId: string;
            name: string;
            amountAllocated: number; // Total of allocated from purchase orders
            balanceToAllocate: number; // The remaining balance to allocate. invoiceValue - amountAllocated
            balanceToAllocateFormatted: string;
            invoiceValue: number; // Value required to be allocated.
        }[] = [];

        invoiceProjects.forEach((project) => {
            projects.push({
                projectId: project.model.projectId!,
                name: project.model.projectName,
                amountAllocated: 0,
                balanceToAllocateFormatted: formatCurrencyFromPounds(project.invoiceValueNumber - 0),
                balanceToAllocate: project.invoiceValueNumber - 0,
                invoiceValue: project.invoiceValueNumber,
            });
        });

        pos.forEach((po) => {
            let matchingProject = projects.find((project) => project.projectId === po.model.projectId);

            if (matchingProject) {
                matchingProject.amountAllocated = parseFloat((matchingProject.amountAllocated + po.getAmountAllocated).toFixed(2));
                matchingProject.balanceToAllocate = parseFloat((matchingProject.invoiceValue - matchingProject.amountAllocated).toFixed(2));
                const balanceToAllocateFormatted: string = matchingProject.balanceToAllocate !== 0 ? formatCurrencyFromPounds(matchingProject.balanceToAllocate) : "---";
                matchingProject.balanceToAllocateFormatted = balanceToAllocateFormatted;
            }
        });

        return projects;
    }

    @observable
    private showDisputedModal = false;

    public get getShowDisputedModal(): boolean {
        return this.showDisputedModal;
    }

    @action
    public handleShowDisputedModalChange = (val: boolean) => {
        this.showDisputedModal = val;
    };

    public server: ServerViewModel = new ServerViewModel();

    @action
    private createViewModels() {
        for (const item of this.model.invoicePurchaseOrders) {
            this.invoicePurchaseOrders.push(new InvoicePurchaseOrderViewModel(item));
        }
    }

    // #endregion Properties

    // #region POFunctionsAndProperties

    @observable
    public purchaseOrders = observable<PurchaseOrderItemDTO>([]);

    /**
     * List of purchase orders for the PO autocomplete/dropdown.
     */
    @computed
    public get purchaseOrderOptions(): KeyValuePair[] {
        return this.purchaseOrders.map(this.toPOOptionsModel);
    }

    public toPOOptionsModel = (item: PurchaseOrderItemDTO): any => {
        const po: KeyValuePair = {
            key: item.id,
            value: `${item.formattedPONumber} - ${item.description}`,
        };

        return po;
    };

    /**
     * Determines whether a PO is already linked to this invoice locally or not. Used to disable the PO in the PO list.
     * @param option The PO to check.
     * @returns True if the PO is already selected against this invoice locally.
     */
    @action
    public isPOSelected = (option: KeyValuePair<any>): boolean => {
        if (this.invoicePurchaseOrders.filter((i) => !i.model.isDeleted).findIndex((i) => i.model.requisitionRequestId === option.key) !== -1) {
            return true;
        }

        return false;
    };

    /**
     * Handles adding a purchase order to the local list of invoice purchase orders. Re-loads the purchase order list after an item has been selected.
     * @param event The event that triggered the function call.
     * @param item The PO item to be added to the list.
     */
    @action
    public handleAddPurchaseOrder = async (event: ChangeEvent<{}>, item: KeyValuePair | null) => {
        if (this.getCanEditInvoice) {
            if (item) {
                let po: PurchaseOrderItemDTO | undefined = this.purchaseOrders.find((i) => i.id === item.key);

                if (po !== undefined) {
                    let result = await this.insertInvoicePurchaseOrder(po.id);
                }
            }

            // Reset the PO autocomplete/dropdown and re-load the list.
            runInAction(() => {
                this.searchText = "";
                this.selectedPurchaseOrder = null;
            });

            if (InvoiceViewModel.GetInstance.detailsViewModel.supplier) {
                this.loadFilteredPurchaseOrders(InvoiceViewModel.GetInstance.detailsViewModel.supplier.id, "");
            }
        }
    };

    /**
     * Handles deleting a purchase order to the local list of invoice purchase orders. Re-loads the purchase order list after an item has been deleted.
     * @param event The event that triggered the function call.
     * @param item The PO item to be removed from the list.
     */
    @action
    public handleDeletePurchaseOrder = async (event: ChangeEvent<{}>, id: string | null) => {
        if (!this.isFormDisabled) {
            if (id) {
                let result = await this.deleteInvoicePurchaseOrder(id);
            }

            // Reset the PO autocomplete/dropdown and re-load the list.
            runInAction(() => {
                this.searchText = "";
                this.selectedPurchaseOrder = null;
            });

            if (InvoiceViewModel.GetInstance.detailsViewModel.supplier) {
                this.loadFilteredPurchaseOrders(InvoiceViewModel.GetInstance.detailsViewModel.supplier.id, "");
            }
        }
    };

    /**
     * Generates an InvoicePurchaseOrder model from  the server. After you have clicked on a PO from the list of POs.
     * @param poId Purchase order id.
     * @returns Promise.
     */
    @action
    public insertInvoicePurchaseOrder = async (poId: string): Promise<void> => {
        const invoiceId: string | null = this.model.invoiceId;

        //let url = `${AppUrls.Server.Invoice.Load.InsertInvoicePurchaseOrder}?invoiceId=${invoiceId}&poId=${poId}`;

        let upsertInvoicePurchaseOrder: UpsertInvoicePurchaseOrderSimpleDTO = {
            invoiceId: invoiceId,
            poId: poId,
        };

        this.setIsLoading(true);
        return await this.server
            .query<InvoicePurchaseOrderDTO>(
                () => this.Post(AppUrls.Server.Invoice.Load.InsertInvoicePurchaseOrder, upsertInvoicePurchaseOrder),
                (result) => {
                    runInAction(() => {
                        let model = new InvoicePurchaseOrderModel();
                        model.fromDto(result);
                        this.invoicePurchaseOrders.push(new InvoicePurchaseOrderViewModel(model));
                        this.model.addInvoicePurchaseOrder(result);
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Deletes an assigned invoice purchase order
     * @param poId Purchase order id.
     * @returns Promise.
     */
    @action
    public deleteInvoicePurchaseOrder = async (invoicePurchaseOrderId: string): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<InvoicePurchaseOrderDTO>(
                () => this.Post(AppUrls.Server.Invoice.Load.DeleteInvoicePurchaseOrder, { id: invoicePurchaseOrderId }),
                (result) => {
                    runInAction(() => {
                        this.invoicePurchaseOrders = this.invoicePurchaseOrders.filter((i) => i.model.id !== invoicePurchaseOrderId);
                        this.model.deleteInvoicePurchaseOrder(invoicePurchaseOrderId);
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    private readonly PO_DEBOUNCE_VALUE_MS = 300;

    @observable
    public searchText: string = "";

    @observable
    public selectedPurchaseOrder: KeyValuePair | null = null;

    /**
     * Handles the search event triggered from the autocomplete dropdown for purchase orders.
     * @param e The type of event that triggered the function to be called.
     * @param searchText The string that will be used to filter the list of purchase orders.
     */
    @action
    public handleSearchPurchaseOrders = (e: React.ChangeEvent<{}>, searchText: string) => {
        // Prevent filtering the list of purchase orders when an item is selected from the list.
        if (e && e.type !== "click") {
            this.searchText = searchText;
            this.handleSearchPurchaseOrdersDebounce(searchText);
        }
    };

    /**
     * Debounce function for filtering the purchase order dropdown.
     */
    public handleSearchPurchaseOrdersDebounce = debounce(
        action((searchText: string) => {
            if (InvoiceViewModel.GetInstance.detailsViewModel.supplier) {
                this.loadFilteredPurchaseOrders(InvoiceViewModel.GetInstance.detailsViewModel.supplier.id, searchText);
            }
        }),
        this.PO_DEBOUNCE_VALUE_MS,
    );

    /**
     * Loads the filtered list of purchase orders for the purchase order dropdown.
     * @param supplierId The supplier that the purchase orders will be filtered by.
     * @param searchText The string that the purchase orders will be filtered by.
     */
    @action
    public loadFilteredPurchaseOrders = async (supplierId: string, searchText: string): Promise<void> => {
        const invoiceId: string | null = this.model.id;
        const ieId: string | null = this.model.ieId;

        let url = `${AppUrls.Server.Invoice.Load.GetFilteredPurchaseOrders}?supplierId=${supplierId}&searchText=${searchText ? searchText : ""}&invoiceId=${
            invoiceId ? invoiceId : ""
        }&ieId=${ieId ? ieId : ""}`;

        this.setIsLoading(true);
        return await this.server
            .query<PurchaseOrderItemDTO[]>(
                () => this.Get(url),
                (result) => {
                    runInAction(() => {
                        this.purchaseOrders.replace(result);
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    // #endregion POFunctionsAndProperties

    // #region Server Actions

    @action
    public loadData = () => {
        isEmptyOrWhitespace(this.model.id) || this.model.id === null ? {} : this.loadInvoiceMatch(this.model.id);
    };

    // req id
    public loadInvoiceMatch = async (id: string): Promise<void> => {
        this.setIsLoading(true);
        const url = this.model.ieId ? `${AppUrls.Server.Invoice.Load.InvoiceMatchById}\\${id}\\${this.model.ieId}` : `${AppUrls.Server.Invoice.Load.InvoiceMatchById}\\${id}`;
        try {
            return await this.server.query<InvoiceMatchResponseDTO>(
                () => this.Get(url),
                (result) => {
                    runInAction(() => {
                        this.model.fromDto(result);
                        this.purchaseOrders.replace(result.purchaseOrders);
                        this.createViewModels();

                        // Set the approval panel data.
                        InvoiceViewModel.GetInstance.approvalPanelViewModel.model.fromDto(result.approvalPanel);
                        InvoiceViewModel.GetInstance.approvalPanelViewModel.populateRequisitionPOStatuses(result.requisitionPOStatuses);
                        InvoiceViewModel.GetInstance.approvalPanelViewModel.populateApprovalHistory(result.approvalHistoryItems);

                        // Determine whether to show the notification bar.
                        if (
                            InvoiceViewModel.GetInstance.approvalPanelViewModel.model.id !== null &&
                            InvoiceViewModel.GetInstance.approvalPanelViewModel.model.approvalStatusId !== null
                        ) {
                            NotificationBarViewModel.Instance.setItem("invoice");
                            NotificationBarViewModel.Instance.setPath(AppUrls.Client.Invoicing.Edit);
                            NotificationBarViewModel.Instance.setIsActive(!InvoiceViewModel.GetInstance.approvalPanelViewModel.getIsRejectedOrApproved);
                        }

                        const approvedStatus: InvoiceStatusDTO | undefined = InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Approved);
                        const paidStatus: InvoiceStatusDTO | undefined = InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Paid);
                        const rejectedStatus: InvoiceStatusDTO | undefined = InvoiceViewModel.GetInstance.invoiceStatuses.find(
                            (s) => s.type === InvoiceStatusEnum.RejectedInternally,
                        );
                        const allocatedAndApprovalStatus: InvoiceStatusDTO | undefined = InvoiceViewModel.GetInstance.invoiceStatuses.find(
                            (s) => s.type === InvoiceStatusEnum.AllocatedAndAwaitingApproval,
                        );

                        // Handle setting the form to viewonly.
                        if (
                            (approvedStatus && InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId === approvedStatus.id) ||
                            (rejectedStatus && InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId === rejectedStatus.id) ||
                            (paidStatus && InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId === paidStatus.id) ||
                            (allocatedAndApprovalStatus && InvoiceViewModel.GetInstance.detailsViewModel.model.invoiceStatusId === allocatedAndApprovalStatus.id)
                        ) {
                            this.setIsViewOnly(true);
                            InvoiceViewModel.GetInstance.detailsViewModel.setIsViewOnly(true);
                        }
                    });
                },
            );
        } finally {
            this.setHasLoaded(true);
            this.setIsLoading(false);
        }
    };

    /**
     * Handles navigating back to the appropriate page after saving or canceling the form.
     */
    public handleCancel = (): void => {
        const ieid: string | null = this.model.ieId;
        let url: string = ieid ? AppUrls.Client.Project.IE.replace(":ieid", ieid) + "#inv" : AppUrls.Client.Invoicing.List;
        if (IEGridItemViewModel.Instance.isCentral && ieid) {
            url = AppUrls.Client.Central.View.replace(":ieid", ieid) + "#inv";
        }
        getHistory().push(url);
    };

    /**
     * Handles upserting the invoice match form.
     */
    public handleUpsert = (): Promise<void> => {
        return this.upsert(false);
    };

    /**
     * Handles upserting the invoice match form.
     */
    public handleUpsertAsDraft = (): Promise<void> => {
        return this.upsert(true);
    };

    public upsert = async (isDraft: boolean): Promise<void> => {
        let dto: InvoiceMatchRequestDTO = this.model.toDto();
        this.invoicePurchaseOrders.forEach((po) => {
            po.invoiceGroups.forEach((group) => {
                group.invoicePurchaseOrderItems.forEach((item) => {
                    dto.invoicePurchaseOrderItems.push(item.model.toDto());
                });
            });
        });

        this.setIsLoading(true);

        if (await this.isMyModelValid()) {
            const apiResult = await this.Post(isDraft ? AppUrls.Server.Invoice.UpsertMatchDraft : AppUrls.Server.Invoice.UpsertMatch, dto).then((apiResult) => {
                if (apiResult) {
                    if (apiResult.wasSuccessful) {
                        runInAction(() => {
                            this.reset();
                            this.handleCancel();
                        });
                    } else {
                        runInAction(() => {
                            let errorMessage: string = "Saving/Updating the invoice match data failed.";
                            if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                                errorMessage =
                                    apiResult.errors[0].message === "Invalid submission. Please refresh to get the latest data and try again."
                                        ? "Invalid submission. Please refresh to get the latest data and try again."
                                        : "Saving/Updating the invoice match data failed.";
                            }
                            this.setSnackMessage(errorMessage);
                            this.setSnackType(this.SNACKERROR);
                            this.setSnackbarState(true);
                        });
                    }
                }
            });
        }

        this.setIsLoading(false);
    };

    public handleDisputeInvoice = async (): Promise<void> => {
        if (this.isDisputed) {
            return await this.updateInvoiceDisputedStatusCode();
        } else {
            return await this.disputeInvoice();
        }
    };

    /**
     * Handle disputing an invoice.
     */
    public disputeInvoice = async (): Promise<void> => {
        this.setIsLoading(true);

        let model: InvoiceDisputedRequestDTO = InvoiceViewModel.GetInstance.invoiceDisputeModalViewModel.model.toDto();
        model.id = InvoiceViewModel.GetInstance.detailsViewModel.model.id;
        model.invoiceStatusId = InvoiceViewModel.GetInstance.invoiceStatuses.find((s) => s.type === InvoiceStatusEnum.Disputed)!.id;

        return await this.server
            .command<InvoiceMatchResponseDTO>(
                () => this.Post(AppUrls.Server.Invoice.DisputeInvoice, model),
                (result: InvoiceMatchResponseDTO) => {
                    runInAction(() => {
                        this.reset();
                        this.handleCancel();
                    });
                },
                InvoiceViewModel.GetInstance.invoiceDisputeModalViewModel.isModelValid,
                "There was an error trying to dispute the invoice",
            )
            .finally(() => this.setIsLoading(false));
    };

    public updateInvoiceDisputedStatusCode = async (): Promise<void> => {
        this.setIsLoading(true);

        let model: UpdateInvoiceDisputedStatusCodeDTO = InvoiceViewModel.GetInstance.invoiceDisputeModalViewModel.model.toStatusCodeDto();
        model.id = InvoiceViewModel.GetInstance.detailsViewModel.model.id;

        return await this.server
            .command<InvoiceMatchResponseDTO>(
                () => this.Post(AppUrls.Server.Invoice.UpdateDisputedInvoiceStatusCode, model),
                (result: InvoiceMatchResponseDTO) => {
                    runInAction(() => {
                        this.reset();
                        this.handleCancel();
                    });
                },
                InvoiceViewModel.GetInstance.invoiceDisputeModalViewModel.isModelValid,
                "There was an error trying to change the disputed invoice code.",
            )
            .finally(() => this.setIsLoading(false));
    };

    /**
     * Custom model validation function. Validates child category models and its children
     * @returns True if model is valid, false if not.
     */
    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // JC: Changed forEach into for loop as the await seems to have issues with forEach.
        for (let i = 0; i < this.invoicePurchaseOrders.length; i++) {
            let item = this.invoicePurchaseOrders[i];
            console.log(item.model);
            let t = 0;

            // Validate each child item.
            if ((await item.isMyModelValid()) === false) {
                isValid = false;
            }
        }

        // Validate the invoice model.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
        this.invoicePurchaseOrders.length = 0;
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<InvoiceMatchModel>): Promise<boolean> {
        return true;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}

export interface PurchaseOrderItemDTO {
    id: string;
    poNumber: number;
    formattedPONumber: string;
    description: string;
    projectReference: string;
    projectName: string;
    poValue: number;
    alreadyAllocated: number;
}

export interface UpsertInvoicePurchaseOrderSimpleDTO {
    invoiceId: string;
    poId: string;
}

export interface PurchaseOrderItemDTO {
    id: string;
    poNumber: number;
    formattedPONumber: string;
    description: string;
    projectReference: string;
    projectName: string;
}

// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";

// Custom
import { DetailsHeader } from "Globals/Views/DetailsPage/DetailsHeader";
import { ProjectGeneralViewModel } from "../../General/ProjectGeneralViewModel";

// Styling & Images
import { SHBox } from "../../../../Components/Box";
import { DetailsPage, DetailsTabs } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { Typography } from "@material-ui/core";
import { DesignDataViewModel } from "./DesignDataViewModel";
import { ITab, Tabs } from "../../../../Components/Tabs/Tabs";
import { useHasRoutedTabs } from "../../../../Utils/UseHasRoutedTabs";
import { AllRDDsListView } from "./AllRDDsListView";
import { ClientRDDsListView } from "./ClientRDDsListView";
import { DarwinRDDsListView } from "./DarwinRDDsListView";
import { AppUrls } from "AppUrls";

export const DesignDataView: React.FunctionComponent = () => {
    const router = useRouter();
    let { projectid } = router.match.params as any;

    const [viewModel] = useState(new DesignDataViewModel());

    useEffect(() => {
        let { projectid } = router.match.params as any;
        viewModel.setProjectId(projectid);

        return () => {
            viewModel.clean();
        };
    }, []);

    const TAB_ALLRDDS = "allrdds";
    const TAB_DARWINRDDS = "darwin";
    const TAB_CLIENTRDDS = "client";

    const tabOptions: ITab[] = [
        { key: TAB_ALLRDDS, title: "All RDDs", hash: "allrdds" },
        { key: TAB_DARWINRDDS, title: "RDD's to Portakabin - Trial", hash: "darwin" },
        { key: TAB_CLIENTRDDS, title: "RDD's to client", hash: "client" },
    ];

    const hashTab = useHasRoutedTabs([...tabOptions]);

    const getTabOptions = (): ITab[] => {
        const projectId = viewModel.projectId && viewModel.projectId !== "" ? viewModel.projectId : projectid;
        const projectTrackersPath = AppUrls.Client.Project.ProjectTrackers.replace(":projectid", projectId);
        const projectTrackersDesignDataPath = AppUrls.Client.Project.DesignData.replace(":projectid", projectId);

        let options: ITab[] = tabOptions;

        switch (location.pathname) {
            case projectTrackersPath:
                options = tabOptions;
                break;
            case projectTrackersDesignDataPath:
                options = tabOptions;
                break;
            default:
                options = tabOptions;
                break;
        }

        return options;
    };

    const getSelectedTab = (): string => {
        const projectId = viewModel.projectId && viewModel.projectId !== "" ? viewModel.projectId : projectid;
        const projectTrackersPath = AppUrls.Client.Project.ProjectTrackers.replace(":projectid", projectId);
        const projectTrackersDesignDataPath = AppUrls.Client.Project.DesignData.replace(":projectid", projectId);

        let selectedTab: string = TAB_ALLRDDS;

        switch (location.pathname) {
            case projectTrackersPath:
                selectedTab = hashTab?.key || TAB_ALLRDDS;
                break;
            case projectTrackersDesignDataPath:
                selectedTab = hashTab?.key || TAB_ALLRDDS;
                break;
            default:
                selectedTab = hashTab?.key || TAB_ALLRDDS;
                break;
        }

        return selectedTab;
    };

    const [currentTabOptions, setCurrentTabOptions] = React.useState<ITab[]>(getTabOptions());

    const [generalViewModel] = useState(() => ProjectGeneralViewModel.Instance);
    const [tabKey, setTabKey] = React.useState(0);
    const [selectedTab, setSelectedTab] = React.useState(hashTab?.key || getSelectedTab());
    const [date, setDate] = React.useState(new Date());

    useEffect(() => {
        setCurrentTabOptions(getTabOptions());
        setSelectedTab(getSelectedTab());
    }, [router.history.location.pathname]);

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    const getTabKey = (): string => {
        return `clienthome-tab-${selectedTab}-${tabKey}`;
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case TAB_ALLRDDS:
                return <div key={getTabKey()}>{viewModel.projectId !== "" && <AllRDDsListView date={date} projectId={viewModel.projectId} />}</div>;
            case TAB_DARWINRDDS:
                return <div key={getTabKey()}>{viewModel.projectId !== "" && <DarwinRDDsListView date={date} projectId={viewModel.projectId} />}</div>;
            case TAB_CLIENTRDDS:
                return <div key={getTabKey()}>{viewModel.projectId !== "" && <ClientRDDsListView date={date} projectId={viewModel.projectId} />}</div>;
        }
    };

    const renderPage = () => {
        return (
            <>
                <DetailsPage className="cell-right rcselect">
                    <DetailsHeader viewModel={generalViewModel.getHeader} />
                    <SHBox style={{ padding: "5px 30px 20px 30px" }}>
                        <Typography variant="h2">Reviewable design data (Technical submissions)</Typography>
                    </SHBox>
                    <DetailsTabs>
                        <Tabs tabs={currentTabOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" />
                        <div className="content">{renderTabContent()}</div>
                    </DetailsTabs>
                </DetailsPage>
            </>
        );
    };

    return useObserver(() => renderPage());
};

// Libs
import * as MobX from "mobx";

// App
import { BaseStore } from "@shoothill/core";
import Moment from "moment";
import { Stores } from "../../Stores";
import { Role, RoleDTO } from "Globals/Models";

interface Filters {
    date: any;
    orderAsc: boolean;
    searchFilter: string;
}

export enum RoleTypeEnum {
    Admin = "admin",
    Variation = "variation",
    CellHead = "cellhead",
    Commdir = "commdir",
    SiteManager = "sitemanager",
    SiteTablet = "sitetablet",
    ExecTeam = "execteam",
    Managers = "managers",
    General = "general",
    CEOCOO = "ceo/coo",
    Finance = "finance",
    Level1Commercial = "level1commercial",
}

export class RoleStore extends BaseStore {
    private roles = MobX.observable<Role>([]);
    @MobX.observable private isLoadingData: boolean = false;
    @MobX.observable private filters: Filters = {
        date: Moment(),
        orderAsc: true,
        searchFilter: "",
    };
    @MobX.observable private lastUpdated: any = Moment();

    public constructor() {
        super();
    }

    public Init(stores: Stores) {}

    @MobX.computed get getRoleCount(): number {
        return this.roles.length;
    }

    @MobX.action
    public setRoles(roles: RoleDTO[]): void {
        this.roles.clear();
        roles.forEach((role, index) => {
            let domainModel = this.roles.find((dm) => dm.id === role.id);

            if (!domainModel) {
                domainModel = new Role(role.id);

                domainModel.fromDto(role);
                this.roles.push(domainModel);
            }
        });
        this.isLoadingData = false;
        this.lastUpdated = Moment();
    }

    @MobX.action
    public clearRoles() {
        this.roles.clear();
    }

    @MobX.action
    public setIsLoadingData(loading: boolean) {
        this.isLoadingData = loading;
    }

    @MobX.computed
    public get getRoles(): Role[] {
        return this.roles.slice();
    }

    @MobX.computed
    public get getIsLoadingData(): boolean {
        return this.isLoadingData;
    }

    @MobX.computed
    public get getFilters(): Filters {
        return this.filters;
    }

    @MobX.computed
    public get getFilteredRoles(): Role[] {
        let roles = this.roles.slice();
        if (this.filters.searchFilter) {
            roles = this.roles.filter((or) => or.name.toLowerCase().includes(this.filters.searchFilter));
        }
        return roles;
    }
}

export enum RoleLevelEnum {
    Unknown = 0,
    General = 1,
    Managers = 2,
    CellHead = 3,
    CommDir = 4,
    CEOCOO = 5,
    ExecTeam = 6,
}

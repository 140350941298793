import { ApiResult, FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { VariationViewModel, VariationViewModelDTO } from "./VariationViewModel";

export class VariationViewViewModel extends ViewModelBase<VariationViewModel> {
    public constructor() {
        super(new VariationViewModel(), false);
        this.setDecorators(VariationViewModel);
        //this.model.fromDto(variation);
    }

    public async isFieldValid(fieldName: keyof FieldType<VariationViewModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class InvoicingListFilterParamsBaseModel extends ModelBase<InvoicingListFilterParamsBaseModel, InvoicingListFilterParamsBaseModelDTO> {
    @observable
    public includeDeleted: boolean = false;

    @observable
    public statuses: string[] | null = [];

    @observable
    public projects: string[] | null = [];

    @observable
    public suppliers: string[] | null = [];

    @observable
    public searchText: string = "";

    fromDto(model: InvoicingListFilterParamsBaseModelDTO): void {
        this.searchText = model.searchText;
    }

    toDto(): InvoicingListFilterParamsBaseModelDTO {
        let dto: InvoicingListFilterParamsBaseModelDTO = {
            includeDeleted: this.includeDeleted,
            statuses: this.statuses,
            projects: this.projects,
            suppliers: this.suppliers,
            searchText: this.searchText,
        };
        return dto;
    }

    @action
    public reset = () => {
        this.statuses = [];
        this.projects = [];
        this.suppliers = [];
        this.searchText = "";
    };
}

export interface InvoicingListFilterParamsBaseModelDTO {
    includeDeleted: boolean;
    statuses: string[] | null;
    projects: string[] | null;
    suppliers: string[] | null;
    searchText: string;
}

import React, { useEffect, useState } from "react";
import { Grid, useObserver, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { AppUrls } from "AppUrls";
import { DarwinPageTitle, DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { TableListSearch } from "Components/Table/TableListSearch";
import { CustomHr } from "Views/Project/CustomComponents";
import { InductionListViewModel } from "./InductionListViewModel";
import { InductionListItemViewModel } from "./InductionListItemViewModel";
import { InductionListFilterViewModel } from "./InductionListFilterViewModel";
import { styled } from "@shoothill/core";
import { theme } from "Globals/Styles/AppTheme";
import { DeleteModal } from "Components/Modal/DeleteModal";
import DeleteLogo from "Content/Bin.svg";
import { Typography } from "@material-ui/core";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";
import { formatDate } from "Utils/Format";

interface IProps {}

export const InductionListView: React.FC<IProps> = (props: IProps) => {
    const tableOptions = Defaults.GetDarwinTableOptions() as any;
    const [viewModel] = useState(() => InductionListViewModel.Instance);
    const { history } = useRouter();
    const router = useRouter();

    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const filtersViewModel = InductionListFilterViewModel.Instance;

    useEffect(() => {
        viewModel.apiGetAllInvoices();

        return () => {
            //filtersViewModel.resetModel();
        };
    }, []);

    const handleRowClick = (e: any, rowData: InductionListItemViewModel | undefined) => {
        if (rowData) {
            const url: string = AppUrls.Client.Induction.View.replace(":inductionid", rowData.id);
            history.push(url);
        }
    };

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
    };

    const handledeleteInduction = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, rowData: InductionListItemViewModel) => {
        e.stopPropagation();
        viewModel.setInductionIdToDelete(rowData.id);
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        viewModel.setInductionIdToDelete(null);
    };

    const handleDeleteInduction = () => {
        const { ieid } = router.match.params as any;

        if (viewModel.inductionIdToDelete) {
            viewModel.deleteInduction(viewModel.inductionIdToDelete, ieid);
            viewModel.setInductionIdToDelete(null);
            setDeleteOpen(false);
        }
    };

    const renderPage = () => {
        return (
            <div style={{ width: "100%" }}>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Induction
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox className="invoice-search-box">
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} />
                        </DarwinTableSearchBox>
                    </Grid>
                </DarwinTableToolbar>
                <Grid padding={"0px 30px"}>
                    <span className="small-title">Showing: {viewModel.filteredInductionViewModels.length} results</span>
                </Grid>
                <CustomHr margin={"6px 0"} padding={"0px 30px"} />
                <Grid margin={"20px 0"}>
                    <DarwinTablePageContent className="invoice-Table">
                        <MaterialTable
                            columns={[
                                {
                                    title: "",
                                    field: "photoUrl",
                                    render: (rowData: InductionListItemViewModel) => (
                                        <InductionPhotoContainer>
                                            <img src={rowData.photographUrl} alt="Induction photo" />
                                        </InductionPhotoContainer>
                                    ),
                                    sorting: false,
                                    filtering: false,
                                },
                                { title: "Name", field: "fullName" },
                                { title: "Priority Employer Name", field: "priorityEmployerName" },
                                { title: "Age", field: "age" },
                                { title: "Inductee type", field: "visitorTypeName" },
                                {
                                    title: "Health problems",
                                    field: "healthProblemsFormatted",
                                    render: (rowData: InductionListItemViewModel) => (
                                        <div
                                            style={{
                                                color: rowData.healthProblemsFormatted === "Y" ? theme.palette.red.main : theme.palette.mediumGrey.contrastText,
                                            }}
                                        >
                                            {rowData.healthProblemsFormatted}
                                        </div>
                                    ),
                                },
                                {
                                    title: "Last signed in",
                                    field: "lastSignedInDate",
                                    render: (rowData: InductionListItemViewModel) => <>{rowData.lastSignedInDateFormatted}</>,
                                },
                                {
                                    title: "Status",
                                    field: "statusName",
                                    render: (rowData: InductionListItemViewModel) => (
                                        <StatusCell
                                            className="Unknown"
                                            style={{
                                                backgroundColor: rowData.statusColor,
                                                color: rowData.statusTextColor,
                                                margin: "3px 0",
                                                padding: "2px 0",
                                            }}
                                        >
                                            {rowData.statusName}
                                        </StatusCell>
                                    ),
                                },
                                {
                                    title: "",
                                    field: "",
                                    render: (rowData: InductionListItemViewModel) => (
                                        <div className="table-svg-container">
                                            <img src={DeleteLogo} onClick={(e) => handledeleteInduction(e, rowData)} />
                                        </div>
                                    ),
                                },
                            ]}
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                            data={viewModel.filteredInductionViewModels}
                            onRowClick={handleRowClick}
                            isLoading={viewModel.IsLoading}
                        />
                    </DarwinTablePageContent>
                </Grid>
                <DeleteModal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onDelete={handleDeleteInduction}
                    title="Delete induction?"
                    text={`Are you sure you want to delete induction ${viewModel.getInductionToDeleteName}?`}
                />
            </div>
        );
    };

    return useObserver(() => renderPage());
};

export const InductionPhotoContainer = styled.div`
    > img {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        border: 1px solid #85b5cc;
    }
`;

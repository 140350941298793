import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { action, observable, runInAction, computed } from "mobx";
import { ApprovalDelegateAndRelatedDTO, ApprovalDelegateUserDataModel } from "./ApprovalDelegateUserDataModel";
import { DelegateUserModel } from "./DelegateUserModel";
import { AppUrls } from "AppUrls";
import { ApprovalDelegateViewModel } from "./ApprovalDelegateViewModel";
import { ApprovalDelegateDTO } from "./ApprovalDelegateModel";
import moment from "moment";

export class ApprovalDelegateUserDataViewModel extends ViewModelBase<ApprovalDelegateUserDataModel> {
    private static _instance: ApprovalDelegateUserDataViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable
    public approvalDelegateViewModel: ApprovalDelegateViewModel = new ApprovalDelegateViewModel();

    @observable public errorMessage: string = "";

    private constructor() {
        super(new ApprovalDelegateUserDataModel(), false);
        this.setDecorators(ApprovalDelegateUserDataModel);
    }

    @action
    public cleanUp = () => {
        this.model.cleanUp();
    };

    /**
     * Custom model validation function. Validates child models.
     * @returns True if model is valid, false if not.
     */
    public isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        if ((await this.approvalDelegateViewModel.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    @observable
    public delegateUsers = observable<DelegateUserModel>([]);

    @computed
    public get delegateUser() {
        const result = this.delegateUsers.find((st) => st.id === this.approvalDelegateViewModel.model.delegatedToUserId);

        return result ? result! : null;
    }

    @computed
    public get delegateUserVal() {
        const result = this.delegateUsers.find((st) => st.id === this.approvalDelegateViewModel.model.delegatedToUserId);

        return result ? result.id : null;
    }

    @action
    public setDelegateUser = async (value: DelegateUserModel | null) => {
        this.approvalDelegateViewModel.model.delegatedToUserId = value ? value.id : null;
    };

    @computed
    public get canAssignDelegation() {
        return !this.model.hasDelegation && !this.model.isDelegatee && !this.model.isDelegator;
    }

    @computed
    public get canDismissDelegatedApprovals() {
        return this.model.hasDelegation && this.model.isDelegator;
    }

    @observable
    private showHasDelegateApprovalsModal = false;

    @computed
    public get getShowHasDelegateApprovalsModal() {
        return this.model.isDelegator && this.showHasDelegateApprovalsModal;
    }

    @computed
    public get getHasDelegateApprovalsModalText() {
        if (this.model.isDelegator && this.approvalDelegateViewModel.model.delegatedToUserName && this.approvalDelegateViewModel.startDateFormatted) {
            return `Your approvals were delegated to ${this.approvalDelegateViewModel.model.delegatedToUserName} on the ${this.approvalDelegateViewModel.startDateFormatted}, do you wish for this to continue?`;
        }

        return "";
    }

    @observable
    private hasClosedHasDelegateApprovalsModalThisSession = false;

    @action
    public setHasClosedHasDelegateApprovalsModalThisSession = (val: boolean) => {
        this.hasClosedHasDelegateApprovalsModalThisSession = val;
    };

    @action
    public setShowHasDelegateApprovalsModal = (val: boolean) => {
        // Only show it once per session.
        if (!this.hasClosedHasDelegateApprovalsModalThisSession) {
            this.showHasDelegateApprovalsModal = val;
        }
    };

    @observable
    private showHasDelegationAddedNotificationModal = false;

    @computed
    public get getShowHasDelegationAddedNotificationModal() {
        return this.model.isDelegatee && this.showHasDelegationAddedNotificationModal;
    }

    @action
    public setShowHasDelegationAddedNotificationModal = (val: boolean) => {
        this.showHasDelegationAddedNotificationModal = val;
    };

    @action
    public handleCloseHasDelegationAddedNotificationModal = () => {
        this.setShowHasDelegationAddedNotificationModal(false);
    };

    @action
    public handleCloseHasDelegateApprovalsModal = () => {
        this.setShowHasDelegateApprovalsModal(false);
        this.setHasClosedHasDelegateApprovalsModalThisSession(true);
    };

    @action
    public handleDismissDelegateApprovalsDelegation = async () => {
        await this.dismissDelegatedApprovals().then((result) => {
            if (result.wasSuccessful) {
                this.handleCloseHasDelegateApprovalsModal();
            }
        });
    };

    @action
    public handleSetDelegationsAsNotified = async () => {
        await this.setDelegationsAsNotified().then((result) => {
            if (result.wasSuccessful) {
                this.handleCloseHasDelegationAddedNotificationModal();
            }
        });
    };

    @computed
    public get getDelegationsAddedNotifications() {
        let notifications: string[] = [];

        this.model.approvalDelegatedToUsers
            .filter((i) => !i.hasDelegatedUserViewedNotification || !i.hasDelegationBeenRemoved)
            .forEach((item) => {
                const startDateFormatted: string = moment(item.startDate).format("DD/MM/YYYY").toString();
                const notification: string = `You were delegated ${item.delegatedByUserName}'s approvals from the ${startDateFormatted}.`;
                notifications.push(notification);
            });

        return notifications;
    }

    @computed
    public get getDelegationsRemovedNotifications() {
        let notifications: string[] = [];

        this.model.approvalDelegatedToUsers
            .filter((i) => i.hasDelegationBeenRemoved)
            .forEach((item) => {
                const notification: string = `You have been removed from monitoring ${item.delegatedByUserName}'s approvals`;
                notifications.push(notification);
            });

        return notifications;
    }

    @observable
    private showHasDelegationRemovedNotificationModal = false;

    @computed
    public get getShowHasDelegationRemovedNotificationModal() {
        return this.model.isDelegatee && this.showHasDelegationRemovedNotificationModal;
    }

    @action
    public setShowHasDelegationRemovedNotificationModal = (val: boolean) => {
        this.showHasDelegationRemovedNotificationModal = val;
    };

    @action
    public handleCloseHasDelegationRemovedNotificationModal = () => {
        this.setShowHasDelegationRemovedNotificationModal(false);
    };

    public getDelegateData = async (): Promise<void> => {
        if (!this.getHasLoadedFromLogin) {
            this.setIsLoading(true);
            const path: string = AppUrls.Server.Approval.GetApprovalDelegateData;

            const apiResult = await this.Get<ApprovalDelegateAndRelatedDTO>(path);

            if (apiResult) {
                if (apiResult.wasSuccessful) {
                    runInAction(() => {
                        this.setServerData(apiResult.payload);
                    });
                } else {
                    console.log(apiResult.errors);
                }
            }
            this.setIsLoading(false);
        } else {
            this.setHasLoadedFromLogin(false);
        }
    };

    public dismissDelegatedApprovals = async (): Promise<ApiResult<ApprovalDelegateAndRelatedDTO>> => {
        this.setIsLoading(true);
        const path: string = AppUrls.Server.Approval.DismissApprovalDelegate;

        const apiResult = await this.Post<ApprovalDelegateAndRelatedDTO>(path);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.setServerData(apiResult.payload);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    public delegateApprovals = async (): Promise<ApiResult<ApprovalDelegateAndRelatedDTO>> => {
        this.setIsLoading(true);
        const path: string = AppUrls.Server.Approval.UpsertApprovalDelegation;

        let dto: ApprovalDelegateDTO = {
            id: null,
            delegatedByUserId: null,
            delegatedToUserId: this.approvalDelegateViewModel.model.delegatedToUserId,
            startDate: this.approvalDelegateViewModel.model.startDate,
            hasDelegatedUserViewedNotification: false,
            hasDelegationBeenRemoved: false,
            createdDate: null,
            createdByUserId: null,
            rowVersion: null,
        };

        const apiResult = await this.Post<ApprovalDelegateAndRelatedDTO>(path, dto);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.setHasClosedHasDelegateApprovalsModalThisSession(true);
                    this.setServerData(apiResult.payload);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);

        return apiResult;
    };

    public setDelegationsAsNotified = async (): Promise<ApiResult<ApprovalDelegateAndRelatedDTO>> => {
        this.setIsLoading(true);
        const path: string = AppUrls.Server.Approval.SetDelegationsAsNotified;

        const apiResult = await this.Post<ApprovalDelegateAndRelatedDTO>(path);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.setServerData(apiResult.payload);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    public deleteDelegations = async (): Promise<ApiResult<ApprovalDelegateAndRelatedDTO>> => {
        this.setIsLoading(true);
        const path: string = AppUrls.Server.Approval.DeleteDelegations;

        const apiResult = await this.Post<ApprovalDelegateAndRelatedDTO>(path);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.setServerData(apiResult.payload);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    /**
     * Prevents loading the data twice, once from the login and once from viewing the approvals list.
     */
    @observable
    private hasLoadedFromLogin: boolean = false;

    @action
    public setHasLoadedFromLogin = (val: boolean) => {
        this.hasLoadedFromLogin = val;
    };

    @computed
    public get getHasLoadedFromLogin() {
        return this.hasLoadedFromLogin;
    }

    @action
    public setServerData = (payload: ApprovalDelegateAndRelatedDTO, isFromLogin: boolean = false) => {
        runInAction(() => {
            this.setHasLoadedFromLogin(isFromLogin);
            this.setShowHasDelegateApprovalsModal(payload.approvalDelegateUserData.canViewHasDelegateApprovalsModal);

            if (isFromLogin) {
                this.setShowHasDelegationAddedNotificationModal(payload.approvalDelegateUserData.canViewDelegationAddedNotificationModal);
                this.setShowHasDelegationRemovedNotificationModal(payload.approvalDelegateUserData.canViewDelegationRemovedNotificationModal);
            }

            this.model.fromDto(payload.approvalDelegateUserData);
            this.approvalDelegateViewModel.model.fromRelatedDto(payload.approvalDelegateUserData.approvalDelegatedByUser);
            this.delegateUsers.replace(DelegateUserModel.fromDtos(payload.users));
        });
    };

    public async isFieldValid(fieldName: keyof FieldType<ApprovalDelegateUserDataModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

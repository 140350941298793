import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import * as InvoicePurchaseOrderModel from "./Supporting/InvoicePurchaseOrderModel";
import { UpsertInvoicePurchaseOrderItemDTO } from "./Supporting/InvoicePurchaseOrderItemModel";
import { InvoiceViewModel } from "../InvoiceViewModel";
import { ApprovalPanelBaseDTO } from "Globals/Models/ApprovalPanelModelBase";
import { RequisitionPOStatus } from "Views/Approval/PurchaseListViewModel";
import { PurchaseOrderItemDTO } from "./InvoiceMatchViewModel";
import { ApprovalHistoryItemDTO } from "Globals/ViewModels/ApprovalPanelViewModelBase";
export class InvoiceMatchModel extends ModelBase<InvoiceMatchModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_INVOICEID = "";
    public static readonly DEFAULT_TOTALINITIALVALUE = null;
    public static readonly DEFAULT_TOTALALREADYALLOCATED = null;
    public static readonly DEFAULT_INVOICEPURCHASEORDERS = [];
    public static readonly DEFAULT_IEID = null;
    public static readonly DEFAULT_ID = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public invoiceId: string = InvoiceMatchModel.DEFAULT_INVOICEID;

    @observable
    public totalInitialValue: number | null = InvoiceMatchModel.DEFAULT_TOTALINITIALVALUE;

    @observable
    public totalAlreadyAllocated: number | null = InvoiceMatchModel.DEFAULT_TOTALALREADYALLOCATED;

    @observable
    public invoicePurchaseOrders: InvoicePurchaseOrderModel.InvoicePurchaseOrderModel[] = InvoiceMatchModel.DEFAULT_INVOICEPURCHASEORDERS;

    @observable
    public ieId: string | null = InvoiceMatchModel.DEFAULT_IEID;

    @observable
    public id: string | null = InvoiceMatchModel.DEFAULT_ID;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.invoiceId = InvoiceMatchModel.DEFAULT_INVOICEID;
        this.totalInitialValue = InvoiceMatchModel.DEFAULT_TOTALINITIALVALUE;
        this.totalAlreadyAllocated = InvoiceMatchModel.DEFAULT_TOTALALREADYALLOCATED;
        this.invoicePurchaseOrders = InvoiceMatchModel.DEFAULT_INVOICEPURCHASEORDERS;
    };

    @action
    public addInvoicePurchaseOrder(po: InvoicePurchaseOrderModel.InvoicePurchaseOrderDTO): void {
        const poToAdd = new InvoicePurchaseOrderModel.InvoicePurchaseOrderModel();
        poToAdd.fromDto(po);

        this.invoicePurchaseOrders.push(poToAdd);
    }

    @action
    public deleteInvoicePurchaseOrder(invoicePurchaseOrderId: string): void {
        this.invoicePurchaseOrders = this.invoicePurchaseOrders.filter((i) => i.id !== invoicePurchaseOrderId);
    }

    @action
    public fromDto(dto: InvoiceMatchResponseDTO): void {
        this.invoiceId = dto.invoiceId;
        this.totalAlreadyAllocated = dto.totalAlreadyAllocated;
        this.totalInitialValue = dto.totalInitialValue;

        // Process the list of purchase orders.
        let processedPos: InvoicePurchaseOrderModel.InvoicePurchaseOrderModel[] = [];

        for (const po of dto.invoicePurchaseOrders) {
            const poToAdd = new InvoicePurchaseOrderModel.InvoicePurchaseOrderModel();
            poToAdd.fromDto(po);
            processedPos.push(poToAdd);
        }

        this.invoicePurchaseOrders = [...this.invoicePurchaseOrders, ...processedPos];
    }

    public toDto() {
        const model: InvoiceMatchRequestDTO = {
            invoiceId: this.invoiceId,
            ieId: this.ieId,
            invoicePurchaseOrderItems: [],
            rowVersion: InvoiceViewModel.GetInstance.detailsViewModel.model.rowVersion,
        };

        return model;
    }

    // #endregion Actions

    // #region Custom Validation

    // #endregion Custom Validation
}

/**
 * Data required for an existing invoice. Extends InvoiceRelatedResponseDTO.
 */
export interface InvoiceMatchResponseDTO {
    invoicePurchaseOrders: InvoicePurchaseOrderModel.InvoicePurchaseOrderDTO[];
    invoiceId: string;
    totalInitialValue: number | null;
    totalAlreadyAllocated: number | null;
    approvalPanel: ApprovalPanelBaseDTO;
    requisitionPOStatuses: RequisitionPOStatus[];
    approvalHistoryItems: ApprovalHistoryItemDTO[];
    purchaseOrders: PurchaseOrderItemDTO[];
}

export interface InvoiceMatchRequestDTO {
    invoiceId: string;
    ieId: string | null;
    invoicePurchaseOrderItems: UpsertInvoicePurchaseOrderItemDTO[];
    rowVersion: string | null;
}

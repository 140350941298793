import { MenuItem } from "@material-ui/core";
import { styled } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { DeleteModal } from "Components/Modal/DeleteModal";
import DeleteLogo from "Content/Bin.svg";
import PictureIcon from "Content/picture.svg";
import { InductionType } from "Globals/Models/Enums/InductionType";
import { DashedDivider, SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import { InductionPersonalProtectiveEquipmentDTO, InductionQualificationDTO } from "./InductionAdminItemModel";
import { InductionAdminItemViewModel } from "./InductionAdminItemViewModel";
import { InductionPhotoEvidenceModal } from "./InductionPhotoEvidenceModal";
import {
    AnswerTile,
    AnswerTileContainer,
    CustomSelect,
    InductionDetailsPageContainer,
    InductionItemRow,
    InductionItemRows,
    IsApprovedSection,
    ItemDetailsContainer,
    ItemDetailsSection,
    ItemDetailsSectionRow,
    QualificationTable,
    SignatureSection,
    ToggleButton,
    VideoResponseTile,
    VideoResponseTileContainer,
} from "./InductionAdminDetailsView.styles";
import { InductionAdminViewModel } from "../InductionAdminViewModel";

interface IProps {
    className?: string;
    viewModel: InductionAdminItemViewModel;
}

interface PhotoEvidence {
    id: string;
    qualificationName: string;
    photoUrl: string;
}

const InductionAdminDetailsViewBase: React.FC<IProps> = (props) => {
    const { viewModel } = props;
    const [activeItemId, setActiveItemId] = React.useState<string | null>(null);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [photoEvidence, setPhotoEvidence] = React.useState<PhotoEvidence | null>(null);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    // #region Code Behind

    const [color, setColor] = useState<string>("");
    const [backgroundColor, setBackgroundColor] = useState<string>("");

    const openEvidenceModal = (evidence: PhotoEvidence) => {
        setPhotoEvidence(evidence);
        setIsOpen(true);
    };

    const closeEvidenceModal = () => {
        setPhotoEvidence(null);
        setIsOpen(false);
    };

    const handleOnDownloadPhotoEvidence = async () => {
        if (photoEvidence && photoEvidence.photoUrl) {
            try {
                const a = document.createElement("a");
                a.href = await toDataURL(photoEvidence?.photoUrl);
                a.download = "myImage.png";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } catch (exception) {
                // do nothing
            }
        }
    };

    const toDataURL = (url: string) => {
        return fetch(url)
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                return URL.createObjectURL(blob);
            });
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const handleDeleteInduction = () => {
        viewModel.deleteInduction();
        setDeleteOpen(false);
    };

    useEffect(() => {
        if (viewModel.inductionAdminViewModelExists) {
            const currentStatus = InductionAdminViewModel.GetInstance.inductionStatuses.find(
                (s) => s.id === InductionAdminViewModel.GetInstance.inductionAdminItemViewModel.model.inductionStatusId,
            );
            if (currentStatus) {
                setColor(currentStatus.textColor);
                setBackgroundColor(currentStatus.color);
            }
        }
    }, []);

    const onSelect = async (value: any) => {
        const newStatus = InductionAdminViewModel.GetInstance.inductionStatuses.find((s) => s.id === value);

        if (newStatus) {
            setColor(newStatus.textColor);
            setBackgroundColor(newStatus.color);
            InductionAdminViewModel.GetInstance.inductionAdminItemViewModel.model.setValue("inductionStatusId", value);
            await viewModel.updateInductionStatus(value);
        }
    };

    const getPassedValue = (val: boolean | null) => {
        if (val === null) {
            return <span>N/A</span>;
        } else if (val === true) {
            return <span className="red">Y</span>;
        } else {
            return <span>N</span>;
        }
    };

    const renderQualificationsTable = (items: InductionQualificationDTO[], nameTitle: string, isDefault: boolean = false) => {
        return (
            <QualificationTable>
                <div className="columns">
                    <div className="column">{nameTitle}</div>
                    <div className="column">Yes/No</div>
                    <div className="column">Type/Level</div>
                    {!isDefault && <div className="column">ID/Roll number</div>}
                    {!isDefault && <div className="column">Expiry date</div>}
                    {!isDefault && <div className="column">Photo evidence</div>}
                </div>
                <div className="rows">
                    {items.map((i) => {
                        return (
                            <div className="row">
                                <div className="cell">{i.name}</div>
                                <div className="cell">{getPassedValue(i.passed)}</div>
                                <div className="cell">{i.typeLevel}</div>
                                {!isDefault && <div className="cell">{i.idRollNumber}</div>}
                                {!isDefault && <div className="cell">{i.expiryDate ? moment(i.expiryDate).format("DD/MM/YYYY") : ""}</div>}
                                {!isDefault && (
                                    <div className="cell">
                                        {i.photoEvidenceUrl && i.photoEvidenceUrl !== "" && (
                                            <img
                                                style={{ cursor: "pointer" }}
                                                src={PictureIcon}
                                                onClick={() => openEvidenceModal({ id: i.id, qualificationName: i.name, photoUrl: i.photoEvidenceUrl })}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </QualificationTable>
        );
    };

    const renderPersonalProtectiveEquipmentTable = (items: InductionPersonalProtectiveEquipmentDTO[]) => {
        return (
            <QualificationTable>
                <div className="columns">
                    <div className="column">Personal Protective Equipment Group</div>
                    <div className="column-autowidth">Equipment</div>
                </div>
                <div className="rows">
                    {items.map((i) => {
                        return (
                            <div className="row" key={i.id}>
                                <div className="cell">{i.displayName}</div>
                                <div className="cell-autowidth">{i.types}</div>
                            </div>
                        );
                    })}
                </div>
            </QualificationTable>
        );
    };

    const renderItemDetails = (item: InductionAdminItemViewModel) => {
        return (
            <ItemDetailsContainer>
                <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                {/* Age */}
                <ItemDetailsSection>
                    <ItemDetailsSectionRow>
                        <p style={{ marginRight: "170px" }}>
                            <span>Age:</span> {item.model.age}
                        </p>
                        <p>
                            <span>Over 18:</span> {item.confirmOver18Formatted}
                        </p>
                    </ItemDetailsSectionRow>
                    <p>
                        <span>Please provide any adjustments need to be made for you based on your age: </span> {item.confirmAdjustmentsFormatted}
                    </p>
                </ItemDetailsSection>
                <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                {/* Health problems */}
                <ItemDetailsSection>
                    <p>
                        <span>Do you have any health problems that could affect your ability to carry out your tasks safely?</span> {item.healthProblemsFormatted}
                    </p>
                    <AnswerTileContainer>
                        <p>
                            <span>Details: </span>
                        </p>
                        <AnswerTile>
                            <p>{item.model.healthDetails}</p>
                        </AnswerTile>
                    </AnswerTileContainer>
                </ItemDetailsSection>
                <SolidDivider gutterBottom />
                {/* Skills/Qualifications, Plants/Equipment and Personal Protective Equipment */}
                {(() => {
                    switch (true) {
                        case viewModel.inductionType?.IsOfType(InductionType.Full):
                            return (
                                <ItemDetailsSection>
                                    <p>
                                        <span>What Skills & Training/Qualifications do you have?</span>
                                    </p>
                                    {renderQualificationsTable(viewModel.model.skillsTrainingOrQualifications, "Skills & training/qualification")}
                                    <p>
                                        <span>What Plant & Equipment Training/Qualifications do you have?</span>
                                    </p>
                                    {renderQualificationsTable(viewModel.model.plantAndEquipmentTrainingOrQualifications, "Plant & Equipment Training/Qualifications")}
                                    <p>
                                        <span>What Personal Protective Equipment have you brought with you?</span>
                                    </p>
                                    {renderPersonalProtectiveEquipmentTable(viewModel.model.personalProtectiveEquipment)}
                                </ItemDetailsSection>
                            );

                        default:
                            return <React.Fragment />;
                    }
                })()}
                {/* Video confirmation */}
                <ItemDetailsSection>
                    <p>
                        <span>Induction video watched, you hereby fully confirm and accept, without exception, that you:</span>
                    </p>
                    {/* General */}
                    <VideoResponseTileContainer>
                        <p>
                            <span>General</span>
                        </p>
                        <VideoResponseTile>
                            <div>
                                <p>Fully understood the video induction and all messages contained within the video.</p>
                                <p>Know where to find a full copy of the Portakabin - Trial Health and Safety Policy.</p>
                                <p>Have read and understood your own company Health and Safety Policy.</p>
                                <p>Will adhere to all site rules and instructions relating to health, safety and housekeeping from site management/supervision.</p>
                            </div>
                            <div>{item.isGeneralConfirmedFormatted}</div>
                        </VideoResponseTile>
                    </VideoResponseTileContainer>
                    {/* PPE */}
                    <VideoResponseTileContainer>
                        <p>
                            <span>PPE</span>
                        </p>
                        <VideoResponseTile>
                            <div>
                                <p>Fully understand the Personal Protective Equipment rules and requirements of the site.</p>
                                <p>Fully understand the Personal Protective Equipment requirements for all tasks that you are undertaking.</p>
                                <p>
                                    Have the correct Personal Protective Equipment, in good working order and free of defect or fault, required to undertake your tasks or visit the
                                    site safely.
                                </p>
                            </div>
                            <div>{item.isPPEConfirmedFormatted}</div>
                        </VideoResponseTile>
                    </VideoResponseTileContainer>
                    {/* Safety */}
                    <VideoResponseTileContainer>
                        <p>
                            <span>Safety</span>
                        </p>
                        <VideoResponseTile>
                            <div>
                                <p>
                                    Understand and accept that under no circumstances will you access the site without permanent escort from the Portakabin - Trial site management
                                    team.
                                </p>
                                <p>
                                    Understand and accept that, at certain parts of the construction period, certain areas of the site will be deemed unsafe for visitors and access
                                    will be prohibited to those areas.
                                </p>
                                <p>Understand and accept that during site tours, you will ensure you are with the site management team at all times during the visit.</p>
                                <p>Understand and accept that you must obey all instructions given by the site management team at all times during the visit.</p>
                            </div>
                            <div>{item.isSafetyConfirmedFormatted}</div>
                        </VideoResponseTile>
                    </VideoResponseTileContainer>
                    {/* Housekeeping */}
                    <VideoResponseTileContainer>
                        <p>
                            <span>Housekeeping</span>
                        </p>
                        <VideoResponseTile>
                            <div>
                                <p>Understand and accept the housekeeping standards and the requirement for daily housekeeping.</p>
                                <p>Understand and accept the Clean Up Notice protocols operated by Portakabin - Trial should you not adhere to housekeeping standards.</p>
                                <p>Understand the environmental impact of chemical spillages and understand the correct emergency spill response procedures.</p>
                                <p>Will dispose of waste in the correct way at all times.</p>
                                <p>Will not burn any waste, under any circumstances.</p>
                            </div>
                            <div>{item.isHousekeepingConfirmedFormatted}</div>
                        </VideoResponseTile>
                    </VideoResponseTileContainer>
                    {/* Rules/Discipline */}
                    <VideoResponseTileContainer>
                        <p>
                            <span>Rules/Discipline</span>
                        </p>
                        <VideoResponseTile>
                            <div>
                                <p>Fully understand the discipline expected from you whilst working on a Portakabin - Trial site.</p>
                                <p>Fully understand and accept the red/yellow card system operated on Portakabin - Trial sites.</p>
                                <p>Fully understand and accept that bullying/harassment of any kind will not be tolerated and the reporting procedures.</p>
                                <p>Will not smoke, vape, or otherwise use e-cigarettes anywhere other than designated smoking areas or outside of the site boundaries.</p>
                                <p>Understand and will adhere to the rules on mobile phones and electronic devices, portable radios, entertainment systems etc.</p>
                            </div>
                            <div>{item.isRulesOrDisciplineConfirmedFormatted}</div>
                        </VideoResponseTile>
                    </VideoResponseTileContainer>
                    {/* Site Specific Briefing */}
                    <VideoResponseTileContainer>
                        <p>
                            <span>Site Specific Briefing</span>
                        </p>
                        <VideoResponseTile>
                            <div>
                                <p>Will report to the Site Manager for a site specific briefing prior to entering the site for the first time.</p>
                                <p>
                                    Will not, <div style={{ display: "inline", textDecoration: "underline" }}>under any circumstances,</div> enter site for the first time without
                                    having received this briefing.
                                </p>
                            </div>
                            <div>{item.isRulesOrDisciplineConfirmedFormatted}</div>
                        </VideoResponseTile>
                    </VideoResponseTileContainer>
                </ItemDetailsSection>
                <SolidDivider gutterBottom />
                {viewModel.hasSignature && (
                    <>
                        <SignatureSection>
                            <p>
                                <span>Signed by {viewModel.model.fullName.toUpperCase()}</span>
                            </p>
                            <div>
                                <img src={viewModel.model.signatureURL} alt="User signature" />
                            </div>
                        </SignatureSection>
                        <SolidDivider gutterBottom />
                    </>
                )}
                <IsApprovedSection>
                    <p>
                        <span>{viewModel.isApprovedStringFormatted}</span>
                    </p>
                </IsApprovedSection>
            </ItemDetailsContainer>
        );
    };

    const renderItems = () => {
        return (
            <InductionItemRows>
                <div>
                    <InductionItemRow>
                        <div>
                            <span style={{ color: "#e03c31" }}>{viewModel.model.visitorTypeName}</span>
                            {activeItemId === viewModel.model.id ? (
                                <ToggleButton>
                                    <span
                                        className={"open"}
                                        onClick={() => {
                                            setActiveItemId(null);
                                        }}
                                    >
                                        &minus;
                                    </span>
                                </ToggleButton>
                            ) : (
                                <ToggleButton>
                                    <span
                                        className={"opcloseen"}
                                        onClick={() => {
                                            setActiveItemId(viewModel.model.id);
                                        }}
                                    >
                                        &#43;
                                    </span>
                                </ToggleButton>
                            )}
                        </div>
                        <div>
                            <strong>Submitted:</strong> {viewModel.createdAtFormatted}
                        </div>
                        <div>
                            <CustomSelect
                                value={viewModel.getCurrentStatusId}
                                onChange={(event) => (onSelect ? onSelect(event.target.value) : {})}
                                disabled={viewModel.IsLoading || (viewModel.inductionAdminViewModelExists ? InductionAdminViewModel.GetInstance.IsLoading : false)}
                                backgroundColor={backgroundColor}
                                textColor={color}
                            >
                                {viewModel.inductionAdminViewModelExists &&
                                    InductionAdminViewModel.GetInstance.inductionStatusOptions?.map((item: any, index: number) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        );
                                    })}
                            </CustomSelect>
                        </div>
                        <div style={{ cursor: "pointer" }} className="icon" onClick={() => setDeleteOpen(true)}>
                            <img src={DeleteLogo} />
                        </div>
                    </InductionItemRow>
                    {activeItemId === viewModel.model.id && renderItemDetails(viewModel)}
                    <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                </div>
            </InductionItemRows>
        );
    };

    const renderPage = () => {
        return (
            <InductionDetailsPageContainer>
                {renderItems()}
                <DeleteModal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onDelete={handleDeleteInduction}
                    title="Delete induction?"
                    text="Are you sure you want to delete this induction?"
                    isLoading={viewModel.IsLoading}
                />
                <InductionPhotoEvidenceModal
                    open={isOpen}
                    onClose={closeEvidenceModal}
                    title={`Photo evidence - ${photoEvidence?.qualificationName}`}
                    onDownload={handleOnDownloadPhotoEvidence}
                >
                    <div className="modal-photoevidence">
                        <div className="modal-photoevidence-body">
                            <div className="photoEvidence-container">
                                <img src={photoEvidence?.photoUrl} />
                            </div>
                        </div>
                    </div>
                </InductionPhotoEvidenceModal>
            </InductionDetailsPageContainer>
        );
    };

    return useObserver(() => renderPage());
};

export const InductionAdminDetailsView = styled(InductionAdminDetailsViewBase)``;

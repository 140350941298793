import styled from "styled-components";
import { theme } from "Globals/Styles/AppTheme";
import { Box } from "@material-ui/core";

const GridViewBase: any = styled.ul`
    list-style: none;
    margin: 0px 10px 50px;
    li {
        width: 100%;
        border-bottom: solid 1px #dddddd;
        display: grid;
        grid-template-columns: minmax(280px, 1fr) repeat(auto-fit, minmax(100px, 1fr));
        grid-template-rows: 35px;
        gap: 0px 0px;
        grid-auto-flow: column;
        height: 50px;

        > div {
            height: 50px;
            padding: 10px 20px;
            font-size: 12px;
            font-weight: bold;
            border-right: solid 1px #dddddd;
        }

        > div:first-of-type {
            //border-left: solid 1px #dddddd;
        }

        > div:last-of-type {
            border-right: none;
        }

        > .noPadding {
            padding: 0px;
        }
    }

    .heading,
    .footing,
    .project,
    .ie,
    .category,
    .subcategory,
    .item {
        & > * {
            display: flex;
            align-items: center;
        }

        .ie-row-name {
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .ie-item-name {
            max-width: 138px;
        }

        .ie-subcat-name {
            max-width: 160px;
        }

        .ie-cat-name {
            max-width: 190px;
        }

        .ie-po-name {
            -webkit-line-clamp: 1;
            max-width: 170px;
        }

        .right {
            /* text-align: right !important; */
            justify-content: flex-end;
        }

        .centre {
            justify-content: center;
        }

        .credit {
            background-color: #caead4;
        }

        .debit {
            background-color: #ecb4b4;
        }

        .notdebitorcredit {
            background-color: #efe350bf;
        }

        .italic {
            font-style: italic;
        }
    }

    .subcategory {
        > div {
            > div {
                display: flex;
                align-items: center;
            }
        }
    }

    .subcategory div.MuiInput-root,
    .subcategory div.MuiFormControl-root,
    .subcategory div.MuiAutocomplete-root {
        padding-left: 0px;
    }

    .subcategory div.MuiAutocomplete-root {
        padding-right: 20px;
    }

    .item,
    .title {
        background-color: #f6fcff;

        .MuiAutocomplete-root,
        .MuiBox-root {
            padding: 0px;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .title {
        grid-template-rows: none;
        height: 80px;

        > div:nth-child(1) {
            font-size: 16px;
        }

        > div {
            padding: 30px 20px 10px 20px;
            height: 100%;
        }
    }

    .heading {
        background-color: #ced4da;
        height: 60px;
        line-height: 1.25;
        padding: 5px 0;
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    .inputIERow {
        border-bottom: dashed 1px #dddddd;
    }
`;

export const SummaryGridView: any = styled(GridViewBase)``;

export const GridHeader: any = styled.div`
    position: sticky;
    top: 68px;
    @media (max-width: 1379px) {
        top: 136px;
    }
    @media (max-width: 775px) {
        top: 65px;
    }
    @media (max-width: 599px) {
        top: 48px;
    }
    ul {
        margin-bottom: 0px;
    }
`;

export const SubsGridView: any = styled(GridViewBase)`
    font-style: italic;
    color: #6c93a6;
    border-top: solid 1px #ced4da;
    border-bottom: solid 1px #ced4da;

    > div {
        border-right: #ffffff;
    }
`;

export const MasterSummaryGridView: any = styled(GridViewBase)`
    border-top: solid 4px ${theme.palette.detail.main};
    border-bottom: solid 4px ${theme.palette.detail.main};
    font-weight: bold;
`;

export const ModalGridView: any = styled(GridViewBase)`
    /* max-width: 807px; */
    width: 100%;
    margin: 0px;
    overflow-x: auto;
    li {
        grid-template-columns: repeat(auto-fit, 104px);
        padding: 0 !important;
        display: flex;
        border: none !important;
        justify-content: center;
        & > div {
            padding: 5px !important;
            flex: 115px 0 0;
            box-sizing: border-box;
            align-items: center !important;
            justify-content: center !important;
            border-bottom: solid 1px #dddddd;
            .MuiInputBase-root.MuiInput-root {
                width: 100%;
                margin: 0 !important;
            }
        }
        &.tableHeading {
            border: none !important;
            background: transparent !important;
            & > div {
                border-bottom: solid 1px #dddddd;
                background-color: #ced4da;
            }
        }
    }

    .heading {
        background-color: #ced4da;
        height: 40px;
        line-height: 1.25;
        overflow: hidden;
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 11px;
        }
    }
`;

export const ModalEditBox: any = styled(Box)`
    .row {
        display: flex;
        width: 100%;
        flex-direction: row;

        label,
        .MuiAutocomplete-root {
            width: 50%;
            display: inline-flex;
            padding: 0;
            margin: 0;
            //max-width: 389px;
        }

        .MuiAutocomplete-root {
            margin-bottom: 16px;
            height: 30px;
        }

        label:first-of-type,
        .MuiAutocomplete-root:first-of-type {
            margin-right: 30px;
        }

        label {
            font-size: 12px;
            letter-spacing: -0.18px;
            color: #191919;
        }
    }

    .fullwidth {
        label,
        .MuiAutocomplete-root {
            width: 100%;
            max-width: 808px;
        }

        label:first-of-type,
        .MuiAutocomplete-root:first-of-type {
            margin-right: 0px;
        }

        .MuiAutocomplete-root {
            margin-bottom: 0px;
        }
    }
`;

export const ActionButtonHolder: any = styled.div`
    right: 10px;
    width: 95%;
    margin: 10px auto;
    min-height: 1px;
    display: flex;
    flex-direction: row-reverse;
`;

export const BackButtonHolder: any = styled(ActionButtonHolder)`
    margin: 10px auto 0px;
    width: 97%;
    flex-direction: row;
    cursor: pointer;
`;

export const BorderedActionButtonHolder: any = styled(ActionButtonHolder)`
    width: 97%;
    border-top: solid 1px #ced4da;
    padding: 10px 0px 0px;
    position: relative;
    align-items: center;
    height: 50px;

    h1 {
        position: absolute;
        left: 0px;
        letter-spacing: -0.64px;
        margin: 0;
        padding: 0;
        line-height: 1;
    }
`;

export const CentralDropdownContainer: any = styled(ActionButtonHolder)`
    padding: 10px 0px 10px 0px;
    border-top: solid 1px #ced4da;
    justify-content: start;
    margin: 10px 30px;

    .MuiFormControl-root {
        max-width: 256px;
    }
`;

const StyledButton: any = styled.div`
    display: inline-block;
    cursor: pointer;
    padding-left: 0px !important;
    color: #ffffff;
`;

export const AddButton: any = styled(StyledButton)`
    width: 70px;
    height: 30px;
    background: #e03c31 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 14px Open Sans;
    letter-spacing: -0.28px;
    color: #ffffff;
    transition: transform 0.2s ease-in-out;
    :hover {
        transform: scale(1.05);
    }
`;

export const SaveButton: any = styled(StyledButton)`
    width: 30px;
    height: 30px;
    background: #e03c31 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    :hover {
        transform: scale(1.05);
    }
`;

export const CancelButton: any = styled(StyledButton)`
    width: 30px;
    height: 30px;
    background: #191919 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    :hover {
        transform: scale(1.05);
    }
`;

export const IEButton: any = styled(StyledButton)`
    width: 262px;
    height: 30px;
    background: #e03c31 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 14px Open Sans;
    letter-spacing: -0.28px;
    color: #ffffff;
    transition: transform 0.2s ease-in-out;
    margin-left: 30px;
    user-select: none;
    :hover {
        transform: scale(1.05);
    }
`;

export const IECancelButton: any = styled(IEButton)`
    background: #191919 0% 0% no-repeat padding-box;
`;

export const CheckMark: any = styled.span`
    display: inline-block;
    transform: rotate(45deg);
    height: 13px;
    width: 7px;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
`;

export const ToggleButton: any = styled.span`
    font-size: 22px;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
    :hover {
        transform: scale(1.3);
    }

    .open {
        color: #e03c31;
    }

    .close {
        color: #191919;
    }

    user-select: none;
    margin-left: 34px;
`;

export const IEPOItemTitle: any = styled.div`
    display: flex;
    flex-direction: row;

    > div {
        display: flex;
        flex-direction: column;

        > span:last-child {
            width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export const VARPOItemTitle: any = styled.div`
    display: flex;
    flex-direction: row;

    > div {
        display: flex;
        flex-direction: column;
    }
`;

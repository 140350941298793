import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";

export class ProjectsAllReportModel extends ModelBase<ProjectsAllReportModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public reportEndDateBottom: string | null = moment().utc().startOf("day").toISOString();

    @observable
    public reportEndDateTop: string | null = null;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.reportEndDateBottom = moment().utc().startOf("day").toISOString();
        this.reportEndDateTop = null;
    };

    @action
    public fromDto(dto: any): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): void {}

    // #endregion Actions

    // #region Custom Validation

    // #endregion Business Logic
}

export type ProjectsReportResponseDTO = {
    projectGridTop: Projects_DTO;
    projectGridBottom: Projects_DTO;
};

export type Projects_DTO = {
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    margin: number;
    roValue: number;
    projects: Project_DTO[];
};

export type Project_DTO = {
    projectId: string;
    projectName: string;
    projectReference: string;
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    margin: number;
    roValue: number;
};

export type ProjectsReportRequestDTO = {
    toDateTop: string | null;
    toDateBottom: string | null;
};

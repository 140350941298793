import React, { useEffect, useState } from "react";
import { Grid, styled, useObserver, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { AppUrls } from "AppUrls";
import { DarwinTablePageContent, DarwinTableSearchBox, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { TableListSearch } from "Components/Table/TableListSearch";
import { CustomHr } from "Views/Project/CustomComponents";
import { InvoicingListViewModel } from "./InvoicingListViewModel";
import { InvoicingListItemViewModel } from "./InvoicingListItemViewModel";
import { MultiSelectView } from "Components/Select/MultiSelect";
import { PatchedPagination } from "../../Components/Table/PatchedPagination";
import { InvoiceListFilterViewModel } from "./InvoiceListFilterViewModel";
import { InvoiceListViewModel } from "./InvoiceListViewModel";
import { InvoicingListFilterViewModel } from "./InvoicingListFilterViewModel";

interface IProps {
    ieId: string;
}

export const InvoicingListView: React.FC<IProps> = (props: IProps) => {
    const { ieId } = props;
    const tableOptions = Defaults.GetDarwinTableOptions() as any;
    const [viewModel] = useState(() => (ieId ? InvoiceListViewModel.Instance : InvoicingListViewModel.Instance));
    const { history } = useRouter();
    const router = useRouter();

    const filtersViewModel = ieId && ieId !== "" ? InvoiceListFilterViewModel.Instance : InvoicingListFilterViewModel.Instance;

    useEffect(() => {
        viewModel.apiGetAll(ieId).then((result) => {
            if (result.wasSuccessful === true) {
                filtersViewModel.setStatuses(result.payload.invoiceStatusType, true);
                filtersViewModel.setProjects(result.payload.projects, true);
                filtersViewModel.setSuppliers(result.payload.suppliers, true);
            }
        });

        return () => {
            //viewModel.setSearchString("");
            //filtersViewModel.resetModel();
        };
    }, []);

    const handleRowClick = (e: any, rowData: InvoicingListItemViewModel | undefined) => {
        if (rowData) {
            if (props.ieId) {
                const url: string = AppUrls.Client.Invoicing.EditIE.replace(":ieid", props.ieId).replace(":invoiceid", rowData.id).replace(":approvalMode", "false");
                history.push(url);
            } else {
                const url: string = AppUrls.Client.Invoicing.Edit.replace(":invoiceid", rowData.id).replace(":approvalMode", "false");
                history.push(url);
            }
        }
    };

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
    };

    const renderPage = () => {
        return (
            <div style={{ width: "100%", paddingTop: 22 }}>
                <DarwinTableToolbar>
                    <FilterRow>
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Status"
                            fieldName="statuses"
                            values={filtersViewModel.model.statuses}
                            getOptions={filtersViewModel.getStatusOptions}
                            handleChange={(values: number[] | null) => viewModel.handleStatusChange(values, ieId)}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Project"
                            fieldName="projects"
                            values={filtersViewModel.model.projects}
                            getOptions={filtersViewModel.getProjectOptions}
                            handleChange={(values: number[] | null) => viewModel.handleProjectChange(values, ieId)}
                        />
                        <MultiSelectView
                            style={{ maxWidth: "200px", marginRight: "20px" }}
                            display="Supplier"
                            fieldName="suppliers"
                            values={filtersViewModel.model.suppliers}
                            getOptions={filtersViewModel.getSupplierOptions}
                            handleChange={(values: number[] | null) => viewModel.handleSupplierChange(values, ieId)}
                        />
                    </FilterRow>
                    <br />
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox className="invoice-search-box">
                            <TableListSearch searchString={viewModel.getSearchStringComputed()} setSearchString={setSearchString} />
                        </DarwinTableSearchBox>
                    </Grid>
                </DarwinTableToolbar>
                <Grid padding={"0px 30px"}>
                    <span className="small-title">Showing: {viewModel.filteredInvoicingViewModels.length} results</span>
                </Grid>
                <CustomHr margin={"6px 0"} padding={"0px 30px"} />
                <Grid margin={"20px 0"}>
                    <DarwinTablePageContent className="invoice-Table">
                        <MaterialTable
                            columns={[
                                { title: "Invoice number", field: "invoiceNumber" },
                                { title: "Project name", field: "projectName" },
                                { title: "Invoice date", field: "invoiceDate", type: "date", render: (rowData: InvoicingListItemViewModel) => <>{rowData.invoiceDateFormatted}</> },
                                { title: "Approver", field: "approverRoleName" },
                                { title: "Supplier", field: "supplierName" },
                                {
                                    title: "Status",
                                    field: "statusName",
                                    render: (rowData: InvoicingListItemViewModel) => (
                                        <StatusCell
                                            className="Unknown"
                                            style={{
                                                backgroundColor: rowData.statusColor,
                                                color: rowData.statusTextColor,
                                                margin: "3px 0",
                                                padding: "2px 0",
                                            }}
                                        >
                                            {rowData.statusNameFormatted}
                                        </StatusCell>
                                    ),
                                },
                                {
                                    title: "Value",
                                    field: "invoiceValue",
                                    render: (rowData: InvoicingListItemViewModel) => <>{rowData.formattedInvoiceValue}</>,
                                },
                                { title: "Approved date", field: "approvedDate", render: (rowData: InvoicingListItemViewModel) => <>{rowData.approvedDateFormatted}</> },
                            ]}
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                            data={viewModel.filteredInvoicingViewModels}
                            onRowClick={handleRowClick}
                            isLoading={viewModel.IsLoading}
                        />
                    </DarwinTablePageContent>
                </Grid>
            </div>
        );
    };

    return useObserver(() => renderPage());
};

export const FilterRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 8px 0;
    align-items: flex-start;
`;

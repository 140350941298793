// Libraries
import { styled, useObserver } from "@shoothill/core";
import React, { useEffect } from "react";

// Custom
import { ProjectListViewModel } from "../ProjectListViewModel";

// Styling & Images
import { ProjectListModelWithAddress } from "../ProjectListModelWithAddress";
import { SHBox } from "../../../Components/Box";
import { GlobalHistory } from "../../../index";
interface Props {
    viewModel: ProjectListViewModel;
    projectDetails: ProjectListModelWithAddress;
}
export const SiteTabletScaffoldView: React.FunctionComponent<Props> = (props: Props) => {
    const { viewModel, projectDetails } = props;

    useEffect(() => {
        //PackagesViewModel.Instance.apiGetRelated();
        viewModel.apiAddScaffold(projectDetails.id);
        return () => {};
    }, []);

    return useObserver(() => {
        return <></>;
    });
};

const Tile = styled(SHBox)`
    display: grid;
    grid-template-columns: 60px 1fr;
    border: 1px solid #e03c31;
    margin-bottom: 15px;
    border-radius: 10px;
    cursor: pointer;
    //height: 25%;
    .number {
        color: #e03c31;
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        align-self: center;
    }
    .title {
        display: flex;
        align-items: center;
        padding-left: 20px;
        color: white;
        background-color: #e03c31;
        font-size: 56px;
        align-self: center;
        height: 100%;
    }
`;
interface CardProps {
    number: number;
    title: string;
    url: string;
}

const CardView: React.FunctionComponent<CardProps> = (props: CardProps) => {
    const handleClick = () => {
        GlobalHistory.push(props.url);
    };
    return (
        <Tile onClick={handleClick}>
            <SHBox className={"number"}>{props.number}</SHBox>
            <SHBox className={"title"}>{props.title}</SHBox>
        </Tile>
    );
};

import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "@shoothill/core";
import { DarwinPage, DarwinPageTitle } from "Globals/Styles/AppStyling";
import { InvoicingListView } from "Views/Invoices/InvoicingListView";
import { InvoicingListViewModel } from "./InvoicingListViewModel";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";

export const InvoiceListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new InvoicingListViewModel());

    useEffect(() => {
        IEGridItemViewModel.Instance.setIsCentral(false);
    }, []);

    const renderPage = () => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Invoices
                    </Typography>
                </DarwinPageTitle>
                <InvoicingListView ieId={viewModel.workingId} />
            </DarwinPage>
        );
    };

    return useObserver(() => renderPage());
};

import { isNullOrUndefined, observable } from "@shoothill/core";

import { ApprovalHistoryDTO } from "./ApprovalHistoryDTO";
import { ApprovalHistoryItemViewModel } from "./ApprovalHistoryItemViewModel";

export class ApprovalHistoryViewModel {
    public approvalHistoryItems = observable<ApprovalHistoryItemViewModel>([]);

    public fromDto = (dtos: ApprovalHistoryDTO[]) => {
        const approvalHistoryItems: ApprovalHistoryItemViewModel[] = [];

        if (!isNullOrUndefined(dtos)) {
            for (const dto of dtos) {
                approvalHistoryItems.push(new ApprovalHistoryItemViewModel(dto.id, dto.type, dto.updatedBy, dto.updatedDate, dto.notes, dto.approvalDocuments));
            }
        }

        this.approvalHistoryItems.replace(approvalHistoryItems);
    };
}

import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "@shoothill/core";
import { PurchaseOrderListViewModel } from "./PurchaseOrderListViewModel";
import { DarwinPage, DarwinPageTitle } from "Globals/Styles/AppStyling";
import { PurchaseOrderListFilterView } from "./PurchaseOrderListFilterView";
import { PurchaseOrderListFilterViewModel } from "./PurchaseOrderListFilterViewModel";
import { AllPurchaseOrderTableView } from "./AllPurchaseOrderTableView";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";

export const PurchaseOrderListView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new PurchaseOrderListViewModel());
    const filtersViewModel = PurchaseOrderListFilterViewModel.Instance;
    useEffect(() => {
        IEGridItemViewModel.Instance.setIsCentral(false);
        viewModel.GetAllWithFiltersAsync().then((result) => {
            if (result.wasSuccessful === true) {
                filtersViewModel.setCategories(result.payload.packageCategories, true);
                filtersViewModel.setStatuses(result.payload.statuses, true);
                filtersViewModel.setPurchaseUsers(result.payload.purchaseUsers, true);
                filtersViewModel.setOrderTypes(result.payload.orderTypes, true);
                filtersViewModel.setPurchaseUsers(result.payload.purchaseUsers, true);
                filtersViewModel.setProjects(result.payload.projects, true);
            }
            /* TODO */
        });

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const renderPage = () => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        Purchase Orders
                    </Typography>
                </DarwinPageTitle>
                <PurchaseOrderListFilterView purchaseOrderListViewModel={viewModel} filtersViewModel={filtersViewModel} />
                <AllPurchaseOrderTableView data={viewModel.getPurchaseOrdersList} isLoading={viewModel.IsLoading} downloadPDF={viewModel.apiGeneratePOPDF} />
            </DarwinPage>
        );
    };

    return useObserver(() => renderPage());
};

// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

// Custom

// Styling & Images
import { theme } from "Globals/Styles/AppTheme";
import { makeStyles } from "@material-ui/core";
import { formatCurrencyForGrid, formatCurrencyNoSign, formatVariationNumber } from "Utils/Format";
import { RowDash } from "Views/Project/CustomComponents";
import { ToggleButton } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { IEReportVariationCategoryDTO, IEReportVariationDTO, IEReportVariationGridDTO, IEReportVariationItemDTO, IEReportVariationSubCategoryDTO } from "./IEReportModelBase";
import { VRCSummaryGridView } from "../VRCReport/IEVRCReport.styles";

interface ITableProps {
    reportViewModel: IEReportVariationGridDTO;
}

export const IEVariationReportGrid: React.FunctionComponent<ITableProps> = (props) => {
    const { reportViewModel } = props;

    const [currentActiveVarIndex, setCurrentActiveVarIndex] = useState(-1);
    const [currentActiveCatIndex, setCurrentActiveCatIndex] = useState(-1);
    const [currentActiveSubCatIndex, setCurrentActiveSubCatIndex] = useState(-1);
    const [currentActiveDescIndex, setCurrentActiveDescIndex] = useState(-1);

    useEffect(() => {}, []);

    const setActiveDescription = (val: number): void => {
        setCurrentActiveDescIndex(val);
    };

    const setActiveSubCategory = (val: number): void => {
        setCurrentActiveSubCatIndex(val);
    };

    const setActiveCategory = (val: number): void => {
        setCurrentActiveCatIndex(val);
    };

    const setActiveVariation = (val: number): void => {
        setCurrentActiveVarIndex(val);
    };

    const classes = useStyles();

    const handleSubCategoryEnable = (variationIndex: number, categoryIndex: number, index: number) => {
        if (variationIndex !== -1) {
            setActiveVariation(variationIndex);
        }
        if (categoryIndex !== -1) {
            setActiveCategory(categoryIndex);
        }
        setActiveSubCategory(index);
        setActiveDescription(-1);
    };

    const handleCategoryEnable = (variationIndex: number, index: number) => {
        if (variationIndex !== -1) {
            setActiveVariation(variationIndex);
        }
        setActiveCategory(index);
        setActiveSubCategory(-1);
        setActiveDescription(-1);
    };

    const handleVariationEnable = (index: number) => {
        setActiveVariation(index);
        setActiveCategory(-1);
        setActiveSubCategory(-1);
        setActiveDescription(-1);
    };

    const itemView = (obj: IEReportVariationItemDTO[], variationIndex: number, categoryIndex: number, subCategoryIndex: number) => {
        return (
            <>
                {obj.map((i: IEReportVariationItemDTO, index: number) => (
                    <>
                        <li className="item" key={"item_" + index}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div
                                    style={{
                                        color: `${
                                            currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === subCategoryIndex && currentActiveDescIndex === index
                                                ? "#e03c31"
                                                : ""
                                        }`,
                                        paddingLeft: "35px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                                    <span className="ie-row-name ie-item-name" title={i.descriptionName}>
                                        {i.descriptionName}
                                    </span>
                                </div>
                            </div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                            <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                            <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                            <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                            <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                            <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                        </li>
                    </>
                ))}
            </>
        );
    };

    const subcategoryView = (obj: IEReportVariationSubCategoryDTO[], variationIndex: number, categoryIndex: number) => {
        return (
            <>
                {obj.map((i: IEReportVariationSubCategoryDTO, index: number) => (
                    <>
                        <div
                            className={
                                currentActiveSubCatIndex === index && currentActiveCatIndex === categoryIndex && currentActiveVarIndex === variationIndex
                                    ? classes.SubCatBorder
                                    : ""
                            }
                        >
                            <li className={"subcategory"} key={"subs_" + index}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div
                                        style={{
                                            color: `${
                                                currentActiveVarIndex === variationIndex && currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === index
                                                    ? "#e03c31"
                                                    : ""
                                            }`,
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                                        <span className="ie-row-name ie-subcat-name" title={i.subCategoryName}>
                                            {i.subCategoryName}
                                        </span>
                                    </div>
                                    {(currentActiveVarIndex === variationIndex && currentActiveCatIndex) === categoryIndex && currentActiveSubCatIndex === index ? (
                                        <ToggleButton>
                                            <span
                                                className={"open"}
                                                onClick={() => {
                                                    handleSubCategoryEnable(-1, -1, -1);
                                                }}
                                            >
                                                &minus;
                                            </span>
                                        </ToggleButton>
                                    ) : (
                                        <ToggleButton>
                                            <span
                                                className={"close"}
                                                onClick={() => {
                                                    handleSubCategoryEnable(variationIndex, categoryIndex, index);
                                                }}
                                            >
                                                &#43;
                                            </span>
                                        </ToggleButton>
                                    )}
                                </div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                                <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                                <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                                <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                                <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                            </li>
                            {currentActiveSubCatIndex === index && currentActiveCatIndex === categoryIndex && currentActiveVarIndex === variationIndex && (
                                <>{itemView(i.items, variationIndex, categoryIndex, index)}</>
                            )}
                        </div>
                    </>
                ))}
            </>
        );
    };

    const categoryView = (obj: IEReportVariationCategoryDTO[], variationIndex: number) => {
        return (
            <>
                {obj.map((i: IEReportVariationCategoryDTO, index: number) => (
                    <>
                        <div className={currentActiveCatIndex === index && currentActiveVarIndex === variationIndex ? classes.CatBorder : ""}>
                            <li className={"subcategory"} key={"subs_" + index}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div
                                        style={{
                                            color: `${currentActiveVarIndex === variationIndex && currentActiveCatIndex === index ? "#e03c31" : ""}`,
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                                        <span className="ie-row-name ie-subcat-name" title={i.categoryName}>
                                            {i.categoryName}
                                        </span>
                                    </div>
                                    {currentActiveVarIndex === variationIndex && currentActiveCatIndex === index ? (
                                        <ToggleButton>
                                            <span
                                                className={"open"}
                                                onClick={() => {
                                                    handleCategoryEnable(-1, -1);
                                                }}
                                            >
                                                &minus;
                                            </span>
                                        </ToggleButton>
                                    ) : (
                                        <ToggleButton>
                                            <span
                                                className={"close"}
                                                onClick={() => {
                                                    handleCategoryEnable(variationIndex, index);
                                                }}
                                            >
                                                &#43;
                                            </span>
                                        </ToggleButton>
                                    )}
                                </div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                                <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                                <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                                <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                                <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                            </li>
                            {currentActiveCatIndex === index && currentActiveVarIndex === variationIndex && <>{subcategoryView(i.subCategories, variationIndex, index)}</>}
                        </div>
                    </>
                ))}
            </>
        );
    };

    return useObserver(() => (
        <>
            <VRCSummaryGridView className={classes.IELineGroup}>
                {reportViewModel?.variations.map((i: IEReportVariationDTO, index: number) => (
                    <>
                        <div className={currentActiveVarIndex === index ? classes.VarBorder : ""}>
                            <li className={"ie"} key={"cat_" + index}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ color: `${currentActiveVarIndex === index ? "#e03c31" : ""}`, paddingLeft: "5px" }}>
                                        {formatVariationNumber(i.variationNumber)}
                                    </span>
                                    {currentActiveVarIndex === index ? (
                                        <ToggleButton>
                                            <span
                                                className={"open"}
                                                onClick={() => {
                                                    handleVariationEnable(-1);
                                                }}
                                            >
                                                &minus;
                                            </span>
                                        </ToggleButton>
                                    ) : (
                                        <ToggleButton>
                                            <span
                                                className={"opcloseen"}
                                                onClick={() => {
                                                    handleVariationEnable(index);
                                                }}
                                            >
                                                &#43;
                                            </span>
                                        </ToggleButton>
                                    )}
                                </div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.current))}</div>
                                <div className={`right ${i.column1.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column1.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.current))}</div>
                                <div className={`right ${i.column2.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column2.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.current))}</div>
                                <div className={`right ${i.column3.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column3.movement))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.previous))}</div>
                                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.current))}</div>
                                <div className={`right ${i.column4.creditOrDebitClassName}`}>{formatCurrencyForGrid(formatCurrencyNoSign(i.column4.movement))}</div>
                            </li>
                            {currentActiveVarIndex === index && <>{categoryView(i.categories, index)}</>}
                        </div>
                    </>
                ))}
                <li className="ie">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ paddingLeft: "5px" }}>{"Totals"}</span>
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column1.previous ? reportViewModel?.column1.previous : 0.0))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column1.current ? reportViewModel?.column1.current : 0.0))}</div>
                    <div className={`right ${reportViewModel.column1.creditOrDebitClassName}`}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column1.movement ? reportViewModel?.column1.movement : 0.0))}
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column2.previous ? reportViewModel?.column2.previous : 0.0))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column2.current ? reportViewModel?.column2.current : 0.0))}</div>
                    <div className={`right ${reportViewModel.column2.creditOrDebitClassName}`}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column2.movement ? reportViewModel?.column2.movement : 0.0))}
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column3.previous ? reportViewModel?.column3.previous : 0.0))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column3.current ? reportViewModel?.column3.current : 0.0))}</div>
                    <div className={`right ${reportViewModel.column3.creditOrDebitClassName}`}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column3.movement ? reportViewModel?.column3.movement : 0.0))}
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column4.previous ? reportViewModel?.column4.previous : 0.0))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column4.current ? reportViewModel?.column4.current : 0.0))}</div>
                    <div className={`right ${reportViewModel.column4.creditOrDebitClassName}`}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(reportViewModel?.column4.movement ? reportViewModel?.column4.movement : 0.0))}
                    </div>
                </li>
            </VRCSummaryGridView>
        </>
    ));
};

const useStyles = makeStyles({
    IELineGroup: {
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    VarBorder: {
        borderTop: `4px solid ${theme.palette.detail.main}`,
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    CatBorder: {
        borderTop: `3px solid ${theme.palette.detail.main}`,
        borderBottom: `3px solid ${theme.palette.detail.main}`,
    },
    SubCatBorder: {
        borderTop: `2px solid ${theme.palette.detail.main}`,
        borderBottom: `2px solid ${theme.palette.detail.main}`,
    },
    ItemBorder: {
        borderTop: `1px solid ${theme.palette.detail.main}`,
        borderBottom: `1px solid ${theme.palette.detail.main}`,
    },
});

export const IESubItem = styled.div`
    > li {
        > div:nth-child(1) {
            margin-left: 50px;
        }
    }
`;
